<template>
  <b-modal
    :title="name"
    autoplay
    class="stream-video"
    hide-footer
    ref="modal"
    size="lg"
    @hide="onHideModal"
  >
    <div class="p-6">
      <StreamVideo
        autoplay
        :background="background"
        :device-id="deviceId"
        :device-type="deviceType"
        ref="StreamVideo"
      ></StreamVideo>
    </div>
  </b-modal>
</template>

<script>
import StreamVideo from '@/components/intercom/StreamVideo.vue';

export default {
  components: {
    StreamVideo,
  },
  props: {
    deviceId: {
      required: true,
    },
    deviceType: {
      type: String,
      required: true,
    },
    name: {
      type: String,
    },
    background: {
      type: String,
    },
  },
  methods: {
    async show() {
      this.$refs.modal.show();
      await this.$nextTick();
      this.$refs.StreamVideo.play();
    },
    onHideModal() {
      this.$refs.StreamVideo.stop();
    },
  },
};
</script>
<style lang="scss">
.video-wrapper {
  min-height: 300px;
}
</style>
