<template>
  <vue2-date-picker
    v-bind="$attrs"
    :editable="false"
    :format="format"
    :lang="lang"
    :value="value"
    @input="onChange"
    ref="datePicker"
  >
    <template v-slot:input>
      <BInputLabel
        :label="label"
        :value="inputValue"
        :is-invalid="isInvalid"
        readonly
      />
    </template>
  </vue2-date-picker>
</template>

<script>
import Vue2DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/locale/ru';
import dayjs from 'dayjs';
import BInputLabel from '@/components/base/BInputLabel';
import { getLocale } from '@/helpers';

Vue2DatePicker.locale(getLocale())

export default {
  name: 'DatePicker',
  props: {
    value: {},
    label: {
      type: String,
    },
    isInvalid: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Vue2DatePicker,
    BInputLabel,
  },
  computed: {
    // notBefore() {
    //   if (this.schema.options && typeof this.schema.options.notBefore !== 'undefined') {
    //     const { notBefore: param } = this.schema.options;
    //     const regex = /([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/gm;
    //     const check = param.toString().match(regex);
    //     if (check) {
    //       return dayjs(param).format('YYYY-MM-DD');
    //     }
    //     return dayjs()
    //       .add(param, 'd')
    //       .format('YYYY-MM-DD');
    //   }
    //   return false;
    // },
    lang() {
      return {
        defaultLocale: 'ru',
        firstDayOfWeek: 1,
      };
    },
    inputValue() {
      if (this.value) {
        if (Array.isArray(this.value)) {
          return this.value
            .map((val) => this.format.stringify(val))
            .join(' - ');
        }
        return this.format.stringify(this.value);
      }
      return '';
    },
  },
  data() {
    return {
      validates: true,
      format: {
        stringify: (val) => (val ? dayjs(val).format('LL') : null),
        parse: (val) => (val ? dayjs(val).toISOString() : null),
      },
      // formatType: {
      //   date2value: date => dayjs(date).toISOString(),
      // },
    };
  },
  methods: {
    onChange(val) {
      this.$emit('input', val);
    },
  },
};
</script>
