<template>
  <b-modal :title="$t('devices.modal.create')" ref="errorCreate" no-stacking>
    <div class="modal-body-row with-cols text-center p-8">
      <div class="d-flex gap-2 align-items-center justify-content-center mb-3">
        <i
          class="icon-local-attention intercom-final__icon intercom-final__icon_attention"
        />
        <p class="intercom-final__text mb-0">
          {{ $t('devices.modal.added.error.title') }}
        </p>
      </div>
      <p class="intercom-final__text">
        {{ $t('devices.modal.added.error.text') }}
        <a href="#">
          {{ $t('devices.modal.added.error.link') }}
        </a>
      </p>
    </div>
    <template #modal-footer>
      <div
        class="d-flex align-items-center justify-content-between flex-grow-1"
      >
        <b-button
          size="sm"
          variant="outline-primary"
          class="intercom-final__btn w-auto h-auto"
          @click="hide"
        >
          {{ $t('devices.modal.cancel') }}
        </b-button>
        <b-button
          variant="primary"
          size="sm"
          class="intercom-final__btn w-auto h-auto"
          @click="openUuidSelectModal"
        >
          {{ $t('devices.modal.reset') }}
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import EventBus from '@/packs/EventBus';
import { EVENTS } from '@/consts';

export default {
  name: 'ErrorCreateIntercom',
  props: {
    clientId: {
      type: [String, Number],
      required: true,
    },
  },
  methods: {
    hide() {
      this.$refs.errorCreate.hide();
    },
    show() {
      this.$refs.errorCreate.show();
    },
    openUuidSelectModal() {
      this.hide();
      EventBus.$emit(EVENTS.intercom.fast.modal.show);
    },
  },
};
</script>

<style lang="sass">
@import 'app/javascript/assets/styles/components/variables';

.intercom-final {
  &__btn {
    min-height: 0 !important;
    padding: 8px 30px;
  }

  &__hint {
    color: $color-default-blue;
    font-size: 12px;
  }

  &__text {
    font-size: 18px;
  }

  &__icon {
    font-size: 25px;
    &_ok {
      color: $color-default-green;
    }
    &_attention {
      color: #FFDD00;
    }
  }
}
</style>
