<script>
import { BFormGroupSlot } from '@/components/base';
import FormPermissionsBuildings from '@/components/forms/FormPermissionsBuildings.vue';
import SelectSlot from '@/vue_slim/components/base/SelectSlot';

export default {
  name: 'PermissionAdminForm',
  components: {
    BFormGroupSlot,
    FormPermissionsBuildings,
    SelectSlot,
  },
  props: {
    buildingsPermissions: {
      type: Array,
    },
    districtsPermissions: {
      type: Array,
    },
    permissions: {
      type: Array,
    },
    permissionData: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      buildings: [],
      districts: [],
      permission: this.permissionData,
    };
  },
  computed: {
    buildingsInputs() {
      return this.buildings.map((building, index) => {
        return Object.entries(building).map(([key, value]) => ({
          name: `permission[buildings_permissions_attributes][${index}][${key}]`,
          value: value,
        }));
      });
    },
    districtsInputs() {
      return this.districts.map((district, index) => {
        return Object.entries(district).map(([key, value]) => ({
          name: `permission[districts_permissions_attributes][${index}][${key}]`,
          value: value,
        }));
      });
    },
    formattedBuildingsPermissions() {
      return this.buildingsPermissions.map((permission) => ({
        building_id: permission.building.id,
        building_name: permission.building.short_name,
        id: permission.id,
      }));
    },
    formattedDistrictsPermissions() {
      return this.districtsPermissions.map((permission) => ({
        district_id: permission.district.id,
        district_name: permission.district.name,
        id: permission.id,
      }));
    },
    permissionInputName() {
      return `permission[permission_type]`;
    },
    permissionsItems() {
      return this.permissions.map(([name, permission]) => ({
        name,
        permission,
      }));
    },
  },
  methods: {
    onInputSetBuildings(permissions) {
      Object.assign(this.$data, permissions);
    },
  },
};
</script>
