<template>
  <b-modal
    ref="modal"
    size="lg"
    @ok="onOk"
    :title="$t('intercom.relay.edit_permission_title')"
  >
    <div class="modal-body-row with-cols">
      <div v-for="(permission, index) in items" :key="index">
        <IntercomRelayPermissionForm
          :is-show-add-button="index + 1 === items.length"
          :value="permission"
          ref="IntercomRelayPermissionForm"
          @input="(value) => onInputPermission(permission, value)"
          @add-item="onAddPermission"
          @delete-item="onDeleteItem(index)"
        ></IntercomRelayPermissionForm>
      </div>
    </div>
    <div class="modal-body-row with-cols" v-if="!items.length">
      <b-form-row>
        <b-col>
          <b-button variant="info" @click="onAddPermission">
            {{ $t('intercom.relay.add_rule') }}
          </b-button>
        </b-col>
      </b-form-row>
    </div>
  </b-modal>
</template>

<script>
import IntercomRelayPermissionForm from '@/components/intercom/IntercomRelayPermissionForm.vue';
import { cloneDeep } from 'lodash';

export default {
  name: 'IntercomRelayPermissions',
  components: {
    IntercomRelayPermissionForm,
  },
  data() {
    return {
      relayId: 5,
      permissions: [],
    };
  },
  computed: {
    items() {
      return this.permissions.filter((permission) => !permission._destroy);
    },
  },
  methods: {
    show(data = [], relayId) {
      this.permissions = cloneDeep(data);
      this.relayId = relayId;
      this.$refs.modal.show();
    },
    onAddPermission() {
      this.permissions.push({
        geo_unit_id: null,
        geo_unit_type: null,
        relay_id: this.relayId,
        range: null,
        _destroy: false,
      });
    },
    onDeleteItem(index) {
      const permission = this.items[index];
      if (permission.id) {
        this.$set(permission, '_destroy', true);
      } else {
        this.permissions.splice(index, 1);
      }
    },
    onInputPermission(permission, newItem) {
      Object.assign(permission, newItem);
    },
    onOk(event) {
      const validation = this.$refs.IntercomRelayPermissionForm.every((form) =>
        form.validate(),
      );
      if (validation) {
        this.$emit('success', this.permissions, this.relayId);
      } else {
        event.preventDefault();
      }
    },
  },
};
</script>
