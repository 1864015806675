const INTERCOM_TYPES = {
  callPanel: 'Intercom::CallPanel',
  accessControl: 'Intercom::AccessControl',
};

export const INTERCOM_COLORS = {
  lastRequest: {
    no_sync: 'text-danger',
    Processing: 'text-warning',
    Success: 'text-success',
    Canceled: 'text-danger',
    Error: 'text-danger',
  },
  status: {
    online: 'text-success',
    offline: 'text-danger',
  },
};

export default INTERCOM_TYPES;
