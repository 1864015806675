<template>
  <div class="list-manage-item">
    <slot></slot>
    <b-button
      variant="outline-danger"
      class="btn-square btn-trash"
      @click="onDeleteItem()"
    >
      <i class="icon-local-trash" />
    </b-button>
    <b-button
      v-if="isShowAddButton"
      variant="outline-primary"
      class="btn-square btn-add"
      :disabled="isDisabledAddBtn"
      @click="onAddItem()"
    >
      <i class="icon-local-plus" />
    </b-button>
    <div v-else class="empty-box"></div>
  </div>
</template>

<script>
export default {
  name: 'ListManageItem',
  props: {
    isDisabledAddBtn: {
      type: Boolean,
    },
    isShowAddButton: {},
  },
  methods: {
    onAddItem() {
      this.$emit('add-item');
    },
    onDeleteItem() {
      this.$emit('delete-item');
    },
  },
};
</script>

<style lang="scss">
@import 'app/javascript/assets/styles/components/variables';

.list-manage-item {
  display: flex;
  flex-shrink: 0;

  .btn-trash {
    color: $gray-700;
    &:hover {
      color: $white;
    }
  }
  .btn-add {
    &:not([disabled]) {
      border-color: $blue;
    }
  }
  .empty-box {
    height: 50px;
    width: 50px;
    margin-left: 10px;
    flex-shrink: 0;
  }
}
</style>
