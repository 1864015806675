<template>
  <div>
    <b-modal
      id="user-role-create-modal"
      ref="modal"
      centered
      modal-class="modal-with-b-form-row"
      :ok-title="$t('button.save')"
      :title="$t('role.edit.title')"
      @ok="onOk"
    >
      <div class="modal-body-row with-cols">
        <b-form-row>
          <BInputLabel
            :value="permissionName"
            :label="$t('permissions.title')"
            class="w-100"
            :custom-display="permissionName"
            disabled
          />
        </b-form-row>
      </div>
      <div class="modal-body-row">
        <FormPermissionsBuildings
          ref="FormPermissionsBuildings"
          :buildings-permissions="buildingPermissions"
          :districts-permissions="districtsPermissions"
        />
      </div>
    </b-modal>
  </div>
</template>

<script>
import { ApiPermissions } from '@/api';
import FormPermissionsBuildings from '@/components/forms/FormPermissionsBuildings.vue';
import { pick } from 'lodash';

export default {
  name: 'UserModalPermissionEdit',
  components: { FormPermissionsBuildings },
  data() {
    return {
      buildingPermissions: null,
      districtsPermissions: null,
      model: {},
    };
  },
  computed: {
    permissionName() {
      return this.model.type && this.$t(`permissions.${this.model.type}`);
    },
  },
  methods: {
    async onOk(event) {
      event.preventDefault();
      const permissions = this.$refs.FormPermissionsBuildings.getPermissions();
      const emptyBuilding = permissions.buildings.filter(x => x.building_id === null)
      const emptyDistrict = permissions.districts.filter(x => x.district_id === null)
      if (emptyBuilding.length > 0 || emptyDistrict.length > 0) {
        this.$bvToast.toast("Заполните недостающие поля!");
      } else {
        console.log('permissions', permissions);
        ApiPermissions.update(this.model.id, {
          buildings_permissions_attributes: permissions.buildings,
          districts_permissions_attributes: permissions.districts,
        })
          .then(() => {
            this.$emit('success');
            this.$refs.modal.hide();
          })
          .catch((err) => {
            this.$bvToast.toast(err);
          });
      }
    },
    show(permission) {
      this.model = pick(permission, ['id', 'type']);
      this.buildingPermissions = permission.buildings_permissions;
      this.districtsPermissions = permission.districts_permissions;

      if(this.buildingPermissions) {
        this.buildingPermissions = this.buildingPermissions.filter(x => x.building_id)
      }
      if(this.districtsPermissions) {
        this.districtsPermissions = this.districtsPermissions.filter(x => x.district_id)
      }

      this.$refs.modal.show();
    },
  },
};
</script>

<style lang="scss" scoped></style>
