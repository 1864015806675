<script>
import GeoUnitsAutocomplete from '@/components/autocompletes/geoUnitsAutocomplete';
import BFormGroupSlot from '@/components/base/BFormGroupSlot.vue';
import BFormPhoneSlot from '@/components/base/BFormPhoneSlot.vue';
import BFormEmailSlot from '@/components/base/BFormEmailSlot.vue';
import keysAutocomplete from '@/components/autocompletes/keysAutocomplete';
import EventBus from '@/packs/EventBus';

export default {
  name: 'ManageAccountForm',
  components: {
    GeoUnitsAutocomplete,
    keysAutocomplete,
    BFormGroupSlot,
    BFormPhoneSlot,
    BFormEmailSlot,
  },
  props: {
    geoBlocksData: Array,
    errors: Object,
  },
  data() {
    return {
      disabledSubmit: false,
      geo_blocks: this.geoBlocksData,
      oldAddressId: null,
      user: this.userData,
    };
  },
  computed: {
    isDisabledSubmit() {
      const isHaveInvalidState = this.geo_blocks.some(
        (geoUnit) => geoUnit.state === false,
      );
      return this.disabledSubmit || isHaveInvalidState;
    },
  },
  mounted() {
    if (this.errors.geo_units) {
      this.$bvToast.toast(this.$t('account.cannot_add_acc_without_property'));
    }
    EventBus.$on('errorUpdate', (val) => {
      this.disabledSubmit = val;
    });
  },
  methods: {
    addKey(blockId) {
      this.geo_blocks
        .find((b) => b.id == blockId)
        .keys.push({ id: 0, code: '' });
    },
    addBlock() {
      let id =
        (Number(
          this.geo_blocks
            .map((b) => b.id)
            .sort()
            .reverse()[0],
        ) || 0) + 1;
      this.geo_blocks.push({ id: id, keys: [{ id: 0 }], geo_unit: {} });
    },
    removeBlock(i) {
      this.geo_blocks.splice(i);
    },
    getKeyByBlock(i, blockId) {
      return this.geo_blocks.find((b) => b.id).keys[i];
    },
    removeKey(i, blockId) {
      this.geo_blocks.find((b) => b.id == blockId).keys.splice(i);
    },
    addGeoUnit(blockId) {
      this.geo_blocks.find((b) => b.id == blockId).geo_units.push({ id: 0 });
    },
    geoUnitHiddenFieldName(blockId) {
      return `geo_units[${blockId}][id]`;
    },
    keyHiddenFieldName(blockId) {
      return `keys[${blockId}][id]`;
    },
    keyFormId(i, attr) {
      return `user_${this.$props.type}_keys_attributes_${i}_${attr}`;
    },
    keyInputName(blockId, i) {
      return `user[blocks][${blockId}][keys][${i}]`;
    },
    geoUnitInputName(blockId, i) {
      return `user[blocks][${blockId}][geo_units][${i}]`;
    },
    getKeyError(i, attr) {
      let errrosArr = this.errors[`keys[${i}].${attr}`];
      if (errrosArr) {
        return errrosArr.join(', ');
      }
    },
    onUpdateUnit(geoUnit, index) {
      const state = !geoUnit.has_owner;
      if (!state) {
        this.$bvToast.toast(
          this.$t('account.error_occupied property', [geoUnit.short_name]),
        );
      }
      this.$set(this.geo_blocks[index], 'state', state);
    },
  },
};
</script>
