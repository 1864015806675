<script>
import GeoUnitsAutocomplete from '@/components/autocompletes/geoUnitsAutocomplete';
import SelectSlot from '@/vue_slim/components/base/SelectSlot';
import { BFormGroupSlot } from '@/components/base';
import DatePickerSlot from '@slim/components/base/DatePickerSlot';

export default {
  name: 'IntercomModelForm',
  components: {
    GeoUnitsAutocomplete,
    BFormGroupSlot,
    SelectSlot,
    DatePickerSlot,
  },
  props: {
    meterData: Object,
    uspdDevices: Array,
    meterModels: Array,
  },
  watch: {
    'meter.uspd_device_id'(val) {
      this.setClient(val);
    },
    'meter.meter_model_id'(val) {
      this.setCurrentModel(val);
    },
  },
  computed: {
    isShowPollingProfile() {
      return (
        (this.model && this.model.tag && this.model.tag.startsWith('vzlet')) ||
        this.meter.kind == 'electro'
      );
    },
  },
  mounted() {
    this.setCurrentModel(this.meter.meter_model_id);
    this.setClient(this.meter.uspd_device_id);
  },
  data() {
    return {
      meter: this.meterData,
      clientId: null,
      model: undefined,
    };
  },
  methods: {
    setCurrentModel(id) {
      this.model = this.meterModels.find((m) => m.id == id);
    },
    setClient(id) {
      this.clientId = this.uspdDevices.find((d) => d.id == id)?.client_id;
    },
  },
};
</script>
