<template>
  <div class="main-tab">
    <b-form-row>
      <b-col cols="12" class="mb-3">
        <h3>{{ $t('devices.tabs.device.title') }}</h3>
        <BSelectLabel
          v-if="!intercom.intercom_model_id"
          id="select-intercom-modal_model_id"
          class="my-0"
          :items="intercomModels"
          object-property="id"
          :customDisplay="intercomModelName"
          :label="$t('devices.tabs.device.model')"
          v-model="intercom.intercom_model_id"
          zero-val-active
          @select="onSelectModel"
          :state="
            validateState ? validateState('intercom_model_id') : undefined
          "
        />
        <BInputLabel
          v-else
          :value="`${intercom.model.info.vendor || ''} ${
            intercom.model.name || ''
          }`"
          :label="$t('devices.tabs.device.model')"
          :state="
            validateState ? validateState('intercom_model_id') : undefined
          "
          class="my-0"
          disabled
        />
        <BError
          v-if="!errors.intercom_model_id.required && isSubmit"
          :error="$t('devices.errors.not_empty')"
        />
      </b-col>
    </b-form-row>
    <b-form-row>
      <b-col cols="12" class="mb-3" v-if="isRequredUuid">
        <BInputLabel
          v-model="intercom.uuid"
          :state="validateState ? validateState('uuid') : undefined"
          label="UUID"
          class="my-0"
        />
        <BError
          v-if="!errors.uuid.required && isSubmit"
          :error="$t('devices.errors.not_empty')"
        />
      </b-col>
    </b-form-row>
    <b-form-row>
      <b-col cols="12" v-if="isRequiredMacAddress" class="mb-3">
        <BInputLabel
          id="input-intercom-modal_macaddr"
          :state="validateState ? validateState('macaddr') : undefined"
          :label="$t('devices.tabs.device.mac')"
          v-model="intercom.macaddr"
          class="my-0"
        />
        <BError
          v-if="!errors.macaddr.required && isSubmit"
          :error="$t('devices.errors.not_empty')"
        />
      </b-col>
    </b-form-row>
    <b-form-row>
      <b-col cols="12" class="mb-3" v-if="isShowIpAddress">
        <BInputLabel
          :state="validateState ? validateState('ip_address') : undefined"
          :label="$t('devices.tabs.device.ip')"
          v-model="intercom.ip_address"
          class="my-0"
        />
        <BError
          v-if="!errors.ip_address.required && isSubmit"
          :error="$t('devices.errors.not_empty')"
        />
        <BError
          v-else-if="!errors.ip_address.ipAddress && isSubmit"
          :error="$t('devices.errors.formatter')"
        />
      </b-col>
    </b-form-row>
    <b-form-row>
      <b-col cols="12" class="mb-3" v-if="isRequredUuid">
        <BInputLabel
          :label="$t('devices.tabs.device.serial')"
          v-model="intercom.serial"
          :state="validateState ? validateState('serial') : undefined"
          class="my-0"
        />
        <BError
          v-if="!errors.serial.required && isSubmit"
          :error="$t('devices.errors.not_empty')"
        />
      </b-col>
    </b-form-row>
    <b-form-row
      v-if="isShowControlPanel"
      class="align-items-center main-tab__mac mb-3"
    >
      <b-col md="12">
        <IntercomMacAutocomplete
          :placeholder="$t('intercom.control_panel')"
          :clientId="parseInt(clientId)"
          :value="intercom.control_panel_id"
          :intercom-data="intercom.control_panel"
          v-model="intercom.control_panel_id"
          class="intercom-autocompletes flex-grow-1"
          hint-id="rusgard"
          popover-text="i"
          :popover-hint="$t('intercom.control_panel_hint')"
          :state="validateState ? validateState('control_panel_id') : undefined"
        />
        <BError
          v-if="!errors.control_panel_id.required && isSubmit"
          :error="$t('devices.errors.not_empty')"
        />
      </b-col>
    </b-form-row>
    <b-form-row>
      <b-col cols="12" class="mb-3">
        <h3>{{ $t('devices.tabs.device.parameters') }}</h3>
        <BInputLabel
          :label="$t('devices.tabs.device.name')"
          v-model="intercom.name"
          class="my-0"
          :state="validateState ? validateState('name') : undefined"
        />
        <BError
          v-if="!errors.name.required && isSubmit"
          :error="$t('devices.errors.not_empty')"
        />
        <BError
          v-if="!errors.name.maxLength && isSubmit"
          :error="$t('devices.errors.max_length', [70])"
        />
      </b-col>
    </b-form-row>
    <b-form-row>
      <b-col cols="12">
        <GeoUnitsAutocomplete
          v-model="intercom.geo_unit_id"
          :geo-unit="intercom.geo_unit"
          :geo-unit-deep-at="geoUnitDeepAt"
          :client-id="Number(clientId)"
          :placeholder="$t('devices.tabs.device.geo_unit')"
          geo-unit-property="short_name"
          show-loading
          class="w-100"
          section="intercom"
          popoverText="i"
          :popoverHint="$t('intercom.address_description')"
          hintId="intercom-edit"
        />
      </b-col>
    </b-form-row>
    <b-form-row v-if="isShowUsernamePassword">
      <b-col md="6" class="mb-3">
        <BInputLabel
          id="input-intercom-modal_username"
          :label="$t('devices.tabs.device.username')"
          autocomplete="off"
          v-model="intercom.settings.username"
          :state="
            validateState ? validateState('settings.username') : undefined
          "
          class="my-0"
        />
        <BError
          v-if="!errors.settings.username.required && isSubmit"
          :error="$t('devices.errors.not_empty')"
        />
      </b-col>
      <b-col md="6">
        <BInputLabel
          id="input-intercom-modal_password"
          name="intercom-password"
          :label="$t('devices.tabs.device.password')"
          autocomplete="off"
          v-model="intercom.settings.password"
          :state="
            validateState ? validateState('settings.password') : undefined
          "
          class="my-0"
        />
        <BError
          v-if="!errors.settings.password.required && isSubmit"
          :error="$t('devices.errors.not_empty')"
        />
      </b-col>
    </b-form-row>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex';
import GeoUnitsAutocomplete from '@/components/autocompletes/geoUnitsAutocomplete.vue';
import IntercomMacAutocomplete from '@/components/autocompletes/IntercomMacAutocomplete.vue';
import BError from '@/components/base/BError';

export default {
  name: 'IntercomMainSettingTab',
  props: {
    clientId: {
      type: [Number, String],
      required: true,
    },
    geoUnitDeepAt: {
      type: Array,
    },
    intercomModels: {
      type: Array,
    },
    isRequredUuid: {
      type: Boolean,
      required: true,
    },
    isRequiredIpAddress: {
      type: Boolean,
      required: true,
    },
    isRequiredMacAddress: {
      type: Boolean,
      required: true,
    },
    isShowControlPanel: {
      type: Boolean,
      required: true,
    },
    validateState: {
      type: Function,
    },
    errors: {},
    isSubmit: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    GeoUnitsAutocomplete,
    IntercomMacAutocomplete,
    BError,
  },
  computed: {
    ...mapState('intercom', ['intercom', 'intercomModel']),
    ...mapGetters('intercom', ['getIntercom', 'getIntercomModel']),
    intercom: {
      get() {
        return this.getIntercom;
      },
      set(value) {
        this.setIntercom(value);
      },
    },
    intercomModel() {
      return this.getIntercomModel;
    },
    isShowIpAddress() {
      return (
        this.isRequiredIpAddress ||
        this.intercomModel?.tags?.includes('ip_address')
      );
    },
    isShowUsernamePassword() {
      return (
        this.intercomModel?.type === 'call_panel' &&
        !(this.intercomModel?.rabbit_queue === 'access_control_queue')
      );
    },
  },
  methods: {
    ...mapMutations('intercom', ['setIntercomModel', 'setIntercom']),

    onSelectModel(val) {
      const current = this.intercomModels.find((model) => model.id == val);
      this.setIntercomModel(current);
    },
    intercomModelName(model) {
      return `${model?.info?.vendor || ''} ${model?.name || ''}`;
    },
  },
};
</script>

<style lang="scss">
.main-tab {
  padding: 0 30px 30px 30px;

  &__mac {
    position: relative;
  }

  &__popover {
    position: absolute;
    top: 14px;
    right: 11px;
    z-index: 1;
    width: 20px;
    height: 20px;
    border: 2px solid #99adc0;
    border-radius: 50%;
    text-align: center;
    margin-left: 10px;
    color: #99adc0;
    font-size: 12px;
    font-weight: 700;
    cursor: pointer;
    justify-content: center;
  }
}
</style>
