var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"b-input-with-search"},[_c('b-form-input',{staticClass:"b-input-with-search-input",class:{ 'b-input-with-search-input-unround-corners': _vm.model.request },attrs:{"type":"text","state":_vm.state,"id":"b-input-with-search-input","disabled":_vm.isDisabled},on:{"input":_vm.handleSearch,"focus":function($event){_vm.isActive = true}},model:{value:(_vm.model.request),callback:function ($$v) {_vm.$set(_vm.model, "request", $$v)},expression:"model.request"}}),_vm._v(" "),_c('label',{staticClass:"b-input-with-search-label",class:{ 'b-input-with-search-label-fixed-to-top': _vm.model.request }},[_vm._v("\n    "+_vm._s(_vm.label)+"\n  ")]),_vm._v(" "),(_vm.withIcon)?_c('i',{staticClass:"icon-local-search"}):_vm._e(),_vm._v(" "),(_vm.loading)?_c('div',{staticClass:"b-input-with-search-result-list"},[_c('div',{staticClass:"b-input-with-search-result-list-item d-flex align-items-center justify-content-center"},[_c('b-spinner')],1)]):(_vm.showOptions)?_c('ul',{staticClass:"b-input-with-search-result-list"},[_vm._l((_vm.items),function(item){return _c('li',{key:item.id,staticClass:"b-input-with-search-result-list-item",on:{"click":function($event){return _vm.selectItem(item)}}},_vm._l((_vm.formatResult),function(position,key){return _c('div',{key:key,class:`b-input-with-search-result-list-item-${key}`},_vm._l((position),function({ title, formatter },idx){return _c('p',{key:idx,class:`b-input-with-search-result-list-item-${key}-text`,domProps:{"innerHTML":_vm._s(
            _vm.getOverlapText(
              typeof formatter === 'function'
                ? formatter(item[title], item)
                : item[title],
            )
          )}})}),0)}),0)}),_vm._v(" "),((!_vm.items && !_vm.loading) || _vm.items.length == 0)?_c('li',{staticClass:"b-input-with-search-result-list-item"},[_c('div',{staticClass:"b-input-with-search-result-list-item-middle"},[_c('p',{staticClass:"b-input-with-search-result-list-item-middle-text"},[_vm._v("\n          "+_vm._s(_vm.emptyResultTitle)+"\n        ")])])]):_vm._e()],2):_vm._e(),_vm._v(" "),(!_vm.disabled && !_vm.loading)?_c('i',{staticClass:"icon-local-times undo-disable-input",on:{"click":_vm.clear}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }