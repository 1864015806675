<script>
import GeoUnitsAutocomplete from '@/components/autocompletes/geoUnitsAutocomplete';
import CamerasModelsAutocomplete from '@/components/autocompletes/CamerasModelsAutocomplete';
import BFormGroupSlot from '@/components/base/BFormGroupSlot';
import { isEmpty } from 'lodash';
import { API_URLS } from '@/consts';
import { request3 } from '@/api/request';

export default {
  name: 'CctvCamerasForm',
  components: {
    GeoUnitsAutocomplete,
    CamerasModelsAutocomplete,
    BFormGroupSlot,
  },
  props: {
    deviceData: Object,
    clientIdData: Number,
    errors: Object,
    modelData: {},
  },
  data() {
    return {
      device: this.deviceData,
      clientId: this.clientIdData,
      model: null,
    };
  },
  async mounted() {
    if (!isEmpty(this.modelData))
      this.model = await request3
        .get(API_URLS.backendManage.camerasModels.fetch(this.clientId), {
          params: {
            q: {
              id_eq: this.modelData.id,
            },
          },
        })
        .then((res) => res.data[0]);
  },
  computed: {
    mqttSupported() {
      if (!this.model) {
        return false;
      } else {
        return Boolean(this.model.mqtt_supported);
      }
    },
    channelIdRequired() {
      if (!this.model) {
        return false;
      } else {
        return Boolean(this.model.channel_id_required);
      }
    },
    cameraModelError() {
      if (isEmpty(this.errors)) {
        return null;
      } else {
        if (this.errors.cameras_model_id) {
          return !this.errors.cameras_model_id.length > 0;
        } else return null;
      }
    },
  },
  methods: {
    selectModel(model) {
      this.model = model;
    },
    clearModel() {
      this.model = null;
    },
  },
};
</script>
