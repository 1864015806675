<template>
  <b-modal ref="errorUuid" :title="$t('devices.modal.create')" no-stacking>
    <div class="modal-body-row with-cols pt-30">
      <b-col cols="12">
        <BInputLabel
          :label="$t('devices.modal.model')"
          v-model="intercom.name"
          disabled
        />
      </b-col>
      <b-col cols="12">
        <BInputLabel label="UUID" v-model="intercom.uuid" disabled />
      </b-col>
      <b-col cols="12">
        <BInputLabel
          :label="$t('devices.modal.serial')"
          v-model="intercom.serial"
          disabled
        />
        <span class="text-danger small" v-for="error in errors" :key="error">
          {{ error }}
        </span>
      </b-col>
    </div>
    <template #modal-footer>
      <div
        class="d-flex align-items-center justify-content-between flex-grow-1"
      >
        <b-button
          @click="openUuidSelect"
          size="sm"
          variant="outline-primary"
          class="intercom-fast-modal__btn w-auto h-auto"
        >
          {{ $t('devices.modal.back') }}
        </b-button>
        <b-button
          variant="primary"
          size="sm"
          class="intercom-fast-modal__btn w-auto h-auto"
          disabled
        >
          {{ $t('devices.modal.next') }}
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex';
import { EVENTS } from '@/consts';
import EventBus from '@/packs/EventBus';

export default {
  name: 'ErrorUuidModal',
  props: {
    clientId: {
      type: [String, Number],
      required: true,
    },
  },
  data() {
    return {
      errors: [],
    };
  },
  computed: {
    ...mapState('intercom', ['intercom']),
    ...mapGetters('intercom', ['getIntercom']),

    intercom: {
      get() {
        return this.getIntercom;
      },
      set(value) {
        this.setIntercom(value);
      },
    },
  },
  methods: {
    ...mapMutations('intercom', ['setIntercom']),

    show(errors) {
      this.$refs.errorUuid.show();
      this.errors = errors;
    },
    hide() {
      this.$refs.errorUuid.hide();
    },
    openUuidSelect() {
      this.hide();
      EventBus.$emit(EVENTS.intercom.fast.modal.show);
    },
  },
};
</script>

<style></style>
