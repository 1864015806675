<template>
  <div class="geo-units-autocomplete">
    <div class="dropdown">
      <BInputLabel
        v-model="word"
        @keyup="onKeyUp"
        type="text"
        :label="placeholder"
        ref="word"
        class="autocomplete-results-dropdown"
        :state="state"
        :disabled="united_disabled"
        :block-value="blockedGeoUnitVal"
        :is-invalid="isInvalid"
        :name="name"
      />
      <input
        :value="selected_id"
        :id="`${model}_${name}`"
        type="hidden"
        :name="`${model}[${name}]`"
      />
      <div
        class="icon-container d-flex align-items-center"
        v-if="!united_disabled"
      >
        <i class="icon-local-times" v-if="!empty_word" @click="wordReset"></i>
        <BHintPopover
          v-if="hintId"
          :popover-text="popoverText"
          :popover-hint="popoverHint"
          :hintId="hintId"
        />
      </div>
      <div v-show="!empty_results" class="dropdown-menu py-0">
        <div
          v-for="result in results"
          :key="result.id"
          @click="choosed(result)"
          :data-id="result.id"
          :data-name="contextName(result)"
          class="dropdown-item"
        >
          {{ contextName(result) }}
        </div>
      </div>
      <b-spinner
        v-if="showLoading && isLoading"
        variant="primary"
        small
      ></b-spinner>
      <slot></slot>
    </div>
  </div>
</template>

<script>
import { isEmpty, isNull, debounce } from 'lodash';
import BInputLabel from '@/components/base/BInputLabel';
import BHintPopover from '@/components/base/BHintPopover';
import { BSpinner } from 'bootstrap-vue';
import { API_URLS } from '@/consts';
import { request3 } from '@/api/request';

export default {
  name: 'GeoUnitsAutocomplete',
  components: {
    BInputLabel,
    BHintPopover,
    BSpinner,
  },
  props: {
    // форма сохраняется если сделан выбор
    saveFormId: String,
    submitAck: {
      type: Boolean,
      default: false,
    },
    // id гео юнитов в контексте которых идёт поиск
    context: {
      type: Array,
      default() {
        return [];
      },
    },
    model: String,
    name: String,
    geoUnit: {
      type: Object,
    },
    clientId: {
      type: Number,
    },
    humanProperties: {
      type: Boolean,
      default: false,
    },
    placeholder: String,
    value: {
      type: Number,
    },
    state: {},
    debouncedTts: {
      type: Number,
      default: 200,
    },
    geoUnitDeepAt: Array,
    blockedGeoUnit: {
      type: Boolean,
    },
    geoUnitProperty: {
      type: String,
      default: 'short_name',
    },
    geoUnitView: {
      type: String,
    },
    searchByName: {
      type: Boolean,
      default: false,
    },
    searchTypeNotIn: {
      type: Array,
      default() {
        return [];
      },
    },
    showInContext: {
      type: Boolean,
      default: false,
    },
    contextProperty: {
      type: String,
      default: 'full_name',
    },
    additionalQueryParams: {
      type: Object,
      default() {
        return {};
      },
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isInvalid: {
      type: Boolean,
      default: false,
    },
    section: {
      type: String,
    },
    showLoading: {
      type: Boolean,
      default: false,
    },
    npr: {
      type: Boolean,
      default: false,
    },
    popoverHint: {
      type: String,
      default: null,
    },
    popoverText: {
      type: String,
      default: 'i',
    },
    hintId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      debouncedSearch: null,
      selected_id: null,
      word: '',
      results: [],
      isShowItems: false,
      isLoading: false,
    };
  },
  computed: {
    in_context() {
      return !_.isEmpty(this.context);
    },
    united_disabled() {
      return this.disabled || _.isUndefined(this.clientId);
    },
    empty_results() {
      return isEmpty(this.results);
    },
    empty_word() {
      return this.word < 1;
    },
    debounced() {
      return this.debouncedTts > 0;
    },
    blockedGeoUnitVal() {
      if (this.blockedGeoUnit) {
        return this.geoUnit && this.geoUnit[this.geoUnitProperty];
      }
      return '';
    },
  },
  mounted() {
    if (this.debounced) {
      this.initDebounce();
    }
    if (this.geoUnit instanceof Object) {
      this.word = this.computedWord(this.geoUnit);
      this.selected_id = this.geoUnit.id;
    }
  },
  methods: {
    computedWord(geo_unit) {
      return this.contextName(geo_unit);
    },
    contextName(result) {
      return this.showInContext
        ? result[this.contextProperty]
        : result[this.geoUnitProperty] || '';
    },
    choosed(geoUnit) {
      this.word = this.computedWord(geoUnit);
      this.selected_id = geoUnit.id;
      this.$emit('input', geoUnit.id);
      this.$emit('update:geoUnit', geoUnit);
      this.$emit('update-unit', geoUnit);
      this.resultsReset();
      this.updateUrl(geoUnit.id);
      this.saveForm();
    },
    onKeyUp() {
      if (this.debounced) {
        this.debouncedSearch();
      } else {
        this.search();
      }
    },
    resultsOpen() {
      $('.autocomplete-results-dropdown').dropdown('show');
      this.$refs.word.focus();
    },
    resultsClose() {
      $('.autocomplete-results-dropdown').dropdown('hide');
      this.$refs.word.focus();
    },
    resultsReset() {
      this.results = [];
    },
    updateUrl(geo_unit_id) {
      if (_.isUndefined(this.updateUrlParam)) {
        return;
      }

      let q = new URLSearchParams(window.location.search);
      q.set(this.updateUrlParam, geo_unit_id);
      window.location.search = q.toString();
    },
    saveForm() {
      if (_.isUndefined(this.saveFormId)) {
        return;
      }

      const vm = this;
      setTimeout(function () {
        if (document.getElementById('submit_ack')) {
          document.getElementById('submit_ack').value = vm.submitAck;
        }
        $(`#${vm.saveFormId}`).submit();
      }, 100);
    },
    wordReset() {
      this.word = '';
      this.selected_id = null;
      this.$emit('input', null);
      this.updateUrl('');
      this.saveForm();
    },
    async search() {
      if (isEmpty(this.word)) return;
      let nameParams = {
        [`${this.geoUnitProperty}_cont_all`]: this.word,
      };
      let query = {
        ...nameParams,
        ...this.additionalQueryParams,
      };
      if (this.geoUnitDeepAt) {
        query = { ...query, type_in: this.geoUnitDeepAt };
      } else {
        if (!isEmpty(this.searchTypeNotIn)) {
          query = { ...query, type_not_in: this.searchTypeNotIn };
        }
      }
      if (this.in_context) {
        query = _.merge(query, { for_parents: this.context });
      }
      let prms = {
        section: this.section,
        q: query,
        limit: 8,
      };
      this.isLoading = true;
      if (this.npr) {
        this.results = await request3
          .get(API_URLS.backendManage.nprGeoUnits.fetch(this.clientId), {
            params: prms,
          })
          .then((r) => {
            return r.data.geo_units;
          })
          .finally(() => {
            this.isLoading = false;
          });
      } else {
        this.results = await request2(
          {
            method: 'get',
            url: `/api/backend/manage/${this.clientId}/geo_units`,
          },
          prms,
        )
          .then((r) => {
            return r.geo_units;
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
    initDebounce() {
      this.debouncedSearch = debounce(() => {
        this.search();
      }, this.debouncedTts);
    },
  },
  watch: {
    value(val) {
      if (!val) {
        this.selected_id = null;
        this.word = '';
      }
    },
    word(value) {
      if (isNull(value) || value.length < 1) {
        this.results = [];
      }
    },
    results(value) {
      if (!isEmpty(value)) {
        this.resultsOpen();
      } else {
        this.resultsClose();
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import '../../assets/styles/components/variables';
.geo-units-autocomplete {
  position: relative;
  .icon-container {
    padding: 5px;
    cursor: pointer;
    position: absolute;
    right: 13px;
    top: 13px;
  }
  fieldset {
    width: 100%;
  }
  .dropdown-menu {
    display: grid;
    overflow-x: auto;
    .dropdown-item {
      border: 1px solid #edeef0;
      height: 50px;
      cursor: pointer;
      display: flex;
      align-items: center;
      &:hover {
        background: $table-hover-bg;
      }
    }
  }
  .spinner-border {
    position: absolute;
    right: 40px;
  }
  .icon-local-times {
    color: $gray-700;
    &:hover {
      color: $red;
    }
  }
}
</style>
