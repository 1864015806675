<template>
  <div>
    <b-modal
      ref="infoCreateFast"
      :title="$t('devices.modal.create')"
      no-stacking
    >
      <b-form-validate
        ref="createFastForm"
        :model="intercom"
        :validations="validations"
      >
        <template slot-scope="{ validateState, v }">
          <div class="modal-body-row pt-30">
            <b-col cols="12" class="mb-3">
              <GeoUnitsAutocomplete
                v-model="intercom.geo_unit_id"
                :client-id="Number(clientId)"
                :placeholder="$t('devices.modal.address')"
                show-loading
                :state="validateState('geo_unit_id')"
                :geo-unit-deep-at="geoUnitDeepAt"
                section="intercom"
                class="w-100 mb-0 intercom-autocompletes"
              ></GeoUnitsAutocomplete>
              <BError
                v-if="!v.model.geo_unit_id.required && isSubmit"
                :error="$t('devices.errors.not_empty')"
              />
            </b-col>
            <b-col cols="12">
              <BInputLabel
                :label="$t('devices.modal.name')"
                :state="validateState('name')"
                v-model="intercom.name"
                class="mb-0"
              />
              <BError
                v-if="!v.model.name.required && isSubmit"
                :error="$t('devices.errors.not_empty')"
              />
              <BError
                v-if="!v.model.name.maxLength && isSubmit"
                :error="$t('devices.errors.max_length', [70])"
              />
            </b-col>
          </div>
        </template>
      </b-form-validate>
      <template #modal-footer>
        <div
          class="d-flex align-items-center justify-content-between flex-grow-1"
        >
          <b-button
            @click="openUuidSelectModal"
            size="sm"
            variant="outline-primary"
            class="intercom-fast-modal__btn w-auto h-auto"
          >
            {{ $t('devices.modal.back') }}
          </b-button>
          <b-button
            variant="primary"
            size="sm"
            class="intercom-fast-modal__btn w-auto h-auto"
            @click="next"
          >
            {{ $t('devices.modal.next') }}
          </b-button>
        </div>
      </template>
    </b-modal>
    <AccessCreateIntercom
      ref="AccessCreateIntercom"
      id="intercom-access-create"
      :clientId="clientId"
    />
    <ErrorCreateIntercom
      ref="ErrorCreateIntercom"
      id="intercom-error-create-modal"
      :clientId="clientId"
    />
    <NeedSettingIntercom
      ref="NeedSettingIntercom"
      id="intercom-need-setting-modal"
      :clientId="clientId"
    />
  </div>
</template>

<script>
import { request3 } from '@/api/request';
import { API_URLS, EVENTS, GEO_UNITS } from '@/consts';
import { maxLength, required } from 'vuelidate/lib/validators';
import { mapGetters, mapMutations, mapState } from 'vuex';
import EventBus from '@/packs/EventBus';
import NeedSettingIntercom from '../final/NeedSettingIntercom.vue';
import ErrorCreateIntercom from '../final/ErrorCreateIntercom.vue';
import AccessCreateIntercom from '../final/AccessCreateIntercom.vue';
import GeoUnitsAutocomplete from '@/components/autocompletes/geoUnitsAutocomplete.vue';
import BError from '@/components/base/BError';

export default {
  name: 'InfoCreateModal',
  components: {
    NeedSettingIntercom,
    ErrorCreateIntercom,
    GeoUnitsAutocomplete,
    AccessCreateIntercom,
    BError,
  },
  props: {
    clientId: {
      type: [String, Number],
      required: true,
    },
  },
  data() {
    return {
      isSubmit: false,
    };
  },
  computed: {
    validations() {
      return {
        name: {
          required,
          maxLength: maxLength(70),
        },
        geo_unit_id: {
          required,
        },
      };
    },
    ...mapState('intercom', ['intercom', 'intercomModel']),
    ...mapGetters('intercom', ['getIntercom', 'getIntercomModel']),
    intercom: {
      get() {
        return this.getIntercom;
      },
      set(value) {
        this.setIntercom(value);
      },
    },
    intercomModel() {
      return this.getIntercomModel;
    },
    geoUnitDeepAt() {
      const baseGeoUnits = [
        GEO_UNITS.Building,
        GEO_UNITS.Entrance,
        GEO_UNITS.Floor,
        GEO_UNITS.ParkingArea,
        GEO_UNITS.Section,
        GEO_UNITS.TechRoom,
        GEO_UNITS.Territory,
      ];
      if (this.intercomModel?.tags?.includes('one_property')) {
        return [
          GEO_UNITS.Apartment,
          GEO_UNITS.Bkfn,
          GEO_UNITS.ParkingPlace,
          GEO_UNITS.StoreRoom,
          GEO_UNITS.TechRoom,
        ];
      }
      if (this.intercomModel?.type === 'access_control') {
        return [...baseGeoUnits, GEO_UNITS.Roof];
      } else return baseGeoUnits;
    },
  },
  methods: {
    ...mapMutations('intercom', ['setIntercom']),

    show() {
      this.$refs.infoCreateFast.show();
    },
    hide() {
      this.$refs.infoCreateFast.hide();
    },
    openUuidSelectModal() {
      this.hide();
      EventBus.$emit(EVENTS.intercom.fast.modal.show);
    },
    next(event) {
      if (event) event.preventDefault();
      this.isSubmit = true;

      if (this.$refs.createFastForm.validate()) {
        this.addDevice();
      }
    },
    async addDevice() {
      this.intercom.relays[0].geo_unit_id = this.intercom.geo_unit_id;
      this.intercom.relays_attributes = this.intercom.relays;
      delete this.intercom.relays;
      request3
        .put(
          API_URLS.backendManage.devices.intercoms.update(
            this.clientId,
            this.intercom.id,
          ),
          { intercom: this.intercom },
        )
        .then((res) => {
          this.intercom = res.data;
          this.validateSetting();
        })
        .catch((error) => {
          this.showErrorCreateIntercomModal();
        });
    },
    async validateSetting() {
      request3
        .get(
          API_URLS.backendManage.devices.intercoms.validate(
            this.clientId,
            this.intercom.id,
          ),
        )
        .then((res) => {
          const validateDeviceSetting = res.data;
          this.hide();
          if (validateDeviceSetting) {
            this.showAccessCreateIntercomModal();
          } else {
            this.showNeedSettingIntercom();
          }
        })
        .catch((error) => {
          this.$bvToast.toast(error);
        });
    },
    show() {
      this.$refs.infoCreateFast.show();
      this.isSubmit = false;
    },
    hide() {
      this.$refs.infoCreateFast.hide();
    },
    showErrorCreateIntercomModal() {
      this.$refs.ErrorCreateIntercom.show();
    },
    showAccessCreateIntercomModal() {
      this.$refs.AccessCreateIntercom.show();
    },
    showNeedSettingIntercom() {
      this.$refs.NeedSettingIntercom.show();
    },
  },
};
</script>

<style lang="scss">
.intercom-create-form {
  .autocomplete-results-dropdown {
    margin-bottom: 0;
  }
}
</style>
