<template>
  <b-modal
    id="intercom-key-set-modal"
    :title="getTitle()"
    @ok="onOk"
    ref="keySetModal"
  >
    <b-form-validate
      ref="form"
      @submit="onOk"
      :validations="validations"
      :model="model"
    >
      <template slot-scope="{ validateState }">
        <div class="modal-body-row with-cols pt-30">
          <b-form-row>
            <b-col cols="12">
              <BInputLabel
                id="input-key-set-modal_name"
                :state="validateState('name')"
                :label="$t('name')"
                autocomplete="off"
                v-model="model.name"
              />
            </b-col>
          </b-form-row>
        </div>
      </template>
    </b-form-validate>
  </b-modal>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import { ApiKeySets } from '@/api';
import { EventBusMixin } from '@app/mixins';

function inidData(keySet) {
  return {
    model: {
      ...(keySet || {
        name: '',
      }),
    },
  };
}

export default {
  name: 'KeySetModal',
  mixins: [EventBusMixin],
  props: {
    clientId: {},
  },
  data() {
    return {
      model: {
        name: '',
      },
      validations: {
        name: {
          required,
        },
      },
      isCreate: true,
    };
  },
  mounted() {
    this.EventBusOn('KeySetModal::show', this.show);
  },
  methods: {
    async onOk(event) {
      if (this.$refs.form.validate()) {
        if (this.isCreate) {
          const keyset = await ApiKeySets.create({
            name: this.model.name,
            client_id: Number(this.clientId),
          });
          this.EventBusEmmit('KeySet::refresh');
          this.EventBusEmmit('KeySet::create', keyset);
        } else {
          const keyset = await ApiKeySets.update(this.model.id, {
            name: this.model.name,
            client_id: Number(this.clientId),
          });
          this.EventBusEmmit('KeySet::refresh');
          this.EventBusEmmit('KeySet::update', keyset);
        }
        this.$refs.keySetModal.hide();
      } else {
        event.preventDefault();
      }
    },
    async show(keySet) {
      this.$refs.keySetModal.show();
      Object.assign(this.$data, inidData(keySet));
      this.isCreate = !keySet;
    },
    getTitle() {
      return this.isCreate
        ? this.$t('intercom.intercom_key_set.create_key_set_title')
        : this.$t('intercom.intercom_key_set.update_key_set_title');
    },
  },
};
</script>
