<script>
import BFormGroupSlot from '@/components/base/BFormGroupSlot.vue';
import SelectSlot from '@/vue_slim/components/base/SelectSlot.vue';
import LocaleSelection from '@slim/components/base/LocaleSelection';
import i18n from '@app/i18n';

export default {
  name: 'AcceptInvitation',
  components: {
    BFormGroupSlot,
    SelectSlot,
    LocaleSelection,
  },
  computed: {
    passwordSign() {
      return this.$refs.password ? i18n.t('user.set_password') : i18n.t('user.enter_password');
    },
  },
};
</script>
