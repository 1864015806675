<script>
import GeoUnitsAutocomplete from '@/components/autocompletes/geoUnitsAutocomplete';

export default {
  name: 'IntercomForm',
  components: {
    GeoUnitsAutocomplete,
  },
  props: {
    intercomData: Object,
    relaysData: Array,
    models: Array,
    errors: Object,
    type: String,
  },
  computed: {
    relaysGeoUnitsData() {
      return this.relays.flatMap((r, ri) => {
        return r.relays_geo_units.map((rgu, rgui) => ({
          ri: ri,
          rgui: rgui,
          rgu_geo_id: rgu.geo_unit_id,
        }));
      });
    },
    parentParams() {
      return { parent_id_or_id_eq: this.intercom.geo_unit_id };
    },
    isPanelQueueFields() {
      return (
        this.currentModel &&
        this.currentModel.type == 'call_panel' &&
        !(this.currentModel.rabbit_queue == 'access_control_queue')
      );
    },
    isFaceIdModel() {
      return (
        this.currentModel?.tags.filter((v) => ['rbfaceid', 'expasoft'].includes(v)).length > 0
      );
    },
    isShowUuid() {
      return (
        !!this.currentModel &&
        (!(this.currentModel.type == 'call_panel') ||
          this.currentModel.rabbit_queue == 'access_control_queue')
      );
    },
  },
  watch: {
    'intercom.intercom_model_id'(val) {
      this.currentModel = this.models.find((m) => m.id == val);
    },
  },
  data() {
    return {
      intercom: this.intercomData,
      relays: this.relaysData.map((r) =>
        Object.assign({}, r, { _destroy: false }),
      ),
      currentModel: this.models.find(
        (m) => m.id == this.intercomData.intercom_model_id,
      ),
    };
  },
  methods: {
    checkGeoType(val) {
      let relayIndex;
      let rguIndexes;
      this.relays.forEach((relay, i) => {
        const changedGus = relay.relays_geo_units.filter(
          (rgu) => rgu.geo_unit_id === val.id,
        );
        if (changedGus) {
          relayIndex = i;
          rguIndexes = changedGus.map((gu) =>
            relay.relays_geo_units.indexOf(gu),
          );
        }
      });
      rguIndexes.forEach((rguIndex) => {
        this.$set(
          this.relays[relayIndex].relays_geo_units[rguIndex],
          'geo_unit',
          val,
        );
      });
    },
    removeRelay(i) {
      this.$set(this.relays[i], '_destroy', true);
    },
    removeRelayGu(relayIndex, relayGuIndex) {
      this.$set(
        this.relays[relayIndex].relays_geo_units[relayGuIndex],
        '_destroy',
        true,
      );
    },
    addRelay() {
      this.relays.push({
        name: '',
        index: null,
        geo_unit_id: null,
        geo_unit: {},
        _destroy: false,
      });
    },
    addRelayGu(relayIndex) {
      this.relays[relayIndex].relays_geo_units.push({
        geo_unit: {},
        geo_unit_id: null,
        _destroy: false,
      });
    },
    relayFormId(i, attr) {
      return `intercom_${this.$props.type}_relays_attributes_${i}_${attr}`;
    },
    relayInputName(i, attr) {
      return `intercom[relays_attributes][${i}][${attr}]`;
    },
    getRelayError(i, attr) {
      const errrosArr = this.errors[`relays[${i}].${attr}`];
      if (errrosArr) {
        return errrosArr.join(', ');
      }
    },
    relayGuFormId(i, attr) {
      return `intercom_${this.$props.type}_relays_geo_units_attributes_${i}_${attr}`;
    },
    relayGuInputName(ri, i, attr) {
      return `intercom[relays_attributes][${ri}][relays_geo_units_attributes][${i}][${attr}]`;
    },
    getRelayGuError(i, attr) {
      const errrosArr = this.errors[`relays_geo_units[${i}].${attr}`];
      if (errrosArr) {
        return errrosArr.join(', ');
      }
    },
  },
};
</script>
