<template>
  <b-modal
    ref="errorsIntercomFind"
    :title="$t('devices.modal.create')"
    no-stacking
  >
    <div class="modal-body-row with-cols pt-30">
      <b-form-row>
        <b-col cols="12">
          <BInputLabel
            id="select-intercom-modal_model_id"
            :value="intercomModelName"
            :label="$t('devices.modal.model')"
            disabled
          />
        </b-col>
      </b-form-row>
      <b-form-row>
        <b-col cols="12" class="mb-3" v-if="uuidRequired">
          <BInputLabel
            disabled
            :label="$t('devices.modal.uuid_or_serial')"
            v-model="intercom.uuid_or_serial"
            class="mb-0"
          />
          <span
            class="text-danger small"
            v-for="err in errors.uuid_or_serial"
            :key="err"
          >
            {{ err }}
          </span>
          <span class="text-danger small" v-for="err in errors.uuid" :key="err">
            {{ err }}
          </span>
          <span
            class="text-danger small"
            v-for="err in errors.serial"
            :key="err"
          >
            {{ err }}
          </span>
        </b-col>
      </b-form-row>
      <b-form-row>
        <b-col cols="12" class="mb-3" v-if="ipAddressRequired">
          <BInputLabel
            disabled
            :label="$t('devices.modal.ip')"
            v-model="intercom.ip_address"
            class="mb-0"
          />
          <span
            class="text-danger small"
            v-for="err in errors.ip_address"
            :key="err"
          >
            {{ err }}
          </span>
        </b-col>
      </b-form-row>
      <b-form-row>
        <b-col cols="12" class="mb-3" v-if="macAddrRequired">
          <BInputLabel
            v-model="intercom.macaddr"
            :label="$t('devices.modal.mac')"
            class="mb-0"
            disabled
          />
          <span
            class="text-danger small"
            v-for="err in errors.macaddr"
            :key="err"
          >
            {{ err }}
          </span>
        </b-col>
      </b-form-row>
      <b-form-row v-if="isShowControlPanel">
        <b-col md="12" class="align-items-center main-tab__mac mb-3">
          <IntercomMacAutocomplete
            :placeholder="$t('intercom.control_panel')"
            :clientId="parseInt(clientId)"
            :value="intercom.control_panel_id"
            :intercom-data="intercom.control_panel"
            v-model="intercom.control_panel_id"
            class="flex-grow-1 mb-0"
            disabled
            hint-id="rusgard-fast-add"
            popover-text="i"
            :popover-hint="$t('intercom.control_panel_hint')"
          />
          <span
            class="text-danger small"
            v-for="err in errors.control_panel_id"
            :key="err"
          >
            {{ err }}
          </span>
        </b-col>
      </b-form-row>
      <div v-if="isShowUsernamePassword" class="p-0">
        <b-form-row class="mb-3">
          <b-col cols="12">
            <BInputLabel
              id="input-intercom-modal_username"
              :label="$t('devices.tabs.device.username')"
              autocomplete="off"
              v-model="intercom.settings.username"
              disabled
              class="mb-0"
            />
          </b-col>
        </b-form-row>

        <b-form-row class="mb-3">
          <b-col cols="12">
            <BInputLabel
              id="input-intercom-modal_password"
              name="intercom-password"
              :label="$t('devices.tabs.device.password')"
              autocomplete="off"
              v-model="intercom.settings.password"
              disabled
              class="mb-0"
            />
          </b-col>
        </b-form-row>
      </div>
    </div>
    <template #modal-footer>
      <div
        class="d-flex align-items-center justify-content-between flex-grow-1"
      >
        <b-button
          @click="openSelectIntercomModelModal"
          size="sm"
          variant="outline-primary"
          class="intercom-fast-modal__btn w-auto h-auto"
        >
          {{ $t('devices.modal.back') }}
        </b-button>
        <b-button
          variant="primary"
          size="sm"
          class="intercom-fast-modal__btn w-auto h-auto"
          disabled
        >
          {{ $t('devices.modal.next') }}
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex';

export default {
  name: 'ErrorsIntercomFindModal',
  props: {
    clientId: {
      type: [String, Number],
      required: true,
    },
    uuidRequired: {
      type: Boolean,
      default: false,
    },
    ipAddressRequired: {
      type: Boolean,
      default: false,
    },
    macAddrRequired: {
      type: Boolean,
      default: false,
    },
    isShowControlPanel: {
      type: Boolean,
      default: false,
    },
    isShowUsernamePassword: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      errors: [],
    };
  },
  computed: {
    ...mapState('intercom', ['intercom', 'intercomModel']),
    ...mapGetters('intercom', ['getIntercom', 'getIntercomModel']),
    intercom: {
      get() {
        return this.getIntercom;
      },
      set(value) {
        this.setIntercom(value);
      },
    },
    intercomModel() {
      return this.getIntercomModel;
    },
    intercomModelName() {
      return `${this.intercomModel?.info?.vendor || ''} ${
        this.intercomModel?.name || ''
      }`;
    },
  },
  methods: {
    ...mapMutations('intercom', ['setIntercom']),

    show(errors) {
      this.$refs.errorsIntercomFind.show();
      this.errors = errors;
    },
    hide() {
      this.$refs.errorsIntercomFind.hide();
    },
    openSelectIntercomModelModal() {
      this.hide();
      this.$emit('showSelectModal');
    },
  },
};
</script>

<style></style>
