<template>
  <b-modal id="intercom-modal" ref="modal" size="lg" @ok="onOk">
    <template #modal-header="{ close }">
      <div v-if="isCreate" class="title">
        {{ $t('intercom.modal_title_create') }}
      </div>
      <div v-else class="title">{{ $t('intercom.modal_title_update') }}</div>
      <button class="close" @click="close()">×</button>
    </template>
    <b-form-row>
      <b-col cols="8">
        <b-form-validate
          @submit="onOk"
          ref="form"
          :model="model"
          :validations="validations"
        >
          <template slot-scope="{ validateState }">
            <div class="modal-body-row with-cols border-bottom border-right">
              <b-form-row>
                <b-col md="12">
                  <div class="title">{{ $t('global.type') }}</div>
                  <BSelectLabel
                    id="select-intercom-modal_type"
                    :items="intercomTypes"
                    display="label"
                    object-property="type"
                    :label="$t('intercom.type')"
                    v-model="model.type"
                    :state="validateState('intercom_model_type')"
                    :disabled="!isCreate"
                    @selected="selectType"
                  />
                </b-col>
              </b-form-row>
              <div class="title">{{ $t('intercom.title') }}</div>
              <b-form-row>
                <b-col md="12">
                  <BInputLabel
                    id="input-intercom-modal_name"
                    :label="$t('name')"
                    v-model="model.name"
                    :state="validateState('name')"
                  />
                </b-col>
              </b-form-row>
            </div>
            <div class="modal-body-row with-cols border-right">
              <div class="title">{{ $t('global.device') }}</div>
              <b-form-row
                class="address-input"
                :class="{ show_description: isShowAddressDescription }"
              >
                <b-col cols="12">
                  <GeoUnitsAutocomplete
                    v-model="model.geo_unit_id"
                    :geo-unit="model.geo_unit"
                    :geo-unit-deep-at="geoUnitDeepAt"
                    :client-id="Number(clientId)"
                    :state="isCreate ? validateState('geo_unit_id') : undefined"
                    :placeholder="$t('intercom.address')"
                    show-loading
                    class="w-100"
                    section="intercom"
                    @input="onUpdateGeoUnitId"
                  ></GeoUnitsAutocomplete>
                  <small>
                    {{ $t('intercom.address_description') }}
                  </small>
                </b-col>
              </b-form-row>
              <b-form-row>
                <b-col md="12">
                  <IntercomModelsAutocomplete
                    v-model="model.intercom_model_id"
                    :current-model="currentModel"
                    :placeholder="$t('intercom.model')"
                    :state="validateState('intercom_model_id')"
                    :items="actualModels"
                    @input:item="onInputIntercomModel"
                    ref="intercom_model_id_component"
                  />
                </b-col>
              </b-form-row>
              <b-form-row v-if="isShowControlPanel" class="align-items-center">
                <b-col md="10">
                  <IntercomMacAutocomplete
                    :placeholder="$t('intercom.control_panel')"
                    :clientId="parseInt(clientId)"
                    :value="model.control_panel_id"
                    :intercom-data="model.control_panel"
                    v-model="model.control_panel_id"
                    class="flex-grow-1"
                  />
                </b-col>
                <b-col md="2" class="mb-4">
                  <div class="editor-header__info" id="info">i</div>
                  <b-popover
                    target="info"
                    triggers="hover"
                    placement="rightbottom"
                  >
                    <div class="mt-2">
                      {{ $t('intercom.control_panel_hint') }}
                    </div>
                  </b-popover>
                </b-col>
              </b-form-row>
              <b-form-row v-if="isShowSelectSipServer">
                <b-col md="12">
                  <BSelectLabel
                    id="select-intercom-sip_server_id"
                    :items="actualServers"
                    :customDisplay="intercomSipServer"
                    object-property="id"
                    :label="$t('intercom.sip_server')"
                    v-model="model.sip_server_id"
                    :state="validateState('sip_server_id')"
                    zero-val-active
                  />
                </b-col>
              </b-form-row>
              <b-form-row>
                <b-col v-if="isShowUuid">
                  <BInputLabel
                    id="input-intercom-modal_uuid"
                    :label="$t('intercom.uuid')"
                    autocomplete="off"
                    v-model="model.uuid"
                    :state="validateState('uuid')"
                  />
                </b-col>
              </b-form-row>
              <b-form-row>
                <b-col v-if="isShowMacAddr">
                  <BInputLabel
                    id="input-intercom-modal_macaddr"
                    :label="$t('intercom.macaddr')"
                    autocomplete="off"
                    v-model="model.macaddr"
                    :state="validateState('macaddr')"
                  />
                </b-col>
                <b-col v-if="isShowIpAddress">
                  <BInputLabel
                    id="input-intercom-modal_ip_address"
                    :label="$t('intercom.ip_address')"
                    autocomplete="off"
                    v-model="model.ip_address"
                    :state="validateState('ip_address')"
                  />
                </b-col>
                <b-col v-if="isShowUuid">
                  <BInputLabel
                    id="input-intercom-modal_serial"
                    :label="$t('intercom.serial')"
                    autocomplete="off"
                    v-model="model.serial"
                    :state="validateState('serial')"
                  />
                </b-col>
              </b-form-row>
              <b-form-row v-if="isShowUsernamePassword">
                <b-col md="6">
                  <BInputLabel
                    id="input-intercom-modal_username"
                    :state="validateState('settings.username')"
                    :label="$t('intercom.username')"
                    autocomplete="off"
                    v-model="model.settings.username"
                  />
                </b-col>
                <b-col md="6">
                  <BInputLabel
                    id="input-intercom-modal_password"
                    :state="validateState('settings.password')"
                    name="intercom-password"
                    :label="$t('password')"
                    autocomplete="off"
                    v-model="model.settings.password"
                  />
                </b-col>
              </b-form-row>
              <b-form-row>
                <b-col v-if="isShowKeyTypes">
                  <BSelectLabel
                    id="select-intercom-modal_key_types"
                    :items="keyTypes"
                    display="label"
                    object-property="type"
                    :label="$t('intercom.key_types')"
                    v-model="model.settings.key_types"
                    @input:item="onInputKeyTypes"
                  />
                </b-col>
                <b-col v-if="isShowKeysProfiles">
                  <BSelectLabel
                    id="select-intercom-modal_keys_profile_id"
                    name="intercom-keys-profiles"
                    :items="intercomKeysProfiles"
                    display="name"
                    object-property="id"
                    :label="$t('intercom.keys_profile')"
                    v-model="model.settings.intercom_keys_profile_id"
                    :state="validateState('settings.intercom_keys_profile_id')"
                    zero-val-active
                  />
                </b-col>
              </b-form-row>
              <b-form-row>
                <b-col md="6" v-if="isShowConciergeNumbers">
                  <BInputLabel
                    id="input-intercom-modal_concierge_numbers"
                    name="intercom-concierge-numbers"
                    :label="$t('intercom.concierge_numbers')"
                    autocomplete="off"
                    v-model="model.settings.concierge_numbers"
                  />
                </b-col>
                <b-col v-if="isShowKeysProfiles">
                  <BSelectLabel
                    id="select-intercom-modal_code_reverse_order"
                    name="intercom-code-reverse-order"
                    :items="intercomCodeReverseOrders"
                    display="label"
                    object-property="value"
                    :label="$t('intercom.code_reverse_order.title')"
                    v-model="model.settings.code_reverse_order"
                    boolean-val-active
                  />
                </b-col>
              </b-form-row>
            </div>
          </template>
        </b-form-validate>
      </b-col>
      <b-col cols="4">
        <div class="modal-body-row with-cols ml--15">
          <div class="title">
            {{ $t('intercom.intercom_relay_permissions.doors') }}
          </div>
          <b-form-row>
            <b-col cols="12">
              <BInputLabel
                :id="`input-intercom-modal-relay_index-${relay.index}_name-${relay.name}`"
                v-for="(relay, index) in getRelays"
                :key="index"
                v-model="relay.name"
                :label="$t('intercom.relay.input_label', [relay.index])"
                :state="checkDuplicates(relay)"
                active-validation-to-disable
                disabled
              >
                <template v-slot:icon>
                  <b-dropdown
                    class="icon dropdown-relay"
                    toggle-class="dropdown-toggle-with-icon"
                    menu-class="dropdown-menu-with-triangle dropdown-menu-width-block-toggle"
                    ref="dropdown"
                    no-caret
                  >
                    <template slot="button-content">
                      <i class="icon-local-cog"></i>
                    </template>
                    <b-dropdown-item @click="onEditRelay(relay)">
                      <i class="icon-local-pencil"></i>
                      <p class="dropdown-item-text">{{ $t('button.edit') }}</p>
                    </b-dropdown-item>
                    <b-dropdown-item @click="onEditPermissionRelay(relay)">
                      <i class="icon-local-key"></i>
                      <p class="dropdown-item-text">
                        {{
                          $t('intercom.intercom_relay_permissions.settings_btn')
                        }}
                      </p>
                    </b-dropdown-item>
                    <b-dropdown-item @click="onDeleteRelay(relay)">
                      <i class="icon-local-trash"></i>
                      <p class="dropdown-item-text">
                        {{ $t('button.delete') }}
                      </p>
                    </b-dropdown-item>
                  </b-dropdown>
                </template>
              </BInputLabel>
            </b-col>
            <b-col cols="12">
              <b-button
                v-if="getRelays.length < 3"
                @click="onAddRelay"
                variant="outline-primary"
                block
                :disabled="disableAddRelayButton"
              >
                {{ $t('intercom.add_door') }}
              </b-button>
            </b-col>
          </b-form-row>
          <div class="title mt-5" v-if="getDeletedRelays.length">
            {{ $t('intercom.will_be_delete') }}
          </div>
          <b-form-row>
            <b-col cols="12">
              <BInputLabel
                v-for="relay in getDeletedRelays"
                :key="relay.index"
                v-model="relay.name"
                :label="$t('intercom.relay.input_label', [relay.index])"
                :state="checkDuplicates(relay)"
                disabled
                active-validation-to-disable
              >
                <template v-slot:icon>
                  <b-dropdown
                    class="icon"
                    toggle-class="dropdown-toggle-with-icon"
                    menu-class="dropdown-menu-with-triangle dropdown-menu-width-block-toggle"
                    ref="dropdown"
                    no-caret
                  >
                    <template slot="button-content">
                      <i class="icon-local-cog"></i>
                    </template>
                    <b-dropdown-item @click="onRestoreRelay(relay)">
                      <i class="icon-local-trash"></i>
                      <p class="dropdown-item-text">
                        {{ $t('button.restore') }}
                      </p>
                    </b-dropdown-item>
                  </b-dropdown>
                </template>
              </BInputLabel>
            </b-col>
          </b-form-row>
          <b-form-row v-if="intercomButtonsCount">
            <b-col cols="12" class="mt-3">
              <b-button
                @click="onEditIntercomButtons"
                variant="outline-primary"
                :disabled="!model.geo_unit_id"
              >
                {{ $t('intercom.buttons.title') }}
              </b-button>
            </b-col>
          </b-form-row>
          <b-form-row v-if="currentModel && isShowCallButton">
            <b-col cols="12" class="mt-3">
              <b-button
                @click="onOpenCallSettingsModal"
                variant="outline-primary"
              >
                {{ $t('intercom.call_sessions.titles') }}
              </b-button>
            </b-col>
          </b-form-row>
        </div>
      </b-col>
    </b-form-row>
    <IntercomRelayModal
      :intercom-type="model.type"
      :relay-indexes="getRelayIndexes"
      :parent-geo-unit-id="model.geo_unit_id"
      :intercom-model="currentModel"
      :face-detection-type="model.face_detection_type"
      :geo-unit-deep-at="geoUnitDeepAt"
      ref="IntercomRelayModal"
      @create="createRelay"
      @update="updateRelay"
    />
    <IntercomCallSettingsModal
      v-if="model && currentModel"
      v-model="model.settings"
      :tags="currentModel.tags"
      :model="currentModel"
      @update="onUpdateCallSettings"
      ref="IntercomCallSettingsModal"
    />
    <IntercomButtonsModal
      v-if="intercomButtonsCount"
      v-model="model.settings.correspond_table"
      :buttons-count="intercomButtonsCount"
      :intercom-geo-unit-id="model.geo_unit_id"
      ref="IntercomButtonsModal"
    />
    <IntercomRelayPermissions
      ref="IntercomRelayPermissions"
      @success="onUpdateRelayPermission"
    ></IntercomRelayPermissions>
  </b-modal>
</template>

<script>
import {
  ApiBackendIntercoms,
  ApiBackendSipServers,
  ApiGeoUnits,
  ApiIntercomKeysProfiles,
} from '@/api';
import { request3 } from '@/api/request';
import GeoUnitsAutocomplete from '@/components/autocompletes/geoUnitsAutocomplete.vue';
import { EVENTS, GEO_UNITS, INTERCOM_TYPES, API_URLS } from '@/consts';
import bridge from '@/helpers/bridge';
import EventBus from '@/packs/EventBus';
import i18n from '@app/i18n';
import _ from 'lodash';
import { minLength, required, requiredIf } from 'vuelidate/lib/validators';
import IntercomButtonsModal from './IntercomButtonsModal.vue';
import IntercomCallSettingsModal from './IntercomCallSettingsModal.vue';
import IntercomRelayModal from './IntercomRelayModal.vue';
import IntercomRelayPermissions from './IntercomRelayPermissions.vue';
import IntercomMacAutocomplete from '@/components/autocompletes/IntercomMacAutocomplete.vue';
import IntercomModelsAutocomplete from '@/components/autocompletes/IntercomModelsAutocomplete.vue';

function initData(intercom, clientId) {
  if (intercom) {
    intercom.relays_attributes = intercom.relays || [];
    intercom.relays_attributes.forEach((relay) => {
      relay.serverRelayIndex = relay.index;
    });
  }
  let keyTypes;
  if (Number(clientId) === 1) {
    keyTypes = [
      { label: 'SL 1', type: 'sl1' },
      { label: 'SL 3', type: 'sl3' },
      { label: 'SL 1 & SL 3', type: 'sl1_sl3' },
      { label: 'SL 0', type: 'sl0' },
    ];
  } else {
    keyTypes = [
      { label: 'SL 3', type: 'sl3' },
      { label: 'SL 0', type: 'sl0' },
    ];
  }
  return {
    intercomModels: [],
    intercomCodeReverseOrders: [
      { label: i18n.t('intercom.code_reverse_order.direct'), value: false },
      { label: i18n.t('intercom.code_reverse_order.reverse'), value: true },
    ],
    intercomKeysProfiles: [],
    faceDetectionTypes: [
      { label: i18n.t('global.off'), type: 'disabled' },
      { label: 'Rbfaceid', type: 'rbfaceid' },
      { label: 'Expasoft', type: 'expasoft' },
    ],
    actualServers: [],
    intercomTypes: [
      { label: i18n.t('intercom.title'), type: INTERCOM_TYPES.callPanel },
      { label: i18n.t('racs.title_full'), type: INTERCOM_TYPES.accessControl },
    ],
    keyTypes: keyTypes,
    model: _.cloneDeep(intercom) || {
      geo_unit_id: null,
      geo_unit: {},
      intercom_model_id: '',
      intercom_keys_profile_id: '',
      sip_server_id: '',
      control_panel_id: '',
      control_panel: {},
      type: INTERCOM_TYPES.callPanel,
      macaddr: '',
      ip_address: '',
      uuid: '',
      serial: '',
      settings: {
        correspond_table: [],
        password: '',
        username: '',
        analog: '',
        key_types: 'sl3',
        code_reverse_order: '',
      },
      relays_attributes: [],
      name: '',
    },
    isCreate: !intercom,
    addressGeoUnits: [],
    address: '',
    initialGeoUnit: intercom ? intercom.geo_unit_id : '',
    currentModel: undefined,
  };
}

export default {
  name: 'IntercomModal',
  components: {
    IntercomMacAutocomplete,
    GeoUnitsAutocomplete,
    IntercomButtonsModal,
    IntercomCallSettingsModal,
    IntercomRelayModal,
    IntercomRelayPermissions,
    IntercomModelsAutocomplete,
  },
  props: {
    clientId: {},
  },
  data() {
    return {
      ...initData(),
      validations: {
        intercom_model_id: { required },
        sip_server_id: {
          required: requiredIf(() => this.isShowSelectSipServer),
        },
        type: { required },
        macaddr: {
          required: requiredIf(() => this.isShowMacAddr),
        },
        ip_address: {
          required: requiredIf(() => this.isRequiredIpAddress),
        },
        serial: {
          required: requiredIf(
            () =>
              this.isShowUuid && (!this.model.uuid || !this.model.uuid.length),
          ),
        },
        uuid: {
          required: requiredIf(
            () =>
              this.isShowUuid &&
              (!this.model.serial || !this.model.serial.length),
          ),
        },
        settings: {
          password: {
            required: requiredIf(() => this.isPanelQueueFields),
          },
          username: {
            required: requiredIf(() => this.isPanelQueueFields),
          },
          intercom_keys_profile_id: {
            required: requiredIf(() => this.isShowKeysProfiles),
          },
        },
        name: { required, minLength: minLength(2) },
      },
    };
  },
  computed: {
    actualModels() {
      const type = {
        [INTERCOM_TYPES.accessControl]: 'access_control',
        [INTERCOM_TYPES.callPanel]: 'call_panel',
      }[this.model.type];
      return this.intercomModels.filter((model) => model.type === type);
    },
    geoUnitDeepAt() {
      const baseGeoUnits = [
        GEO_UNITS.Building,
        GEO_UNITS.Entrance,
        GEO_UNITS.Floor,
        GEO_UNITS.ParkingArea,
        GEO_UNITS.Section,
        GEO_UNITS.TechRoom,
        GEO_UNITS.Territory,
      ];
      if (this.currentModel?.tags?.includes('one_property')) {
        return [
          GEO_UNITS.Apartment,
          GEO_UNITS.Bkfn,
          GEO_UNITS.ParkingPlace,
          GEO_UNITS.StoreRoom,
          GEO_UNITS.TechRoom,
        ];
      }
      if (this.model.type === INTERCOM_TYPES.accessControl) {
        return [...baseGeoUnits, GEO_UNITS.Roof];
      }
      return baseGeoUnits;
    },
    disableAddRelayButton() {
      return (
        !this.currentModel ||
        this.model.relays_attributes.length >= this.currentModel.relays_count
      );
    },
    intercomButtonsCount() {
      const selectedIntercomModel = this.actualModels.find(
        (model) => model.id === this.model.intercom_model_id,
      );
      return selectedIntercomModel?.buttons_count || undefined;
    },
    getRelayIndexes() {
      return this.model.relays_attributes.map(({ index }) => Number(index));
    },
    getRelays() {
      return this.model.relays_attributes.filter((relay) => !relay._destroy);
    },
    getDeletedRelays() {
      return this.model.relays_attributes.filter((relay) => relay._destroy);
    },
    getDuplicatesIndex() {
      return this.getRelayIndexes.filter((val, i, relays) =>
        relays.includes(val, i + 1),
      );
    },
    isShowCallButton() {
      return this.currentModel.tags.some((tag) =>
        ['is_analog', 'proxy_call'].includes(tag),
      );
    },
    isPanelQueueFields() {
      return (
        this.currentModel?.type === 'call_panel' &&
        !(this.currentModel?.rabbit_queue === 'access_control_queue')
      );
    },
    isRequiredIpAddress() {
      return [
        'ip_address_required',
        'config_with_exchange',
        'correspond_table',
      ].some((tag) => this.currentModel?.tags?.includes(tag));
    },
    isShowAddressDescription() {
      return this.model.type === INTERCOM_TYPES.callPanel;
    },
    isShowConciergeNumbers() {
      return (
        this.model.type === INTERCOM_TYPES.callPanel &&
        !this.currentModel?.tags.includes('no_concierge_numbers')
      );
    },
    isShowSelectSipServer() {
      return (
        this.actualServers.length &&
        this.model?.type === INTERCOM_TYPES.callPanel
      );
    },
    isShowControlPanel() {
      return this.currentModel?.tags.includes('rusguard_cloud');
    },
    isShowKeyTypes() {
      return !this.currentModel?.tags.includes('no_key_types');
    },
    isShowKeysProfiles() {
      return this.currentModel?.tags.includes('keys_profiled');
    },
    isShowMacAddr() {
      return this.currentModel?.tags?.includes('required_macaddr');
    },
    isShowUuid() {
      return this.currentModel?.tags?.includes('required_uuid_or_serial');
    },
    isShowIpAddress() {
      return (
        this.isRequiredIpAddress ||
        this.currentModel?.tags?.includes('ip_address')
      );
    },
    isShowUsernamePassword() {
      return this.model.type === INTERCOM_TYPES.callPanel && !this.isShowUuid;
    },
  },
  mounted() {
    EventBus.$on(EVENTS.intercom.modal.show, this.show);
  },
  watch: {
    currentModel(model) {
      if (model?.tags?.includes('one_property') && this.isCreate) {
        this.$set(this.model, 'geo_unit', {});
        this.$set(this.model, 'geo_unit_id', null);
      }
    },
    isPanelQueueFields(val) {
      if (val) {
        this.model.uuid = null;
      }
    },
    isShowUuid(val) {
      if (val) {
        this.model.macaddr = null;
        this.model.settings.password = null;
        this.model.settings.username = null;
      }
    },
  },
  methods: {
    async show(intercom) {
      if (intercom?.id) {
        intercom = await ApiBackendIntercoms.getOne(intercom.id);
      }
      Object.assign(this.$data, initData(intercom, this.clientId));
      if (
        !intercom &&
        bridge.geoUnit &&
        bridge.geoUnit.type !== GEO_UNITS.District
      ) {
        try {
          await ApiGeoUnits.getOne(bridge.geoUnit.id).then((res) => {
            this.model.geo_unit = res;
            this.model.geo_unit_id = res.id;
          });
        } catch (e) {
          console.error(e);
        }
      }
      this.getIntercomKeysProfiles();
      await request3
        .get(API_URLS.backendManage.intercomModels.fetch(this.clientId), {})
        .then((res) => {
          this.intercomModels = res.data;
          this.currentModel = this.intercomModels.find(
            (model) => model.id === this.model.intercom_model_id,
          );
        });
      await ApiBackendSipServers.get()
        .allAsync()
        .then((res) => {
          this.actualServers = res.filter((m) => m.active === true);
        });
      if (!this.model.type) {
        this.model.type = this.model.macaddr
          ? INTERCOM_TYPES.callPanel
          : INTERCOM_TYPES.accessControl;
      }
      this.$refs.modal.show();
    },
    onEditIntercomButtons() {
      this.$refs.IntercomButtonsModal.show();
    },
    onUpdateGeoUnitId(val) {
      if (!this.isCreate && val && this.getRelays.length) {
        this.$bvToast.toast(this.$t('intercom.warning_change_position_doors'), {
          variant: 'info',
        });
      }
    },
    async getIntercomKeysProfiles() {
      await ApiIntercomKeysProfiles.get({
        q: { key_types_eq: this.model.settings.key_types },
      })
        .allAsync()
        .then((res) => {
          this.intercomKeysProfiles = res;
        });
    },
    checkDuplicates(relay) {
      if (!_.isObject(relay.geo_unit)) return undefined;
      if (this.getDuplicatesIndex.includes(relay.index)) return false;
      return undefined;
    },
    intercomSipServer(sip_server) {
      return `${sip_server?.name || ''}`;
    },
    onAddRelay() {
      const { geo_unit_id } = this.model;
      const relay = geo_unit_id ? { geo_unit_id } : null;
      this.$refs.IntercomRelayModal.show(relay, this.model.is_face_detection);
    },
    onOpenCallSettingsModal() {
      this.$refs.IntercomCallSettingsModal.show(this.model.settings);
    },
    createRelay(relay) {
      this.model.relays_attributes.push(relay);
    },
    updateRelay(relay, previousIndexRelay) {
      const relayIndex = this.model.relays_attributes.findIndex((el) => {
        if (el.id) {
          return el.index === previousIndexRelay && relay.id === el.id;
        }
        return el.index === previousIndexRelay;
      });
      if (relayIndex > -1) {
        this.$set(this.model.relays_attributes, relayIndex, relay);
      }
    },
    async onOk(event) {
      event.preventDefault();
      const [validateForm] = this.$refs.form.validate('full');
      if (validateForm) {
        // VALIDATION
        if (!this.getRelays.length) {
          this.$bvToast.toast(this.$t('intercom.will_be_add_door'), {
            id: 'toast-intercom-modal-error_not-relays',
          });
          return;
        }
        if (this.getDuplicatesIndex.length) {
          this.$bvToast.toast(this.$t('intercom.has_duplicate doors_indexes'), {
            id: 'toast-intercom-modal-error_duplicate_index',
          });
          return;
        }
        EventBus.$emit('preloader::show');
        await this.$nextTick();

        // CREATE/UPDATE
        if (this.isCreate) {
          try {
            await ApiBackendIntercoms.create({
              ...this.model,
              client_id: this.clientId,
            });
            EventBus.$emit(EVENTS.intercom.refresh);
            this.$refs.modal.hide();
            this.$bvToast.toast(this.$t('intercom.intercom_is_set_up'));
          } catch (error) {
            console.log(error);
            const toast = {
              message: '',
              params: {},
            };
            if (error.errors) {
              if (error.errors.macaddr) {
                toast.message = `Mac Address: ${error.errors.macaddr}`;
              } else if (error.errors.ip_address) {
                toast.message = `Ip Address: ${error.errors.ip_address}`;
              } else if (
                error.errors?.uuid?.[0] ===
                this.$t('intercom.errors.already_exists')
              ) {
                toast.message = this.$t('intercom.errors.duplicate_uiid');
              } else if (
                error.errors?.serial?.[0] ===
                this.$t('intercom.errors.already_exists')
              ) {
                toast.message = this.$t('intercom.errors.duplicate_serial');
              } else {
                toast.message = JSON.stringify(
                  error.errors || this.$t('error.occurred'),
                );
              }
              toast.params.id = 'toast-intercom-modal-errors';
              this.$bvToast.toast(toast.message, toast.params);
            } else if (error.errors.size > 0) {
              this.$bvToast.toast(error.errors.join(', '));
            }
            EventBus.$emit(EVENTS.preloader.hide);
          }
        } else {
          try {
            await ApiBackendIntercoms.update({
              ..._.omit(this.model, ['relays']),
            });
            EventBus.$emit(EVENTS.intercom.update);
            this.$refs.modal.hide();
          } catch (error) {
            error.errors
              ? this.$bvToast.toast(error.errors.join(', '))
              : this.$bvToast.toast(error.error);
            EventBus.$emit(EVENTS.preloader.hide);
          }
        }
        EventBus.$emit('preloader::hide');
      }
    },
    onEditRelay(relay) {
      console.log('relay', relay);
      this.$refs.IntercomRelayModal.show(relay, this.model.is_face_detection);
    },
    onInputKeyTypes() {
      this.$set(this.model.settings, 'intercom_keys_profile_id', null);
      this.getIntercomKeysProfiles();
    },
    onInputIntercomModel(model) {
      this.$set(this.model.settings, 'intercom_keys_profile_id', null);
      this.currentModel = model;
    },
    onEditPermissionRelay(relay) {
      this.$refs.IntercomRelayPermissions.show(
        relay.relays_geo_units,
        relay.id,
      );
    },
    onUpdateRelayPermission(permissions = [], relayId) {
      const relay = this.model.relays_attributes.find(
        ({ id }) => id === relayId,
      );
      relay.relays_geo_units_attributes = permissions;
      relay.relays_geo_units = permissions;
    },
    onDeleteRelay(relay) {
      if (relay.id) {
        relay.index = relay.serverRelayIndex;
        this.$set(relay, '_destroy', true);
      } else {
        this.model.relays_attributes.splice(
          this.model.relays_attributes.indexOf(relay),
          1,
        );
      }
    },
    onRestoreRelay(relay) {
      this.$delete(relay, '_destroy');
    },
    onUpdateCallSettings(settings) {
      this.model.settings = settings;
    },
    selectType() {
      this.$refs.intercom_model_id_component.onClear();
    },
  },
};
</script>

<style lang="scss">
.hr-vertical {
  background: silver;
  height: 100%;
  width: 1px;
  position: absolute;
}

.border-between > [class*='col-']:before {
  background: #e3e3e3;
  bottom: 0;
  content: ' ';
  left: 0;
  position: absolute;
  width: 1px;
  top: 0;
}

.border-between > [class*='col-']:first-child:before {
  display: none;
}

.address-input {
  small {
    display: none;
  }
  &.show_description {
    fieldset {
      margin-bottom: 5px !important;
    }
    small {
      display: block;
    }
    margin-bottom: 15px;
  }
}

#intercom-modal {
  .dropdown-relay {
    .dropdown-toggle {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
</style>
