<template>
  <div>
    <b-modal
      centered
      id="user-property-add-modal"
      ref="modal"
      @ok="onOk"
      :ok-title="$t('button.add')"
      :title="$t(`property.add.create_title`)"
    >
      <BFormValidate
        :model="model"
        :validations="validations"
        ref="form"
        @submit="onOk"
      >
        <template slot-scope="{ validateState }">
          <div class="modal-body-row with-cols">
            <div class="title">{{ $t('property.add.select_property') }}</div>
            <b-form-row>
              <b-col cols="12">
                <GeoUnitsAutocomplete
                  :client-id="clientId"
                  v-model="model.geo_unit_id"
                  :placeholder="$t('address.title')"
                  geo-unit-property="post_name"
                  class="flex-grow-1"
                  :state="validateState('geoUnitId')"
                  :additional-query-params="{
                    type_in: allowedGeoUnitTypes,
                    id_not_in: ownedPropertyIds,
                  }"
                />
              </b-col>
            </b-form-row>
          </div>
        </template>
      </BFormValidate>
    </b-modal>
  </div>
</template>

<script>
import { ApiGeoUnitUser } from '@/api/index';
import GeoUnitsAutocomplete from '@/components/autocompletes/geoUnitsAutocomplete.vue';
import { GEO_UNITS } from '@/consts';
import { required } from 'vuelidate/lib/validators';

function initData(user_id) {
  return {
    model: {
      geo_unit_id: NaN,
      user_id: user_id || '',
    },
    geoUnit: {},
  };
}
export default {
  name: 'UserModalProperty',
  components: { GeoUnitsAutocomplete },
  props: {
    clientId: {
      required: true,
    },
    ownedPropertyIds: {
      type: Array,
    },
  },
  data() {
    return {
      ...initData(),
      validations: { geo_unit_id: { required }, user_id: { required } },
      roles: [],
    };
  },
  computed: {
    allowedGeoUnitTypes() {
      return [
        GEO_UNITS.Apartment,
        GEO_UNITS.ParkingPlace,
        GEO_UNITS.StoreRoom,
        GEO_UNITS.Bkfn,
      ];
    },
  },
  methods: {
    onShow(userId) {
      Object.assign(this.$data, initData(userId));
      this.$refs.modal.show();
    },
    async onOk(event) {
      event.preventDefault();
      if (this.$refs.form.validate()) {
        await ApiGeoUnitUser.create(this.model)
          .then(() => {
            this.$emit('created');
            this.$nextTick(() => {
              this.$refs.modal.hide();
            });
          })
          .catch((err) => {
            this.$bvToast.toast(err.errors.is_guest[0]);
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
