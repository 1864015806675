import { getManageId, getDistrictId } from '@/helpers';
import { getSelectedRolePanel } from '@/utils';
import geocode from './geocode';
import { Request, request2 } from './request';
import batch from './Batches/batch';

import ApiGeoUnitsRequest from './Requests/ApiGeoUnitsRequest';

export function baseApiBackendUrl() {
  switch (getSelectedRolePanel()) {
    case 'manage':
      return `/api/backend/manage/${getManageId()}`;
    default:
      return `/api/backend/personal`;
  }
}

function districtBackendUrl() {
  return `/api/backend/manage/${getManageId()}/intercom/districts/${getDistrictId()}`;
}

const manageApi = '/api/manage';

export const ApiBatch = new Request('/batches', 'batch');
export const RequestBatch = batch;
export const ApiClients = new Request('/clients', 'client');
export const ApiCallableGeoUnit = new Request(
  '/callable_geo_units',
  'callable_geo_unit',
);
export const ApiGeoUnitsClients = new Request(
  '/geo_units_clients',
  'geo_unit_client',
);

export const ApiGeoUnits = new ApiGeoUnitsRequest('/geo_units', 'geo_unit');
export const ApiIntercoms = new Request('/intercoms', 'intercom');
export const ApiIntercomModels = new Request('/intercom_models');
export const ApiIntercomKeysProfiles = new Request('/intercom_keys_profiles');
export const ApiKeys = new Request('/keys', 'key');
export const ApiV1Keys = new Request('/keys', 'key', '/api/v1/manage');
export const ApiKeySets = new Request('/keysets', 'keyset');
export const ApiRelays = new Request('/relays', 'relay');
export const ApiUsersClients = new Request('/users_clients', 'user_client');
export const ApiCallSessions = new Request('/call_sessions', 'call_session');

export const ApiGeoUnitUser = new Request(
  '/geo_units_users',
  'geo_unit_user',
  baseApiBackendUrl(),
);

export const ApiConfigAlertSms = new Request(
  '/config_sms_alerts', 'config_sms_alert', '/admin'
);

export const ApiPermissions = new Request('/permissions', 'permission');

export const ApiBackendKeys = new Request('/keys', 'key', baseApiBackendUrl());

export const ApiBackendCities = new Request(
  '/cities',
  'city',
  baseApiBackendUrl(),
);
export const ApiBackendGeoUnits = new Request(
  '/geo_units',
  'geo_unit',
  baseApiBackendUrl(),
);
export const ApiBackendNprGeoUnits = new Request(
  '/geo_units',
  'geo_unit',
  `${baseApiBackendUrl()}/autocomplete`,
);
export const ApiBackendUsers = new Request(
  '/users',
  'user',
  baseApiBackendUrl(),
);
export const ApiBackendUsersClients = new Request(
  '/users_clients',
  'user',
  baseApiBackendUrl(),
);
export const ApiBackendAccounts = new Request(
  '/accounts',
  'user',
  baseApiBackendUrl(),
);
export const ApiBackendSipServers = new Request(
  '/sip_servers',
  'sip_server',
  baseApiBackendUrl(),
);

export const ApiBackendIntercoms = new Request(
  '/intercoms',
  'intercom',
  baseApiBackendUrl(),
);

export const ApiBackendRelays = new Request(
  '/relays',
  'relay',
  baseApiBackendUrl(),
);
export const ApiBackendDistricts = new Request(
  '/main_districts',
  'district',
  baseApiBackendUrl(),
);
export const ApiBackendRelaysWithAddress = new Request(
  '/relays',
  'relay',
  `${baseApiBackendUrl()}/autocomplete`,
);
export const ApiBackendRelaysGroups = new Request(
  '/relays_groups',
  'relays_groups',
  districtBackendUrl(),
);
export const ApiManagePermissions = new Request(
  '/permissions',
  'permission',
  manageApi,
);
export const ApiManageIntercom = new Request(
  '/intercoms',
  'intercom',
  manageApi,
);

window.api = {
  // ApiBatch,
  // ApiClients,
  // ApiCallableGeoUnit,
  // ApiGeoUnitsClients,
  // ApiGeoUnits,
  // ApiPermissions,
  // ApiIntercoms,
  // ApiIntercomModels,
  // ApiIntercomKeys,
  // ApiKeys,
  // ApiKeySets,
  // ApiUsersClients,
  // ApiCallSessions,
  // ApiRelays,
  // ApiRelaysKeys,
  // ApiKeysUsers,
  // ApiSipAccounts,
  // ApiGeoUnitUser,
  // request,
  // RequestBatch,
  // ApiRelaysGroups,
  // relaysKeys,
  // ApiGeoUnitUser,
};

export function signOut() {
  return request2({
    url: '/api/v1/users/sign_out',
    method: 'delete',
  });
}

export function fetchDistricts(id) {
  const postfix =
    (id && ['number', 'string'].includes(typeof id) && `/${id}`) || '';
  return request2({
    url: `/api/backend/manage/${getManageId()}/main_districts${postfix}`,
  });
}

export function fetchRelaysGroups(config = {}, data) {
  return request2(
    {
      url: `${districtBackendUrl()}/relays_groups`,
      method: config.method || 'GET',
    },
    data,
  );
}

// export const ApiRelaysGroups = new Request(
//   '/relays_groups',
//   'relay',
//   districtBackendUrl(),
// );

export { geocode, request2, Request };
