<template>
  <div
    v-if="isUpdating"
    class="
      building-update-structure-status
      building-update-structure-status__updating
    "
  >
    <b-spinner small variant="primary"></b-spinner>
    {{ $t("building_create.update_structure.status.in_progress") }}
  </div>
  <div
    v-else-if="isComplete"
    class="
      building-update-structure-status
      building-update-structure-status__complete
    "
  >
    {{ $t("building_create.update_structure.status.complete") }}
    <a href="">{{ $t("button.update_page") }}</a>
  </div>
</template>

<script>
import { request2 } from '@/api';
import { getManageId } from '@/helpers';

const STATUS_CHECK_INTERVAL = 3000;

export default {
  name: 'BuildingUpdateStructureStatus',
  props: {
    buildingId: { required: true },
  },
  data() {
    return {
      isUpdating: false,
      isComplete: false,
    };
  },
  mounted() {
    this.checkStatus();
  },
  methods: {
    checkStatus() {
      return request2({
        method: 'GET',
        url: `/api/backend/manage/${getManageId()}/geo_units/${
          this.buildingId
        }/structure/status`,
      })
        .then(({ status }) => {
          if (status === 'in_progress') {
            this.isUpdating = true;
            setTimeout(() => {
              this.checkStatus();
            }, STATUS_CHECK_INTERVAL);
          } else if (status === 'ok') {
            if (this.isUpdating) this.isComplete = true;
            this.isUpdating = false;
            this.removeDisabledFromChangeStructureBtn();
          }
        })
        .catch(() => {
          this.removeDisabledFromChangeStructureBtn();
        });
    },
    removeDisabledFromChangeStructureBtn() {
      let button = document.querySelector('#edit-house');
      if (!button) {
        button = document.querySelector('#change-structure');
      }

      if (button) {
        button.classList.remove('disabled');
      }
    },
  },
};
</script>

<style lang="scss">
@import '../../assets/styles/components/variables';

.building-update-structure-status {
  height: 32px;
  width: 100%;
  border-radius: 6px;
  display: flex;
  align-items: center;
  padding: 8px 16px;
  margin-bottom: 14px;
  font-weight: 350;
  font-size: 14px;
  line-height: 18px;
}

.building-update-structure-status__updating {
  background-color: #dff5ff;
  color: $blue;
  .spinner-border {
    margin-right: 10px;
  }
}

.building-update-structure-status__complete {
  background-color: #d1e4c9;
  color: #6aa253;
  a {
    margin-left: 5px;
  }
}
</style>
