var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-tabs',{attrs:{"nav-wrapper-class":"intercom-tabs-wrapper","active-tab-class":"intercom-tabs__tab_active","active-nav-item-class":"intercom-tabs__tab_active","nav-class":"intercom-tabs"}},[_c('b-tab',{attrs:{"title-link-class":[
      {
        'intercom-tabs__error': _vm.mainTabErrors && _vm.isSubmit,
      },
      'intercom-tabs__tab',
    ],"active":""},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('div',{staticClass:"d-flex align-items-center gap-2"},[_c('i',{staticClass:"icon-local-intercom-tablet"}),_vm._v(" "),_c('p',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.$t('intercom.table_titles.main')))])])]},proxy:true}])},[_vm._v(" "),_c('IntercomMainSettingTab',{attrs:{"errors":_vm.errors,"clientId":_vm.clientId,"geoUnitDeepAt":_vm.geoUnitDeepAt,"intercomModels":_vm.intercomModels,"validateState":_vm.validateState,"isRequredUuid":_vm.isRequredUuid,"isRequiredIpAddress":_vm.isRequiredIpAddress,"isRequiredMacAddress":_vm.isRequiredMacAddress,"isShowControlPanel":_vm.isShowControlPanel,"isSubmit":_vm.isSubmit}})],1),_vm._v(" "),_c('b-tab',{attrs:{"title-link-class":[
      {
        'intercom-tabs__error': _vm.relaysErrors.$invalid && _vm.isSubmit,
      },
      'intercom-tabs__tab',
    ],"lazy":""},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('div',{staticClass:"d-flex align-items-center gap-2"},[_c('i',{staticClass:"icon-local-door-open"}),_vm._v(" "),_c('p',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.$t('intercom.table_titles.doors')))])])]},proxy:true}])},[_vm._v(" "),_c('IntercomRelaysTab',{attrs:{"errors":_vm.relaysErrors,"clientId":_vm.clientId,"geoUnitDeepAt":_vm.geoUnitDeepAt,"disabled":!_vm.intercomModel,"validateState":_vm.validateState,"isSubmit":_vm.isSubmit}})],1),_vm._v(" "),(_vm.isShowCallButton)?_c('b-tab',{attrs:{"title-link-class":[
      {
        'intercom-tabs__error': _vm.proxyErrors.$invalid && _vm.isSubmit,
      },
      'intercom-tabs__tab',
    ],"lazy":""},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('div',{staticClass:"d-flex align-items-center gap-2"},[_c('i',{staticClass:"icon-local-intercom0"}),_vm._v(" "),_c('p',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.$t('intercom.table_titles.calls')))])])]},proxy:true}],null,false,3013181824)},[_vm._v(" "),_c('IntercomCallsTab',{attrs:{"proxyErrors":_vm.proxyErrors,"sipErrors":_vm.sipErrors,"clientId":_vm.clientId,"validateState":_vm.validateState,"actualServers":_vm.actualServers,"isRequiredSelectSipServer":_vm.isRequiredSelectSipServer,"isSubmit":_vm.isSubmit}})],1):_vm._e(),_vm._v(" "),_c('b-tab',{attrs:{"title-link-class":[
      {
        'intercom-tabs__error': _vm.keysErrors.$invalid && _vm.isSubmit,
      },
      'intercom-tabs__tab',
    ],"lazy":""},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('div',{staticClass:"d-flex align-items-center gap-2"},[_c('i',{staticClass:"icon-local-key"}),_vm._v(" "),_c('p',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.$t('intercom.table_titles.keys')))])])]},proxy:true}])},[_vm._v(" "),_c('IntercomKeysTab',{attrs:{"clientId":_vm.clientId,"validateState":_vm.validateState,"isShowKeysProfiles":_vm.isShowKeysProfiles,"errors":_vm.settingErrors,"isSubmit":_vm.isSubmit}})],1),_vm._v(" "),(_vm.intercomButtonsCount)?_c('b-tab',{attrs:{"title-link-class":"intercom-tabs__tab","lazy":""},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('div',{staticClass:"d-flex align-items-center gap-2"},[_c('i',{staticClass:"icon-local-dots-dots-vertical"}),_vm._v(" "),_c('p',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.$t('intercom.table_titles.buttons')))])])]},proxy:true}],null,false,4102746099)},[_vm._v(" "),_c('IntercomButtonsTab',{attrs:{"clientId":_vm.clientId,"validateState":_vm.validateState}})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }