import { CollapsePlugin } from 'bootstrap-vue';
import PortalVue from 'portal-vue';
import Rollbar from 'rollbar';
import Loading from 'vue-loading-overlay';
import YmapPlugin from 'vue-yandex-maps';
import VueCookies from 'vue-cookies';
import Vue from 'vue/dist/vue.esm';
import Vuelidate from 'vuelidate';
import VueResize from 'vue-resize';

import App from '@app/App.vue';
import i18n from '@app/i18n';
import router from '@app/router';
import store from '@app/store';

import EventBus from '@/packs/EventBus';

import {
  BAlert,
  BBadge,
  BButtonGroup,
  BCard,
  BCol,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BPopover,
  BRow,
  BSpinner,
  BTab,
  BTable,
  BTabs,
  ButtonPlugin,
  BVConfigPlugin,
  DropdownPlugin,
  FormInputPlugin,
  FormPlugin,
  VBPopover,
  VBTooltip,
} from 'bootstrap-vue';

import {
  BDropdownMenu,
  BFormValidate,
  BInputLabel,
  BSelectLabel,
  BSelectSearch,
  BHintPopover,
} from '@/components/base';
import PTable from '@app/components/base/PTable.vue';
import PTable2 from '@app/components/base/PTable2.vue';
import SignalStatus from '@app/components/intercom/SignalStatus.vue';
import { TURBO_EVENTS } from '@/consts/hotwire';
import { currentEnv, isProductionEnv } from '@/consts';

window.EventBus = EventBus;

const yandexSettings = {
  apiKey: process.env.VUE_APP_YANDEX_MAP_KEY,
  lang: 'ru_RU',
  coordorder: 'latlong',
  version: '2.1',
};

const configBootstrapVue = {
  BButton: {
    variant: 'primary',
  },
  BModal: {
    okTitle: i18n.t('button.save'),
    cancelTitle: i18n.t('button.cancel'),
    cancelVariant: 'secondary',
  },
  BToast: {
    solid: true,
    toaster: 'b-toaster-top-right',
    variant: 'danger',
  },
};

Vue.use(VueCookies);
Vue.use(YmapPlugin, yandexSettings);
Vue.use(PortalVue);
Vue.use(Vuelidate);
Vue.use(Loading);
Vue.use(CollapsePlugin);

Vue.use(BVConfigPlugin, configBootstrapVue);
Vue.use(DropdownPlugin);
Vue.use(ButtonPlugin);
Vue.use(FormPlugin);
Vue.use(FormInputPlugin);
Vue.use(VueResize);

Vue.component('BAlert', BAlert);
Vue.component('BBadge', BBadge);
Vue.component('BSpinner', BSpinner);
Vue.component('BRow', BRow);
Vue.component('BCol', BCol);
Vue.component('BTable', BTable);
Vue.component('BCard', BCard);
Vue.component('BTabs', BTabs);
Vue.component('BTab', BTab);
Vue.component('BFormInput', BFormInput);
Vue.component('BFormGroup', BFormGroup);
Vue.component('BFormCheckbox', BFormCheckbox);
Vue.component('BPopover', BPopover);
Vue.component('BButtonGroup', BButtonGroup);
Vue.directive('b-tooltip', VBTooltip);

Vue.component('BDropdownMenu', BDropdownMenu);
Vue.component('SignalStatus', SignalStatus);
Vue.component('BFormValidate', BFormValidate);
Vue.component('BInputLabel', BInputLabel);
Vue.component('BSelectLabel', BSelectLabel);
Vue.component('BSelectSearch', BSelectSearch);
Vue.component('BHintPopover', BHintPopover);
Vue.component('PTable', PTable);
Vue.component('PTable2', PTable2);
Vue.directive('b-popover', VBPopover);

Vue.component('resize-observer', VueResize.ResizeObserver);

document.addEventListener(TURBO_EVENTS.load, () => {
  const app = document.querySelector('#app-vue');
  if (app) {
    Vue.prototype.$rollbar = new Rollbar({
      accessToken: '0620cfc0230d4904be9f0636059b2e50',
      captureUncaught: true,
      captureUnhandledRejections: true,
      payload: {
        environment: currentEnv,
      },
      enabled: isProductionEnv,
    });

    Vue.config.errorHandler = (err, vm) => {
      vm.$rollbar.error(err);
      throw err; // rethrow
    };

    // amplitude.getInstance().init('a7c3fa43d471cc5730b14b7e2261b488');
    // eslint-disable-next-line no-new
    window.vm_app = new Vue({
      i18n,
      el: '#app-vue',
      router,
      store,
      render: (h) => h(App, { props: document.getElementById('app')?.dataset }),
    });
  }
});
