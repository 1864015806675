<script>
import UserSearch from '@/vue_slim/components/base/UserSearch.vue'
import CarsAutocomplete from '@/components/autocompletes/CarsAutocomplete.vue'
import PlatesAutocomplete from '@/components/autocompletes/PlatesAutocomplete.vue'
import SelectSlot from '@/vue_slim/components/base/SelectSlot.vue';
import GeoUnitsAutocomplete from '@/components/autocompletes/geoUnitsAutocomplete';
export default {
  name: "FiltersPlatesFormSlim",
  components: { 
    UserSearch,
    CarsAutocomplete,
    PlatesAutocomplete,
    GeoUnitsAutocomplete,
    SelectSlot
  },
  props: {
    userData: {
      type: Object,
      default: {}
    },
    plateData: {
      type: Object,
      default: {}
    },
    clientId: {
      type: Number,
      default: null
    },
    carMarkData: {
      type: Object,
      default: null
    },
    carModelData: {
      type: Object,
      default: null
    },
    geoUnitData: {
      type: Object,
      default: null
    },
    optionCars: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      
    }
  },
  computed: {
    userId() {
      return this.userData?.user_id ? this.userData.user_id : null
    },
    carData() {
      if (this.carModelData?.id) {
        return this.carModelData
      } else if (this.carMarkData?.id) {
        return this.carMarkData
      }
      return null
    },
    nameCarsAutocomplete() {
      if (this.carModelData?.id) {
        return this.optionCars.model
      } else if (this.carMarkData?.id) {
        return this.optionCars.mark
      }
      return null
    }
  },
}
</script>
