<template>
  <b-modal
    id="intercom-add-relay-modal"
    ref="modal"
    :title="$t('intercom.call_settings.title')"
    @ok="onOk"
    :ok-title="$t('button.save')"
  >
    <b-form @submit.prevent="onOk">
      <div v-if="isShowRelative" class="modal-body-row border-bottom with-cols">
        <b-form-group class="m-0">
          <b-form-checkbox v-model.number="settings.is_relative">
            {{ $t('intercom.call_settings.use_relative_number') }}
          </b-form-checkbox>
        </b-form-group>
      </div>
      <div
        class="modal-body-row border-bottom with-cols"
        v-if="isShowAnalog || isShowProxyCall"
      >
        <b-form-row>
          <b-col>
            <b-form-group>
              <b-form-radio
                v-model="callMode"
                :value="CALL_MODE_TYPES.regular"
                name="call_mode"
              >
                {{ $t('intercom.settings.regular_call') }}
              </b-form-radio>
              <b-form-radio
                v-if="isShowAnalog"
                v-model="callMode"
                :value="CALL_MODE_TYPES.analog"
                name="call_mode"
              >
                {{ $t('intercom.settings.is_analog_support') }}
              </b-form-radio>
              <b-form-radio
                v-if="isShowProxyCall"
                v-model="callMode"
                :value="CALL_MODE_TYPES.proxy"
                name="call_mode"
              >
                {{ $t('intercom.call_settings.use_redirection_scheme') }}
              </b-form-radio>
            </b-form-group>
          </b-col>
          <br />
        </b-form-row>
        <b-form-row v-if="settings.is_analog && coordSchemaAvailable()" class="align-items-center">
            <b-col md="6">
                <BInputLabel
                        v-model="settings.coord_schema"
                        :label="$t('intercom.settings.coord_schema')"
                        type="text"
                />
            </b-col>
            <b-col md="6" class="mb-4">
                <div class="editor-header__info" id="info">i</div>
                <b-popover
                        target="info"
                        triggers="hover"
                        placement="rightbottom"
                >
                    <div class="mt-2">{{ $t('intercom.settings.coord_schema_hint') }}</div>
                </b-popover>
            </b-col>
        </b-form-row>
        <b-form-row v-if="settings.is_analog && selectedOptions.type">
          <b-col md="6">
            <BSelectLabel
              :value="selectedOptions.type"
              :items="TYPE_ITEMS"
              display="title"
              object-property="value"
              :label="$t('intercom.call_type.call_order')"
              @input="onInputType"
            ></BSelectLabel>
          </b-col>
          <b-col md="6" v-if="selectedOptions.type !== 'both'">
            <BSelectLabel
              :value="selectedOptions.delay"
              :items="DELAY_ITEMS"
              display="value"
              object-property="value"
              :label="$t('intercom.call_type.delay')"
              @input="onInputDelay"
            ></BSelectLabel>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col md="12" class="mt-3" v-if="settings.is_proxy_call">
            <List
              v-model="proxySchema"
              :item="{ ip: '', prefix: '', range: '' }"
              not-empty
              #default="{ item, index }"
            >
              <b-form-row>
                <b-col md="3">
                  <BInputLabel
                    v-model="item.prefix"
                    :state="getState(index, 'prefix')"
                    :label="$t('global.prefix')"
                    type="text"
                  />
                </b-col>
                <b-col md="4">
                  <BInputLabel
                    v-model="item.range"
                    :label="$t('global.range')"
                    :state="getState(index, 'range')"
                    type="text"
                  />
                </b-col>
                <b-col md="5">
                  <BInputLabel
                    v-model="item.ip"
                    :label="$t('global.ip_address')"
                    :state="getState(index, 'ip')"
                    type="text"
                  />
                </b-col>
              </b-form-row>
            </List>
          </b-col>
        </b-form-row>
      </div>
    </b-form>
  </b-modal>
</template>

<script>
import { isDigits } from '@/helpers/lodash';
import { BFormRadio } from 'bootstrap-vue';
import { cloneDeep } from 'lodash-es';
import { helpers, ipAddress, required } from 'vuelidate/lib/validators';

import List from '@/components/lists/List.vue';

const CT_VALUES = {
  0: { type: null },
  2: { type: 'both' },
  3: { type: 'sip_then_rs', delay: 10 },
  4: { type: 'sip_then_rs', delay: 20 },
  5: { type: 'sip_then_rs', delay: 30 },
  6: { type: 'sip_then_rs', delay: 60 },
  7: { type: 'sip_then_rs', delay: 120 },
  8: { type: 'rs_then_sip', delay: 10 },
  9: { type: 'rs_then_sip', delay: 20 },
  10: { type: 'rs_then_sip', delay: 30 },
  11: { type: 'rs_then_sip', delay: 60 },
  12: { type: 'rs_then_sip', delay: 120 },
};

const CALL_MODE_TYPES = {
  analog: 'analog',
  proxy: 'proxy',
  regular: 'regular',
};

export default {
  name: 'IntercomCallSettingsModal',
  components: {
    BFormRadio,
    List,
  },
  props: {
    model: {},
    tags: {
      type: Array,
    },
  },
  data() {
    return {
      settings: {
        analog_call_type: 0,
        is_analog: 0,
        is_relative: 0,
        is_proxy_call: 0,
        coord_schema: '',
      },
      callMode: null,
      proxySchema: [],
      isSubmit: false,
    };
  },

  computed: {
    isShowAnalog() {
      return this.tags.includes('is_analog');
    },
    isShowRelative() {
      return !this.tags.includes('no_relative');
    },
    isShowProxyCall() {
      return this.tags.includes('proxy_call');
    },
    CALL_MODE_TYPES: () => CALL_MODE_TYPES,
    DELAY_ITEMS() {
      return [
        { value: 10 },
        { value: 20 },
        { value: 30 },
        { value: 60 },
        { value: 120 },
      ];
    },
    TYPE_ITEMS() {
      if (this.coordSchemaAvailable() && this.model?.rabbit_queue === 'panel_queue') {
        return [{
          title: this.$t('intercom.call_type.both'),
          value: 'both',
        }]
      }

      return [
        {
          title: this.$t('intercom.call_type.both'),
          value: 'both',
        },
        {
          title: this.$t('intercom.call_type.sip_then_rs'),
          value: 'sip_then_rs',
        },
        {
          title: this.$t('intercom.call_type.rs_then_sip'),
          value: 'rs_then_sip',
        },
      ];
    },
    selectedOptions() {
      return CT_VALUES[Number(this.settings.analog_call_type || 0)];
    },
  },
  watch: {
    callMode(val) {
      switch (val) {
        case CALL_MODE_TYPES.regular:
          this.settings.is_analog = 0;
          this.settings.is_proxy_call = 0;
          this.proxySchema = [];
          this.setAnalogCallType(0);
          break;
        case CALL_MODE_TYPES.analog:
          this.settings.is_analog = 1;
          this.settings.is_proxy_call = 0;
          this.proxySchema = [];
          this.setAnalogCallType(2);
          break;
        case CALL_MODE_TYPES.proxy:
          this.settings.is_analog = 0;
          this.settings.is_proxy_call = 1;
          this.setAnalogCallType(120);
          break;
      }
    },
    'settings.is_proxy_call'(val) {
      if (val) {
        const schema = this.parseProxySchema();
        if (schema.length) {
          this.proxySchema = schema;
        } else {
          this.proxySchema = [{ ip: '', prefix: '', range: '' }];
        }
      }
    },
  },
  validations: {
    proxySchema: {
      $each: {
        prefix: {
          isInteger: (val) => {
            console.log('req', helpers.req(val), isDigits(val));
            return !helpers.req(val) || isDigits(val);
          },
        },
        range: {
          required,
          isRange: (value) => {
            const range = value
              .split('-')
              .map((i) => Number(i.trim()))
              .filter((i) => isDigits(i));
            return range.length === 2 && range[0] < range[1];
          },
        },
        ip: {
          required,
          ipAddress,
        },
      },
    },
  },
  methods: {
    defineCallMode(settings) {
      if (settings.is_analog) {
        this.callMode = CALL_MODE_TYPES.analog;
      } else if (settings.is_proxy_call) {
        this.callMode = CALL_MODE_TYPES.proxy;
      } else {
        this.callMode = CALL_MODE_TYPES.regular;
      }
    },
    getState(index, field) {
      if (this.isSubmit) {
        return !this.$v.proxySchema.$each[index][field].$invalid;
      }
      return undefined;
    },
    onInputType(type) {
      if (type === 'both') {
        this.settings.analog_call_type = 2;
      } else {
        const [ctValue] = Object.entries(CT_VALUES).find(
          ([key, value]) =>
            value.type === type &&
            value.delay === (this.selectedOptions.delay || 10),
        );
        this.settings.analog_call_type = Number(ctValue);
      }
    },
    onInputDelay(delay) {
      const [ctValue] = Object.entries(CT_VALUES).find(
        ([key, value]) =>
          value.type === this.selectedOptions.type && value.delay === delay,
      );
      this.settings.analog_call_type = Number(ctValue);
    },
    async onOk(event) {
      event.preventDefault();
      this.isSubmit = true;
      if (this.settings.is_proxy_call) {
        if (this.$v.$invalid) {
          return;
        }
      }
      const proxy_schema = this.proxySchema
        .map(({ ip, prefix, range }) => {
          const [startRange, endRange] = range.replace(/\s/g, '').split('-');
          if (prefix) {
            return `${ip}:${prefix}.${startRange}-${prefix}.${endRange}`;
          }
          return `${ip}:${startRange}-${endRange}`;
        })
        .join(', ');
      this.$emit('update', {
        ...this.settings,
        proxy_schema,
      });
      await this.$nextTick();
      this.$refs.modal.hide();
    },
    parseProxySchema() {
      if (this.settings.proxy_schema) {
        return this.settings.proxy_schema.split(',').map((row) => {
          /**
           * ip = 127.0.0.1
           * prefix = 2
           * range = 1-30
           *
           * value: 127.0.0.1:2.1-2.30 | mask: [ip]:[prefix].[startRange]-[prefix].[endRange]
           *
           * ip = 127.0.0.1
           * range = 1-30
           *
           * value: 127.0.0.1:1-30 | mask: [ip]:[prefix].[startRange]-[endRange]
           */
          const regex =
            /(\w.+):(\d+)\.(\d+\s?)\-\s?\d+\.(\d+)|(\w.+):()(\d+)\s?-\s?(\d+)/m;
          const [_, ip, prefix = '', startRange, endRange] = row
            .match(regex)
            .filter((i) => i !== undefined);
          return {
            ip,
            prefix,
            range: `${startRange}-${endRange}`,
          };
        });
      }
      return [];
    },
    setAnalogCallType(value) {
      this.$set(this.settings, 'analog_call_type', value);
    },
    async show(settings) {
      this.defineCallMode(settings);
      this.isSubmit = false;

      await this.$nextTick();

      this.settings = cloneDeep(settings);
      this.$refs.modal.show();
    },
    coordSchemaAvailable() {
      return this.model.name.includes('RV-3438')
    },
  },
};
</script>
