<template>
  <div class="user-search">
    <BSelectSearch
      :id="`${model}_${name}`"
      :handler="searchUser"
      :de-bounce-timeout="deBounceTimeout"
      :format-result="formatSearchResult"
      :label="placeholder"
      @select="(item) => (user = item)"
      @clear="saveForm"
      v-model="user"
      :selected="selected"
      :displayProperty="display"
    />
    <input
      :value="selectId"
      :id="`${model}_${name}`"
      type="hidden"
      :name="`${model}[${name}]`"
    />
  </div>
</template>
<script>
import BSelectSearch from '@/components/base/BSelectSearch.vue';
import i18n from '@app/i18n';
import { API_URLS } from '@/consts';
import { request3 } from '@/api/request';

export default {
  name: 'UserSearch',
  components: { BSelectSearch },
  props: {
    model: String,
    name: String,
    deBounceTimeout: {
      type: Number,
      default: 800,
    },
    placeholder: {
      type: String,
      default: i18n.t('global.owner'),
    },
    saveFormId: String,
    selected: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      user: this.selected || {},
    };
  },
  watch: {
    user: {
      deep: true,
      handler() {
        if (
          this.saveFormId &&
          !_.isEqual(this.selected, this.user) &&
          this.user
        ) {
          this.saveForm();
        }
      },
    },
  },
  computed: {
    formatSearchResult() {
      return {
        top: [
          {
            title: 'name',
            formatter: (val, item) =>
              item.name && item.surname
                ? `${item.surname || ''} ${item.name || ''} ${
                    item.patronymic || ''
                  }`
                : item.phone,
          },
          { title: 'id', formatter: (value) => `#${value}` },
        ],
        bottom: [
          {
            title: 'contact',
            formatter: (val, item) =>
              [item.phone, item.email].filter((e) => e).join(', '),
          },
        ],
      };
    },
    selectId() {
      return this.user?.id ? this.user.id : null;
    },
    display() {
      if (this.user) {
        return this.user?.name ? 'name' : 'phone';
      }
      return 'name';
    },
  },
  methods: {
    searchUser(val) {
      const encoldeVal = encodeURIComponent(val);
      return request3.get(API_URLS.backendManage.users.fetch(this.clientId), {
        params: {
          q: {
            phone_or_email_or_name_or_surname_or_patronymic_or_full_name_or_reverse_full_name_or_users_clients_name_or_users_clients_surname_or_users_clients_patronymic_or_users_clients_full_name_or_users_clients_reverse_full_name_cont_any:
              encoldeVal,
          },
          per_page: 10,
        },
      });
    },
    saveForm() {
      if (_.isUndefined(this.saveFormId)) {
        return;
      }

      const vm = this;
      setTimeout(function () {
        $(`#${vm.saveFormId}`).submit();
      }, 100);
    },
    selectUser(user) {
      this.user = Object.assign({}, user);
      if (this.saveFormId) {
        this.saveForm();
      }
    },
  },
};
</script>
<style lang="scss">
@import 'app/javascript/assets/styles/components/variables';

.user-search {
  .b-input-with-search-input {
    border-radius: 5px;

    &:disabled {
      background: inherit;
      border-color: $border-color !important;
    }
  }

  .b-input-with-search .undo-disable-input {
    right: 18px;
  }
}
</style>
