<template>
  <b-modal
    id="plate-create-modal"
    ref="modal"
    :ok-title="$t('button.save')"
    @ok="onOk"
  >
    <template #modal-header="{ close }">
      <h2 class="modal-title" v-if="!model.id">{{ $t('plate.modal.new') }}</h2>
      <h2 class="modal-title" v-else>{{ $t('plate.modal.edit') }}</h2>
      <button class="close" @click="close()">×</button>
    </template>
    <b-form-validate ref="form" :model="model" :validations="validations">
      <template slot-scope="{ validateState }">
        <div class="modal-body-row with-cols pt-30">
          <b-form-row class="mb-3">
            <b-col cols="12">
              <BSelectSearch
                id="input-plate-modal_user"
                :handler="searchUser"
                :de-bounce-timeout="1000"
                :format-result="formatSearchResult"
                :label="$t('plate.modal.owner')"
                @select="(item) => (user = item)"
                v-model="model.user_attributes"
                :selected="model.user_attributes || {}"
                :disabled="is_new_user"
                :state="
                  is_new_user ? undefined : validateState('user_attributes')
                "
              />
              <span class="small text-danger">{{ errors.user }}</span>
            </b-col>
          </b-form-row>
          <b-form-row class="mb-3">
            <b-col cols="12">
              <b-form-checkbox v-model="is_new_user" @change="newUser">
                {{ $t('plate.modal.create_user') }}
              </b-form-checkbox>
            </b-col>
          </b-form-row>
          <b-form-row v-if="is_new_user">
            <b-col cols="6">
              <BInputLabel
                id="input-plate-modal_phone"
                :label="$t('plate.modal.user.phone')"
                :state="validateState('user_attributes.phone')"
                v-model="model.user_attributes.phone"
                class="my-0"
              />
              <span class="small text-danger">{{ errors.phone }}</span>
            </b-col>
            <b-col cols="6" class="mb-3">
              <BInputLabel
                id="input-plate-modal_email"
                :label="$t('plate.modal.user.email')"
                :state="validateState('user_attributes.email')"
                v-model="model.user_attributes.email"
                class="my-0"
              />
              <span class="small text-danger">{{ errors.email }}</span>
            </b-col>
          </b-form-row>
          <b-form-row v-if="is_new_user" class="border-bottom mb-4 pb-2">
            <b-col cols="4" class="mb-3">
              <BInputLabel
                id="input-plate-modal_surname"
                :label="$t('plate.modal.user.surname')"
                :state="validateState('user_attributes.surname')"
                v-model="model.user_attributes.surname"
                class="my-0"
              />
              <span class="small text-danger">{{ errors.surname }}</span>
            </b-col>
            <b-col cols="4" class="mb-3">
              <BInputLabel
                id="input-plate-modal_name"
                :label="$t('plate.modal.user.name')"
                :state="validateState('user_attributes.name')"
                v-model="model.user_attributes.name"
                class="my-0"
              />
              <span class="small text-danger">{{ errors.name }}</span>
            </b-col>
            <b-col cols="4" class="mb-3">
              <BInputLabel
                id="input-plate-modal_patronymic"
                :label="$t('plate.modal.user.patronymic')"
                v-model="model.user_attributes.patronymic"
              />
            </b-col>
          </b-form-row>
          <b-form-row>
            <b-col cols="6" class="mb-3">
              <div class="d-flex align-items-center">
                <BInputLabel
                  id="input-plate-modal_plate"
                  :label="$t('plate.modal.plate')"
                  :state="validateState('plate')"
                  v-model="model.plate"
                  class="flex-grow-1 m-0"
                />
                <div class="hint-info my-0" id="info">i</div>
                <b-popover
                  target="info"
                  triggers="hover"
                  placement="rightbottom"
                  :title="$t('plate.hint.title')"
                >
                  <p class="mb-2">
                    - {{ $t('plate.hint.lungs') }} (
                    <strong>C222HA69</strong>
                    )
                  </p>
                  <p class="mb-2">
                    - {{ $t('plate.hint.motorcycles') }} (
                    <strong>1133AA77</strong>
                    )
                  </p>
                  <p class="mb-2">
                    - {{ $t('plate.hint.police') }} ({{ $t('plate.hint.cars') }}
                    -
                    <strong>A123478</strong>
                    , {{ $t('plate.hint.motorcycles') }} -
                    <strong>5537A99</strong>
                    )
                  </p>
                  <p class="mb-2">
                    - {{ $t('plate.hint.transport') }} (
                    <strong>AO36578</strong>
                    )
                  </p>
                  <p class="mb-2">
                    - {{ $t('plate.hint.diplomatic') }} ({{
                      $t('plate.hint.two_types')
                    }}
                    -
                    <strong>002CD178</strong>
                    ,
                    <strong>002D04078</strong>
                    )
                  </p>
                  <p class="mb-2">
                    - {{ $t('plate.hint.military') }} (
                    <strong>0245OK43</strong>
                    )
                  </p>
                </b-popover>
              </div>
              <span class="small text-danger">{{ errors.plate }}</span>
            </b-col>
            <b-col cols="6" class="mb-3">
              <SelectSlot
                :label="$t('plate.modal.color')"
                :items="carColors"
                :state="validateState('color')"
                selector="mb-0"
                @select="(value) => onSelect(value, index)"
                id="input-plate-modal_color"
              >
                <select
                  v-model="model.color"
                  class="form-control select optional"
                >
                  <option
                    v-for="(color, index) in carColors"
                    :value="color.value"
                    :key="index"
                    :title="color.title"
                  >
                    {{ color.title }}
                  </option>
                </select>
              </SelectSlot>
              <span class="small text-danger">{{ errors.color }}</span>
            </b-col>
          </b-form-row>
          <b-form-row>
            <b-col cols="12">
              <MarksAutocomplete
                class="w-100"
                :placeholder="$t('plate.modal.mark')"
                :clientId="clientId"
                v-model="model.npr_car_mark_id"
                :markId="model.npr_car_mark_id"
              />
            </b-col>
          </b-form-row>
          <b-form-row>
            <b-col cols="12">
              <p class="font-weight-bold">
                {{ $t('plate.modal.access') }}
              </p>
            </b-col>
            <b-col cols="12">
              <BuildingsList
                v-model="model.geo_units"
                ref="BuildingsList"
                :required="false"
                :clientId="clientId"
                npr
                :q="{
                  type_not_in: ['City', 'Street', 'Floor', 'Section', 'Roof'],
                }"
                :add-title="$t('plate.modal.add_geo_unit')"
                disable-add-check-property="building_id"
                init-geo-unit-name-property="building_name"
                @focus="onFocus"
              />
              <span class="small text-danger">{{ errors.geo_units }}</span>
            </b-col>
          </b-form-row>
        </div>
      </template>
    </b-form-validate>
  </b-modal>
</template>

<script>
import BSelectSearch from '@/components/base/BSelectSearch.vue';
import { API_URLS, EVENTS } from '@/consts';
import { request3 } from '@/api/request';
import EventBus from '@/packs/EventBus';
import SelectSlot from '@/vue_slim/components/base/SelectSlot';
import {
  maxLength,
  minLength,
  required,
  requiredIf,
} from 'vuelidate/lib/validators';
import _ from 'lodash';
import BuildingsList from '@/components/lists/BuildingsList.vue';
import { BPopover } from 'bootstrap-vue';
import MarksAutocomplete from '../autocompletes/MarksAutocomplete.vue';

function initData(plate) {
  return {
    model: _.cloneDeep(plate) || {
      npr_car_mark_id: null,
      npr_car_model_id: null,
      plate: null,
      service_plate_id: null,
      color: null,
      user: {},
      type: 'permanent',
      valid_from: null,
      valid_to: null,
      user_attributes: {},
      npr_plates_geo_units: [],
      geo_units: [],
    },
    is_new_user: false,
    origin_plate_geo_units: plate?.npr_plates_geo_units || [],
    errors: {
      plate: null,
      phone: null,
      email: null,
      name: null,
      surname: null,
      user: null,
      geo_units: null,
      color: null,
    },
  };
}

export default {
  name: 'PlateModal',
  components: {
    BSelectSearch,
    SelectSlot,
    BuildingsList,
    BPopover,
    MarksAutocomplete,
  },
  props: {
    clientId: {
      required: true,
    },
    carColors: {
      type: Array,
      default: null,
    },
    types: {
      type: Array,
      default: null,
    },
  },

  data() {
    return {
      ...initData(),
      isCreate: false,
      carMarks: null,
    };
  },
  computed: {
    validations() {
      return {
        user_attributes: {
          id: requiredIf(() => !this.is_new_user),
          phone: {
            required: requiredIf(() => !this.model.user_attributes.email),
            maxLength: maxLength(12),
            minLength: minLength(12),
          },
          surname: { required: requiredIf(() => this.is_new_user) },
          name: { required: requiredIf(() => this.is_new_user) },
          email: {
            required: requiredIf(() => !this.model.user_attributes.phone),
          },
        },
        plate: { required, maxLength: maxLength(16) },
        geo_units: {
          required,
          $each: {
            building_id: { required },
          },
        },
        color: { required },
      };
    },
    formatSearchResult() {
      return {
        top: [
          {
            title: 'name',
            formatter: (val, item) =>
              `${item.surname || ''} ${item.name || ''} ${
                item.patronymic || ''
              }`,
          },
          { title: 'id', formatter: (value) => `#${value}` },
        ],
        bottom: [
          {
            title: 'contact',
            formatter: (val, item) =>
              [item.phone, item.email].filter((e) => e).join(', '),
          },
        ],
      };
    },
  },
  mounted() {
    EventBus.$on('PlateModal::show', this.show);
  },
  methods: {
    async show(value, isCreate = true) {
      this.isCreate = isCreate;
      if (value) {
        value.geo_units.forEach((gu) => {
          gu.building_id = gu.id || gu.building_id;
          delete gu.id;
        });
        Object.assign(this.$data, initData(value));
        this.$set(this.model, 'user_attributes', Object.assign({}, value.user));
      } else {
        Object.assign(this.$data, initData());
      }
      this.$refs.modal.show();
    },
    onFocus(index) {
      let user_id = null;
      if (!_.isUndefined(this.model.user_attributes.id)) {
        user_id = this.model.user_attributes.id;
      }
      this.$refs.BuildingsList.onFocusAction(user_id, index);
    },
    onOk(event) {
      this.errors = {
        plate: null,
        phone: null,
        email: null,
        name: null,
        surname: null,
        user: null,
        geo_units: null,
      };
      if (event) event.preventDefault();

      if (this.$refs.form.validate()) {
        const message = this.isCreate
          ? this.$t('plate.modal.creation_message')
          : this.$t('plate.modal.updating_message');
        EventBus.$emit(EVENTS.preloader.show, message);
        const plate = { ...this.model };
        delete plate['user'];

        if (plate.user_attributes.id) {
          plate.user_id = plate.user_attributes.id;
          delete plate['user_attributes'];
        }
        let geoUnits = [];
        if (this.isCreate) {
          geoUnits = plate.geo_units.map((gu) => {
            return Object.assign(
              {},
              {
                geo_unit_id: gu.building_id,
              },
            );
          });
        } else {
          const origin_grouped = _.groupBy(
            this.origin_plate_geo_units,
            (origin_gu) => origin_gu.geo_unit_id,
          );
          let origin_gui_counts = {};
          Object.keys(origin_grouped).forEach(
            (geo_unit_id) =>
              (origin_gui_counts[geo_unit_id] =
                origin_grouped[geo_unit_id].length),
          );
          const current_grouped = _.groupBy(
            plate.geo_units,
            (gu) => gu.building_id,
          );
          let current_gui_counts = {};
          Object.keys(current_grouped).forEach(
            (geo_unit_id) =>
              (current_gui_counts[geo_unit_id] =
                current_grouped[geo_unit_id].length),
          );

          let gui_counts = {};

          Object.keys(origin_gui_counts).forEach((geo_unit_id) => {
            const count = origin_gui_counts[geo_unit_id];
            gui_counts[geo_unit_id] = count;
          });

          Object.keys(current_gui_counts).forEach((geo_unit_id) => {
            const count = current_gui_counts[geo_unit_id];
            gui_counts[geo_unit_id] = count;
          });

          Object.keys(gui_counts).forEach((geo_unit_id) => {
            const origin_count = origin_gui_counts[geo_unit_id] || 0;
            const current_count = current_gui_counts[geo_unit_id] || 0;
            const amount_to_delete = origin_count - current_count;
            for (let i = 0; i < amount_to_delete; i += 1) {
              const origin_gu = origin_grouped[geo_unit_id][i];
              geoUnits.push({
                id: origin_gu.id,
                _destroy: true,
              });
            }
            const amount_to_add = current_count - origin_count;
            for (let i = 0; i < amount_to_add; i += 1) {
              geoUnits.push({
                geo_unit_id,
                id: '',
                _destroy: '',
              });
            }
          });
        }

        plate.npr_plates_geo_units_attributes = geoUnits;

        const data = {
          npr_car_mark_id: plate.npr_car_mark_id,
          npr_car_model_id: plate.npr_car_model_id,
          plate: plate.plate,
          service_plate_id: plate.service_plate_id,
          color: plate.color,
          type: plate.type,
          valid_from: plate.valid_from,
          valid_to: plate.valid_from,
          npr_plates_geo_units_attributes:
            plate.npr_plates_geo_units_attributes,
        };

        if (plate.user_id && !plate.user_attributes) {
          data.user_id = plate.user_id;
        } else {
          let str = plate.user_attributes.email
            ? plate.user_attributes.email.replace(/\s/g, '')
            : null;
          if (!str || str == '') {
            delete plate.user_attributes.email;
          }
          str = plate.user_attributes.phone
            ? plate.user_attributes.phone.replace(/\s/g, '')
            : null;
          if (!str || str == '') {
            delete plate.user_attributes.phone;
          }
          data.user_attributes = plate.user_attributes;
        }

        const request = this.isCreate ? request3.post : request3.patch;
        const url = this.isCreate
          ? API_URLS.npr.plates.fetch(this.clientId)
          : API_URLS.npr.plates.one(plate.id, this.clientId);

        request(url, {
          manage_id: this.clientId,
          plate: data,
        })
          .then(() => {
            this.msgBoxOkSuccess();
            this.$refs.modal.hide();
            setTimeout(() => {
              location.reload();
            }, 500);
          })
          .catch((error) => {
            this.$bvToast.toast(
              Object.values(error.response.data.errors).join('. '),
            );
          })
          .finally(() => {
            EventBus.$emit(EVENTS.preloader.hide);
          });
      } else {
        if (!this.model.plate) {
          this.errors.plate = this.$t('plate.modal.error_empty');
        } else {
          if (this.model.plate.length > 16) {
            this.errors.plate = this.$t('plate.modal.error_plate_length');
          }
        }

        if (this.is_new_user) {
          if (this.model.user_attributes) {
            if (
              !this.model.user_attributes.phone &&
              !this.model.user_attributes.email
            ) {
              this.errors.phone = this.$t('plate.modal.error_empty');
              this.errors.email = this.$t('plate.modal.error_empty');
            }
            if (!this.model.user_attributes.name) {
              this.errors.name = this.$t('plate.modal.error_empty');
            }
            if (!this.model.user_attributes.surname) {
              this.errors.surname = this.$t('plate.modal.error_empty');
            }
          }
        } else {
          if (!this.model.user_attributes.id) {
            this.errors.user = this.$t('plate.modal.error_empty');
            this.errors.phone = this.$t('plate.modal.error_empty');
            this.errors.email = this.$t('plate.modal.error_empty');
            this.errors.name = this.$t('plate.modal.error_empty');
            this.errors.surname = this.$t('plate.modal.error_empty');
          }
        }

        if (this.model.geo_units.length < 1) {
          this.errors.geo_units = this.$t('plate.modal.error_geo_units');
        } else {
          const valid_geo_units = this.model.geo_units.filter(
            (gu) => gu.building_id,
          );
          if (valid_geo_units.length != this.model.geo_units.length) {
            this.errors.geo_units = this.$t('plate.modal.error_geo_units_empty');
          }
        }

        if (!this.model.color) {
          this.errors.color = this.$t('plate.modal.error_empty');
        }
      }
    },
    searchUser(val) {
      const encoldeVal = encodeURIComponent(val);
      return request3.get(API_URLS.backendManage.users.fetch(this.clientId), {
        params: {
          q: {
            phone_or_email_or_name_or_surname_or_patronymic_or_full_name_or_reverse_full_name_or_users_clients_name_or_users_clients_surname_or_users_clients_patronymic_or_users_clients_full_name_or_users_clients_reverse_full_name_cont_any:
              encoldeVal,
          },
          per_page: 10,
        },
      });
    },
    msgBoxOkSuccess() {
      const message = this.isCreate
        ? this.$t('plate.modal.success_create_message')
        : this.$t('plate.modal.success_update_message');
      this.$bvModal.msgBoxOk(message, {
        modalClass: 'modal-ok',
        okTitle: this.$t('button.ok'),
        title: this.$t('title.successfully'),
      });
    },
    newUser() {
      this.$set(this.model, 'user_attributes', {});

      if (this.is_new_user) {
        this.$set(this.model, 'user_attributes', {
          name: null,
          surname: null,
          patronymic: null,
          email: null,
          phone: null,
        });
      }

      if (this.errors.user) {
        this.errors.user = null;
      }
    },
    removeGeoUnit(index) {
      let geoUnits = this.model.npr_plates_geo_units_attributes;
      geoUnits = geoUnits.splice(index, 1);
      this.$set(this.model, 'npr_plates_geo_units_attributes', geoUnits);
    },
    addGeoUnit() {
      let geoUnits = this.model.npr_plates_geo_units_attributes;
      geoUnits.push({});
      this.$set(this.model, 'npr_plates_geo_units_attributes', geoUnits);
    },
  },
};
</script>
<style lang="scss">
@import '../../assets/styles/components/variables';

.border-bottom {
  border-bottom: 1px solid $border;
}

.hint-info {
  width: 30px;
  height: 30px;
  border: 1px solid $gray-300;
  border-radius: 50%;
  text-align: center;
  margin-left: 10px;
  margin-bottom: 15px;
  color: $gray-600;
  font-style: italic;
  font-size: $font-size-20px;
  padding-right: 1px;
  cursor: pointer;
}
</style>
