<template>
  <b-form-validate ref="form" :validations="validations" :model="value">
    <template slot-scope="{ validateState }">
      <b-form-row>
        <b-col md="5">
          <GeoUnitsAutocomplete2
            :value="value.geo_unit_id"
            :state="validateState('geo_unit_id')"
            @clear="onClearGeoUnit"
            @select:item="onInputGeoUnit"
          />
        </b-col>
        <b-col md="3">
          <BSelectLabel
            :label="$t('global.type')"
            :disabled="ifDisabledType"
            :items="typeItems"
            :value="value.geo_unit_type"
            @select="onSelectType"
            objectProperty="type"
            display="translate"
          ></BSelectLabel>
        </b-col>
        <b-col md="4">
          <ListManageItem
            :is-show-add-button="isShowAddButton"
            @add-item="onAddItem"
            @delete-item="onDeleteItem"
          >
            <BInputLabel
              :label="$t('global.range')"
              :disabled="ifDisabledType"
              :value="value.range"
              :state="validateState('range')"
              @input="onInputRange"
            ></BInputLabel>
          </ListManageItem>
        </b-col>
      </b-form-row>
    </template>
  </b-form-validate>
</template>

<script>
import { ApiGeoUnits } from '@/api';
import GeoUnitsAutocomplete2 from '@/components/autocompletes/GeoUnitsAutocomplete2.vue';
import { GEO_UNITS } from '@/consts';
import { checkStringWithNumbers } from '@/vue_app/components/building_creat/utils';
import ListManageItem from '@app/components/lists/ListManageItem.vue';
import { required } from 'vuelidate/lib/validators';

const { Apartment, Bkfn, ParkingPlace, StoreRoom } = GEO_UNITS;

export default {
  name: 'IntercomRelayPermissionForm',
  components: {
    GeoUnitsAutocomplete2,
    ListManageItem,
  },
  props: {
    isShowAddButton: {
      type: Boolean,
    },
    value: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      geoUnit: null,
      validations: {
        // eslint-disable-next-line consistent-return
        range: {
          checkStringWithNumbers: (range) => {
            console.log('range', range);
            if (
              range?.length &&
              this.value.geo_unit_type === GEO_UNITS.Building
            ) {
              return checkStringWithNumbers(range);
            }
            return true;
          },
        },
        geo_unit_id: { required },
      },
    };
  },
  computed: {
    typeItems() {
      const geoUnits = [ParkingPlace, Apartment, StoreRoom, Bkfn].map(
        (type) => ({
          type,
          translate: this.$tc(`geo_unit.type_only_names.${type}`, 0),
        }),
      );
      return [
        { type: 'none', translate: this.$t('global.not_selected') },
        ...geoUnits,
      ];
    },
    ifDisabledType() {
      return this.geoUnit?.type !== GEO_UNITS.Building;
    },
  },
  async mounted() {
    await this.getGeoUnit();
  },
  methods: {
    emitInput(data) {
      this.$emit('input', { ...this.value, ...data });
    },
    async getGeoUnit() {
      if (this.value.geo_unit_id) {
        try {
          this.geoUnit = await ApiGeoUnits.getOne(this.value.geo_unit_id);
        } catch (err) {
          this.$bvToast.toast(this.$t('error.get_data'));
        }
      }
    },
    onAddItem() {
      this.$emit('add-item');
    },
    onClearGeoUnit() {
      this.emitInput({
        geo_unit_id: null,
        geo_unit_type: null,
        range: null,
      });
    },
    onDeleteItem() {
      this.$emit('delete-item');
    },
    onInputGeoUnit(geoUnit) {
      this.geoUnit = geoUnit;
      const data = { geo_unit_id: geoUnit.id || null };
      if (geoUnit.type !== GEO_UNITS.Building) {
        data.geo_unit_type = null;
        data.range = null;
      }
      this.emitInput(data);
    },
    onInputRange(value) {
      this.emitInput({ range: value });
    },
    onSelectType(value) {
      if (value === 'none') {
        this.emitInput({ geo_unit_type: null, range: null });
      } else {
        this.emitInput({ geo_unit_type: value });
      }
    },
    validate() {
      return this.$refs.form.validate();
    },
  },
};
</script>

<style scoped></style>
