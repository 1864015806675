<template>
  <div
    class="portlet mb-5"
    v-if="this.resources.find((resource) => resource.present === true)"
  >
    <div class="d-flex align-items-center justify-content-between mb-2">
      <h4 class="m-0">{{ $t("meters.sheet") }}</h4>
    </div>
    <div class="text-muted mb-1">{{ $t("meters.total") }}</div>

    <div class="row row-md mt-3 mb-5">
      <div
        v-for="resource in resources"
        v-if="resource.present"
        :key="resource.id"
        class="col-md-6 col-lg-4 col-xxxl-3 mb-1 mb-xxxl-0"
      >
        <div
          class="card information-count-card px-4 py-3 card-link"
          :class="{ 'card-active': selectResource === resource.id }"
          @click="selectResource = resource.id"
        >
          <div class="text-muted mb-1">{{ resource.name }}</div>
          <div class="d-flex flex-wrap">
            <div v-for="(variable, index) in resource.variables" :key="index">
              <!-- <div v-if="variable.variable_prefix" class="h4 mb-0 mr-3">
                <span class="text-muted">{{variable.variable_prefix}}</span> 
                {{variable.variable_data}}{{variable.variable_suffix}}
              </div>
              <div v-else class="h4 mb-0">
                {{variable.variable_data}}{{variable.variable_suffix}}
              </div> -->
              <div class="h4 mb-0">
                {{ variable.variable_data }} {{ variable.variable_suffix }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="
        this.resources.find(
          (resource) =>
            resource.present === true && resource.name === this.$tc('personal.meters.cold_water'),
        )
      "
      v-show="selectResource === '1'"
      class="mt-xxxl-3"
    >
      <div class="font-size-16px text-muted">Холодное водоснабжение</div>
      <div class="mt-2">
        <div class="row row-md">
          <div
            v-for="count in coldWaterCounters"
            :key="count.id"
            class="col-md-6 col-lg-4"
          >
            <!-- <div class="card information-count-card px-4 py-3 card-link bg-white" :class="{'card-active': selectColdWaterCount === count.id}" @click="selectColdWaterCount = count.id"> -->
            <div
              class="card information-count-card px-4 py-3 card-default bg-white"
              :class="count.status_card"
            >
              <div class="d-flex align-items-center justify-content-between">
                <div class="pointer text-muted mb-1 d-inline-block">
                  <span>Счетчик #{{ count.name }}</span>
                </div>
                <div
                  v-if="count.status"
                  :class="'pointer ml-2 ' + count.status_color_class"
                >
                  <span :id="'status-cold-water-' + count.id">{{
                    count.status
                  }}</span>
                  <b-tooltip
                    :target="'status-cold-water-' + count.id"
                    placement="top"
                  >
                    {{ count.status_full }}
                  </b-tooltip>
                </div>
              </div>
              <div class="h4 mb-2">{{ count.variable }} м³</div>
              <div class="d-flex align-items-center justify-content-between">
                <div class="text-muted mr-4">{{ count.date }}</div>
                <div v-if="count.battery > 60">
                  <!-- TODO: Отрисовать горизонтальную иконку сюда и далее -->
                  <span class="icon-local-vertical-buttery text-success"></span>
                </div>
                <div v-else-if="count.battery > 30 && count.battery < 60">
                  <span class="icon-local-vertical-buttery text-warning"></span>
                </div>
                <div v-else>
                  <span class="icon-local-vertical-buttery text-danger"></span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- <div class="row row-md mt-3">
          <div class="col-lg-7">
            <div class="bg-light rounded-top rounded-bottom line-chart d-flex align-items-center justify-content-center">
              График расхода
            </div>
          </div>
          <div class="col-lg-5">
            <div class="mb-4">
              <div><span class="text-muted">Расход за</span> Январь 2021</div>
              <div class="line-graph mb-1"></div>
              <div v-show="selectColdWaterCount === '1'" class="h5">11,4335м³</div>
              <div v-show="selectColdWaterCount === '2'" class="h5">7,7655м³</div>
            </div>
            <div class="mb-3">
              <div class="text-muted">Расход за <a @click="mounthDropdown = !mounthDropdown" class="text-primary pointer">Декабрь 2020 <span class="icon-local-shevron-down"></span></a></div>
              <div class="dot-graph mb-1"></div>
              <div v-show="selectColdWaterCount === '1'" class="h5">14,8459м³</div>
              <div v-show="selectColdWaterCount === '2'" class="h5">6,5646м³</div>
            </div>
          </div>
        </div> -->
      </div>
    </div>

    <div
      v-if="
        this.resources.find(
          (resource) =>
            resource.present === true && resource.name === this.$tc('personal.meters.hot_water'),
        )
      "
      v-show="selectResource === '2'"
      class="mt-3"
    >
      <div class="font-size-16px text-muted">Горячее водоснабжение</div>
      <div class="mt-2">
        <div class="row row-md">
          <div
            v-for="count in hotWaterCounters"
            :key="count.id"
            class="col-md-6 col-lg-4"
          >
            <!-- <div class="card information-count-card px-4 py-3 card-link bg-white" :class="{'card-active': selectHotWaterCount === count.id}" @click="selectHotWaterCount = count.id"> -->
            <div
              class="card information-count-card px-4 py-3 card-default bg-white"
              :class="count.status_card"
            >
              <div class="d-flex align-items-center justify-content-between">
                <div class="pointer text-muted mb-1 d-inline-block">
                  <span>Счетчик #{{ count.name }}</span>
                </div>
                <div
                  v-if="count.status"
                  :class="'pointer ml-2 ' + count.status_color_class"
                >
                  <span :id="'status-hot-water-' + count.id">{{
                    count.status
                  }}</span>
                  <b-tooltip
                    :target="'status-hot-water-' + count.id"
                    placement="top"
                  >
                    {{ count.status_full }}
                  </b-tooltip>
                </div>
              </div>
              <div class="h4 mb-2">{{ count.variable }} м³</div>
              <div class="d-flex align-items-center justify-content-between">
                <div class="text-muted mr-4">{{ count.date }}</div>
                <div v-if="count.battery > 60">
                  <span class="icon-local-vertical-buttery text-success"></span>
                </div>
                <div v-else-if="count.battery > 30 && count.battery < 60">
                  <span class="icon-local-vertical-buttery text-warning"></span>
                </div>
                <div v-else>
                  <span class="icon-local-vertical-buttery text-danger"></span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- <div class="row row-md mt-3">
          <div class="col-lg-7">
            <div class="bg-light rounded-top rounded-bottom line-chart d-flex align-items-center justify-content-center">
              График расхода
            </div>
          </div>
          <div class="col-lg-5">
            <div class="mb-4">
              <div><span class="text-muted">Расход за</span> Январь 2021</div>
              <div class="line-graph mb-1"></div>
              <div v-show="selectHotWaterCount === '1'" class="h5">10,7845м³</div>
              <div v-show="selectHotWaterCount === '2'" class="h5">21,7698м³</div>
            </div>
            <div class="mb-3">
              <div class="text-muted">Расход за <a @click="mounthDropdown = !mounthDropdown" class="text-primary pointer">Декабрь 2020 <span class="icon-local-shevron-down"></span></a></div>
              <div class="dot-graph mb-1"></div>
              <div v-show="selectHotWaterCount === '1'" class="h5">15,5434м³</div>
              <div v-show="selectHotWaterCount === '2'" class="h5">12,7984м³</div>
            </div>
          </div>
        </div> -->
      </div>
    </div>

    <div
      v-if="
        this.resources.find(
          (resource) =>
            resource.present === true && resource.name === this.$tc('personal.meters.electricity'),
        )
      "
      v-show="selectResource === '3'"
      class="mt-3"
    >
      <div class="font-size-16px text-muted">Электричество</div>
      <div class="mt-2">
        <div class="row row-md">
          <div
            v-for="(count, index) in electroCounters"
            :key="count.id"
            class="col-md-6 col-lg-4"
          >
            <!-- <div class="card information-count-card px-4 py-3 card-link bg-white" :class="{'card-active': selectElectroCount === count.id}" @click="selectElectroCount = count.id"> -->
            <div
              class="card information-count-card px-4 py-3 card-default bg-white"
            >
              <div class="text-muted mr-5 mb-1">
                Тариф {{ index + 1 }} ({{ count.name }})
              </div>
              <div class="h4 mb-2">{{ count.variable }} кВт•ч</div>

              <div class="d-flex align-items-center justify-content-between">
                <div class="text-muted">{{ count.date }}</div>
              </div>
            </div>
          </div>
        </div>

        <!-- <div class="row row-md mt-3">
          <div class="col-lg-7">
            <div class="bg-light rounded-top rounded-bottom line-chart d-flex align-items-center justify-content-center">
              График расхода
            </div>
          </div>
          <div class="col-lg-5">
            <div class="mb-4">
              <div><span class="text-muted">Расход за</span> Январь 2021</div>
              <div class="line-graph mb-1"></div>
              <div v-show="selectElectroCount === '1'" class="h5">11,5кВт•ч</div>
              <div v-show="selectElectroCount === '2'" class="h5">6,25кВт•ч</div>
            </div>
            <div class="mb-3">
              <div class="text-muted">Расход за <a @click="mounthDropdown = !mounthDropdown" class="text-primary pointer">Декабрь 2020 <span class="icon-local-shevron-down"></span></a></div>
              <div class="dot-graph mb-1"></div>
              <div v-show="selectElectroCount === '1'" class="h5">21,76кВт•ч</div>
              <div v-show="selectElectroCount === '2'" class="h5">12,79кВт•ч</div>
            </div>
          </div>
        </div> -->
      </div>
    </div>

    <div
      v-if="
        this.resources.find(
          (resource) => resource.present === true && resource.name === this.$tc('personal.meters.heat'),
        )
      "
      v-show="selectResource === '4'"
      class="mt-3"
    >
      <div class="font-size-16px text-muted">Тепло</div>
      <div class="mt-2">
        <div class="row row-md">
          <div
            v-for="(count, index) in heatCounters"
            :key="count.id"
            class="col-md-6 col-lg-4"
          >
            <!-- <div class="card information-count-card px-4 py-3 card-link bg-white" :class="{'card-active': selectHeatCount === count.id}" @click="selectHeatCount = count.id"> -->
            <div
              class="card information-count-card px-4 py-3 card-default bg-white"
              :class="count.status_card"
            >
              <div class="d-flex align-items-center justify-content-between">
                <div class="pointer text-muted mb-1 d-inline-block">
                  <span>Счетчик #{{ count.name }}</span>
                </div>
                <div
                  v-if="count.status"
                  :class="'pointer ml-2 ' + count.status_color_class"
                >
                  <span :id="'status-heat-' + count.id">{{
                    count.status
                  }}</span>
                  <b-tooltip
                    :target="'status-heat-' + count.id"
                    placement="top"
                  >
                    {{ count.status_full }}
                  </b-tooltip>
                </div>
              </div>
              <div class="d-flex align-items-center mb-2">
                <div class="h4 mb-0">{{ count.variable }} Гкал</div>
                <div class="d-flex ml-2">
                  <div class="font-sise-16px ml-3">{{ count.capacity }} м³</div>
                  <div class="font-sise-16px ml-3 text-danger">
                    {{ count.temp_in }}°
                  </div>
                  <div class="font-sise-16px ml-3 text-primary">
                    {{ count.temp_out }}°
                  </div>
                </div>
              </div>
              <div class="d-flex align-items-center justify-content-between">
                <div class="text-muted mr-4">{{ count.date }}</div>
                <div v-if="count.battery > 60">
                  <span class="icon-local-vertical-buttery text-success"></span>
                </div>
                <div v-else-if="count.battery > 30 && count.battery < 60">
                  <span class="icon-local-vertical-buttery text-warning"></span>
                </div>
                <div v-else>
                  <span class="icon-local-vertical-buttery text-danger"></span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- <div class="row row-md mt-3">
          <div class="col-lg-7">
            <div class="bg-light rounded-top rounded-bottom line-chart d-flex align-items-center justify-content-center">
              График расхода
            </div>
          </div>
          <div class="col-lg-5">
            <div class="mb-4">
              <div><span class="text-muted">Расход за</span> Январь 2021</div>
              <div class="line-graph mb-1"></div>
              <div v-show="selectHeatCount === '1'" class="h5">34,75Гкал</div>
              <div v-show="selectHeatCount === '2'" class="h5">23,25Гкал</div>
            </div>
            <div class="mb-3">
              <div class="text-muted">Расход за <a @click="mounthDropdown = !mounthDropdown" class="text-primary pointer">Декабрь 2020 <span class="icon-local-shevron-down"></span></a></div>
              <div class="dot-graph mb-1"></div>
              <div v-show="selectHeatCount === '1'" class="h5">21,76Гкал</div>
              <div v-show="selectHeatCount === '2'" class="h5">12,79Гкал</div>
            </div>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import LineChart from '@/components/charts/LineChart.js';
import { BTooltip } from 'bootstrap-vue';

export default {
  components: {
    LineChart,
    BTooltip,
  },
  props: {
    selectedGeoUnitId: {
      required: true,
      type: Number,
    },
    resources: {
      type: Array,
    },
    coldWaterCounters: {
      type: Array,
    },
    hotWaterCounters: {
      type: Array,
    },
    electroCounters: {
      type: Array,
    },
    heatCounters: {
      type: Array,
    },
  },
  mounted() {
    console.log(this.$tc('personal.meters.electricity'))
  },
  data() {
    return {
      mounthDropdown: false,
      selectResource: this.selectedResource(),
    };
  },
  methods: {
    selectedResource() {
      const selected_resource = this.resources.find(
        (resource) => resource.present === true,
      );
      return selected_resource ? selected_resource.id : '';
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../../assets/styles/components/variables';

.line-chart {
  height: 320px;
}

.line-graph {
  border-top: 2px solid $blue;
}

.dot-graph {
  border-bottom: 2px dotted $blue;
}
</style>
