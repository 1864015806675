/* eslint-disable */

import i18n from '@app/i18n';
import axios from 'axios';
import _ from 'lodash';
import qs from 'qs';

import { getManageId } from '@/helpers';

// import * as Sentry from '@sentry/browser';
import { getAuthToken, getSelectedRolePanel } from '@/utils';

const config = {
  baseURL: process.env.VUE_APP_SERVER_URL,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
};

const axiosInstance = axios.create(config);
const axiosInstance3 = axios.create(config);

axiosInstance3.interceptors.request.use((requestConfig) => {
  if (['get', 'delete'].includes(requestConfig.method)) {
    requestConfig.paramsSerializer = (requestParams) =>
      qs.stringify(requestParams, {
        arrayFormat: 'brackets',
        encode: false,
      });
  }
  return requestConfig;
});

export const request3 = axiosInstance3;

// axiosInstance.interceptors.request.use(req => {
//   console.log('Starting Request', req);
//   return req;
// });

// axiosInstance.interceptors.response.use(res => {
//   console.log(
//     'RES:',
//     res.config.method.toUpperCase(),
//     res.config.url.replace(res.config.baseURL, ''),
//     'REQUEST',
//     res.config.params,
//     'DATA: ',
//     res.data,
//   );
//   return res;
// });

function callError(error) {
  if (error.response) {
    const { status, data } = error.response;
    switch (status) {
      case 401:
        // localStorage.clear();
        // if (window.location.href.indexOf('/auth') < 0) {
        //   window.location.href = '/';
        // }
        return data;
      // return '401 Unauthorized';
      case 404:
        return i18n.t('ajax_errors.statuses.404');
      default:
        // Sentry &&
        //   Sentry.captureException({ message: 'axios error', response: error.response, data });
        return data;
    }
  } else {
    return i18n.t('ajax_errors.statuses.500');
  }
}

function getCsrfToken() {
  const element = document.querySelector('meta[name~="csrf-token"]');
  return element?.getAttribute('content');
}

function generateUrl(url, ids) {
  if (ids) {
    if (typeof ids === 'object') {
      for (const key in ids) {
        url = url.replace(`:${key}`, ids[key]);
      }
    }
    return url.replace(':id', ids);
  }
  return url;
}

export function request(
  method,
  url,
  ids = null,
  data = {},
  fullResponse,
  headers,
) {
  // set Authorization headers
  const token = getAuthToken();
  axiosInstance.defaults.headers.common.Authorization = token
    ? `${token.token_type} ${token.access_token}`
    : '';
  if (headers) {
    Object.assign(axiosInstance.defaults.headers.common, headers);
  }

  const manageId = getManageId();
  if (manageId) data.manage_id = manageId;
  const req =
    method === 'get' || method === 'delete'
      ? {
          params: data,
          paramsSerializer: (requestParams) =>
            qs.stringify(requestParams, {
              arrayFormat: 'brackets',
              encode: false,
            }),
        }
      : data;
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosInstance[method](generateUrl(url, ids), req, {
        headers: {
          'X-CSRF-Token': getCsrfToken(),
        },
      });
      resolve(fullResponse ? response : response.data);
    } catch (error) {
      reject(callError(error, data));
    }
  });
}

/**
 * @param config {{
 *   method: string
 *   url: string
 *   ids?: (string | number | Array)
 *   fullResponse?: boolean
 *   headers?: Object
 * }}
 * @param [data]
 * @returns {Promise<unknown>}
 */

export function request2(
  { method = 'GET', url, ids, fullResponse, headers },
  data,
) {
  // set Authorization headers
  const token = getAuthToken();
  axiosInstance.defaults.headers.common.Authorization = token
    ? `${token.token_type} ${token.access_token}`
    : '';
  if (headers) {
    Object.assign(axiosInstance.defaults.headers.common, headers);
  }
  const req =
    method.toLowerCase() === 'get'
      ? {
          params: data,
          paramsSerializer: (requestParams) =>
            qs.stringify(requestParams, {
              arrayFormat: 'brackets',
              encode: false,
            }),
        }
      : data;
  return new Promise(async (resolve, reject) => {
    try {
      headers = { headers: { 'X-CSRF-Token': getCsrfToken() } };
      let options =
        method.toLowerCase() === 'delete'
          ? [generateUrl(url, ids), headers]
          : [generateUrl(url, ids), req, headers];
      const response = await axiosInstance[method.toLowerCase()](...options);
      resolve(fullResponse ? response : response.data);
    } catch (error) {
      reject(fullResponse ? error : callError(error, data));
    }
  });
}

window.request2 = request2;

export function getRolePrefix(url, additionalUrl = '') {
  if (additionalUrl) url += `/${additionalUrl}`;
  switch (getSelectedRolePanel()) {
    case 'admin':
      return `/api/admin${url}`;
    case 'personal':
      return `/api/v1/personal${url}`;
    case 'manage':
      return `/api/manage${url}`;
    default:
      return `/api/backend/personal${url}`;
    // throw Error('Error #001 : Not found specification prefix');
  }
}

/** @class Request */
export class Request {
  constructor(url, model, baseUrl, errorHandlers) {
    this.baseUrl = baseUrl;
    this.url = url;
    this.model = model;
    this.request = request;
  }

  async first(params, mutationFn) {
    const { data } = await this.get(params).pagination(mutationFn);
    return data;
  }

  getAll(params, mutationFn) {
    return this.get(params).allAsync(mutationFn);
  }

  getAllSync(params, mutationFn) {
    return this.get(params).allSync(mutationFn);
  }

  getPagination(params, mutationFn) {
    return this.get(params).pagination(mutationFn);
  }

  async getCount(params) {
    const { count } = await this.get({ ...params, per_page: 1 }).pagination();
    return count;
  }

  generateUrl(url, additionalUrl = '') {
    if (this.baseUrl) {
      return `${this.baseUrl}${url}/${additionalUrl}`;
    }
    return getRolePrefix(url, additionalUrl);
  }

  /** @params {Object} params - query get params */
  get(params) {
    return {
      /**
       * Пагинация данных
       * @param {function} [mutationFn]
       * @returns {Array}
       * */
      pagination: async (mutationFn) => {
        const { data, headers } = await this.request(
          'get',
          this.generateUrl(this.url),
          null,
          { ...params },
          true,
        );
        // console.log(data);
        const self = this;
        return {
          data: await this.callMutations(mutationFn, data, 'reset'),
          async nextPage() {
            const { next, per_page } = this.pagination;
            if (next) {
              const { data, headers } = await self.request(
                'get',
                self.generateUrl(self.url),
                null,
                { ...params, page: next, per_page },
                true,
              );
              this.pagination = JSON.parse(headers['x-pagination'] || '{}');
              this.data = this.data.concat(
                await self.callMutations(mutationFn, data),
              );
              return true;
            }
            return false;
          },
          get isNextPage() {
            return !!this.pagination.next;
          },
          pagination: JSON.parse(headers['x-pagination'] || '{}'),
          ...JSON.parse(headers['x-pagination'] || '{}'),
        };
      },
      allSync: async (mutationFn) => {
        // eslint-disable-next-line prefer-const
        let { pages, data } = await this.get({
          ...params,
          per_page: 50,
        }).pagination();
        if (pages === 1) return this.callMutations(mutationFn, data, 'reset');
        for (const page of _.range(2, pages + 1)) {
          // eslint-disable-next-line no-await-in-loop
          const res = await this.request(
            'get',
            this.generateUrl(this.url),
            null,
            {
              ...params,
              page,
              per_page: 50,
            },
          );
          data = data.concat(res);
        }
        return this.callMutations(mutationFn, data, 'reset');
      },
      allAsync: async (mutationFn) => {
        const { pages, data } = await this.get({
          ...params,
          per_page: 50,
        }).pagination();
        if (pages === 1) return this.callMutations(mutationFn, data, 'reset');
        const requests = [data];
        _.range(2, pages + 1).forEach((page) => {
          requests.push(
            this.request('get', this.generateUrl(this.url), null, {
              ...params,
              page,
              per_page: 50,
            }),
          );
        });
        const allRes = await Promise.all(requests);
        const allData = _.flatten(allRes);
        return this.callMutations(mutationFn, allData, 'reset');
      },
      one: (id) => {
        return this.request(
          'get',
          this.generateUrl(`${this.url}/:id`),
          id,
          params,
        );
      },
      id: (id, additionalUrl) => {
        return this.request(
          'get',
          this.generateUrl(`${this.url}/:id`, additionalUrl),
          id,
          params,
        );
      },
    };
  }

  async getOne(id, params, mutationFn) {
    const data = await this.request(
      'get',
      this.generateUrl(`${this.url}/:id`),
      id,
      params,
    );
    return this.callMutations(mutationFn, data, 'reset');
  }

  create(data, additionalUrl = '') {
    if (Array.isArray(Array)) {
      return Promise.all(
        data.map((el) =>
          this.request('post', this.generateUrl(this.url), null, el),
        ),
      );
    }

    return this.request(
      'post',
      this.generateUrl(this.url, additionalUrl),
      null,
      this.model ? { [this.model]: data } : data,
    );
  }

  createWithId(id, additionalUrl = '', data) {
    return this.request(
      'post',
      this.generateUrl(`${this.url}/:id`, additionalUrl),
      id,
      data,
    );
  }

  update(id, data, additionalUrl = '') {
    if (typeof id === 'number') {
      if (!Number.isNaN(id)) {
        return this.request(
          'put',
          this.generateUrl(`${this.url}/:id`, additionalUrl),
          id,
          this.model ? { [this.model]: data } : data,
        );
      }
    }
    if (Array.isArray(id)) {
      return Promise.all(
        data.map((el) =>
          this.request('put', this.generateUrl(`${this.url}/:id`), el.id, el),
        ),
      );
    }
    if (id instanceof Object) {
      if (id.id)
        return this.request(
          'put',
          this.generateUrl(`${this.url}/:id`, additionalUrl),
          id.id,
          this.model ? { [this.model]: id } : id,
        );
    }
    return false;
  }

  delete(id, data, additionalUrl = '') {
    if (Array.isArray(id)) {
      return Promise.all(
        id.map((el) =>
          this.request('delete', this.generateUrl(`${this.url}/:id`), el, data),
        ),
      );
    }
    return this.request(
      'delete',
      this.generateUrl(`${this.url}/:id`, additionalUrl),
      id,
      data,
    );
  }

  async callMutations(mutations, data, params) {
    if (!mutations) return data;
    if (!Array.isArray(mutations)) {
      mutations = [mutations];
    }
    const requests = mutations.map(async (mutation) => {
      if (this[mutation]) await this[mutation](data, params);
    });
    await Promise.all(requests);
    return data;
  }
}

export function uploadFiles(type, ids, data) {
  const url = generateUrl(type, ids);
  const formData = new FormData();
  if (Array.isArray(data)) {
    data.forEach((file) => formData.append('files[]', file));
  } else {
    formData.append('files[]', data);
  }
  // for (let key in data) {
  //   if (key === 'files') {
  //     if (Array.isArray(data[key])) {
  //       data[key].forEach(file => formData.append('files[]', file))
  //     }
  //   } else {
  //     formData.append(key, data[key])
  //   }
  // }
  console.log('url', url, formData);
  return axios
    .post(`${process.env.VUE_APP_BASE_API}${url}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      // data: { files: data },
    })
    .then((res) => res.data)
    .catch((error) => {
      callError(error);
    });
}
