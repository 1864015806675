<template>

  <div class="portlet mb-5">
    <div class="d-flex flex-wrap align-items-center justify-content-between mb-2">
      <h4 class="m-0 pr-3">Приборы учета</h4>
      <div class="text-muted my-1">Показания от 1.03.2021 по 23.03.2021</div>
    </div>

    <div class="row row-md mt-3 mb-5">

      <div v-for="resource in resources" :key="resource.id" class="col-md-6 col-xxxl-3 mb-1 mb-xxxl-0">
        <div class="card information-count-card px-4 py-3 card-link" :class="{'card-active': selectResource === resource.id}" @click="selectResource = resource.id">
          <div class="text-muted mb-1">{{resource.name}}</div>
          <div class="d-flex flex-wrap">
            <div v-for="(variable, index) in resource.variables" :key="index">
              <!-- <div v-if="variable.variable_prefix" class="h4 mb-0 mr-3">
                <span class="text-muted">{{variable.variable_prefix}}</span>
                {{variable.variable_data}}{{variable.variable_suffix}}
              </div>
              <div v-else class="h4 mb-0">
                {{variable.variable_data}}{{variable.variable_suffix}}
              </div> -->
              <div class="h4 mb-0">
                {{variable.variable_data}}{{variable.variable_suffix}}
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>

    <div v-show="selectResource === '1'" class="mt-xxxl-3">
      <div class="font-size-16px text-muted">Холодное водоснабжение</div>
      <div class="mt-2">

        <div class="row row-md">

          <div v-for="count in coldWaterCounters" :key="count.id" class="col-md-6 col-lg">
            <!-- <div class="card information-count-card px-4 py-3 card-link bg-white" :class="{'card-active': selectColdWaterCount === count.id}" @click="selectColdWaterCount = count.id"> -->
            <div class="card information-count-card px-4 py-3 card-default bg-white" :class="count.status_card">
              <div class="d-flex align-items-center justify-content-between">
                <div class="pointer text-muted mb-1 d-inline-block">
                  <span :id="'count-cold-water-' + count.id">{{count.placement}}</span>
                  <b-tooltip :target="'count-cold-water-' + count.id" placement="top">
                    Счетчик #{{count.name}}
                  </b-tooltip>
                </div>
                <div v-if="count.status" :class="'pointer ml-2 ' + count.status_color_class">
                  <span :id="'status-cold-water-' + count.id">{{count.status}}</span>
                  <b-tooltip :target="'status-cold-water-' + count.id" placement="top">
                    {{count.status_full}}
                  </b-tooltip>
                </div>
              </div>
              <div class="h4 mb-2">{{count.variable}}м³</div>
              <div class="d-flex align-items-center justify-content-between">
                <div class="text-muted mr-4">{{count.date}}</div>
                <div v-if="count.battery > 60">
                  <!-- TODO: Отрисовать горизонтальную иконку сюда и далее -->
                  <span class="icon-local-vertical-buttery text-success"></span>
                </div>
                <div v-else-if="count.battery > 30 && count.battery < 60">
                  <span class="icon-local-vertical-buttery text-warning"></span>
                </div>
                <div v-else>
                  <span class="icon-local-vertical-buttery text-danger"></span>
                </div>
              </div>
            </div>
          </div>

        </div>

        <!-- <div class="row row-md mt-3">
          <div class="col-lg-7">
            <div class="bg-light rounded-top rounded-bottom line-chart d-flex align-items-center justify-content-center">
              График расхода
            </div>
          </div>
          <div class="col-lg-5">
            <div class="mb-4">
              <div><span class="text-muted">Расход за</span> Январь 2021</div>
              <div class="line-graph mb-1"></div>
              <div v-show="selectColdWaterCount === '1'" class="h5">11,4335м³</div>
              <div v-show="selectColdWaterCount === '2'" class="h5">7,7655м³</div>
            </div>
            <div class="mb-3">
              <div class="text-muted">Расход за <a @click="mounthDropdown = !mounthDropdown" class="text-primary pointer">Декабрь 2020 <span class="icon-local-shevron-down"></span></a></div>
              <div class="dot-graph mb-1"></div>
              <div v-show="selectColdWaterCount === '1'" class="h5">14,8459м³</div>
              <div v-show="selectColdWaterCount === '2'" class="h5">6,5646м³</div>
            </div>
          </div>
        </div> -->

      </div>
    </div>

    <div v-show="selectResource === '2'" class="mt-3">
      <div class="font-size-16px text-muted">Горячее водоснабжение</div>
      <div class="mt-2">

        <div class="row row-md">

          <div v-for="count in hotWaterCounters" :key="count.id" class="col-md-6 col-lg">
            <!-- <div class="card information-count-card px-4 py-3 card-link bg-white" :class="{'card-active': selectHotWaterCount === count.id}" @click="selectHotWaterCount = count.id"> -->
            <div class="card information-count-card px-4 py-3 card-default bg-white" :class="count.status_card">
              <div class="d-flex align-items-center justify-content-between">
                <div class="pointer text-muted mb-1 d-inline-block">
                  <span :id="'count-hot-water-' + count.id">{{count.placement}}</span>
                  <b-tooltip :target="'count-hot-water-' + count.id" placement="top">
                    Счетчик #{{count.name}}
                  </b-tooltip>
                </div>
                <div v-if="count.status" :class="'pointer ml-2 ' + count.status_color_class">
                  <span :id="'status-hot-water-' + count.id">{{count.status}}</span>
                  <b-tooltip :target="'status-hot-water-' + count.id" placement="top">
                    {{count.status_full}}
                  </b-tooltip>
                </div>
              </div>
              <div class="h4 mb-2">{{count.variable}}м³</div>
              <div class="d-flex align-items-center justify-content-between">
                <div class="text-muted mr-4">{{count.date}}</div>
                <div v-if="count.battery > 60">
                  <span class="icon-local-vertical-buttery text-success"></span>
                </div>
                <div v-else-if="count.battery > 30 && count.battery < 60">
                  <span class="icon-local-vertical-buttery text-warning"></span>
                </div>
                <div v-else>
                  <span class="icon-local-vertical-buttery text-danger"></span>
                </div>
              </div>
            </div>
          </div>

        </div>

        <!-- <div class="row row-md mt-3">
          <div class="col-lg-7">
            <div class="bg-light rounded-top rounded-bottom line-chart d-flex align-items-center justify-content-center">
              График расхода
            </div>
          </div>
          <div class="col-lg-5">
            <div class="mb-4">
              <div><span class="text-muted">Расход за</span> Январь 2021</div>
              <div class="line-graph mb-1"></div>
              <div v-show="selectHotWaterCount === '1'" class="h5">10,7845м³</div>
              <div v-show="selectHotWaterCount === '2'" class="h5">21,7698м³</div>
            </div>
            <div class="mb-3">
              <div class="text-muted">Расход за <a @click="mounthDropdown = !mounthDropdown" class="text-primary pointer">Декабрь 2020 <span class="icon-local-shevron-down"></span></a></div>
              <div class="dot-graph mb-1"></div>
              <div v-show="selectHotWaterCount === '1'" class="h5">15,5434м³</div>
              <div v-show="selectHotWaterCount === '2'" class="h5">12,7984м³</div>
            </div>
          </div>
        </div> -->

      </div>
    </div>

    <div v-show="selectResource === '3'" class="mt-3">
      <div class="font-size-16px text-muted">Электричество</div>
      <div class="mt-2">

        <div class="row row-md">

          <div v-for="(count, index) in electroCounters" :key="count.id" class="col-md-6 col-lg">
            <!-- <div class="card information-count-card px-4 py-3 card-link bg-white" :class="{'card-active': selectElectroCount === count.id}" @click="selectElectroCount = count.id"> -->
            <div class="card information-count-card px-4 py-3 card-default bg-white">
              <div class="text-muted mr-5 mb-1">Тариф {{index + 1}} ({{count.name}})</div>
              <div class="h4 mb-2">{{count.variable}}кВт*ч</div>

              <div class="d-flex align-items-center justify-content-between">
                <div class="text-muted">{{count.date}}</div>
              </div>

            </div>
          </div>

        </div>

        <!-- <div class="row row-md mt-3">
          <div class="col-lg-7">
            <div class="bg-light rounded-top rounded-bottom line-chart d-flex align-items-center justify-content-center">
              График расхода
            </div>
          </div>
          <div class="col-lg-5">
            <div class="mb-4">
              <div><span class="text-muted">Расход за</span> Январь 2021</div>
              <div class="line-graph mb-1"></div>
              <div v-show="selectElectroCount === '1'" class="h5">11,5кВт*ч</div>
              <div v-show="selectElectroCount === '2'" class="h5">6,25кВт*ч</div>
            </div>
            <div class="mb-3">
              <div class="text-muted">Расход за <a @click="mounthDropdown = !mounthDropdown" class="text-primary pointer">Декабрь 2020 <span class="icon-local-shevron-down"></span></a></div>
              <div class="dot-graph mb-1"></div>
              <div v-show="selectElectroCount === '1'" class="h5">21,76кВт*ч</div>
              <div v-show="selectElectroCount === '2'" class="h5">12,79кВт*ч</div>
            </div>
          </div>
        </div> -->

      </div>
    </div>

    <div v-show="selectResource === '4'" class="mt-3">
      <div class="font-size-16px text-muted">Тепло</div>
      <div class="mt-2">

        <div class="row row-md">

          <div v-for="(count, index) in heatCounters" :key="count.id" class="col-md-6 col-lg">
            <!-- <div class="card information-count-card px-4 py-3 card-link bg-white" :class="{'card-active': selectHeatCount === count.id}" @click="selectHeatCount = count.id"> -->
            <div class="card information-count-card px-4 py-3 card-default bg-white" :class="count.status_card">
              <div class="d-flex align-items-center justify-content-between">
                <div class="pointer text-muted mb-1 d-inline-block">
                  <span :id="'count-heat-' + count.id">{{count.placement}}</span>
                  <b-tooltip :target="'count-heat-' + count.id" placement="top">
                    Счетчик #{{count.name}}
                  </b-tooltip>
                </div>
                <div v-if="count.status" :class="'pointer ml-2 ' + count.status_color_class">
                  <span :id="'status-heat-' + count.id">{{count.status}}</span>
                  <b-tooltip :target="'status-heat-' + count.id" placement="top">
                    {{count.status_full}}
                  </b-tooltip>
                </div>
              </div>
              <div class="d-flex align-items-center mb-2">
                <div class="h4 mb-0">{{count.variable}}Гкал</div>
                <div class="d-flex ml-2">
                  <div class="font-sise-16px ml-3">{{count.capacity}}м³</div>
                  <div class="font-sise-16px ml-3 text-danger">{{count.temp_in}}°C</div>
                  <div class="font-sise-16px ml-3 text-primary">{{count.temp_out}}°C</div>
                </div>
              </div>
              <div class="d-flex align-items-center justify-content-between">
                <div class="text-muted mr-4">{{count.date}}</div>
                <div v-if="count.battery > 60">
                  <span class="icon-local-vertical-buttery text-success"></span>
                </div>
                <div v-else-if="count.battery > 30 && count.battery < 60">
                  <span class="icon-local-vertical-buttery text-warning"></span>
                </div>
                <div v-else>
                  <span class="icon-local-vertical-buttery text-danger"></span>
                </div>
              </div>
            </div>
          </div>

        </div>

        <!-- <div class="row row-md mt-3">
          <div class="col-lg-7">
            <div class="bg-light rounded-top rounded-bottom line-chart d-flex align-items-center justify-content-center">
              График расхода
            </div>
          </div>
          <div class="col-lg-5">
            <div class="mb-4">
              <div><span class="text-muted">Расход за</span> Январь 2021</div>
              <div class="line-graph mb-1"></div>
              <div v-show="selectHeatCount === '1'" class="h5">34,75Гкал</div>
              <div v-show="selectHeatCount === '2'" class="h5">23,25Гкал</div>
            </div>
            <div class="mb-3">
              <div class="text-muted">Расход за <a @click="mounthDropdown = !mounthDropdown" class="text-primary pointer">Декабрь 2020 <span class="icon-local-shevron-down"></span></a></div>
              <div class="dot-graph mb-1"></div>
              <div v-show="selectHeatCount === '1'" class="h5">21,76Гкал</div>
              <div v-show="selectHeatCount === '2'" class="h5">12,79Гкал</div>
            </div>
          </div>
        </div> -->

      </div>
    </div>

  </div>

</template>

<script>

import LineChart from '@/components/charts/LineChart.js';
import { BTooltip } from 'bootstrap-vue';

export default {
  data () {
    return {
      mounthDropdown: false,
      selectResource: '1',
      resources: [
        {
          id: '1',
          name: 'Холодная вода',
          money: '612',
          variables: [
            {variable_prefix: '', variable_data: '19,2', variable_suffix: 'м³'},
          ],
        },
        {
          id: '2',
          name: 'Горячая вода',
          money: '873',
          variables: [
            {variable_prefix: '', variable_data: '32,5', variable_suffix: 'м³'},
          ],
        },
        {
          id: '3',
          name: 'Электричество',
          money: '560',
          variables: [
            {variable_prefix: '', variable_data: '17,75', variable_suffix: 'кВт*ч'},
          ],
        },
        {
          id: '4',
          name: 'Тепло',
          money: '720',
          variables: [
            {variable_prefix: '', variable_data: '58', variable_suffix: 'Гкал'},
          ],
        },
      ],
      //selectColdWaterCount: '1',
      coldWaterCounters: [
        {id: '1', name: '3423423-5433', variable: '11,4335', battery: '51', date: '2 часа назад', status_card: '', status: 'В норме', status_full: 'В норме', status_color_class: 'text-success', placement: 'Стояк 1'},
        {id: '2', name: '3423423-5436', variable: '7,7655', battery: '0', date: '3 часа назад', status_card: 'card-danger', status: 'Нет связи', status_full: 'Нет связи с приемным радиомодулем', status_color_class: 'text-danger', placement: 'Стояк 2'},
      ],
      //selectHotWaterCount: '1',
      hotWaterCounters: [
        {id: '1', name: '3423423-0001', variable: '10,7845', battery: '89', date: 'Час назад', status_card: 'card-warning', status: 'Ошибка', status_full: 'Ошибка передачи данных', status_color_class: 'text-warning', placement: 'Стояк 1'},
        {id: '2', name: '3423423-0002', variable: '21,7698', battery: '88', date: '19 января 2021', status_card: '', status: 'В норме', status_full: 'В норме', status_color_class: 'text-success', placement: 'Стояк 2'},
      ],
      //selectElectroCount: '1',
      electroCounters: [
        {id: '1', name: 'День', variable: '11,5', date: '18 января 2021'},
        {id: '2', name: 'Ночь', variable: '6,25', date: '18 января 2021'},
      ],
      //selectHeatCount: '1',
      heatCounters: [
        {id: '1', name: '343223-4445', variable: '34,75', battery: '98', date: 'Час назад', capacity: '23', temp_in: '68', temp_out: '45', status_card: '', status: 'В норме', status_full: 'В норме', status_color_class: 'text-success', placement: 'Стояк 1'},
        {id: '2', name: '343223-4440', variable: '23,25', battery: '79', date: '18 января 2021', capacity: '16', temp_in: '66', temp_out: '46', status_card: '', status: 'В норме', status_full: 'В норме', status_color_class: 'text-success', placement: 'Стояк 1'},
      ],
    }
  },
  components: {
    LineChart,
    BTooltip,
  },
}

</script>

<style lang="scss" scoped>
@import '../../../assets/styles/components/variables';

.line-chart {
  height: 320px;
}

.line-graph {
  border-top: 2px solid $blue;
}

.dot-graph {
  border-bottom: 2px dotted $blue;
}

</style>
