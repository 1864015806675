<template>
  <div class="meters-autocomplete">
    <div class="dropdown">
      <BInputLabel
        v-model="word"
        @keyup="onKeyUp"
        type="text"
        :label="placeholder"
        ref="word"
        class="autocomplete-results-dropdown"
        :state="state"
        :disabled="disabled"
        :is-invalid="isInvalid"
      />
      <div class="close-icon-container" v-if="!disabled">
        <i class="icon-local-times" v-if="!empty_word" @click="wordReset"></i>
      </div>
      <input
        :value="selected_id"
        :id="`${model}_${name}`"
        type="hidden"
        :name="`${model}[${name}]`"
      />
      <div v-show="!empty_results" class="dropdown-menu py-0">
        <div
          v-for="result in results"
          :key="result.id"
          @click="choosed(result)"
          :data-id="result.id"
          :data-name="result.serial"
          class="dropdown-item"
        >
          {{ result.serial }}
        </div>
      </div>
      <slot></slot>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { isEmpty, isNull, debounce, merge } from 'lodash';
import BInputLabel from '@/components/base/BInputLabel';

export default {
  name: 'metersAutocomplete',
  components: {
    BInputLabel,
  },
  props: {
    // форма сохраняется если сделан выбор
    saveFormId: String,
    model: String,
    name: String,
    meter: Object,
    clientId: {
      type: Number,
    },
    placeholder: String,
    value: {
      type: Number,
    },
    state: {},
    debouncedTts: {
      type: Number,
      default: 200,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isInvalid: {
      type: Boolean,
      default: false,
    },
    emptyResultCreateDevice: {
      type: Boolean,
      default: true,
    }
  },
  data() {
    return {
      debouncedSearch: null,
      selected_id: null,
      word: '',
      results: [],
      isShowItems: false,
    };
  },
  computed: {
    empty_results() {
      return isEmpty(this.results);
    },
    empty_word() {
      return this.word < 1;
    },
    debounced() {
      return this.debouncedTts > 0;
    },
  },
  mounted() {
    if (this.debounced) {
      this.initDebounce();
    }
    if (this.meter instanceof Object) {
      this.word = this.meter.serial
      this.selected_id = this.meter.id;
    }
  },
  methods: {
    choosed(result) {
      if(result.id) {
        this.word = result.serial
        this.selected_id = result.id;
        this.$emit('input', result.id);
        this.$emit('input_full', result);
        this.resultsReset();
        this.saveForm();
      } else if(result.serial == this.$t("meter.create_device")) {
        var win = window.open('/admin/metering/meters/new', '_blank');
        win.focus();
      }
    },
    onKeyUp() {
      if (this.debounced) {
        this.debouncedSearch();
      } else {
        this.search();
      }
    },
    resultsOpen() {
      $('.autocomplete-results-dropdown').dropdown('show');
      this.$refs.word.focus();
    },
    resultsClose() {
      $('.autocomplete-results-dropdown').dropdown('hide');
      this.$refs.word.focus();
      this.saveForm();
    },
    resultsReset() {
      this.results = [];
    },
    saveForm() {
      if (_.isUndefined(this.saveFormId)) {
        return;
      }

      const vm = this;
      setTimeout(function () {
        $(`#${vm.saveFormId}`).submit();
      }, 100);
    },
    wordReset() {
      this.word = '';
      this.selected_id = null;
      this.$emit('input', null);
      this.saveForm();
    },
    search() {
      if (isEmpty(this.word)) return;
      let prms = {
        q: this.word,
        limit: 8,
      };

      axios
        .get(`/api/backend/manage/${this.clientId}/meters`, {
          params: prms,
        })
        .then((response) => {
          if (response.data != null) {
            if(response.data.meters.length == 0) {
              if (this.emptyResultCreateDevice) this.results = [{id: undefined, serial: this.$t("meter.create_device")}];
            } else {
              this.results = response.data.meters;
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    initDebounce() {
      this.debouncedSearch = debounce(() => {
        this.search();
      }, this.debouncedTts);
    },
  },
  watch: {
    word(value) {
      if (isNull(value) || _.isUndefined(value) || value.length < 1) {
        this.results = [];
      }
    },
    results(value) {
      if (!isEmpty(value)) {
        this.resultsOpen();
      } else {
        this.resultsClose();
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import '../../assets/styles/components/variables';
.meters-autocomplete {
  position: relative;
  .close-icon-container {
    padding: 5px;
    cursor: pointer;
    position: absolute;
    right: 13px;
    top: 13px;
  }
  fieldset {
    width: 100%;
  }
  .dropdown-menu {
    overflow-x: auto;
    .dropdown-item {
      border: 1px solid #edeef0;
      height: 50px;
      cursor: pointer;
      display: flex;
      align-items: center;
      &:hover {
        background: $table-hover-bg;
      }
    }
  }
}
</style>
