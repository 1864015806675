<template>
  <div style="height: 100%">
    <Preloader ref="preloader" />
    <router-view></router-view>
  </div>
</template>

<script>
import Preloader from '@/components/Preloader.vue';
import EventBus from '@/packs/EventBus';
import bridge from '@/helpers/bridge';

export default {
  name: 'App',
  provide: {
    preloader: {
      show: (message) => EventBus.$emit('preloader::show', message),
      hide: () => EventBus.$emit('preloader::hide'),
    },
  },
  props: {
    fb_api_key: String,
    fb_auth_domain: String,
    fb_database_url: String,
    fb_project_id: String,
    fb_storage_bucket: String,
    fb_messaging_sender_id: String,
    fb_app_id: String,
    fb_measurement_id: String,
  },
  created() {
    bridge.appData = this.$props;
  },
  components: {
    Preloader,
  },
};
</script>

<style scoped></style>
