<template>
  <div>
    <div class="row">
      <div class="col-lg-6">
        <div class="portlet">
          <div class="h5 mt-1">
            {{ $t("global.device") }}
          </div>

          <div class="row pt-2">
            <div class="col-lg-6">
              <BSelectLabel
                v-model="form.area"
                :label="$t('meters.form.device_type')"
                :items="areas_filtered"
                display="name"
                @selected="areaChanged"
              />
            </div>
            <div class="col-lg-6">
              <BInputLabel
                v-model="form.serial"
                :label="$t('meters.form.serial')"
                :is-invalid="errors['serial']"
              />
              <div v-if="errors['serial']" class="invalid-feedback">
                {{ $t("global.serial") }} {{ errors['serial'].join(', ') }}
              </div>
            </div>
          </div>
          <div class="row pt-2">
            <div class="col-lg-6">
              <BSelectLabel
                v-model="form.model"
                :label="$t('meters.form.model')"
                :items="data.models"
                display="name"
                @selected="modelChanged"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="portlet">
          <div class="h5 mt-1">
            {{ $t("global.location") }}
            <div class="mt-3">
              <geo-units-autocomplete
                v-model="form.geo_unit.id"
                :placeholder="$t('global.address')"
                ref="address"
                :additional-query-params="{ type_in: ['Building', 'Apartment', 'Bkfn', 'TechRoom', 'ParkingPlace'] }"
                :client-id='clientId'
                :disabled="noArea"
                :geo-unit="form.geo_unit"
                @input="geoUnitChanged"
              ></geo-units-autocomplete>
            </div>
          </div>
        </div>
        <div v-if="showUspdConnection" class="portlet">
          <div class="h5 mt-1">
            {{ $t("meters.form.binding_to_USPD") }}
          </div>
          <div class="mt-3">
            <uspd-devices-autocomplete
              v-model="form.uspd_device.id"
              :placeholder="$t('meters.form.serial_USPD')"
              ref="uspd_device"
              :client-id='clientId'
              :geo-unit-id='form.geo_unit.id'
              :uspd="form.uspd_device"
              @input="formChanged"
            ></uspd-devices-autocomplete>
          </div>
        </div>
        <div v-if="!noModel" class="portlet">
          <div class="h5 mt-1">
            {{ $t('meters.form.channels') }}
          </div>
          <button @click="channelAdd" :disabled="allChannelsApplied" class="button btn btn-block btn-outline-primary mb-3 mt-3">
            {{ channelsAddButtonName }}
          </button>
          <div v-for="channel in appliedChannels">
            <div class="container mb-3 p-0">
              <div class="row align-items-center">
                <div class="col-10">
                  <div class="short-div">
                    <BSelectLabel
                      v-model="channel.index"
                      display="name"
                      :label="$t('meters.form.channel_number')"
                      :items="unusedChannels"
                      :key="'channel' + channel.key"
                    />
                  </div>
                  <div class="short-div">
                    <meters-autocomplete
                      v-model="channel.meter.id"
                      :placeholder="$t('meters.form.serial_PU')"
                      :client-id="clientId"
                      :geo-unit-id="form.geo_unit.id"
                      :key="'meter' + channel.key"
                      :meter="channel.meter"
                      :empty-result-create-device="false"
                      @input_full="meterChanged"
                    ></meters-autocomplete>
                  </div>
                </div>
                <div class="col-2">
                  <button @click="channelRemove" :channel-int-id="channel.int_id" class="btn btn-lg btn-outline-primary">
                    {{ $t('button.delete') }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BInputLabel from '@/components/base/BInputLabel';
import BSelectInputLabel from '@/components/base/BSelectInputLabel';
import GeoUnitsAutocomplete from '@/components/autocompletes/geoUnitsAutocomplete';
import MetersAutocomplete from '@/components/autocompletes/metersAutocomplete';
import UspdDevicesAutocomplete from '@/components/autocompletes/uspdDevicesAutocomplete';

export default {
  name: 'meteringMeterPulseForm',
  components: {
    BSelectInputLabel,
    BInputLabel,
    GeoUnitsAutocomplete,
    MetersAutocomplete,
    UspdDevicesAutocomplete,
  },
  props: {
    areas : {
      type: Array,
      required: true,
    },
    clientId: {
      type: Number,
      required: true,
    },
    currentArea: null,
    data: {
      type: Object
    },
    device: {
      type: Object,
    },
    errors: {
      type: Object,
    },
  },
  data() {
    return {
      area: null,
      areas_filtered: [],
      form: {
        geo_unit: {},
        uspd_device: {},
        channels: [],
      },
    };
  },
  computed: {
    allChannelsApplied() {
      return this.appliedChannels.length === this.channelsCapacity;
    },
    appliedChannels() {
      return this.form.channels.filter(ch => ch.applied === true);
    },
    availableChannels() {
      let indexes = [];
      for (let i = 1; i <= this.form.model.inputs_count; i ++) {
        indexes.push(i);
      }
      return indexes;
    },
    channelsCapacity() {
      return this.form.model.inputs_count;
    },
    channelsAddButtonName() {
      return !this.allChannelsApplied ? this.$t('meters.form.add_channel') : this.$t('meters.form.all channels_added')
    },
    editMode() {
      return this.getProp(this, 'device') != null;
    },
    noArea() {
      return this.getProp(this.form, 'area') === null;
    },
    noModel() {
      return this.getProp(this.form, 'model') === null;
    },
    showUspdConnection() {
      return !this.noArea;
    },
    usedChannelIndexes() {
      return this.form.channels.filter(ch => ch.index != null).map(ch => ch.index).map(ch => ch.id);
    },
    unusedChannels() {
      return this.availableChannels.filter(index => !this.usedChannelIndexes.includes(index)).map(index => ({ id: index, name: String(index) }));
    },
  },
  methods: {
    areaChanged() {
      this.$emit('areaChanged', this.form.area);
      this.$refs.address.wordReset();
    },
    areasSet() {
      if (this.editMode) {
        this.areas_filtered = this.areas.filter(x => x.id === 'pulse');
      } else {
        this.areas_filtered = this.areas;
      }
    },
    channelAdd() {
      let int_id = _.max(this.form.channels.map(ch => ch.int_id)) + 1;
      this.form.channels.push({ meter: {}, index: null, int_id: int_id, applied: true, key: this.keyGenerator() });
      this.formChanged();
    },
    channelRemove(e) {
      let channel = this.form.channels.filter(ch => ch.int_id === Number(e.target.getAttribute('channel-int-id')))[0];
      channel.meter = {};
      channel.index = null;
      channel.applied = false;
      this.formChanged();
    },
    channelsDeploy() {
      if (this.noModel) return;

      this.$set(this.form, 'channels', [{ meter: {id: null, serial: null}, index: null, int_id: 1, applied: true, key: this.keyGenerator() }]);
    },
    channelsParams() {
      return this.form.channels.filter(ch => !(this.getProp(ch, 'id') === null && !ch.applied))
        .map(ch => ({ id: ch.id, _destroy: !ch.applied, meter_id: this.getProp(ch.meter, 'id'), index: this.getProp(ch.index, 'id') }));
    },
    channelsValide() {
      return this.form.channels.filter(ch => ch.index != null && this.getProp(ch.meter, 'id') != null).length > 0;
    },
    editDeploy() {
      this.$set(this, 'form',
        {
          area: this.areas.filter(x => x.id === this.device.area)[0],
          model: this.device.model,
          serial: this.device.serial,
          uspd_device: this.device.uspd_device || {},
          geo_unit: this.device.geo_unit || {},
          channels: this.channelsRecover(),
        },
      );
    },
    channelsRecover() {
      let channels = [];
      this.device.channels.forEach ((ch) =>
        channels.push({ meter: ch.meter, index: { id: ch.index, name: ch.index }, id: ch.id, int_id: ch.id, applied: true })
      )

      return channels;
    },
    meterChanged(value) {
      this.form.channels.filter(ch => this.getProp(ch.meter, 'id') === value.id && this.getProp(ch.meter, 'serial') === null)[0].meter.serial = value.serial;
      this.formChanged();
    },
    formChanged() {
      this.$emit('formValide', this.formValide());
    },
    formValide() {
      if (Object.values(this.paramsRequired()).filter(x => x === null || x === '').length > 0) return false;
      if (!this.channelsValide()) return false;

      return true;
    },
    geoUnitChanged() {
      this.uspdDeviceIdReset();
    },
    getProp(obj, prop) {
      return _.get(obj, prop, null);
    },
    keyGenerator() {
      return Math.floor(Math.random() * 10000 + 1);
    },
    modelChanged() {
      this.channelsDeploy();
      this.formChanged();
    },
    paramsRequired() {
      return {
        geo_unit_id: this.getProp(this.form, 'geo_unit.id'),
        pulse_counters_model_id: this.getProp(this.form, 'model.id'),
        serial: this.getProp(this.form, 'serial'),
        uspd_device_id: this.getProp(this.form, 'uspd_device.id'),
        channels_attributes: this.channelsParams(),
      }
    },
    params() {
      return this.paramsRequired();
    },
    uspdDeviceIdReset() {
      if (this.$refs.uspd_device === undefined) return;
      this.$refs.uspd_device.wordReset();
    },
  },
  created() {
    this.areasSet();
    if(this.editMode) {
      this.editDeploy();
    } else {
      this.$set(this.form, 'area', this.currentArea);
    }
    this.formChanged();
  },
};
</script>
