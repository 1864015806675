<template>
  <b-modal
    id="intercom-add-relay-modal"
    ref="modal"
    @ok="onOk"
    :title="
      isCreate
        ? $t('intercom.relay.modal_title')
        : $t('intercom.relay.modal_title_edit')
    "
    :ok-title="isCreate ? $t('button.add') : $t('button.save')"
  >
    <b-form-validate
      @submit="onOk"
      ref="form"
      :validations="validations"
      :model="model"
    >
      <template slot-scope="{ validateState }">
        <div class="modal-body-row with-cols border-bottom">
          <b-form-row>
            <b-col cols="6">
              <BInputLabel
                id="input-relay-modal_name"
                v-model="model.name"
                :label="$t('intercom.relay.name')"
                :state="validateState('name')"
              />
            </b-col>
            <b-col cols="6">
              <BInputLabel
                id="input-relay-modal_index"
                type="number"
                min="0"
                v-model="model.index"
                :label="$t('intercom.relay.index')"
                :state="validateState('index')"
              />
            </b-col>
          </b-form-row>
        </div>
        <div class="modal-body-row border-bottom with-cols">
          <b-form-row>
            <b-col v-if="intercomType === 'Intercom::CallPanel'">
              <b-form-checkbox v-model="model.is_internal_reader">
                {{ $t('intercom.relay.internal_reader') }}
              </b-form-checkbox>
              <b-form-checkbox v-model="model.is_external_reader">
                {{ $t('intercom.relay.external_reader') }}
              </b-form-checkbox>
            </b-col>
            <b-col>
              <b-form-checkbox v-model="model.is_hidden">
                {{ $t('intercom.relay.hidden') }}
              </b-form-checkbox>
              <b-form-checkbox
                v-if="isShowIsFaceDetection"
                v-model="model.is_face_detection"
                :disabled="isDisabledIsFaceDetection"
              >
                {{ $t('intercom.relay.face_detection') }}
              </b-form-checkbox>
            </b-col>
          </b-form-row>
          <b-form-row>
            <b-col
              v-if="intercomModel && intercomModel.tags.includes('inverse')"
            >
              <b-form-checkbox v-model="model.is_inverse">
                {{ $t('intercom.relay.inverse') }}
              </b-form-checkbox>
            </b-col>
          </b-form-row>
        </div>
        <div class="modal-body-row with-cols">
          <b-col cols="12">
            <GeoUnitsAutocomplete2
              :geo-unit-deep-at="geoUnitDeepAt"
              :label="$t('intercom.relay.arrangement')"
              :state="validateState('geo_unit_id')"
              :value="model.geo_unit_id"
              @select:item="(value) => (relay.geo_unit_id = value.id)"
            />
            <small>{{ $t('intercom.relay.arrangement_description') }}</small>
          </b-col>
        </div>
      </template>
    </b-form-validate>
  </b-modal>
</template>

<script>
import { cloneDeep } from 'lodash';
import { integer, minValue, numeric, required } from 'vuelidate/lib/validators';

import GeoUnitsAutocomplete2 from '@/components/autocompletes/GeoUnitsAutocomplete2.vue';
import bridge from '@/helpers/bridge';

function initData(relay = {}, is_face_detection) {
  return {
    model: {
      name: '',
      geo_unit_id: undefined,
      index: '',
      is_internal_reader: true,
      ...cloneDeep(relay),
    },
    isCreate: !relay?.index,
    relayIndex: relay?.index || null,
    isFaceDetection: is_face_detection,
  };
}

export default {
  name: 'IntercomRelayModal',
  props: {
    faceDetectionType: {
      type: String,
      default: 'disabled',
      validator: (val) => ['disabled', 'expasoft', 'rbfaceid'].includes(val),
    },
    geoUnitDeepAt: {
      type: Array,
    },
    relayIndexes: {
      type: Array,
      required: true,
    },
    intercomType: {
      type: String,
    },
    intercomModel: {
      type: Object,
    },
  },
  components: {
    GeoUnitsAutocomplete2,
  },
  data() {
    return {
      ...initData(),
      availableGeoUnits: [],
      validations: {
        geo_unit_id: { required },
        name: { required },
        index: {
          required,
          numeric,
          minValue: minValue(1),
          integer,
          confirmIndexUniqueness: (index) =>
            index <= this.intercomModel.relays_count,
        },
      },
    };
  },
  computed: {
    isDisabledIsFaceDetection() {
      return !(bridge.isAdmin || bridge.permissions.includes('Owner'));
    },
    isShowIsFaceDetection() {
      return this.faceDetectionType !== 'disabled';
    },
  },
  methods: {
    async show(relay, is_face_detection) {
      Object.assign(this.$data, initData(relay, is_face_detection));
      this.$refs.modal.show();
    },
    async onOk(event) {
      event.preventDefault && event.preventDefault();
      if (this.$refs.form.validate()) {
        if (this.isCreate) {
          this.$emit('create', this.model);
        } else {
          this.$emit('update', this.model, this.relayIndex);
        }
        await this.$nextTick();
        this.$refs.modal.hide();
      } else {
        event.preventDefault();
      }
    },
  },
};
</script>

<style lang="scss">
#intercom-add-relay-modal {
  .geo-units-autocomplete-2 {
    fieldset {
      margin-bottom: 5px !important;
    }
  }
}
</style>
