<template>
  <div class="b-input-with-search">
    <b-form-input
      class="b-input-with-search-input"
      v-model="model.request"
      :class="{ 'b-input-with-search-input-unround-corners': model.request }"
      type="text"
      :state="state"
      id="b-input-with-search-input"
      @input="handleSearch"
      @focus="isActive = true"
      :disabled="isDisabled"
    />
    <label
      class="b-input-with-search-label"
      :class="{ 'b-input-with-search-label-fixed-to-top': model.request }"
    >
      {{ label }}
    </label>
    <i class="icon-local-search" v-if="withIcon"></i>
    <div class="b-input-with-search-result-list" v-if="loading">
      <div
        class="b-input-with-search-result-list-item d-flex align-items-center justify-content-center"
      >
        <b-spinner />
      </div>
    </div>
    <ul v-else-if="showOptions" class="b-input-with-search-result-list">
      <li
        :key="item.id"
        v-for="item in items"
        class="b-input-with-search-result-list-item"
        @click="selectItem(item)"
      >
        <div
          :key="key"
          v-for="(position, key) of formatResult"
          :class="`b-input-with-search-result-list-item-${key}`"
        >
          <p
            :key="idx"
            v-for="({ title, formatter }, idx) of position"
            :class="`b-input-with-search-result-list-item-${key}-text`"
            v-html="
              getOverlapText(
                typeof formatter === 'function'
                  ? formatter(item[title], item)
                  : item[title],
              )
            "
          ></p>
        </div>
      </li>
      <li
        v-if="(!items && !loading) || items.length == 0"
        class="b-input-with-search-result-list-item"
      >
        <div class="b-input-with-search-result-list-item-middle">
          <p class="b-input-with-search-result-list-item-middle-text">
            {{ emptyResultTitle }}
          </p>
        </div>
      </li>
    </ul>
    <i
      v-if="!disabled && !loading"
      class="icon-local-times undo-disable-input"
      @click="clear"
    ></i>
  </div>
</template>

<script>
import i18n from '@app/i18n';

export default {
  name: 'BSelectSearch',
  props: {
    handler: { type: Function, required: true },
    deBounceTimeout: { type: Number, default: 800 },
    formatResult: { type: Object, required: true },
    label: {
      type: String,
      default: i18n.t('input.name_id_or_phone_number'),
    },
    emptyResultTitle: {
      type: String,
      default: i18n.t('input.nothing_was_found'),
    },
    selected: { type: Object, default: () => {} },
    state: {
      type: [Boolean || undefined],
      default: undefined,
    },
    withIcon: { type: Boolean, default: false },
    defaultOptions: { type: Array },
    disabled: {
      type: Boolean,
    },
    displayProperty: {
      default: 'name',
    },
  },
  data() {
    return {
      model: {
        request: '',
      },
      items: [],
      selectedItem: '',
      deBounce: null,
      isDisableLocal: false,
      isActive: false,
      loading: false,
    };
  },
  watch: {
    loading: {
      immediate: false,
      handler() {
        if (!loading) {
          this.onFocus();
        }
      },
    },
  },
  computed: {
    showOptions() {
      return (
        (this.model.request && !this.selectedItem) ||
        (this.defaultOptions && this.isActive)
      );
    },
    isDisabled() {
      return this.disabled || this.isDisableLocal || this.loading;
    },
  },
  mounted() {
    this.selectMountFromSelected(this.selected);
    document.addEventListener('mousedown', this.clickHandler);
  },
  beforeDestroy() {
    document.removeEventListener('mousedown', this.clickHandler);
  },
  methods: {
    clickHandler({ target }) {
      if (this.isActive && !this.$el.contains(target)) {
        this.clearInput();
        this.isActive = false;
      }
    },
    async handleSearch() {
      clearTimeout(this.deBounce);
      if (this.model.request && this.model.request.length) {
        this.deBounce = setTimeout(async () => {
          this.loading = true;
          const { data } = await this.handler(this.model.request);
          this.items = data;
          this.loading = false;
        }, this.deBounceTimeout);
      } else this.addDefaultValues();
    },
    selectMountFromSelected(val) {
      if (val && Object.keys(val).length) {
        this.selectItem(val);
      } else {
        this.clearInput();
      }
    },
    getOverlapText(value) {
      const re = new RegExp(
        this.model.request.replace(/\\/, '').replace(/\+/g, '\\+'),
        'ig',
      );
      return value
        ? String(value).replace(re, (change) => `<u>${change}</u>`)
        : '';
    },
    selectItem(item) {
      this.selectedItem = item;
      if (this.displayProperty === 'name') {
        const name =
          item.name || item.surname || item.patronymic
            ? [item.surname, item.name, item.patronymic].join(' ')
            : item.phone || item.email || '';
        this.model.request = name;
      } else {
        this.model.request = item[this.displayProperty];
      }
      this.isDisableLocal = !!item;
      this.$emit('select', item);
      this.$emit('input', item);
      this.isActive = false;
    },
    clearInput() {
      this.isDisableLocal = false;
      this.selectItem('');
    },
    clear() {
      this.clearInput();
      this.$emit('clear', '');
    },
    addDefaultValues() {
      this.items = this.defaultOptions;
    },
  },
  watch: {
    selected(val) {
      this.selectMountFromSelected(val);
    },
    defaultOptions(val) {
      if (val && val.length) this.addDefaultValues();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../assets/styles/components/variables';

.b-input-with-search {
  height: 50px;
  box-sizing: border-box;
  position: relative;
  font-family: $font-family-sans-serif;
  font-weight: 300;
  font-size: 14px;
  color: $gray-900;

  &-input {
    width: 100%;
    height: 100%;
    border: 1px solid $border;
    padding: 18px 11px 4px 15px !important;
    border-radius: 5px;
    font-family: $font-family-sans-serif;
    font-weight: 300;
    font-size: 14px;
    color: $gray-900;
    &:focus {
      border-color: $blue;
      outline: none;
      + label {
        top: 6px;
        left: 15px;
        font-size: 10px;
      }
    }
    &:disabled {
      background: #fafafa;
      border-color: #fafafa;
      + label {
        cursor: default;
      }
      ~ .undo-disable-input {
        cursor: pointer;
        color: $gray-900;
        z-index: 100;
      }
    }
    &-unround-corners {
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    }
  }

  &-label {
    position: absolute;
    font-family: $font-family-sans-serif;
    font-weight: 300;
    font-size: 16px;
    color: $color-table-th;
    cursor: text;
    pointer-events: none;
    top: 19px;
    left: 16px;
    transition: all ease-in-out 0.2s;
    transition-delay: 0.1s;
    &-fixed-to-top {
      top: 6px;
      left: 15px;
      font-size: 10px;
    }
  }
  &-result-list {
    width: 100%;
    z-index: 1020;
    position: absolute;
    list-style: none;
    padding: 0;
    margin: 0;
    background: $white;
    box-shadow: 0px 4px 25px 0 rgba(0, 0, 0, 0.75);
    clip-path: inset(0px -25px -25px -25px);
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    &-item {
      min-height: 50px;
      position: relative;
      padding: 7px 13px;
      transition: background ease-in-out 0.2s;
      overflow-y: auto;
      &-top,
      &-middle,
      &-bottom {
        /*position: absolute;*/
        display: flex;
        p {
          margin-bottom: 0;
        }
      }

      &-top {
        &-text {
          color: #c1c4cb;
          font-weight: 400;
        }
        &-text:first-child {
          color: $gray-900;
          padding-right: 0.5rem;
          font-weight: 300;
        }
      }
      &-bottom {
        top: 26px;
        &-text {
          font-size: 10px;
          font-weight: 400;
          color: #c1c4cb;
          transition: color ease-in-out 0.2s;
        }
      }
      &-middle {
        width: 100%;
        height: 100%;
        top: 0;
        align-items: center;
        &-text {
        }
      }

      &:hover {
        width: calc(100% + 2px);
        left: -1px;
        border: 1px solid $blue;
        padding: 6px 13px;
        background: $cyan;
        cursor: pointer;
        .b-input-with-search-result-list-item-bottom {
          top: 25px;
          &-text {
            color: $blue;
          }
        }
      }
      &:last-child {
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
      }
      &:first-child:hover {
        padding: 6px 13px;
        .b-input-with-search-result-list-item-bottom {
          top: 25px;
        }
      }
    }
  }
  .undo-disable-input {
    display: block;
    z-index: -1;
    position: absolute;
    right: 10px;
    font-size: 14px;
    top: 20px;
    color: #ced4da;
  }

  .icon-local-search {
    position: absolute;
    right: 17px;
    top: 17px;
    &:before {
      font-size: 20px;
      color: $color-close-button;
    }
  }
}
</style>
