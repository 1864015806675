<script>
import NprEvents from './Events.vue';
import NprRelays from './Relays.vue';
import NprCameras from './Cameras.vue';

export default {
  name: 'NprBarrier',
  components: {
    NprEvents,
    NprRelays,
    NprCameras,
  },
  data() {
    return {
      allEvents: [],
    };
  },
  props: {
    events: {
      type: Array,
      default: new Array(),
    },
    cameras: {
      type: Array,
      default: new Array(),
    },
    relays: {
      type: Array,
      default: new Array(),
    },
    intercom: {
      type: Object,
      // required: true,
      default: () => ({}),
    },
  },
  mounted() {
    console.log('props Barrier.vue', this.$props);
  },
};
</script>
