<template>
  <b-form-group
    :id="`group-${id}`"
    :label="label"
    :label-class="
      value || value === 0 ? 'float-label-active' : 'float-label-inactive'
    "
    class="input-with-label file"
    :state="state"
  >
    <b-form-file
      :value="value"
      placeholder=" "
      :accept="accept"
      type="file"
      :browse-text="buttonText"
      :state="state"
      :disabled="disabled"
      title=""
      @input="changeValue"
    />
    <slot name="tooltip"></slot>
  </b-form-group>
</template>

<script>
import i18n from '@app/i18n';
import { BFormFile, BFormGroup } from 'bootstrap-vue';

export default {
  name: 'BInputFile',
  components: {
    BFormGroup,
    BFormFile,
  },
  props: {
    value: {},
    id: String,
    label: String,
    disabled: Boolean,
    accept: {
      type: String,
      default: '.csv',
    },
    buttonText: {
      type: String,
      default: i18n.t('input.choose_file'),
    },
    state: {
      type: [Boolean || undefined],
      default: undefined,
    },
  },
  data() {
    return {};
  },
  methods: {
    changeValue(val) {
      this.$emit('input', val);
    },
  },
};
</script>

<style scoped></style>
