<template>
  <div class="row form-row mt-3">
    <div class="col">
      <BSelectLabel
        v-model="permission"
        :items="permissionsItems"
        display="name"
        :label="$t('permissions.title')"
        object-property="permission"
      ></BSelectLabel>
      <input :name="permissionInputName" :value="permission" class="d-none" />
      <template v-if="permission !== 'Owner'">
        <FormPermissionsBuildings
          ref="FormPermissionsBuildings"
          @input="onInputSetBuildings"
        />
        <input
          v-for="input in buildingsInputs"
          :key="input.name"
          :name="input.name"
          :value="input.value"
          class="d-none"
        />
        <input
          v-for="input in districtInputs"
          :key="input.name"
          :name="input.name"
          :value="input.value"
          class="d-none"
        />
      </template>
    </div>
  </div>
</template>

<script>
import FormPermissionsBuildings from '@/components/forms/FormPermissionsBuildings.vue';

export default {
  name: 'UserPermissionsSlim',
  components: {
    FormPermissionsBuildings,
  },
  props: {
    model: {
      type: String,
      required: true,
    },
    permissions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      buildings: [],
      districts: [],
      permission: 'Owner',
    };
  },
  computed: {
    buildingsInputs() {
      return this.buildings.map((building, index) => {
        return {
          name: `${this.model}[buildings_permissions_ids][${index}]`,
          value: building.building_id,
        };
      });
    },
    districtInputs() {
      return this.districts.map((district, index) => {
        return {
          name: `${this.model}[districts_permissions_ids][${index}]`,
          value: district.district_id,
        };
      });
    },
    permissionInputName() {
      return `${this.model}[permission_type]`;
    },
    permissionsItems() {
      return this.permissions.map(([name, permission]) => ({
        name,
        permission,
      }));
    },
  },
  methods: {
    onInputSetBuildings(permissions) {
      Object.assign(this.$data, permissions);
    },
  },
};
</script>
