import { ApiBackendIntercoms } from '@/api';
import Vue from 'vue';

const state = {
  intercom: {
    geo_unit_id: null,
    geo_unit: {},
    intercom_model_id: '',
    intercom_keys_profile_id: '',
    sip_server_id: null,
    control_panel_id: '',
    control_panel: {},
    type: null,
    macaddr: '',
    ip_address: '',
    uuid: '',
    serial: '',
    settings: {
      correspond_table: {},
      password: '',
      username: '',
      analog: '',
      key_types: '',
      code_reverse_order: '',
    },
    relays_attributes: [],
    relays: [],
    name: '',
  },
  intercomModel: {},
  callMode: null,
  type: '',
};

const CALL_MODE_TYPES = {
  analog: 'analog',
  proxy: 'proxy',
  regular: 'regular',
};

const getEmptyRelay = () => ({
  name: '',
  geo_unit_id: null,
  index: '',
  is_internal_reader: true,
  relays_geo_units_attributes: [],
});

const getEmptyRange = () => ({ ip: '', prefix: '', range: '' });

const getters = {
  getIntercom(state) {
    return state.intercom;
  },
  getType(state) {
    return state.type;
  },
  getIntercomModel(state) {
    return state.intercomModel;
  },
  getRelays(state) {
    return state.intercom.relays_attributes;
  },
  getCallMode(state) {
    return state.callMode;
  },
};
const mutations = {
  setIntercom(state, payload) {
    if (payload) {
      state.intercom = payload;
    }
  },

  setType(state, payload) {
    state.type = payload;
  },

  setIntercomModel(state, payload) {
    if (payload) {
      state.intercom.model = Object.assign({}, payload);
      state.intercomModel = Object.assign({}, payload);
      if (
        state.intercomModel.buttons_count &&
        Object.keys(state.intercom.settings.correspond_table).length == 0
      ) {
        let panel = {
          ...Array.from(
            { length: state.intercomModel.buttons_count },
            () => null,
          ),
        };
        state.intercom.settings.correspond_table = Object.assign({}, panel);
      }
    }
  },

  setCallMode(state, payload) {
    if (payload) {
      state.callMode = payload;
    }
  },

  setRelays(state, payload) {
    Vue.set(state.intercom, 'relays_attributes', payload);
  },

  addRelay(state) {
    state.intercom.relays_attributes.push(getEmptyRelay());
  },

  removeRelay(state, payload) {
    if (payload.id) {
      let relay = state.intercom.relays_attributes[payload.index];
      relay._destroy = true;

      state.intercom.relays_attributes.splice(payload.index, 1, relay);
    } else {
      state.intercom.relays_attributes.splice(payload.index, 1);
    }
  },

  createAccessRelay(state, payload) {
    let relays = state.intercom.relays_attributes;
    relays[payload.relay_index].relays_geo_units_attributes.push({
      geo_unit_id: null,
      geo_unit_type: null,
      relay_id: payload.id,
      range: null,
      _destroy: false,
    });

    Vue.set(state.intercom, 'relays_attributes', relays);
  },

  removeAccessRelay(state, payload) {
    let relays = state.intercom.relays_attributes;
    if (payload.id) {
      let relay =
        relays[payload.relay_index].relays_geo_units_attributes[
          payload.gu_index
        ];
      relay._destroy = true;
      relays[payload.relay_index].relays_geo_units_attributes.splice(
        payload.gu_index,
        1,
        relay,
      );
    } else {
      relays[payload.relay_index].relays_geo_units_attributes.splice(
        payload.gu_index,
        1,
      );
    }

    Vue.set(state.intercom, 'relays_attributes', relays);
  },

  parseProxySchema(state) {
    let schema = [];
    if (
      state.intercom.settings.proxy_schema &&
      !Array.isArray(state.intercom.settings.proxy_schema)
    ) {
      schema = state.intercom.settings.proxy_schema.split(',').map((row) => {
        /**
         * ip = 127.0.0.1
         * prefix = 2
         * range = 1-30
         *
         * value: 127.0.0.1:2.1-2.30 | mask: [ip]:[prefix].[startRange]-[prefix].[endRange]
         *
         * ip = 127.0.0.1
         * range = 1-30
         *
         * value: 127.0.0.1:1-30 | mask: [ip]:[prefix].[startRange]-[endRange]
         */
        const regex =
          /(\w.+):(\d+)\.(\d+\s?)\-\s?\d+\.(\d+)|(\w.+):()(\d+)\s?-\s?(\d+)/m;
        const [_, ip, prefix = '', startRange, endRange] = row
          .match(regex)
          .filter((i) => i !== undefined);
        return {
          ip,
          prefix,
          range: `${startRange}-${endRange}`,
        };
      });
    }
    if (Array.isArray(state.intercom.settings.proxy_schema)) {
      return;
    }
    Vue.set(state.intercom.settings, 'proxy_schema', schema);
  },

  convertProxy(state) {
    if (state.intercom.settings.is_proxy_call) {
      const proxy_schema = state.intercom.settings.proxy_schema
        .map(({ ip, prefix, range }) => {
          const [startRange, endRange] = range.replace(/\s/g, '').split('-');
          if (prefix) {
            return `${ip}:${prefix}.${startRange}-${prefix}.${endRange}`;
          }
          return `${ip}:${startRange}-${endRange}`;
        })
        .join(', ');
      Vue.set(state.intercom.settings, 'proxy_schema', proxy_schema);
    } else {
      Vue.set(state.intercom.settings, 'proxy_schema', '');
    }
  },

  addRange(state) {
    let proxy = state.intercom.settings.proxy_schema;
    proxy.push(getEmptyRange());

    Vue.set(state.intercom.settings, 'proxy_schema', proxy);
    console.log(state.intercom.settings.proxy_schema);
  },

  removeRange(state, index) {
    let proxy = state.intercom.settings.proxy_schema;
    proxy.splice(index, 1);

    Vue.set(state.intercom.settings, 'proxy_schema', proxy);
  },

  setMode(state, payload) {
    if (payload == CALL_MODE_TYPES.regular) {
      Vue.set(state.intercom.settings, 'is_analog', 0);
      Vue.set(state.intercom.settings, 'is_proxy_call', 0);

      Vue.set(state.intercom.settings, 'proxy_schema', []);
      Vue.set(state.intercom.settings, 'analog_call_type', 0);
    } else {
      if (payload == CALL_MODE_TYPES.analog) {
        Vue.set(state.intercom.settings, 'is_analog', 1);
        Vue.set(state.intercom.settings, 'is_proxy_call', 0);

        Vue.set(state.intercom.settings, 'proxy_schema', []);

        Vue.set(state.intercom.settings, 'analog_call_type', 2);
      } else {
        if (payload == CALL_MODE_TYPES.proxy) {
          Vue.set(state.intercom.settings, 'is_analog', 0);
          Vue.set(state.intercom.settings, 'is_proxy_call', 1);
          Vue.set(state.intercom.settings, 'analog_call_type', 120);
        }
      }
    }
  },

  setInitRelays(state, payload) {
    if (payload) {
      let relays = payload.map((relay) => {
        const value = Object.assign({}, relay);
        value.relays_geo_units_attributes = [];
        value.relays_geo_units.forEach((gu) => {
          value.relays_geo_units_attributes.push(Object.assign({}, gu));
        });

        delete value.relays_geo_units;
        return value;
      });

      Vue.set(state.intercom, 'relays_attributes', relays);
    } else {
      state.intercom.relays_attributes.splice(
        0,
        state.intercom.relays_attributes.length,
      );
    }
    delete state.intercom.relays;
  },

  resetIntercom(state) {
    state.intercom = Object.assign(
      {},
      {
        geo_unit_id: null,
        geo_unit: {},
        intercom_model_id: '',
        intercom_keys_profile_id: '',
        sip_server_id: '',
        control_panel_id: '',
        control_panel: {},
        type: null,
        macaddr: '',
        ip_address: '',
        uuid: '',
        serial: '',
        settings: {
          correspond_table: {},
          password: '',
          username: '',
          analog: '',
          key_types: '',
          code_reverse_order: '',
        },
        relays_attributes: [],
        relays: [],
        name: '',
      },
    );

    state.intercomModel = Object.assign({});
    state.callMode = null;
  },
};

const actions = {
  async update({ commit, state }, intercom) {
    return ApiBackendIntercoms.update(intercom);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
