<template>
  <div>
    <b-modal
      ref="readSelected"
      id="read-selected-modal"
      :title="$t('devices.modal.create')"
      no-stacking
    >
      <div class="modal-body-row with-cols pt-30">
        <b-form-row>
          <b-col cols="12">
            <BInputLabel
              :label="$t('devices.modal.model')"
              v-model="intercom.name"
              disabled
            />
          </b-col>
          <b-col cols="12">
            <BInputLabel label="UUID" v-model="intercom.uuid" disabled />
          </b-col>
          <b-col cols="12">
            <BInputLabel
              :label="$t('devices.modal.serial')"
              v-model="intercom.serial"
              disabled
            />
          </b-col>
        </b-form-row>
      </div>
      <template #modal-footer>
        <div
          class="d-flex align-items-center justify-content-between flex-grow-1"
        >
          <b-button
            @click="openUuidSelectModal"
            size="sm"
            variant="outline-primary"
            class="intercom-fast-modal__btn w-auto h-auto"
          >
            {{ $t('devices.modal.back') }}
          </b-button>
          <b-button
            variant="primary"
            size="sm"
            class="intercom-fast-modal__btn w-auto h-auto"
            @click="importIntercom"
          >
            {{ $t('devices.modal.next') }}
          </b-button>
        </div>
      </template>
    </b-modal>
    <ErrorUuidModal
      :clientId="clientId"
      ref="ErrorUuidModal"
      id="intercom-error-uuid"
    />
    <InfoCreateModal
      :clientId="clientId"
      ref="InfoCreateModal"
      id="intercom-info-create"
    />
  </div>
</template>

<script>
import { request3 } from '@/api/request';
import { API_URLS, EVENTS } from '@/consts';
import { mapGetters, mapMutations, mapState } from 'vuex';
import EventBus from '@/packs/EventBus';
import ErrorUuidModal from './ErrorUuidModal.vue';
import InfoCreateModal from './InfoCreateModal.vue';

export default {
  name: 'ReadSelectedModal',
  components: { ErrorUuidModal, InfoCreateModal },
  props: {
    clientId: {
      type: [String, Number],
      required: true,
    },
  },
  computed: {
    ...mapState('intercom', ['intercom']),
    ...mapGetters('intercom', ['getIntercom']),
    intercom: {
      get() {
        return this.getIntercom;
      },
      set(value) {
        this.setIntercom(value);
      },
    },
  },
  methods: {
    ...mapMutations('intercom', ['setIntercom']),

    show() {
      this.$refs.readSelected.show();
    },
    hide() {
      this.$refs.readSelected.hide();
    },
    async importIntercom() {
      request3
        .post(
          API_URLS.backendManage.devices.intercoms.importByUuid(this.clientId),
          {
            intercom: {
              uuid_or_serial: encodeURIComponent(this.intercom.uuid),
            },
          },
        )
        .then((res) => {
          this.intercom = res.data;
          this.hide();
          this.openInfoCreateModal();
        })
        .catch((error) => {
          const errors = error.response.data.errors;
          this.hide();
          this.openErrorUuidModal(errors);
        });
    },
    openInfoCreateModal() {
      this.$refs.InfoCreateModal.show();
    },
    openErrorUuidModal(errors) {
      this.$refs.ErrorUuidModal.show(errors);
    },
    openUuidSelectModal() {
      this.hide();
      EventBus.$emit(EVENTS.intercom.fast.modal.show);
    },
  },
};
</script>

<style></style>
