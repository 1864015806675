<template>
  <img
    :src="srcAvatar"
    alt=""
    class="img-circle"
    onerror="this.src='/img/icon-no-avatar.svg'"
    :style="style"
  />
</template>

<script>
export default {
  name: 'UserAvatar',
  props: {
    user: {
      type: Object,
      required: true,
    },
    size: {
      default: '32px',
    },
  },
  computed: {
    style() {
      return { height: this.size, width: this.size };
    },
    srcAvatar() {
      return (
        (this.user.avatar && this.user.avatar.url) || '/img/icon-no-avatar.svg'
      );
    },
  },
};
</script>
