<template>
  <b-form-group
    :id="getGroupId"
    :label="label"
    :invalid-feedback="invalidFeedback"
    :label-class="
      labelIsActive || value || value === 0 || placeholder || autofilled
        ? 'float-label-active'
        : 'float-label-inactive'
    "
    :class="getFormGroupClasses"
    :description="description"
    :state="state"
    class="b-input-label"
  >
    <div
      v-if="security && value"
      class="float-security"
      :class="show ? 'icon-local-eye-closed' : 'icon-local-eye'"
      @click="toggleShowingPassword"
    ></div>
    <div v-if="isShowIconSlot" class="icon-slot">
      <slot name="icon"></slot>
    </div>
    <div
      class="icon-block pointer"
      v-if="icon"
      @click="
        () => {
          onIconClick();
          handler && handler();
        }
      "
    >
      <i :class="icon" />
    </div>
    <b-form-input
      ref="input"
      class="float-input"
      :class="{
        'active-validation-to-disable': this.activeValidationToDisable,
        'is-invalid': isInvalid,
      }"
      :value="value"
      :id="id"
      :type="getInputType"
      :name="name"
      :disabled="disabled"
      :placeholder="placeholder"
      :state="state"
      v-bind="$attrs"
      @keyup="$emit('keyup')"
      @focus="onInputFocus($event)"
      @blur="onInputBlur($event)"
      @input="input($event)"
      @input.native="emmitEvent($event, 'inputNative')"
      @change="change"
      @update="update"
      :autocomplete="autocomplete"
      :autofocus="autofocus"
      :pattern="pattern"
      @animationstart="checkAnimation"
    ></b-form-input>
    <div v-if="withCustomValidation" class="custom-validation">
      <span class="error-text">{{ customValidationText }}</span>
      <i class="icon-error"></i>
    </div>
    <slot></slot>
  </b-form-group>
</template>

<script>
import { BFormGroup, BFormInput } from 'bootstrap-vue';

export default {
  name: 'BInputLabel',
  components: { BFormGroup, BFormInput },
  props: {
    value: {},
    description: { type: String },
    id: String,
    label: String,
    name: String,
    placeholder: String,
    autocomplete: String,
    autofocus: { type: Boolean, default: false },
    security: Boolean,
    disabled: Boolean,
    withCustomValidation: Boolean,
    customValidationText: String,
    type: String,
    icon: {
      type: String,
      default: '',
    },
    handler: Function,
    activeValidationToDisable: Boolean,
    state: {
      type: [Boolean || undefined],
      default: undefined,
    },
    focusHandler: Function,
    invalidFeedback: String,
    pattern: String,
    inputGroupClasses: Object,
    blockValue: String,
    isInvalid: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      labelIsActive: false,
      show: true,
      autofilled: false,
    };
  },
  computed: {
    getInputType() {
      if (this.type) return this.type;
      return this.show && this.security ? 'password' : 'text';
    },
    getGroupId() {
      return this.id ? `group-${this.id}` : '';
    },
    getFormGroupClasses() {
      return {
        'input-with-label': true,
        ...this.inputGroupClasses,
      };
    },
    isShowIconSlot() {
      return !!this.$slots.icon;
    },
  },
  methods: {
    activatedLabel() {
      this.labelIsActive = true;
    },
    deactivateLabel() {
      this.labelIsActive = false;
    },
    input(val) {
      this.emmitEvent(val, 'input');
    },
    change(val) {
      this.emmitEvent(val, 'change');
    },
    update(val) {
      this.emmitEvent(val, 'update');
    },
    emmitEvent(val, event) {
      if (this.blockValue) {
        if (val.indexOf(this.blockValue) !== 0) {
          this.$emit(event, this.blockValue);
          this.$refs.input.$data.localValue = this.blockValue;
          return;
        }
      }
      if (this.type === 'number') {
        if (val === '') {
          this.$emit(event, null);
        } else {
          this.$emit(event, Number(val));
        }
      } else {
        this.$emit(event, val);
      }
    },
    changeValue(val) {
      if (this.blockValue) {
        if (!(val.indexOf(this.blockValue) < -1)) {
          this.$emit('input', this.blockValue);
          return;
        }
      }
      if (this.type === 'number') {
        if (val === '') {
          this.$emit('input', val);
        } else {
          this.$emit('input', Number(val));
        }
      } else {
        this.$emit('input', val);
      }
    },
    toggleShowingPassword() {
      this.show = !this.show;
    },
    checkAnimation(event) {
      if (event.animationName === 'onAutoFillStart') this.autofilled = true;
      else if (event.animationName === 'onAutoFillCancel')
        this.autofilled = false;
    },
    focus() {
      this.$refs.input.focus();
    },
    blur() {
      this.$refs.input.blur();
    },
    onInputFocus(event) {
      if (this.focusHandler) this.focusHandler();
      this.activatedLabel();
      this.$emit('focus', event);
    },
    onInputBlur() {
      this.deactivateLabel();
      this.$emit('blur', this.value);
    },
    onIconClick() {
      console.log('onIconClick');
      this.$emit('click-icon');
    },
  },
};
</script>

<style lang="scss">
@import '../../assets/styles/components/variables';
.float-security {
  font-size: $font-size-20px;
  position: absolute;
  right: 50px;
  margin-top: 15px;
}

.icon-slot {
  position: absolute;
  right: 0;
  top: 0;
  height: 50px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;

  .dropdown {
    height: 50px;

    ul {
      left: -15px !important;
      top: 40px !important;

      &,
      * {
        font-family: $font-family-sans-serif;
      }
    }
  }
  &:hover {
    * {
      color: $blue;
      cursor: pointer;
    }
  }
}

.icon-block {
  position: absolute;
  cursor: pointer !important;
  text-align: center;
  right: 45px;
  height: 30px;

  &:hover {
    i {
      color: $blue;
    }
  }
  i {
    color: #bfc5d1;
    font-size: 20px;
    line-height: 50px;
    cursor: pointer;
  }
}
.b-input-label {
  position: relative;
}
</style>
