<template>
  <div class="cameras-autocomplete">
    <div class="dropdown">
      <BInputLabel
        v-model="word"
        @keyup="onKeyUp"
        type="text"
        :label="placeholder"
        ref="word"
        class="autocomplete-results-dropdown"
        :state="state"
        :is-invalid="isInvalid"
        :disabled="disabled"
      />
      <div class="close-icon-container" v-if="!united_disabled">
        <i class="icon-local-times" v-if="!empty_word" @click="onClear"></i>
      </div>
      <div v-show="!empty_results" class="dropdown-menu py-0">
        <div
          v-for="result in results"
          :key="result.id"
          @click="choosed(result)"
          :data-id="result.id"
          :data-name="result.intercom_model_id"
          class="dropdown-item"
        >
          <div
            v-for="(position, index) of formattedResult"
            :key="index"
            :class="`cameras-autocomplete__result-list-item-${index}`"
          >
            <p
              v-for="({ title, formatter }, idx) of position"
              :key="idx"
              :class="`cameras-autocomplete__result-list-item-${index}-text pr-2`"
              v-html="getOverlapText(formatter(result[title], result))"
            ></p>
          </div>
        </div>
      </div>
      <slot></slot>
    </div>
  </div>
</template>

<script>
import BInputLabel from '@/components/base/BInputLabel';
import { debounce, isEmpty, isNull } from 'lodash';
import { API_URLS } from '@/consts';
import { request3 } from '@/api/request';

export default {
  name: 'CamerasAutocomplete',
  components: {
    BInputLabel,
  },
  props: {
    saveFormId: String,
    model: String,
    name: String,
    cameraData: Object,
    clientId: {
      type: Number,
    },
    placeholder: String,
    value: {
      type: [String, Number],
    },
    state: {},
    debouncedTts: {
      type: Number,
      default: 200,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isInvalid: {
      type: Boolean,
      default: false,
    },
    additionalQueryParams: {
      type: Object,
      default() {
        return {};
      },
    },
    selectedCameras: {
      type: Array,
      default: [],
    },
  },
  data() {
    return {
      debouncedSearch: null,
      selected_id: null,
      word: '',
      results: [],
      isShowItems: false,
    };
  },
  computed: {
    united_disabled() {
      return this.disabled;
    },
    empty_results() {
      return isEmpty(this.results);
    },
    empty_word() {
      return this.word < 1;
    },
    debounced() {
      return this.debouncedTts > 0;
    },
    formattedResult() {
      return {
        top: [
          {
            title: 'uuid',
            formatter: (value) => value,
          },
        ],
        bottom: [
          {
            title: 'geo_unit_short_name',
            formatter: (val, item) => item.geo_unit_short_name,
          },
          {
            title: 'name',
            formatter: (val, item) => item.name,
          },
        ],
      };
    },
  },
  mounted() {
    if (this.debounced) {
      this.initDebounce();
    }
    if (this.cameraData instanceof Object) {
      if (this.cameraData.uuid || this.cameraData.id) {
        this.word = this.cameraData.uuid;
        this.selected_id = this.cameraData.id;
      }
    }
  },
  methods: {
    choosed(result) {
      this.word = result.uuid;
      this.selected_id = result.id;
      this.$emit('input', result.id);
      this.resultsReset();
      this.saveForm();

      this.$emit('select', result);
    },
    onKeyUp() {
      if (this.debounced) {
        this.debouncedSearch();
      } else {
        this.search();
      }
    },
    resultsOpen() {
      $('.autocomplete-results-dropdown').dropdown('show');
      this.$refs.word.focus();
    },
    resultsClose() {
      $('.autocomplete-results-dropdown').dropdown('hide');
      this.$refs.word.focus();
      this.saveForm();
    },
    resultsReset() {
      this.results = [];
    },
    saveForm() {
      if (_.isUndefined(this.saveFormId)) {
        return;
      }

      const vm = this;
      setTimeout(function () {
        $(`#${vm.saveFormId}`).submit();
      }, 100);
    },
    wordReset() {
      this.word = '';
      this.selected_id = null;
      this.$emit('input', null);
      this.saveForm();
    },
    async getCameras() {
      let params = {
        uuid_or_name_or_geo_unit_short_name_cont_any: this.word,
        uuid_not_in: this.selectedCameras,
      };
      let query = {
        ...params,
        ...this.additionalQueryParams,
      };

      this.results = await request3
        .get(API_URLS.backendManage.cameras.fetch(this.clientId), {
          params: {
            q: query,
          },
        })
        .then((res) => res.data);
    },
    search() {
      if (isEmpty(this.word)) return;
      this.getCameras();
    },
    onClear() {
      this.wordReset();
      this.$emit('clear');
    },
    initDebounce() {
      this.debouncedSearch = debounce(() => {
        this.search();
      }, this.debouncedTts);
    },
    getOverlapText(value) {
      return value
        ? String(value).replace(this.word, (change) => `<u>${change}</u>`)
        : '';
    },
  },
  watch: {
    word(value) {
      if (isNull(value) || isEmpty(value)) {
        this.results = [];
      }
    },
    results(value) {
      if (!isEmpty(value)) {
        this.resultsOpen();
      } else {
        this.resultsClose();
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import '../../assets/styles/components/variables';
.cameras-autocomplete {
  position: relative;
  .close-icon-container {
    padding: 5px;
    cursor: pointer;
    position: absolute;
    right: 13px;
    top: 13px;
  }
  fieldset {
    width: 100%;
  }
  .dropdown-menu {
    overflow-x: auto;
    .dropdown-item {
      border: 1px solid #edeef0;
      height: 50px;
      cursor: pointer;
      display: flex;
      align-items: center;
      &:hover {
        background: $table-hover-bg;
      }
    }
  }
  &__result-list-item {
    &-top,
    &-middle,
    &-bottom {
      display: flex;
      p {
        margin-bottom: 0;
      }
    }

    &-top {
      &-text {
        color: #c1c4cb;
        font-weight: 400;
      }
      &-text:first-child {
        color: $gray-900;
        padding-right: 0.5rem;
        font-weight: 300;
      }
    }
    &-bottom {
      top: 26px;
      &-text {
        font-size: 10px;
        font-weight: 400;
        color: #c1c4cb;
        transition: color ease-in-out 0.2s;
      }
    }
    &-middle {
      width: 100%;
      height: 100%;
      top: 0;
      align-items: center;
    }
  }
}
</style>
