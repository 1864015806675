<template>
  <div>
    <div class="row justify-content-between my-4">
      <div class="col-auto">
        <label for="period">{{ $t('meters.readings_period') }}</label>
      </div>
      <div class="col-auto">
        <div class="form-group">
          <b-form-radio-group
            id="period"
            v-model="period"
            :options="periodOptions"
            buttons
            button-variant="outline-secondary"
            name="period"
          ></b-form-radio-group>
        </div>
      </div>
    </div>
    <div class="small text-warning mb-1" id="pickerInfo" style="display: none">
      {{ $t('datepicker.choose_calendar_range') }}
    </div>
    <div class="position-relative">
      <vue2-date-picker
        v-bind="$attrs"
        :append-to-body="false"
        :disabled-date="notAfterToday"
        :editable="false"
        :formatter="format"
        :lang="lang"
        :popup-style="{ top: '50px', left: '0px' }"
        :value="value"
        @input="onChange"
        @pick="handlePick"
        range
        ref="datePicker"
      >
        <template v-slot:input>
          <BFormGroupSlot :label="label" :value="inputValue" ref="bFGInput">
            <slot></slot>
          </BFormGroupSlot>
        </template>
      </vue2-date-picker>
    </div>
  </div>
</template>

<script>
import BFormGroupSlot from '@/components/base/BFormGroupSlot.vue';
import i18n from '@app/i18n';
import { BFormRadioGroup } from 'bootstrap-vue';
import dayjs from 'dayjs';
import Vue2DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/locale/ru';
import { getLocale } from '@/helpers';

const today = new Date();
Vue2DatePicker.locale(getLocale())

export default {
  name: 'ReportDatePickerSlot',
  props: {
    label: {
      type: String,
    },
    model: String,
    name: String,
    required: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    Vue2DatePicker,
    BFormRadioGroup,
    BFormGroupSlot,
  },
  data() {
    return {
      periodOptions: [
        { text: i18n.t('periods.month'), value: 'month' },
        { text: i18n.t('periods.week'), value: 'week' },
        { text: i18n.t('periods.day'), value: 'today' },
        { text: i18n.t('global.own'), value: 'range' },
      ],
      period: 'month',
      format: {
        stringify: (val) => (val ? dayjs(val).format('LL') : null),
        parse: (val) => (val ? dayjs(val).toISOString() : null),
      },
      value: '',
    };
  },
  computed: {
    lang() {
      return {
        defaultLocale: 'ru',
        firstDayOfWeek: 1,
      };
    },
    inputValue() {
      if (this.value) {
        if (Array.isArray(this.value)) {
          return this.value
            .map((val) => this.format.stringify(val))
            .join(' - ');
        }
        return this.format.stringify(this.value);
      }
      return '';
    },
  },
  mounted() {
    if (this.$refs.bFGInput.isValue) {
      this.value = this.$refs.bFGInput.input.value
        .split(' - ')
        .map((val) => new Date(dayjs(val, 'LL').toISOString()));
    } else if (this.required) {
      this.value = [
        dayjs().subtract(1, 'months').toISOString(),
        dayjs().toISOString(),
      ];
    }
  },
  watch: {
    period(val, oldVal) {
      if (val != 'range') {
        switch (val) {
          case 'today':
            this.startDay = dayjs().toISOString();
            this.endDay = dayjs().toISOString();
            break;
          case 'week':
            this.endDay = dayjs().toISOString();
            this.startDay = dayjs().subtract(7, 'days').toISOString();
            break;
          case 'month':
            this.endDay = dayjs().toISOString();
            this.startDay = dayjs().subtract(1, 'months').toISOString();
            break;
        }
        this.value = [this.startDay, this.endDay];
        this.$refs.bFGInput.setInputValue(this.inputValue);
        this.$refs.bFGInput.input.dispatchEvent(new Event('change'));
      }
    },
  },
  methods: {
    onChange(val) {
      this.value = val;
      this.$refs.bFGInput.setInputValue(this.inputValue);
      this.$refs.bFGInput.input.dispatchEvent(new Event('change'));
    },
    notAfterToday(date) {
      return date > new Date();
    },
    handlePick(date) {
      this.period = 'range';
    },
  },
};
</script>
<style lang="scss">
$primary-color: #03a9f4;
@import '~vue2-datepicker/scss/index';

.mx-datepicker {
  width: 100%;
  input {
    background: none !important;
    cursor: pointer !important;
  }
  .mx-icon-calendar {
    margin-right: 10px;
  }
}
.mx-datepicker-main {
  .mx-date-row {
    td {
      border: none !important;
    }
  }
}
</style>
