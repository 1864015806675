<template>
  <div class="dropdown-item pointer" @click="onOpenUserModal">
    {{ $t('button.edit') }}
  </div>
</template>

<script>
import EventBus from '@/packs/EventBus';

export default {
  name: 'UserEditLink',
  props: {
    email: {
      type: String,
    },
    phone: {
      type: String
    },
    avatar: {
      type: Object
    },
    user: {
      type: Object,
      required: true,
    },
  },
  methods: {
    onOpenUserModal() {
      EventBus.$emit('UserEditModal::show', {
        email: this.email,
        phone: this.phone,
        avatar: this.avatar,
        ...this.user,
      });
    },
  },
};
</script>

<style scoped></style>
