<template>
  <div class="select-key-group">
    <BDropdownInput
      v-model="input"
      :label="$t('add_button.key_set')"
      :items="keySets"
      :state="state"
      display-property="name"
      compare-property="id"
      @select="onSelect"
      @input="onInput"
      :scrollable="keySets.length > 5"
    />
  </div>
</template>

<script>
import { ApiKeySets } from '@/api';
import BDropdownInput from '@/components/base/BDropdownInput.vue';

export default {
  name: 'SelectKeySet',
  components: { BDropdownInput },
  props: {
    label: {
      type: String,
    },
    state: {
      type: Boolean,
    },
    value: {
      type: Number,
      default: null,
    },
    clientId: { type: [Number, String], required: true },
  },
  data() {
    return {
      input: '',
      keySets: [],
    };
  },
  mounted() {
    this.value && this.getKeyById(this.value);
    this.getSets();
  },
  methods: {
    onSelect(id) {
      this.$nextTick(() => {
        this.$emit('input', id);
      });
    },
    onInput(value) {
      this.value && this.$emit('input', null);
      this.getSetsBySearch(value);
    },
    async getKeyById(id) {
      try {
        const { name } = await ApiKeySets.getOne(id);
        this.input = name;
      } catch (err) {
        console.log(err);
      }
    },
    async getSets() {
      try {
        const { data } = await ApiKeySets.getPagination({
          q: { client_id_eq: this.clientId },
        });
        this.keySets = data;
      } catch (err) {
        console.log(err);
      }
    },
    async getSetsBySearch(input) {
      try {
        const { data } = await ApiKeySets.getPagination({
          q: { name_cont_all: input },
        });
        this.keySets = data;
      } catch (err) {
        console.log(err);
      }
    },
  },
};
</script>
<style lang="scss">
@import '../../assets/styles/components/variables';
.scrollable {
  height: 216px;
  overflow-y: scroll;
  border-radius: 0;
}
</style>
