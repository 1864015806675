<template>
  <div>
    <BMultiSelect
      v-model="value"
      :label="label"
      :items="items"
      @hidden="onHidden"
      @input="onInput"
    />
    <div class="slot-input">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import BMultiSelect from '@/components/base/BMultiSelect.vue';
import { isEmpty } from 'lodash';

export default {
  name: 'MultiSelectSlot',
  components: {
    BMultiSelect,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
    label: {
      type: [Number, String],
    },
  },
  data() {
    return {
      input: null,
      value: [],
    };
  },
  mounted() {
    this.input = this.$el.querySelector('input');
    if (this.input && this.input.value) {
      try {
        const value = JSON.parse(this.input.value);
        if (Array.isArray(value)) {
          this.value = value.map((event_array) => JSON.stringify(event_array));
        }
      } catch (err) {
        console.error(`invalid parse ${this.input}`);
      }
    }
  },
  methods: {
    onHidden() {
      this.input.dispatchEvent(new Event('change'));
    },
    onInput(value) {
      if (this.input) {
        if (!isEmpty(value)) {
          this.input.value = JSON.stringify(
            value.map((event) => JSON.parse(event)),
          );
        } else {
          this.input.value = null;
        }
      }
    },
  },
};
</script>

<style scoped lang="scss">
.slot-input {
  display: none;
}
</style>
