<script>
import GeoUnitsAutocomplete from '@/components/autocompletes/geoUnitsAutocomplete';
import metersAutocomplete from '@/components/autocompletes/metersAutocomplete';

export default {
  name: 'PulseCountersDeviceForm',
  components: {
    GeoUnitsAutocomplete,
    metersAutocomplete,
  },
  props: {
    deviceData: Object,
    uspdDevices: Array,
    models: Array,
    clientIdData: Number,
    channelsData: Array,
    errors: Object,
  },
  data() {
    let modelId = this.deviceData.pulse_counters_model_id;
    let maxChannles = modelId
      ? this.models.find((m) => m.id == modelId).inputs_count
      : 0;
    return {
      device: this.deviceData,
      clientId: this.clientIdData,
      channels: this.channelsData,
      maxChannles: maxChannles,
    };
  },
  watch: {
    'device.uspd_device_id'(val) {
      this.clientId = this.uspdDevices.find((d) => d.id == val).client_id;
    },
    'device.pulse_counters_model_id'(val) {
      this.maxChannles = this.models.find((m) => m.id == val).inputs_count;
    },
  },
  computed: {
    activeChannelsCount() {
      return this.channels.filter((c) => !c._destroy).length;
    },
  },
  methods: {
    removeChannel(i) {
      if (this.channels[i].id) {
        this.$set(this.channels[i], '_destroy', true);
      } else {
        const index = this.channels.indexOf(i);
        this.channels.splice(index, 1);
      }
      this.reIndex();
    },
    addChannel() {
      this.channels.push({
        weight: '',
        index: null,
        _destroy: false,
      });
      this.reIndex();
    },
    channelFormId(i, attr) {
      return `pulse_counters_device_channels_attributes_${i}_${attr}`;
    },
    reIndex() {
      this.channels.forEach((c, i) => (c.index = i + 1));
    },
    channelInputName(i, attr) {
      return `pulse_counters_device[channels_attributes][][${attr}]`;
    },
    getChannelError(i, attr) {
      let errrosArr = this.errors[`channels[${i}].${attr}`];
      if (errrosArr) {
        return errrosArr.join(', ');
      }
    },
  },
};
</script>
