<template>
  <div class="b-popover align-items-center d-flex">
    <div class="b-popover__hint hint" :id="hintId">
      {{ popoverText }}
    </div>
    <b-popover :target="hintId" triggers="hover" :placement="placement">
      <p>
        {{ popoverHint }}
      </p>
    </b-popover>
  </div>
</template>

<script>
export default {
  name: 'BHintPopover',
  props: {
    popoverText: {
      type: String,
      default: 'i',
    },
    hintId: {
      type: String,
      default: 'hint',
    },
    popoverHint: {
      type: String,
      default: null,
    },
    placement: {
      type: String,
      default: 'bottom',
    },
  },
};
</script>

<style lang="scss">
.hint {
  width: 18px;
  height: 18px;
  border: 2px solid #99adc0;
  border-radius: 50%;
  text-align: center;
  margin-left: 10px;
  color: #99adc0;
  font-size: 11px;
  font-weight: bold;
  cursor: pointer;
  display: flex;
  justify-content: center;
  font-family: 'Circe';
}
</style>
