<template>
  <div>
    <base-form ref="meter" v-if="baseCounter" :errors="errors" :device="device" :client-id="clientId" :current-area="area" :areas="areas" :data="baseCounters" @areaChanged="areaChanged" @formValide="formValide"></base-form>
    <pulse-form ref="pulseCountersDevice" v-else :errors="errors" :device="device" :areas="areas" :client-id="clientId" :current-area="area" :data="pulseCounters" @areaChanged="areaChanged" @formValide="formValide"></pulse-form>
    <div class="row row-md mt-3">
      <div class="col-2">
        <button @click="formSave" :disabled="!form_valid" class="btn btn btn-primary w-100">
          {{ $t("button.save") }}
        </button>
      </div>
      <div class="col-2">
        <button @click="redirectToBackward" class="btn btn btn-outline-secondary w-100">
          {{ $t("button.cancel") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import BaseForm from './blocks/BaseForm';
import PulseForm from './blocks/PulseForm';
import { serialize } from 'object-to-formdata';

export default {
  name: 'meteringForm',
  components: {
    BaseForm,
    PulseForm,
  },
  props: {
    areas: {
      type: Array,
      required: true,
    },
    backwardLink: {
      type: String,
      required: true,
    },
    baseCounters: {
      type: Object,
      required: true,
    },
    clientId: {
      type: Number,
      required: true,
    },
    device: {
      type: Object,
    },
    pulseCounters: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      area: null,
      form_valid: false,
      errors: {},
    };
  },
  computed: {
    baseCounter() {
      return _.get(this.area, 'id', null) != 'pulse';
    },
    editMode() {
      return this.device != undefined;
    },
  },
  methods: {
    areaChanged(value) {
      this.$set(this, 'area', value);
    },
    errorsReset() {
      this.$set(this, 'errors', {});
    },
    redirectTo(url) {
      url ? window.location.href = url : this.redirectToBackward();
    },
    redirectToBackward() {
      window.location.href = this.backwardLink;
    },
    formValide(value) {
      this.errorsReset();
      this.form_valid = value;
    },
    async formSave () {
      let url = `/api/backend/manage/${this.clientId}/` + (this.baseCounter ? 'meters' : 'pulse_counters/devices') + (this.editMode ? '/' + this.device.id : '');
      let key = this.baseCounter ? 'meter' : 'pulse_counters_device';
      let params = this.formData(this.$refs[_.camelCase(key)].params(), key);
      let method = this.editMode ? 'patch' : 'post';

      await request2(
          {
            method: method,
            url: url,
          },
          params,
      ).then((r) => {
        if (r.errors === undefined) {
          this.redirectTo(r.redirect_to);
        }
        else {
          this.errors = r.errors;
        }
      }).catch((error) => console.log(error));
    },
    formData(data, key) {
      return serialize(data,null, null, key);
    },
  },
  created() {
    let area = this.editMode ? this.areas.filter(x => x.id === this.device.area)[0] : this.areas[0];
    this.$set(this, 'area', area);
  },
};
</script>
