<template>
  <div class="list-component">
    <div class="d-flex" v-for="(item, index) in value" :key="index">
      <slot v-bind:item="item" v-bind:index="index"></slot>
      <b-button
        variant="outline-danger"
        class="btn-square btn-trash"
        :disabled="notEmpty ? value.length === 1 : false"
        @click="onDelete(index)"
      >
        <i class="icon-local-trash" />
      </b-button>
      <b-button
        v-if="index === value.length - 1"
        variant="outline-primary"
        class="btn-square btn-add"
        @click="onAdd()"
      >
        <i class="icon-local-plus" />
      </b-button>
      <div v-else class="empty-box"></div>
    </div>
    <b-button
      v-if="value.length === 0"
      class="w-100"
      variant="outline-primary"
      @click="onAdd()"
    >
      Add new item
    </b-button>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash-es';
export default {
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
    value: {
      type: Array,
    },
    notEmpty: {
      type: Boolean,
    },
  },
  methods: {
    onAdd() {
      this.$emit('input', this.value.concat(cloneDeep(this.item)));
    },
    onDelete(index) {
      this.$emit(
        'input',
        this.value.filter((_, i) => i !== index),
      );
    },
  },
};
</script>

<style lang="scss">
.list-component {
  .empty-box {
    height: 50px;
    width: 50px;
    margin-left: 10px;
    flex-shrink: 0;
  }
}
</style>
