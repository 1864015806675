<template>
  <BFormValidate
    v-if="formName === 'personal'"
    ref="form"
    :model="value"
    :validations="validationsPersonal"
    @submit="onSubmit"
  >
    <template v-slot="{ validateState }">
      <BInputLabel
        :label="$t('user.surname')"
        v-model="value.surname"
        :state="getState(validateState('surname'))"
        :disabled="isDisabled"
        ref="inputSurname"
      >
        <template v-slot:icon>
          <UserModelFormEditIcon
            v-if="isShowEditButton"
            :editing="isEditing"
            :saving="isSaving"
            @edit="onEdit"
            @submit="onSubmit"
          />
        </template>
      </BInputLabel>
      <BInputLabel
        :label="$t('global.name')"
        v-model="value.name"
        :state="getState(validateState('name'))"
        :disabled="isDisabled"
      />
      <BInputLabel
        :label="$t('user.patronymic')"
        v-model="value.patronymic"
        :state="getState(validateState('patronymic'))"
        :disabled="isDisabled"
      />
    </template>
  </BFormValidate>
  <BFormValidate
    v-else
    ref="form"
    :model="value"
    :validations="validationsEmail"
    @submit="onSubmit"
  >
    <template v-slot="{ validateState }">
      <BInputLabel
        :label="$t('email')"
        v-model="value.email"
        :state="getState(validateState('email'))"
        :disabled="isDisabled"
        ref="inputSurname"
      >
        <template v-slot:icon>
          <UserModelFormEditIcon
            v-if="isShowEditButton"
            :editing="isEditing"
            :saving="isSaving"
            @edit="onEdit"
            @submit="onSubmit"
          />
        </template>
      </BInputLabel>
    </template>
  </BFormValidate>
</template>

<script>
import { pick, isEqual } from 'lodash-es';
import { ApiBackendAccounts } from '@/api';
import { email, required } from 'vuelidate/lib/validators';
import UserModelFormEditIcon from './UserModelFormEditIcon.vue';

export default {
  components: {
    UserModelFormEditIcon,
  },
  props: {
    formName: {
      type: String,
      required: true,
    },
    value: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isEditing: false,
      isSaving: false,
      initialValue: {},
    };
  },
  computed: {
    isShowEditButton() {
      if (this.value.user_id) {
        if (this.formName === 'email') return !this.initialValue.email;
        return true;
      }
      return false;
    },
    isDisabled() {
      if (this.isSaving) return true;
      return !this.isEditing;
    },
    validationsEmail() {
      return {
        email: { email },
      };
    },
    validationsPersonal() {
      return {
        surname: { required },
        name: { required },
      };
    },
    formFields() {
      if (this.formName === 'email') {
        return ['email'];
      } else {
        return ['name', 'surname', 'patronymic'];
      }
    },
  },
  mounted() {
    this.handleValue();
  },
  methods: {
    getState(state) {
      if (this.isDisabled) return undefined;
      return state;
    },
    handleValue() {
      this.initialValue = this.reduceData(this.value);
    },
    reduceData(value) {
      return pick(value, this.formFields);
    },
    onEdit() {
      this.isEditing = true;
      this.$nextTick(() => this.$refs.inputSurname.focus());
    },
    async onSubmit() {
      if (this.$refs.form.validate()) {
        this.isSaving = true;
        try {
          const reducedValue = this.reduceData(this.value);
          const changed = !isEqual(reducedValue, this.initialValue);
          if (changed) {
            const response = await ApiBackendAccounts.update(
              this.value.user_id,
              reducedValue,
            );
            this.$emit('changed', changed);
            this.handleValue();
          }
        } catch (error) {
          error.errors
            ? this.$bvToast.toast(error.errors.join(', '))
            : this.$bvToast.toast(error);
        } finally {
          this.isEditing = false;
          this.isSaving = false;
        }
      }
    },
  },
};
</script>
