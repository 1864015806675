<template>
  <b-modal
    id="district-modal"
    :title="this.$t('building_create.addresses.district_modal.title')"
    @ok="onOk"
    ref="entityModal"
  >
    <b-form-validate
      ref="form"
      @submit="onOk"
      :validations="validations"
      :model="model"
    >
      <template slot-scope="{ validateState }">
        <div class="modal-body-row with-cols pt-30">
          <b-form-row>
            <b-col cols="12">
              <BInputLabel
                id="input-district-modal_name"
                :state="validateState('name')"
                :label="$t('name')"
                autocomplete="off"
                v-model="model.name"
              />
            </b-col>
          </b-form-row>
        </div>
      </template>
    </b-form-validate>
  </b-modal>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import { ApiBackendDistricts } from '@/api';

export default {
  name: 'DistrictModal',
  data() {
    return {
      model: {
        name: '',
      },
      validations: {
        name: {
          required,
          backend_valid: () => {
            return this.backend_valid;
          },
        },
      },
      backend_valid: true,
    };
  },
  methods: {
    async onOk(event) {
      if (event.preventDefault) {
        event.preventDefault();
      }

      if (this.$refs.form.validate()) {
        ApiBackendDistricts.create({
          name: this.model.name.trim(),
        })
          .then((entity) => {
            this.$emit('entityCreated', entity);
            this.$refs.entityModal.hide();
          })
          .catch((error) => {
            this.backend_valid = false;
            this.$bvToast.toast(error.errors.join(', '));
          });
      }
    },
    async show() {
      this.$refs.entityModal.show();
    },
  },
  watch: {
    'model.name': {
      handler: function () {
        this.backend_valid = true;
      },
    },
  },
};
</script>
