<template>
  <div>
    <CamerasList
      :value="list"
      disable-add-check-property="id"
      :mode-recognitions="modeRecognitions"
      :driving-directions="drivingDirections"
      @input="onInput"
      :relays="relays"
      :barrierId="barrierId"
      :geoUnitId="geoUnitId"
    />
    <div v-for="camera in inputsValue" :key="camera.value.id">
      <input
        v-for="key of Object.keys(camera.value).filter(
          (key) => key != 'settings' && key != 'npr_zone',
        )"
        :name="`${camera.name}[][${key}]`"
        :value="camera.value[key]"
        :key="key"
        hidden
      />
      <div v-if="camera.value.settings">
        <input
          v-for="key of Object.keys(camera.value.settings)"
          :name="`${camera.name}[][settings][${key}]`"
          :value="camera.value.settings[key]"
          :key="key"
          hidden
        />
      </div>
      <div v-if="camera.value.npr_zone">
        <input
          :name="`${camera.name}[][npr_zone]`"
          :value="camera.value.npr_zone"
          hidden
        />
      </div>
    </div>
  </div>
</template>

<script>
import CamerasList from '@/components/lists/CamerasList.vue';

export default {
  name: 'CamerasListSlim',
  components: {
    CamerasList,
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    value: {
      type: Array,
      default: [],
    },
    drivingDirections: {
      type: Array,
      default: null,
    },
    modeRecognitions: {
      type: Array,
      default: null,
    },
    relays: {
      type: Array,
      default: [],
    },
    barrierId: {
      type: [Number, String],
      default: null,
    },
    geoUnitId: {
      type: [Number, String],
      default: null,
    },
  },
  data() {
    return {
      list: this.value,
    };
  },
  computed: {
    inputsValue() {
      return this.list
        .filter((camera) => camera)
        .map((camera) => {
          return {
            value: camera,
            name: `${this.name}`,
          };
        });
    },
  },
  methods: {
    onInput(list) {
      this.list = list || [];
      this.input();
    },
    input() {
      this.$emit('input', this.list);
    },
  },
};
</script>
