<template>
  <div class="geo-units-autocomplete-2">
    <BDropdownInput
      v-model="input"
      :description="description"
      :disabled="disabled"
      :items="items"
      :label="label"
      :state="state"
      :validator="validator"
      autocomplete="off"
      compare-property="id"
      display-property="name"
      @clear="onClear"
      @create="onCreateNew"
      @focus="onFocus"
      @input="onInput"
      @select="onSelect"
      @select:item="onSelectItem"
    />
  </div>
</template>

<script>
import { request3 } from '@/api/request';
import BDropdownInput from '@/components/base/BDropdownInput.vue';
import { API_URLS } from '@/consts';
import { getManageId } from '@/helpers';
import i18n from '@app/i18n';

export default {
  name: 'DistrictsAutocomplete',
  components: { BDropdownInput },
  props: {
    clientId: {
      type: [Number, String],
      default: () => getManageId(),
    },
    description: {
      type: String,
    },
    disabled: {
      type: Boolean,
    },
    initName: {
      type: String,
    },
    label: {
      type: String,
      default: () => i18n.tc('geo_unit.type_only_names.District', 0),
    },
    state: {
      type: [Boolean, undefined],
      default: undefined,
    },
    validator: {
      type: Function,
    },
    value: {
      type: Number,
    },
  },
  data() {
    return {
      input: null,
      items: [],
      modalDistrictName: null,
    };
  },
  computed: {
    modalTitle() {
      return 'Добавить ЖК';
    },
  },
  async mounted() {
    this.initInputValue();
  },
  methods: {
    async fetchItems() {
      if (this.input?.trim().length < 2) {
        this.items = [];
        return;
      }
      this.items = await request3
        .get(API_URLS.backendManage.districts.fetch(this.clientId), {
          params: {
            q: { name_cont_all: this.input },
            limit: 8,
          },
        })
        .then((res) => res.data);
    },
    async initInputValue() {
      if (this.initName) {
        this.input = this.initName;
      } else if (this.value) {
        this.input = await request3(
          API_URLS.backendManage.districts.one(this.value, this.clientId),
        ).then((res) => res.data.name);
      }
    },
    onClear() {
      this.$emit('clear');
      this.items = [];
    },
    onCreateNew(name) {
      this.modalDistrictName = name;
    },
    onFocus() {
      this.$emit('focus');
    },
    onInput(value, { reason }) {
      if (reason === 'input') {
        this.$emit('input:search', value);
        this.$emit('input', null);
      }
      this.fetchItems();
    },
    onSelectItem(item) {
      this.$emit('select:item', item);
    },
    onSelect(value) {
      this.$emit('input', value);
      this.$emit('select', value);
    },
  },
};
</script>
