<template>
  <div class="relays-autocomplete mb-3">
    <div class="dropdown">
      <BInputLabel
        v-model="word"
        @keyup="onKeyUp"
        type="text"
        :label="placeholder"
        ref="word"
        class="autocomplete-results-dropdown my-0"
        :disabled="disabled"
        :is-invalid="isInvalid"
      />
      <input :value="selected_id" type="hidden" :name="hiddenFieldName" />
      <div class="close-icon-container" v-if="!disabled">
        <i class="icon-local-times" v-if="!empty_word" @click="wordReset"></i>
      </div>
      <div v-show="!empty_results" class="dropdown-menu mh-250 py-0">
        <div
          v-for="result in results"
          :key="result.id"
          @click="choosed(result)"
          :data-id="result.id"
          :data-name="result.name"
          class="dropdown-item"
        >
          {{ `${result.name_with_address}` }}
        </div>
      </div>
      <slot></slot>
    </div>
  </div>
</template>

<script>
import { request2 } from '@/api/request';
import BInputLabel from '@/components/base/BInputLabel.vue';
import { debounce, isEmpty, isNull } from 'lodash';

export default {
  name: 'RelaysAutocomplete',
  components: {
    BInputLabel,
  },
  props: {
    hiddenFieldName: String,
    clientId: {
      type: Number,
      required: true,
    },
    relay: Object,
    placeholder: String,
    value: {
      type: Number,
    },
    debouncedTts: {
      type: Number,
      default: 200,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isInvalid: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      debouncedSearch: null,
      selected_id: null,
      word: '',
      results: [],
    };
  },
  computed: {
    empty_results() {
      return isEmpty(this.results);
    },
    empty_word() {
      return this.word < 1;
    },
    debounced() {
      return this.debouncedTts > 0;
    },
  },
  mounted() {
    if (this.debounced) {
      this.initDebounce();
    }
    if (this.relay instanceof Object) {
      this.word = this.relay.name_with_address;
      this.selected_id = this.relay.id;
    }
  },
  watch: {
    word(value) {
      if (isNull(value) || _.isUndefined(value) || value.length < 1) {
        this.results = [];
      }
    },
    results(value) {
      if (!isEmpty(value)) {
        this.resultsOpen();
      } else {
        this.resultsClose();
      }
    },
  },
  methods: {
    choosed(result) {
      if (result.id) {
        this.word = result.name_with_address;
        this.selected_id = result.id;
        this.$emit('input', result.id);
        this.$emit('input_full', result);
        this.resultsReset();
      }
    },
    onKeyUp() {
      if (this.debounced) {
        this.$emit('update:input', this.word);
        this.debouncedSearch();
      } else {
        this.search();
      }
    },
    resultsOpen() {
      $('.autocomplete-results-dropdown').dropdown('show');
      this.$refs.word.focus();
    },
    resultsClose() {
      $('.autocomplete-results-dropdown').dropdown('hide');
      this.$refs.word.focus();
    },
    resultsReset() {
      this.results = [];
    },
    wordReset() {
      this.word = '';
      this.selected_id = null;
      this.$emit('input', null);
      this.$emit('update:input', this.word);
    },
    async search() {
      if (isEmpty(this.word)) return;
      const params = {
        q: {
          by_name_or_geo_unit_name: this.word,
        },
        limit: 8,
      };
      try {
        this.results = await request2(
          {
            method: 'get',
            url: `/api/backend/manage/${this.clientId}/autocomplete/relays`,
          },
          params,
        );
      } catch (err) {
        this.$bvToast.toast(this.$t('error.get_data'));
      }
    },
    initDebounce() {
      this.debouncedSearch = debounce(() => {
        this.search();
      }, this.debouncedTts);
    },
  },
};
</script>

<style scoped lang="scss">
@import '../../assets/styles/components/variables';
.relays-autocomplete {
  position: relative;
  .close-icon-container {
    padding: 5px;
    cursor: pointer;
    position: absolute;
    right: 13px;
    top: 13px;
  }
  fieldset {
    width: 100%;
  }
  .dropdown-menu {
    overflow-x: auto;
    .dropdown-item {
      border: 1px solid #edeef0;
      height: 50px;
      cursor: pointer;
      display: flex;
      align-items: center;
      &:hover {
        background: $table-hover-bg;
      }
    }
  }
}
</style>
