<template>
  <div class="clients-autocomplete">
    <div class="dropdown">
      <BInputLabel
        v-model="word"
        @keyup="onKeyUp"
        @focus="onFocus"
        type="text"
        :label="legendLabel"
        ref="word"
        class="clients-autocomplete-results-dropdown"
        :class="{ show: isShowedDropdown }"
      />
      <div class="close-icon-container">
        <i class="icon-local-times" v-if="!empty_word && saveFormId" @click="wordReset"></i>
      </div>
      <input :value="selected_id" type="hidden" :name="name" />
      <div v-show="!empty_results" class="dropdown-menu py-0">
        <div
          v-for="result in results"
          :key="result.id"
          @click="choosed(result)"
          :data-id="result.id"
          :data-name="result.serial"
          class="dropdown-item"
        >
          <p>
            {{ result.name }}
          </p>
        </div>
      </div>
      <slot></slot>
    </div>
  </div>
</template>

<script>
import { isEmpty, isNull } from 'lodash';
import BInputLabel from '@/components/base/BInputLabel.vue';

export default {
  name: 'adminClientsAutocomplete',
  components: {
    BInputLabel,
  },
  props: {
    saveFormId: String,
    currentClient: {
      type: Object,
    },
    name: {
      type: String,
    },
    clients: {
      type: Array,
      required: true,
    },
    legendLabel: {
      type: String,
      default: function () {
        return this.$t('abbreviations.MC')
      }
    },
  },
  data() {
    return {
      selected_id: null,
      word: '',
      results: this.clients,
      isShowedDropdown: false,
    };
  },
  computed: {
    empty_word() {
      return this.word < 1;
    },
    empty_results() {
      return this.results.length == 0;
    },
  },
  mounted() {
    if (this.currentClient) {
      this.word = this.currentClient.name;
      this.selected_id = this.currentClient.id;
    }
    window.addEventListener('click', this.resultsClose);
  },
  methods: {
    choosed(result) {
      this.word = result.name;
      this.selected_id = result.id;
      this.saveFormId ? this.saveForm() : this.changeClientPath(result);
    },
    onKeyUp() {
      this.results = this.clients.filter((client) =>
        client.name.toLowerCase().includes(this.word.toLowerCase()),
      );
    },
    onFocus() {
      if (!this.saveFormId) {
        this.wordReset();
      }
      this.resultsReset();
    },
    saveForm() {
      if (_.isUndefined(this.saveFormId)) {
        return;
      }

      const vm = this;
      setTimeout(function () {
        $(`#${vm.saveFormId}`).submit();
      }, 100);
    },
    changeClientPath(result) {
      let splitedUrl = window.location.toString().split('/');

      const pathMap = {
        home: 'home/districts',
        intercom: 'intercom/district/all/devices',
        npr: 'npr/districts',
        metering: 'metering/devices',
        fa: 'fa/devices',
        sa: 'sa/devices',
        cctv: 'cctv',
        accounts: 'accounts',
        users: 'users',
      };

      let section = splitedUrl.find((i) =>
        [
          'home',
          'intercom',
          'npr',
          'metering',
          'fa',
          'sa',
          'cctv',
          'accounts',
          'users',
        ].includes(i),
      );

      const newPath = pathMap[section]
        ? `/manage/${result.id}/${pathMap[section]}`
        : `/manage/${result.id}`;
      location.pathname = newPath;
    },
    resultsOpen() {
      $('.clients-autocomplete-results-dropdown').dropdown('show');
      this.isShowedDropdown = true;
    },
    resultsClose() {
      $('.clients-autocomplete-results-dropdown').dropdown('hide');
      this.isShowedDropdown = false;
    },
    resultsReset() {
      this.results = this.clients;
    },
    wordReset() {
      this.word = '';
      this.selected_id = null;
      if (this.saveFormId) {
        this.saveForm();
      }
    },
  },
  watch: {
    word(value) {
      if (isNull(value) || isEmpty(value)) {
        this.resultsReset();
      }
    },
    results(value) {
      isEmpty(value) ? this.resultsClose() : this.resultsOpen();
    },
  },
};
</script>

<style lang="scss">
@import '../../assets/styles/components/variables';
.clients-autocomplete {
  position: relative;
  width: 255px;
  .close-icon-container {
    padding: 5px;
    cursor: pointer;
    position: absolute;
    right: 13px;
    top: 13px;
    z-index: 9999;
  }
  fieldset {
    width: 100%;
    z-index: 9999;
    &.show {
      .form-control {
        border-bottom-left-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
      }
    }
  }
  .dropdown-menu {
    z-index: 999 !important;
    overflow-x: auto;
    max-height: 300px;
    overflow-y: auto;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    border: 1px solid #dfe2e5;
    top: calc(100% - 17px);
    .dropdown-item {
      border-bottom: 1px solid #dfe2e5;
      height: 50px;
      cursor: pointer;
      display: flex;
      p {
        margin-bottom: auto;
        margin-top: auto;
        overflow: hidden;
        text-align: start;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      &:hover {
        background: $table-hover-bg;
      }
      &:last-child {
        border: none;
      }
    }
  }
}
</style>
