<template>
  <div>
    <b-modal
      id="intercom-selection-model-modal"
      class="intercom-selection-model-modal"
      ref="SelectIntercom"
      :title="$t('devices.modal.create')"
      size="sm"
      no-stacking
    >
      <b-form-validate
        ref="selectInfoIntercom"
        :model="intercom"
        :validations="validations"
      >
        <template slot-scope="{ validateState, v }">
          <div class="modal-body-row with-cols pt-30">
            <b-form-row class="mb-3">
              <b-col cols="12">
                <BSelectLabel
                  id="select-intercom-modal_model_id"
                  :items="actualModels"
                  :customDisplay="intercomModelName"
                  object-property="id"
                  :label="$t('devices.modal.model')"
                  v-model="intercom.intercom_model_id"
                  @select="selectModel"
                  zero-val-active
                  :state="validateState('intercom_model_id')"
                  class="mb-0"
                />
                <BError
                  v-if="!v.model.intercom_model_id.required && isSubmit"
                  :error="$t('devices.errors.not_empty')"
                />
              </b-col>
            </b-form-row>
            <b-form-row class="mb-3" v-if="uuidRequired">
              <b-col cols="12">
                <BInputLabel
                  :disabled="!intercom.intercom_model_id"
                  :label="$t('devices.modal.uuid_or_serial')"
                  v-model="intercom.uuid_or_serial"
                  :state="validateState('uuid_or_serial')"
                  class="mb-0"
                />
                <BError
                  v-if="!v.model.uuid_or_serial.required && isSubmit"
                  :error="$t('devices.errors.not_empty')"
                />
              </b-col>
            </b-form-row>
            <b-form-row class="mb-3" v-if="ipAddressRequired">
              <b-col cols="12">
                <BInputLabel
                  :disabled="!intercom.intercom_model_id"
                  :label="$t('devices.modal.ip')"
                  v-model="intercom.ip_address"
                  :state="validateState('ip_address')"
                  class="mb-0"
                />
                <BError
                  v-if="!v.model.ip_address.required && isSubmit"
                  :error="$t('devices.errors.not_empty')"
                />
                <BError
                  v-else-if="!v.model.ip_address.ipAddress && isSubmit"
                  :error="$t('devices.errors.formatter')"
                />
              </b-col>
            </b-form-row>
            <b-form-row class="mb-3" v-if="macAddrRequired">
              <b-col cols="12">
                <BInputLabel
                  :disabled="!intercom.intercom_model_id"
                  :label="$t('devices.modal.mac')"
                  v-model="intercom.macaddr"
                  :state="validateState('macaddr')"
                  class="mb-0"
                />
                <BError
                  v-if="!v.model.macaddr.required && isSubmit"
                  :error="$t('devices.errors.not_empty')"
                />
              </b-col>
            </b-form-row>
            <b-form-row v-if="isShowControlPanel" class="mb-3">
              <b-col md="12" class="align-items-center main-tab__mac">
                <IntercomMacAutocomplete
                  :placeholder="$t('intercom.control_panel')"
                  :clientId="parseInt(clientId)"
                  :value="intercom.control_panel_id"
                  :intercom-data="intercom.control_panel"
                  v-model="intercom.control_panel_id"
                  class="flex-grow-1 intercom-autocompletes"
                  hint-id="rusgard-fast-add"
                  popover-text="i"
                  :popover-hint="$t('intercom.control_panel_hint')"
                  :state="validateState('control_panel_id')"
                />
                <BError
                  v-if="!v.model.control_panel_id.required && isSubmit"
                  :error="$t('devices.errors.not_empty')"
                />
              </b-col>
            </b-form-row>
            <div v-if="isShowUsernamePassword" class="p-0">
              <b-form-row class="mb-3">
                <b-col cols="12">
                  <BInputLabel
                    id="input-intercom-modal_username"
                    :label="$t('devices.tabs.device.username')"
                    autocomplete="off"
                    v-model="intercom.settings.username"
                    :state="validateState('settings.username')"
                    class="mb-0"
                  />
                  <BError
                    v-if="!v.model.settings.username.required && isSubmit"
                    :error="$t('devices.errors.not_empty')"
                  />
                </b-col>
              </b-form-row>

              <b-form-row class="mb-3">
                <b-col cols="12">
                  <BInputLabel
                    id="input-intercom-modal_password"
                    name="intercom-password"
                    :label="$t('devices.tabs.device.password')"
                    autocomplete="off"
                    v-model="intercom.settings.password"
                    :state="validateState('settings.password')"
                    class="mb-0"
                  />
                  <BError
                    v-if="!v.model.settings.password.required && isSubmit"
                    :error="$t('devices.errors.not_empty')"
                  />
                </b-col>
              </b-form-row>
            </div>
          </div>
        </template>
      </b-form-validate>
      <template #modal-footer>
        <div
          class="d-flex align-items-center justify-content-between flex-grow-1"
        >
          <b-button
            @click="openUuidSelectModal"
            size="sm"
            variant="outline-primary"
            class="intercom-fast-modal__btn w-auto h-auto"
          >
            {{ $t('devices.modal.back') }}
          </b-button>
          <b-button
            variant="primary"
            size="sm"
            class="intercom-fast-modal__btn w-auto h-auto"
            @click="findDevice"
          >
            {{ $t('devices.modal.next') }}
          </b-button>
        </div>
      </template>
    </b-modal>
    <InfoIntercomModal
      ref="InfoIntercomModal"
      id="intercom-info"
      :clientId="clientId"
      @showSelectModal="show"
    />
    <ErrorsIntercomFindModal
      ref="ErrorsIntercomFindModal"
      id="intercom-errors-find"
      :clientId="clientId"
      :uuidRequired="uuidRequired"
      :ipAddressRequired="ipAddressRequired"
      :macAddrRequired="macAddrRequired"
      :isShowControlPanel="isShowControlPanel"
      :isShowUsernamePassword="isShowUsernamePassword"
      @showSelectModal="show"
    />
  </div>
</template>

<script>
import { API_URLS, EVENTS } from '@/consts';
import { request3 } from '@/api/request';
import { ApiIntercomModels } from '@/api';
import { required, requiredIf, ipAddress } from 'vuelidate/lib/validators';
import { mapGetters, mapMutations, mapState } from 'vuex';
import InfoIntercomModal from '../modelSelection/InfoIntercomModal.vue';
import ErrorsIntercomFindModal from '../modelSelection/ErrorsIntercomFindModal.vue';
import IntercomMacAutocomplete from '@/components/autocompletes/IntercomMacAutocomplete.vue';
import BError from '@/components/base/BError';

export default {
  name: 'SelectIntercomModelModal',
  components: {
    InfoIntercomModal,
    ErrorsIntercomFindModal,
    IntercomMacAutocomplete,
    BError,
  },
  props: {
    clientId: {
      type: [String, Number],
      required: true,
    },
    type: String,
  },
  data() {
    return {
      intercomModels: [],
      isSubmit: false,
    };
  },
  computed: {
    ...mapState('intercom', ['intercom', 'intercomModel']),
    ...mapGetters('intercom', ['getIntercom', 'getIntercomModel']),
    intercom: {
      get() {
        return this.getIntercom;
      },
      set(value) {
        this.setIntercom(value);
      },
    },
    actualModels() {
      if (this.type) {
        return this.intercomModels.filter((model) => model.type === this.type);
      } else return this.intercomModels;
    },
    intercomModel() {
      return this.getIntercomModel;
    },
    validations() {
      return {
        ip_address: {
          required: requiredIf(() => this.ipAddressRequired),
          ipAddress,
        },
        uuid_or_serial: {
          required: requiredIf(() => this.uuidRequired),
        },
        macaddr: {
          required: requiredIf(() => this.macAddrRequired),
        },
        control_panel_id: {
          required: requiredIf(() => this.isShowControlPanel),
        },
        intercom_model_id: {
          required,
        },
        settings: {
          password: {
            required: requiredIf(() => this.isShowUsernamePassword),
          },
          username: {
            required: requiredIf(() => this.isShowUsernamePassword),
          },
        },
      };
    },
    uuidRequired() {
      return this.intercomModel?.tags?.includes('required_uuid_or_serial');
    },
    ipAddressRequired() {
      return [
        'ip_address_required',
        'config_with_exchange',
        'correspond_table',
      ].some((tag) => this.intercomModel?.tags?.includes(tag));
    },
    macAddrRequired() {
      return this.intercomModel?.tags?.includes('required_macaddr');
    },
    isShowControlPanel() {
      return this.intercomModel?.tags?.includes('rusguard_cloud');
    },
    isShowUsernamePassword() {
      return (
        this.intercomModel?.type === 'call_panel' &&
        !(this.intercomModel?.rabbit_queue === 'access_control_queue')
      );
    },
  },
  methods: {
    ...mapMutations('intercom', ['setIntercomModel', 'setIntercom']),

    async findDevice(event) {
      if (event) event.preventDefault();
      this.isSubmit = true;

      if (this.$refs.selectInfoIntercom.validate()) {
        let params = {};
        if (this.uuidRequired) {
          params = Object.assign(params, {
            uuid_or_serial: this.intercom.uuid_or_serial,
          });
        }
        if (this.macAddrRequired) {
          params = Object.assign(params, {
            macaddr: this.intercom.macaddr,
          });
        }
        if (this.uuidRequired || this.macAddrRequired) {
          request3
            .get(
              API_URLS.backendManage.devices.intercoms.validate_params(
                this.clientId,
              ),
              {
                params,
              },
            )
            .then((res) => {
              this.hide();
              this.openInfoIntercomModal();
            })
            .catch((error) => {
              const errors = error.response.data.errors;

              this.hide();
              this.openErrorsIntercomFindModal(errors);
            });
        } else {
          this.hide();
          this.openInfoIntercomModal();
        }
      }
    },
    selectModel(val) {
      const current = this.intercomModels.find((model) => model.id == val);
      this.setIntercomModel(current);
    },
    intercomModelName(model) {
      return `${model?.info?.vendor || ''} ${model?.name || ''}`;
    },
    openInfoIntercomModal() {
      this.$refs.InfoIntercomModal.show();
    },
    openErrorsIntercomFindModal(errors) {
      this.$refs.ErrorsIntercomFindModal.show(errors);
    },
    async show() {
      this.$refs.SelectIntercom.show();
      this.isSubmit = false;
      await ApiIntercomModels.get()
        .allAsync()
        .then((res) => {
          this.intercomModels = res;
        });
    },
    hide() {
      this.$refs.SelectIntercom.hide();
    },
    openUuidSelectModal() {
      this.hide();
      EventBus.$emit(EVENTS.intercom.fast.modal.show);
    },
  },
};
</script>

<style></style>
