<template>
  <div>
    <b-modal
      :title="typeTitle"
      id="intercom-edit-modal"
      modal-class="intercom-edit-modal"
      header-border-variant="transparent"
      ref="editModal"
      size="lg"
    >
      <b-form-validate
        ref="IntercomForm"
        :model="intercom"
        :validations="validations"
      >
        <template slot-scope="{ validateState, v }">
          <IntercomTabs
            :validateState="validateState"
            :v="v"
            :intercomModels="actualModels"
            :clientId="clientId"
            :actualServers="actualServers"
            :isShowKeysProfiles="isShowKeysProfiles"
            :isRequredUuid="isRequredUuid"
            :isRequiredIpAddress="isRequiredIpAddress"
            :isRequiredMacAddress="isRequiredMacAddress"
            :isRequiredSelectSipServer="isRequiredSelectSipServer"
            :isShowControlPanel="isRequiredControlPanel"
            :isSubmit="isSubmit"
          />
        </template>
      </b-form-validate>
      <template #modal-footer>
        <div
          class="d-flex align-items-center justify-content-between flex-grow-1"
        >
          <b-button
            @click="hide"
            size="sm"
            variant="outline-primary"
            class="intercom-edit-modal__btn w-auto h-auto"
          >
            {{ $t('devices.modal.cancel') }}
          </b-button>
          <b-button
            variant="primary"
            size="sm"
            class="intercom-edit-modal__btn w-auto h-auto"
            @click="onOk"
          >
            {{ $t('devices.modal.save') }}
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import _ from 'lodash';
import EventBus from '@/packs/EventBus';
import { mapGetters, mapMutations, mapState, mapActions } from 'vuex';
import { EVENTS } from '@/consts';
import IntercomTabs from './tabs/IntercomTabs.vue';
import { ApiBackendSipServers } from '@/api';
import { ApiIntercomModels } from '@/api';
import {
  required,
  requiredIf,
  helpers,
  ipAddress,
  maxLength,
} from 'vuelidate/lib/validators';
import { isDigits } from '@/helpers/lodash';

export default {
  name: 'IntercomEditModal',
  components: {
    IntercomTabs,
  },
  props: {
    clientId: {
      type: [Number, String],
      required: true,
    },
  },
  data() {
    return {
      intercomModels: [],
      actualServers: [],
      isSubmit: false,
    };
  },
  async created() {
    await ApiIntercomModels.get()
      .allAsync()
      .then((res) => {
        this.intercomModels = res;
      });
    await ApiBackendSipServers.get()
      .allAsync()
      .then((res) => {
        this.actualServers = res.filter((m) => m.active === true);
      });
  },
  computed: {
    ...mapState('intercom', ['intercomModel']),
    ...mapGetters('intercom', ['getIntercom', 'getIntercomModel']),
    validations() {
      return {
        name: { required, maxLength: maxLength(70) },
        intercom_model_id: { required },
        sip_server_id: {
          required: requiredIf(() => this.isRequiredSelectSipServer),
        },
        macaddr: {
          required: requiredIf(() => this.isRequiredMacAddress),
        },
        ip_address: {
          required: requiredIf(() => this.isRequiredIpAddress),
          ipAddress,
        },
        control_panel_id: {
          required: requiredIf(() => this.isRequiredControlPanel),
        },
        serial: {
          required: requiredIf(
            () =>
              this.isRequredUuid &&
              (!this.intercom.uuid || !this.intercom.uuid.length),
          ),
        },
        uuid: {
          required: requiredIf(
            () =>
              this.isRequredUuid &&
              (!this.intercom.serial || !this.intercom.serial.length),
          ),
        },
        settings: {
          password: {
            required: requiredIf(() => this.isPanelQueueFields),
          },
          username: {
            required: requiredIf(() => this.isPanelQueueFields),
          },
          intercom_keys_profile_id: {
            required: requiredIf(() => this.isShowKeysProfiles),
          },
          proxy_schema: {
            $each: {
              prefix: {
                isInteger: (val) => {
                  return !helpers.req(val) || isDigits(val);
                },
              },
              range: {
                required,
                isRange: (value) => {
                  const range = value
                    .split('-')
                    .map((i) => Number(i.trim()))
                    .filter((i) => isDigits(i));
                  return range.length === 2 && range[0] < range[1];
                },
              },
              ip: {
                required,
                ipAddress,
              },
            },
          },
        },
        relays_attributes: {
          required,
          $each: {
            index: {
              required,
              isUnique: this.isUniqueRelaysIndex,
            },
            geo_unit_id: { required },
            name: { required, isUnique: this.isUniqueRelaysName },
            relays_geo_units_attributes: {
              $each: {
                geo_unit_id: { required },
              },
            },
          },
        },
      };
    },
    intercom() {
      return this.getIntercom;
    },
    intercomModel() {
      return this.getIntercomModel;
    },
    isRequiredSelectSipServer() {
      return Boolean(
        this.actualServers.length && this.intercomModel?.type === 'call_panel',
      );
    },
    isRequiredMacAddress() {
      return this.intercomModel?.tags?.includes('required_macaddr');
    },
    isRequredUuid() {
      return this.intercomModel?.tags?.includes('required_uuid_or_serial');
    },
    isShowKeysProfiles() {
      return this.intercomModel?.tags?.includes('keys_profiled');
    },
    isShowIpAddress() {
      return (
        this.isRequiredIpAddress ||
        this.intercomModel?.tags?.includes('ip_address')
      );
    },
    isPanelQueueFields() {
      return (
        this.intercomModel?.type === 'call_panel' &&
        !(this.intercomModel?.rabbit_queue === 'access_control_queue')
      );
    },
    isRequiredIpAddress() {
      return [
        'ip_address_required',
        'config_with_exchange',
        'correspond_table',
      ].some((tag) => this.intercomModel?.tags?.includes(tag));
    },
    isRequiredControlPanel() {
      return this.intercomModel?.tags?.includes('rusguard_cloud');
    },
    actualModels() {
      if (this.intercomModel?.type) {
        return this.intercomModels.filter(
          (model) => this.intercomModel.type === model.type,
        );
      } else return this.intercomModels;
    },
    typeTitle() {
      if (this.intercomModel?.type) {
        if (this.intercomModel.type == 'call_panel') {
          return this.$t('devices.modal.settings.title.call_panel');
        } else return this.$t('devices.modal.settings.title.access_control');
      } else return this.$t('devices.modal.settings.title.device');
    },
    getRelayIndexes() {
      if (this.intercom.relays_attributes) {
        return this.intercom.relays_attributes.map(({ index }) =>
          Number(index),
        );
      } else return [];
    },
    getRelayNames() {
      if (this.intercom.relays_attributes) {
        return this.intercom.relays_attributes.map(({ name }) => name);
      } else return [];
    },
  },
  methods: {
    ...mapMutations('intercom', [
      'setIntercom',
      'setIntercomModel',
      'setInitRelays',
      'convertProxy',
      'parseProxySchema',
    ]),
    ...mapActions('intercom', ['update']),

    onOk(event) {
      if (event) event.preventDefault();
      this.isSubmit = true;

      if (this.$refs.IntercomForm.validate()) {
        this.updateDevice();
      }
    },
    async show(device) {
      this.$refs.editModal.show();
      this.setIntercom(device);
      this.initRelays(Object.assign([], device.relays));
      this.selectModel(device.intercom_model_id);
      this.parseProxySchema();
      setTimeout(() => {
        if (!device.configured) {
          this.isSubmit = true;
          this.$refs.IntercomForm.validate();
        } else {
          this.isSubmit = false;
        }
      }, 300);
    },
    hide() {
      this.$refs.editModal.hide();
      this.isSubmit = false;
    },
    updateDevice() {
      EventBus.$emit(
        EVENTS.preloader.show,
        this.$t('devices.tabs.messages.wait'),
      );
      this.convertProxy();
      this.update(this.intercom)
        .then(() => {
          this.$bvModal.msgBoxOk(this.$t('devices.tabs.messages.success'), {
            modalClass: 'modal-ok',
            okTitle: this.$t('button.ok'),
            title: this.$t('title.successfully'),
          });
          this.hide();
          EventBus.$emit(EVENTS.intercom.update);
        })
        .catch((response) => {
          this.parseProxySchema();
          this.$bvToast.toast(Object.values(response.errors).join('. '));
        })
        .finally(() => {
          EventBus.$emit(EVENTS.preloader.hide);
        });
    },
    selectModel(val) {
      const current = this.intercomModels.find((model) => model.id == val);
      this.setIntercomModel(current);
    },
    initRelays(relays) {
      this.setInitRelays(relays);
    },
    isUniqueRelaysIndex(index) {
      const duplicates = this.getDuplicates(this.getRelayIndexes);
      return !duplicates.includes(index);
    },
    isUniqueRelaysName(name) {
      const duplicates = this.getDuplicates(this.getRelayNames);
      return !duplicates.includes(name);
    },
    getDuplicates(arr) {
      const map = new Map();
      for (const el of arr) {
        const count = map.get(el);
        map.set(el, (count ?? 0) + 1);
      }

      return Array.from(map.entries()).reduce((res, [el, count]) => {
        if (count > 1) {
          res.push(el);
        }
        return res;
      }, []);
    },
  },
};
</script>
<style lang="scss">
.intercom-edit-modal {
  &__btn {
    min-height: 0 !important;
    padding: 8px 30px;
  }
}
</style>
