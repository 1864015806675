<template>
  <div class="intercom-models-autocomplete">
    <div class="dropdown">
      <BInputLabel
        v-model="word"
        type="text"
        :label="placeholder"
        :state="state"
        :disabled="disabled"
        @focus="resultsOpen"
        @blur="onBlur"
        ref="componentInput"
      />
      <div class="close-icon-container" v-if="!disabled">
        <i class="icon-local-times" v-if="!empty_word" @click="onClear"></i>
      </div>
      <div class="dropdown-menu py-0" tabindex="0">
        <div
          v-for="result in results"
          :key="result.id"
          @mousedown="choosed(result)"
          :data-id="result.id"
          :data-name="result.name"
          class="dropdown-item"
        >
          {{ itemName(result) }}
        </div>
      </div>
      <slot></slot>
    </div>
  </div>
</template>

<script>
import BInputLabel from '@/components/base/BInputLabel';

export default {
  name: 'IntercomModelsAutocomplete',
  components: {
    BInputLabel,
  },
  props: {
    placeholder: String,
    state: {},
    disabled: {
      type: Boolean,
      default: false,
    },
    items: Array,
    currentModel: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      word: '',
      model: {},
    };
  },
  computed: {
    empty_word() {
      return this.word < 1;
    },
    results() {
      if (this.word.length < 3) return this.items;

      return this.items.filter((item) => {
        return this.itemName(item)
          .toLowerCase()
          .includes(this.word.toLowerCase());
      });
    },
  },
  mounted() {
    if (this.currentModel instanceof Object) {
      this.model = this.currentModel;
    }
  },
  methods: {
    choosed(result) {
      this.model = result;
      this.$emit('input', result?.id);
      this.$emit('input:item', result);
    },
    onBlur() {
      this.resultsClose();
    },
    resultsOpen() {
      $(this.$refs.componentInput.$el).dropdown('show');
    },
    resultsClose() {
      $(this.$refs.componentInput.$el).dropdown('hide');
    },
    onClear() {
      this.choosed(null);
    },
    itemName(item) {
      return `${item?.info?.vendor || ''} ${item?.name || ''}`;
    },
  },
  watch: {
    model(value) {
      this.word = this.itemName(value);
    },
  },
};
</script>

<style scoped lang="scss">
@import '../../assets/styles/components/variables';
.intercom-models-autocomplete {
  position: relative;
  .close-icon-container {
    padding: 5px;
    cursor: pointer;
    position: absolute;
    right: 13px;
    top: 13px;
  }
  fieldset {
    width: 100%;
  }
  .dropdown-menu {
    overflow-x: auto;
    max-height: 300px;
    .dropdown-item {
      border: 1px solid #edeef0;
      height: 50px;
      cursor: pointer;
      display: flex;
      align-items: center;
      &:hover {
        background: $table-hover-bg;
      }
    }
  }
}
</style>
