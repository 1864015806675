<template>
  <div class="camera-play-stream" @click="onOpenStream">
    <div class="camera-play-stream__circle">
      <div class="camera-play-stream__button"></div>
    </div>
    <StreamVideoModal
      ref="StreamVideoModal"
      device-type="camera"
      :device-id="deviceId"
      :name="name"
      :background="background"
    ></StreamVideoModal>
  </div>
</template>

<script>
import StreamVideoModal from '@/components/modals/StreamVideoModal.vue';

export default {
  components: {
    StreamVideoModal,
  },
  props: {
    deviceId: {
      required: true,
    },
    background: {
      type: String,
      default: '',
    },
    name: {},
  },
  methods: {
    onOpenStream() {
      this.$refs.StreamVideoModal.show();
    },
  },
};
</script>

<style lang="scss">
.camera-play-stream {
  height: calc(100% - 4rem);
  width: calc(100% - 4rem);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  .camera-play-stream__circle {
    display: flex;
    border-radius: 100px;
    height: 100px;
    width: 100px;
    background-color: rgb(0%, 0%, 0%, 0.5);

    justify-content: center;
    align-items: center;
    transition: opacity 0.3s;
  }

  &:hover .camera-play-stream__circle {
    opacity: 1;
    transition: opacity 0.3s;
    background-color: #000;
  }

  .camera-play-stream__button {
    margin-left: 13px;
    box-sizing: border-box;
    width: 43px;
    height: 50px;
    border-style: solid;
    border-color: transparent transparent transparent white;
    border-width: 25px 0 25px 43px;
  }
}
</style>
