<template>
  <div class="row mb-4">
    <div class="col-6 text-center">
      <img
        :src="srcAvatar"
        alt="avatar"
        class="img-circle"
        :width="size"
        :height="size"
      />
    </div>
    <div class="col-6">
      <div class="bolder mb-3">{{ $t('avatar.upload_photo') }}</div>
      <div class="">
        <div class="row">
          <div class="col-auto">
            <label :for="inputId" class="btn btn-primary btn-xs">
              <span>{{ $t('button.select') }}</span>
            </label>
          </div>
          <div class="col-auto" v-if="!isUploaded">
            {{ $t('avatar.file_not_chosen') }}
          </div>
        </div>
        <input
          class="hidden"
          :id="inputId"
          type="file"
          :name="name"
          @change="handleFile"
          @click="clearInput"
        />
        <div class="small text-muted">
          {{ $t('avatar.format_message') }}
        </div>
        <div class="small text-danger" v-if="!!errorMessage">
          {{ errorMessage }}
        </div>
      </div>
      <hr />
      <div class="">
        <input
          id="user_remove_avatar"
          type="hidden"
          name="user[remove_avatar]"
          value="1"
          :disabled="!removeAvatar"
        />
        <button
          v-if="srcAvatar !== defaultSrc"
          type="button"
          name="remove"
          class="btn btn-outline-danger btn-xs"
          @click="removeImage"
        >
          {{ $t('button.delete') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AvatarUpload',
  props: {
    inputId: String,
    name: String,
    defaultSrc: {
      type: String,
      default: '/img/icon-no-avatar.svg',
    },
    currentSrc: String,
    size: {
      default: '150',
    },
  },
  data() {
    return {
      srcAvatar: '',
      errorMessage: '',
      removeAvatar: false,
      isUploaded: false,
    };
  },
  mounted() {
    this.srcAvatar = this.currentSrc || this.defaultSrc;
  },
  methods: {
    handleFile(event) {
      let file = event.target.files[0];
      if (file) {
        if (!['image/png', 'image/jpeg'].includes(file.type)) {
          this.errorMessage = this.$t('avatar.error_format');
          this.clearInput();
        } else if (file.size > 5242880) {
          this.errorMessage = this.$t('avatar.error_size_too_big');
          this.clearInput();
        } else if (file.size < 10240) {
          this.errorMessage = this.$t('avatar.error_size_too_small');
          this.clearInput();
        } else {
          this.removeAvatar = false;
          this.errorMessage = '';
          this.isUploaded = true;
          this.srcAvatar = URL.createObjectURL(file);
        }
      }
    },
    removeImage() {
      this.srcAvatar = this.defaultSrc;
      this.errorMessage = '';
      this.removeAvatar = true;
      this.isUploaded = false;
    },
    clearInput() {
      const input = this.$el.querySelector('input[type="file"]');
      input.value = '';
    },
  },
};
</script>

<style lang="scss">
input.hidden {
  opacity: 0;
  position: absolute;
  z-index: -1;
}
.bolder {
  font-weight: bolder;
}
</style>
