<template>
  <div>
    <vue2-date-picker
      v-bind="$attrs"
      :disabled-date="notAfterToday"
      :editable="false"
      :formatter="format"
      :lang="lang"
      :value="value"
      @input="onChange"
      range
      ref="datePicker"
    >
      <template v-slot:input>
        <BFormGroupSlot :label="label" :value="inputValue" ref="bFGInput">
          <slot></slot>
        </BFormGroupSlot>
      </template>
    </vue2-date-picker>
  </div>
</template>

<script>
import { isEmpty, compact } from 'lodash';
import Vue2DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/locale/ru';
import dayjs from 'dayjs';
import BFormGroupSlot from '@/components/base/BFormGroupSlot.vue';
import { getLocale } from '@/helpers';

Vue2DatePicker.locale(getLocale());

function parseDate(date) {
  if (!date) return;

  const regex = /^(\d{1,2})\s([а-я]+)\s(\d{4})/m;
  const cyrillicMonthFormat =
    'января_февраля_марта_апреля_мая_июня_июля_августа_сентября_октября_ноября_декабря'.split(
      '_',
    );
  const isCyrillic = date.search(/[\u0400-\u04FF]/) > -1;
  if (isCyrillic) {
    const [unused, day, cyrillicMonth, year] = date.match(regex);
    const month =
      cyrillicMonthFormat.findIndex((month) => month === cyrillicMonth) + 1;
    const formattedDate = [
      year,
      String(month).padStart(2, '0'),
      day.padStart(2, 0),
    ].join('-');
    return dayjs(formattedDate).toISOString();
  }
  return dayjs(date, 'LL').toISOString();
}

export default {
  name: 'RangeDatePickerSlot',
  props: {
    label: {
      type: String,
    },
    model: String,
    name: String,
    required: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    Vue2DatePicker,
    BFormGroupSlot,
  },
  data() {
    return {
      validates: true,
      format: {
        stringify: (val) => (val ? dayjs(val).format('LL') : null),
        parse: (val) => (val ? dayjs(val).toISOString() : null),
      },
      value: '',
    };
  },
  computed: {
    lang() {
      return {
        defaultLocale: 'ru',
        firstDayOfWeek: 1,
      };
    },
    inputValue() {
      if (this.value) {
        if (Array.isArray(this.value)) {
          return this.value
            .map((val) => this.format.stringify(val))
            .join(' - ');
        }
        return this.format.stringify(this.value);
      }
      return '';
    },
  },
  mounted() {
    if (this.$refs.bFGInput.isValue) {
      this.value = this.$refs.bFGInput.input.value.split(' - ').map((val) => {
        const parsedDate = parseDate(val);
        return new Date(parsedDate);
      });
    } else if (this.required) {
      this.value = this.defaultDate();
    }
  },
  methods: {
    defaultDate() {
      return [
        dayjs().subtract(1, 'months').toISOString(),
        dayjs().toISOString(),
      ];
    },
    onChange(val) {
      this.value = isEmpty(compact(val)) ? this.defaultDate() : val;
      this.$refs.bFGInput.setInputValue(this.inputValue);
      this.$refs.bFGInput.input.dispatchEvent(new Event('change'));
    },
    notAfterToday(date) {
      return date > new Date();
    },
  },
};
</script>
<style lang="scss">
$primary-color: #03a9f4;
@import '~vue2-datepicker/scss/index';

.mx-datepicker {
  width: 100%;
  input {
    background: none !important;
    cursor: pointer !important;
  }
  .mx-icon-calendar {
    margin-right: 10px;
  }
}
.mx-datepicker-main {
  .mx-date-row {
    td {
      border: none !important;
    }
  }
}
</style>
