<template>
  <div>
    <h5 class="mb-5">{{ $t('barrier.controller') }}</h5>
    <div class="d-flex">
      <IntercomAutocomplete
        :placeholder="$t('barrier.placeholder_autocomplete')"
        :clientId="clientId"
        :value="value.id"
        :intercom-data="value"
        :additionalQueryParams="{ npr_relays_free: true, npr_supported: true }"
        @clear="(value) => onClear(value)"
        @select="(value) => onSelect(value)"
        class="flex-grow-1"
      />
    </div>
    <div v-for="(relay, index) in relays" :key="relay.id">
      <div class="mb-3 mt-3 d-flex justify-content-between align-items-center">
        <span>
          {{ $t('relay.title') }}
          <span v-if="relay.index">{{ relay.index }}</span>
          <span v-else>{{ index + 1 }}</span>
        </span>
        <SelectSlot
          v-if="relay.npr_barrier_id == barrierId || !relay.npr_barrier_id"
          :label="$t('relay.action')"
          :items="relaysActions"
          class="mt-2 w-50"
          :disabled="
            !relay.id ||
            (relay.npr_barrier_id && relay.npr_barrier_id != barrierId)
          "
        >
          <select
            v-model="relay.npr_action"
            class="form-control select optional"
          >
            <option
              v-for="(action, index) in relaysActions"
              :value="action.value"
              :key="index"
              :title="action.title"
            >
              {{ action.title }}
            </option>
          </select>
        </SelectSlot>
        <BInputLabel
          v-else
          :value="$t('barrier.relay_used')"
          :label="$t('relay.action')"
          class="w-50 mt-2"
          disabled
          type="text"
        />
      </div>
      <div class="mb-3 d-flex justify-content-between align-items-center">
        <span>{{ $t('relay.npr_delay') }}</span>
        <BInputLabel
          :label="$t('relay.delay')"
          type="text"
          v-model="relay.npr_delay"
          class="w-50"
          :disabled="
            !relay.id ||
            (relay.npr_barrier_id && relay.npr_barrier_id != barrierId) ||
            relay.npr_action == 'not_used'
          "
        ></BInputLabel>
      </div>
    </div>
  </div>
</template>

<script>
import SelectSlot from '@/vue_slim/components/base/SelectSlot';
import IntercomAutocomplete from '@/components/autocompletes/IntercomAutocomplete';
const EMPTY_RELAY = [
  {
    npr_barrier_id: null,
    npr_delay: null,
    npr_action: null,
    id: null,
  },
  {
    npr_barrier_id: null,
    npr_delay: null,
    npr_action: null,
    id: null,
  },
];
export default {
  name: 'RelaysList',
  components: {
    SelectSlot,
    IntercomAutocomplete,
  },
  props: {
    addTitle: {
      type: String,
      default: '',
    },
    clientId: {
      type: [Number, String],
    },
    value: {
      type: Object,
      default: null,
    },
    required: {
      type: Boolean,
      default: false,
    },
    relays: {
      type: Array,
      required: true,
    },
    relaysActions: {
      type: Array,
      default: null,
    },
    barrierId: {
      type: [Number, String],
      default: null,
    },
    geoUnitId: {
      type: [Number, String],
      default: null,
    },
  },
  methods: {
    onSelect(item) {
      this.$emit('select', item);
      item.relays.forEach((relay) => {
        if (
          (!relay.npr_barrier_id || relay.npr_barrier_id == this.barrierId) &&
          !relay.npr_action
        ) {
          relay.npr_action = 'not_used';
        }
      });
      this.$emit('input', item.relays, item);
    },
    onClear() {
      this.$emit('input', EMPTY_RELAY, { id: null });

      this.$emit('clear');
    },
  },
  watch: {
    relays: {
      immediate: false,
      deep: true,
      handler() {
        this.relays.forEach((relay) => {
          if (relay.npr_action == 'not_used') {
            relay.npr_delay = null;
          }
        });
      },
    },
  },
  created() {
    if (this.value.id) {
      this.onSelect(
        Object.assign({}, this.value, {
          relays: this.relays,
        }),
      );
    }
  },
};
</script>

<style lang="scss">
@import 'app/javascript/assets/styles/components/variables';

.btn-trash {
  color: $blue;
  &:hover {
    color: $white;
  }
}
</style>
