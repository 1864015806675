<template>
  <div class="card information-count-card px-4 py-3">
    <div class="row row-md">
      <div class="col-auto d-none d-lg-block">
        <div
          :class="
            'object-condition object-condition-' +
            devicesConditionColor +
            ' object-condition-' +
            devicesCondition +
            ' mr-2'
          "
        >
          {{ devicesCondition < 0 ? '-' : devicesCondition }}
          <pie-chart
            :percent="devicesCondition < 0 ? 0 : devicesCondition"
            :stroke-width="1"
            :stroke-opacity="0.8"
            :opacity="0.8"
            label=" "
            label-small=" "
            class="object-condition-segment"
          />
        </div>
      </div>
      <div class="col-12 col-lg">
        <div class="row align-items-center">
          <div
            class="
              col-12 col-lg-auto
              font-size-16px font-weight-bold
              my-1 my-lg-2
            "
          >
            {{ deviceName }}
          </div>
        </div>
        <div class="row mt-2 pr-lg-5">
          <div class="col-6 col-lg mb-2 mb-lg-0">
            <div class="font-size-12px text-muted">
              {{ $t('global.total') }}
            </div>
            <div class="font-size-18px">
              <a
                class="simple-link"
                href="javascript:void(0)"
                @click="onSelectStat('all')"
              >
                {{ stats['all'] }}
              </a>
            </div>
          </div>
          <div class="col-6 col-lg mb-2 mb-lg-0">
            <div class="font-size-12px text-muted">
              {{ $t('global.online') }}
            </div>
            <div class="font-size-18px">
              <a
                class="text-success simple-link"
                href="javascript:void(0)"
                @click="onSelectStat('online')"
              >
                {{ stats['online'] }}
              </a>
            </div>
          </div>
          <div class="col-6 col-lg">
            <div class="font-size-12p text-mutedx">
              {{ $t('global.offline') }}
            </div>
            <div class="font-size-18px">
              <a
                class="text-danger simple-link"
                href="javascript:void(0)"
                @click="onSelectStat('offline')"
              >
                {{ stats['offline'] }}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PieChart from 'vue-pie-chart/src/PieChart.vue';

export default {
  props: {
    deviceName: String,
    stats: Object,
  },
  data() {
    return {};
  },
  computed: {
    devicesCondition() {
      const sum = this.stats.all;
      const online = this.stats.online;

      return sum > 0 ? Math.round((online / sum) * 100) : -1;
    },
    devicesConditionColor() {
      const perc = this.devicesCondition;
      if (perc < 0) {
        return 'grey';
      }
      if (perc < 35) {
        return 'red';
      }
      if (perc < 70) {
        return 'yellow';
      }
      return 'green';
    },
  },
  components: {
    PieChart,
  },
  methods: {
    onSelectStat(statusName) {
      this.$emit(`select-stat`, statusName);
    },
  },
};
</script>

<style lang="scss">
a.simple-link {
  text-decoration: none;
  color: inherit;
}
</style>
