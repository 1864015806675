<template>
  <div class="geo-units-autocomplete">
    <div class="dropdown">
      <BInputLabel
        v-model="word"
        @keyup="onKeyUp"
        type="text"
        :label="placeholder"
        :state="state"
        :disabled="disabled"
        @focus="search"
        @blur="onBlur"
        ref="componentInput"
      />
      <div class="close-icon-container" v-if="!disabled">
        <i class="icon-local-times" v-if="!empty_word" @click="onClear"></i>
      </div>
      <div class="dropdown-menu py-0" tabindex="0">
        <div class="dropdown-item btn-secondary" @mousedown="openModal">
          {{ $t('button.add') }}
        </div>
        <div
          v-for="result in results"
          :key="result.id"
          @mousedown="choosed(result)"
          :data-id="result.id"
          :data-name="result.short_name"
          class="dropdown-item"
        >
          {{ result.short_name }}
        </div>
      </div>
      <BSpinner v-if="isLoading" variant="primary" small />
      <slot></slot>
    </div>
    <GeoUnitTerritoryModal
      :districtId="this.districtId"
      ref="territoryModal"
      @entityCreated="onTerritoryCreated"
    />
  </div>
</template>

<script>
import BInputLabel from '@/components/base/BInputLabel';
import GeoUnitTerritoryModal from '@/components/modals/GeoUnitTerritoryModal.vue';
import { debounce } from 'lodash';
import { API_URLS, GEO_UNITS } from '@/consts';
import { request3 } from '@/api/request';
import { BSpinner } from 'bootstrap-vue';

export default {
  name: 'GeoUnitsTerritoryAutocomplete',
  components: {
    BInputLabel,
    GeoUnitTerritoryModal,
  },
  props: {
    initName: String,
    clientId: [String, Number],
    districtId: Number,
    placeholder: String,
    state: {},
    debouncedTts: {
      type: Number,
      default: 400,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      debouncedSearch: null,
      word: '',
      results: [],
      isLoading: false,
    };
  },
  computed: {
    empty_word() {
      return this.word < 1;
    },
    debounced() {
      return this.debouncedTts > 0;
    },
  },
  mounted() {
    if (this.debounced) {
      this.initDebounce();
    }
    if (this.initName) {
      this.word = this.initName;
    }
  },
  methods: {
    choosed(result) {
      this.word = result.name;
      this.$emit('input', result.id);
      this.$emit('input:search', result.name);
    },
    onKeyUp() {
      if (this.debounced) {
        this.debouncedSearch();
      } else {
        this.search();
      }
    },
    onBlur() {
      this.resultsClose();
    },
    resultsOpen() {
      $(this.$refs.componentInput.$el).dropdown('show');
    },
    resultsClose() {
      $(this.$refs.componentInput.$el).dropdown('hide');
    },
    wordReset() {
      this.word = '';
      this.value = null;
    },
    async getTerritories() {
      this.isLoading = true;
      const q = {
        type_eq: GEO_UNITS.Territory,
        district_id_eq: this.districtId,
        territories_for_building: 1,
        short_name_cont_all: this.word,
        short_name_search: true,
      };
      this.results = await request3
        .get(API_URLS.backendManage.geoUnits.fetch(this.clientId), {
          params: {
            q: q,
            limit: 8,
          },
        })
        .then((res) => res.data.geo_units)
        .finally(() => {
          this.isLoading = false;
        });
    },
    search() {
      this.getTerritories();
      this.resultsOpen();
    },
    onClear() {
      this.wordReset();
      this.$emit('clear');
    },
    initDebounce() {
      this.debouncedSearch = debounce(() => {
        this.search();
      }, this.debouncedTts);
    },
    openModal() {
      this.resultsClose();
      this.$refs.territoryModal.show();
    },
    onTerritoryCreated(entity) {
      this.choosed(entity);
    },
  },
};
</script>

<style scoped lang="scss">
@import '../../assets/styles/components/variables';
.geo-units-autocomplete {
  position: relative;
  .close-icon-container {
    padding: 5px;
    cursor: pointer;
    position: absolute;
    right: 13px;
    top: 13px;
  }
  fieldset {
    width: 100%;
  }
  .dropdown-menu {
    overflow-x: auto;
    max-height: 300px;
    .dropdown-item {
      border: 1px solid #edeef0;
      height: 50px;
      cursor: pointer;
      display: flex;
      align-items: center;
      &:hover {
        background: $table-hover-bg;
      }
    }
  }
  .spinner-border {
    position: absolute;
    right: 40px;
  }
}
</style>
