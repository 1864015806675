<template>
  <div>
    <p>{{ $t('user.edit.permission.provide_access_to_buildings') }}:</p>
    <BuildingList
      v-model="buildings.list"
      :required="true"
      :clientId="clientId"
      :add-title="$t('user.edit.permission.add_building')"
      disable-add-check-property="building_id"
      init-geo-unit-name-property="building_name"
      type-eq="Building"
      @input="input"
    />
    <p class="mt-3">
      {{ $t('user.edit.permission.provide_access_to_districts') }}:
    </p>
    <DistrictsList
      v-model="districts.list"
      :required="true"
      :clientId="clientId"
      :add-title="$t('user.edit.permission.add_district')"
      disable-add-check-property="district_id"
      init-geo-unit-name-property="title"
      @input="input"
    ></DistrictsList>
  </div>
</template>

<script>
import DistrictsList from '@/components/lists/DistrictsList.vue';
import BuildingList from '@/components/lists/BuildingsList.vue';
import { NestedAttributes } from '@/helpers';

export default {
  name: 'FormPermissionsBuildings',
  components: { DistrictsList, BuildingList },
  props: {
    buildingsPermissions: {
      type: Array,
    },
    clientId: {
      type: [Number, String],
    },
    districtsPermissions: {
      type: Array,
    },
  },
  data() {
    return {
      buildings: { list: [] },
      districts: { list: [] },
      model: {},
    };
  },
  watch: {
    buildingsPermissions: 'init',
    districtsPermissions: 'init',
  },
  created() {
    this.init();
  },
  methods: {
    getPermissions() {
      return {
        buildings: this.buildings.compare,
        districts: this.districts.compare,
      };
    },
    input() {
      this.$emit('input', this.getPermissions());
    },
    init() {
      this.buildings = new NestedAttributes(
        this.buildingsPermissions,
        'building_id',
        'district_name',
      );
      this.districts = new NestedAttributes(
        this.districtsPermissions,
        'district_id',
        'district_name',
      );
    },
  },
};
</script>

<style lang="scss" scoped></style>
