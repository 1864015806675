<template>
  <div class="w-100 position-relative video-wrapper rounded-lg h-100">
    <img
      v-if="!playing"
      :src="backgroundImage"
      class="h-auto w-auto mw-100 mh-100 rounded-lg position-absolute"
      :style="{ opacity: loading ? 0.5 : 1 }"
    />
    <video
      v-show="playing"
      ref="video"
      controls
      class="h-auto w-auto mw-100 mh-100 rounded-lg"
      :autoplay="autoplay"
      @playing="playingStart"
    ></video>
    <div
      v-if="loading"
      class="d-flex flex-grow-1 justify-content-center align-items-center"
    >
      <b-spinner variant="primary"></b-spinner>
    </div>
  </div>
</template>

<script>
import { WsJsonRpcAsync } from '@/mixins';
import { request3 } from '@/api/request';
import { API_URLS } from '@/consts';

export default {
  mixins: [WsJsonRpcAsync],
  props: {
    autoplay: {
      type: Boolean,
      default: false,
    },
    deviceId: {
      required: true,
    },
    deviceType: {
      type: String,
      required: true,
      validator: (val) => ['camera', 'intercom'].includes(val),
    },
    name: {
      type: String,
    },
    background: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      iceServer: null,
      loading: false,
      peerConnection: null,
      pingInterval: null,
      playing: false,
      remoteStream: null,
      timeoutHide: null,
    };
  },
  computed: {
    backgroundImage() {
      return this.background ? this.background : '../../assets/icons/Img.svg';
    },
  },
  watch: {
    playing(val) {
      if (val) {
        this.$emit('play');
      }
    },
  },
  methods: {
    async auth() {
      try {
        const { token, ws_url, ice_server } = await request3
          .get(API_URLS.manage[this.deviceType].webRtcToken(this.deviceId))
          .then((res) => res.data);

        this.iceServer = ice_server;

        await this.wsConnect(ws_url);
        return this.wsAsyncSend('auth', { token });
      } catch (err) {
        return Promise.reject(err);
      }
    },
    handleError(error) {
      this.$bvToast.toast(error?.message || String(error));
      this.$emit('error', error);
    },
    async peerConnectionSendAnswer(offer) {
      try {
        this.timeoutHideClear();

        await this.peerConnection.setRemoteDescription(
          new RTCSessionDescription(offer),
        );

        const answer = await this.peerConnection.createAnswer();
        await this.peerConnection.setLocalDescription(answer);
        this.wsSend('webrtc_sdp', answer);
      } catch (err) {
        this.handleError(err);
      }
    },
    async play() {
      this.loading = true;
      try {
        await this.auth();
        this.peerConnectionCreate();

        this.pingStart();

        this.wsOnMessage((data) => {
          if (data.method === 'webrtc_sdp' && data.params.type === 'offer') {
            this.peerConnectionSendAnswer(data.params);
          }
          if (data.method === 'webrtc_ice_candidate') {
            const candidate = new RTCIceCandidate(data.params);
            this.peerConnection.addIceCandidate(candidate);
          }
        });
      } catch (err) {
        this.handleError();
      }
    },
    stop() {
      this.wsCloseConnect();
      this.peerConnectionClose();
      this.pingStop();
    },
    peerConnectionClose() {
      if (this.peerConnection) {
        this.peerConnection.close();
        this.peerConnection = null;
      }
      this.remoteStream = null;
    },
    peerConnectionCreate() {
      this.peerConnection = new RTCPeerConnection({
        iceServers: [this.iceServer],
      });
      this.peerConnection.onicecandidate = (e) => {
        if (!e.candidate) return;
        this.wsSend('webrtc_ice_candidate', e.candidate);
      };

      this.peerConnection.ontrack = (event) => {
        if (event.track.kind === 'audio') {
          return;
        }
        const [stream] = event.streams;
        if (this.$refs.video.srcObject !== stream) {
          this.$refs.video.srcObject = stream;
        }
      };

      this.wsSend('webrtc_open', { mode: 1 });
    },
    playingStart() {
      this.playing = true;
      this.loading = false;
    },
    pingStart() {
      this.pingInterval = setInterval(() => {
        this.wsSend('webrtc_ping');
      }, 10 * 1000);
    },
    pingStop() {
      if (this.pingInterval) clearInterval(this.pingInterval);
    },
    timeoutHideCreate() {
      this.timeoutHide = setTimeout(() => {
        this.$bvToast.toast(
          'Ошибка подключения. Превышено время ожидания ответа от домофона. Попробуйте позже',
          { variant: 'danger' },
        );
        this.$refs.modal.hide();
      }, 10000);
    },
    timeoutHideClear() {
      if (this.timeoutHide) clearTimeout(this.timeoutHide);
    },
  },
};
</script>
<style lang="scss">
.video-wrapper {
  align-items: center;
  display: flex;
  justify-content: center;
  min-height: 300px;
}
</style>
