<template>
  <div class="relay-modal">
    <div v-if="relays">
      <div v-for="(relay, relay_idx) in relays" :key="relay_idx">
        <div
          v-if="relay._destroy != true"
          class="card relay-modal__inner p-3 mb-3"
        >
          <b-form-row>
            <b-col cols="3" class="mb-3">
              <BSelectLabel
                :items="indexList"
                display="label"
                object-property="value"
                :label="$t('devices.tabs.relay.door')"
                v-model="relay.index"
                :state="relayState(relay_idx, 'index')"
                class="my-0"
              />
              <BError
                v-if="!relayEmptyState(relay_idx, 'index') && isSubmit"
                :error="$t('devices.errors.not_empty')"
              />
              <BError
                v-if="!relayUniqueState(relay_idx, 'index') && isSubmit"
                :error="$t('devices.errors.unique')"
              />
            </b-col>
            <b-col :cols="relays.length > 1 ? 8 : 9" class="mb-3">
              <BInputLabel
                :label="$t('devices.tabs.device.name')"
                v-model="relay.name"
                :state="relayState(relay_idx, 'name')"
                class="my-0"
              />
              <BError
                v-if="!relayEmptyState(relay_idx, 'name') && isSubmit"
                :error="$t('devices.errors.not_empty')"
              />
              <BError
                v-if="!relayUniqueState(relay_idx, 'name') && isSubmit"
                :error="$t('devices.errors.unique')"
              />
            </b-col>
            <b-col cols="1" v-if="relays.length > 1">
              <b-button
                @click="deleteRelay(relay, relay_idx)"
                variant="outline-primary"
                class="relay-modal__button"
              >
                <i class="icon-local-trash relay-modal__icon p-0" />
              </b-button>
            </b-col>
          </b-form-row>
          <b-form-row>
            <b-col cols="12" class="mb-3">
              <GeoUnitsAutocomplete2
                :geo-unit-deep-at="geoUnitDeepAt"
                :label="$t('devices.tabs.relay.address.title')"
                :value="relay.geo_unit_id"
                :state="relayState(relay_idx, 'geo_unit_id')"
                @select:item="(value) => (relay.geo_unit_id = value.id)"
                @clear="(value) => (relay.geo_unit_id = null)"
                fieldsetClass="my-0"
                popoverText="i"
                :popoverHint="$t('devices.tabs.relay.address.hint')"
                :hintId="`relay-door-${relay_idx}`"
              />
              <BError
                v-if="!relayState(relay_idx, 'geo_unit_id') && isSubmit"
                :error="$t('devices.errors.not_empty')"
              />
            </b-col>
          </b-form-row>
          <b-form-row v-if="intercomModel.type === 'call_panel'">
            <b-col cols="12">
              <BSelectLabel
                :items="readers"
                display="label"
                object-property="value"
                :label="$t('devices.tabs.relay.readers.title')"
                :value="readerValue(relay_idx)"
                @selected="(value) => selectReaderRelay(value, relay_idx)"
              />
            </b-col>
          </b-form-row>
          <b-form-row>
            <b-col cols="12" class="mb-3 col-12 d-flex gap-3">
              <div class="d-flex align-items-center">
                <b-form-checkbox v-model="relay.is_hidden">
                  {{ $t('devices.tabs.relay.hidden.title') }}
                </b-form-checkbox>
                <BHintPopover
                  :hintId="`is_hidden-${relay_idx}`"
                  popover-text="i"
                  :popover-hint="$t('devices.tabs.relay.hidden.hint')"
                />
              </div>
              <div
                v-if="isShowIsFaceDetection"
                class="d-flex align-items-center"
              >
                <b-form-checkbox
                  v-model="relay.is_face_detection"
                  :disabled="isDisabledIsFaceDetection"
                >
                  {{ $t('intercom.relay.face_detection') }}
                </b-form-checkbox>
              </div>
              <div v-if="isShowInverse" class="d-flex align-items-center">
                <b-form-checkbox v-model="relay.is_inverse">
                  {{ $t('devices.tabs.relay.inversion.title') }}
                </b-form-checkbox>
                <BHintPopover
                  :hintId="`inverse-${relay_idx}`"
                  popover-text="i"
                  :popover-hint="$t('devices.tabs.relay.inversion.hint')"
                />
              </div>
            </b-col>
          </b-form-row>
          <div class="relay-modal__access card p-3">
            <b-form-row>
              <b-col cols="12">
                <div
                  class="d-flex justify-content-between align-items-center flex-row"
                >
                  <p class="m-0">{{ $t('devices.tabs.relay.access') }}</p>
                  <b-button
                    class="w-auto h-auto relay-modal__button relay-modal__button_access relay-modal__button_create"
                    @click="createAccess(relay.id, relay_idx)"
                  >
                    <i
                      class="icon-local-add relay-modal__icon relay-modal__icon_blue"
                    />
                  </b-button>
                </div>
              </b-col>
            </b-form-row>
            <div
              class="mt-2"
              v-if="relay.relays_geo_units_attributes.length > 0"
            >
              <div
                v-for="(geo_unit, gu_idx) in relay.relays_geo_units_attributes"
                :key="gu_idx"
              >
                <IntercomRelayPermission
                  v-show="geo_unit._destroy != true"
                  :geoUnitDeepAt="geoUnitDeepAt"
                  :geo_unit="geo_unit"
                  :gu_idx="gu_idx"
                  :relay_idx="relay_idx"
                  :errors="errors"
                  :isSubmit="isSubmit"
                  @editRelayPermission="editRelayPermission"
                  @deleteAccess="deleteAccess"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-form-row>
      <b-col cols="12">
        <b-button
          :disabled="disabledCreateRelay"
          class="w-100"
          variant="outline-primary"
          @click="createRelay()"
        >
          {{ $t('devices.tabs.relay.add') }}
        </b-button>
      </b-col>
    </b-form-row>
  </div>
</template>

<script>
import GeoUnitsAutocomplete2 from '@/components/autocompletes/GeoUnitsAutocomplete2.vue';
import { mapGetters, mapMutations, mapState } from 'vuex';
import bridge from '@/helpers/bridge';
import BError from '@/components/base/BError';
import IntercomRelayPermission from '../IntercomRelayPermission.vue';

export default {
  name: 'IntercomRelaysTab',
  components: { GeoUnitsAutocomplete2, IntercomRelayPermission, BError },
  props: {
    geoUnitDeepAt: {
      type: Array,
    },
    validateState: {
      type: Function,
    },
    isSubmit: {
      type: Boolean,
      default: false,
    },
    errors: {},
  },
  computed: {
    ...mapState('intercom', ['intercom', 'intercomModel']),
    ...mapGetters('intercom', ['getIntercom', 'getIntercomModel', 'getRelays']),
    relays: {
      get() {
        return this.getRelays;
      },
      set(value) {
        this.setRelays(value);
      },
    },
    intercomModel() {
      return this.getIntercomModel;
    },
    intercom() {
      return this.getIntercom;
    },
    readers() {
      return [
        {
          label: this.$t('devices.tabs.relay.readers.external'),
          value: 'external',
        },
        {
          label: this.$t('devices.tabs.relay.readers.interior'),
          value: 'interior',
        },
        {
          label: this.$t('devices.tabs.relay.readers.all'),
          value: 'all',
        },
      ];
    },
    indexList() {
      if (this.intercomModel) {
        let list = [];
        for (let i = 0; i < this.intercomModel.relays_count; i++) {
          list.push({
            label: this.$t('devices.tabs.relay.index', [i + 1]),
            value: i + 1,
          });
        }
        return list;
      } else {
        return [];
      }
    },
    freeIndexList() {
      let list = [];
      if (this.indexList.length > 0) {
        this.indexList.forEach((relay) => {
          const isUsed = this.relays.find((item) => item.index == relay.value);
          if (!isUsed) {
            list.push({
              label: this.$t('devices.tabs.relay.index', [relay.value]),
              value: relay.value,
            });
          }
        });
      }
      return list;
    },
    validRelays() {
      if (this.relays) {
        return this.relays.filter((relay) => relay._destroy != true);
      } else return [];
    },
    disabledCreateRelay() {
      if (this.validRelays?.length < this.intercomModel.relays_count) {
        return false;
      } else return true;
    },
    isShowIsFaceDetection() {
      return this.intercom.face_detection_type !== 'disabled';
    },
    isShowInverse() {
      return Boolean(
        this.intercomModel && this.intercomModel.tags.includes('inverse'),
      );
    },
    isDisabledIsFaceDetection() {
      return !(bridge.isAdmin || bridge.permissions.includes('Owner'));
    },
  },
  methods: {
    ...mapMutations('intercom', [
      'setIntercom',
      'setIntercomModel',
      'setRelays',
      'addRelay',
      'removeRelay',
      'createAccessRelay',
      'removeAccessRelay',
    ]),
    createRelay() {
      this.addRelay();
    },
    deleteRelay(relay, index) {
      this.removeRelay({ id: relay.id, index: index });
    },
    createAccess(relay_id, relay_index) {
      this.createAccessRelay({
        id: relay_id,
        relay_index: relay_index,
      });
    },
    deleteAccess(relay_idx, gu_idx, gu) {
      this.removeAccessRelay({
        relay_index: relay_idx,
        gu_index: gu_idx,
        id: gu.id,
      });
      // this.key = Date.now();
    },
    selectReaderRelay(value, relay_idx) {
      let val = this.relays;
      if (value == 'external') {
        val[relay_idx].is_internal_reader = false;
        val[relay_idx].is_external_reader = true;
      }
      if (value == 'interior') {
        val[relay_idx].is_internal_reader = true;
        val[relay_idx].is_external_reader = false;
      }
      if (value == 'all') {
        val[relay_idx].is_internal_reader = true;
        val[relay_idx].is_external_reader = true;
      }

      this.setRelays(val);
    },
    editRelayPermission(relay_idx, gu_idx, relay) {
      let val = this.relays;

      val[relay_idx].relays_geo_units_attributes.splice(gu_idx, 1, relay);

      this.setRelays(val);
    },
    readerValue(relay_idx) {
      let val = this.relays[relay_idx];
      if (val.is_internal_reader && val.is_external_reader) {
        return 'all';
      } else if (val.is_internal_reader) {
        return 'interior';
      } else return 'external';
    },
    relayState(relay_idx, field) {
      if (!this.errors || !this.isSubmit) {
        return undefined;
      }
      return !this.errors.$each.$iter[relay_idx][field].$invalid;
    },
    relayEmptyState(relay_idx, field) {
      if (!this.errors || !this.isSubmit) {
        return undefined;
      }
      return this.errors.$each.$iter[relay_idx][field].required;
    },
    relayUniqueState(relay_idx, field) {
      if (!this.errors || !this.isSubmit) {
        return undefined;
      }
      return this.errors.$each.$iter[relay_idx][field].isUnique;
    },
  },
};
</script>

<style lang="scss">
@import 'app/javascript/assets/styles/components/variables';

.relay-modal {
  padding: 0 30px 30px 30px;
  &__inner {
    border-color: $body-bg;
    background-color: $body-bg;
  }
  &__access {
    border-color: #e0e6ec;
    background-color: #e0e6ec;
  }
  &__button {
    border-color: $border-color;
    display: flex;
    justify-content: center;
    align-items: center;

    &:focus {
      background: transparent;
    }

    &:active {
      background: transparent !important;
    }

    &:hover {
      background: #e0e6ec;
      border-color: #e0e6ec;
    }

    &_access {
      background: transparent;
      border: none;
      padding: 0;
    }

    &_create {
      min-height: unset !important;
    }
  }

  &__icon {
    font-size: 22px;
    color: $color-font-black-black;

    &_blue {
      color: $blue;
    }
  }
}
</style>
