<template>
  <b-modal
    id="intercom-add-relay-modal"
    ref="modal"
    @ok="onOk"
    :title="$t('intercom.buttons.title')"
    :ok-title="$t('button.save')"
  >
    <div class="modal-body-row">
      <b-form-row v-for="button in buttons" :key="button.key">
        <b-col md="6">
          <BInputLabel
            :label="$t('intercom.button.title')"
            :value="button.key"
            disabled
          ></BInputLabel>
        </b-col>
        <b-col md="6">
          <b-form-group>
            <BSelectLabel
              object-property="id"
              :label="$t('geo_unit.type_name_column.Apartment')"
              v-model="button.geoUnitId"
              :items="apartments"
              display="name"
              deselect
            />
          </b-form-group>
        </b-col>
      </b-form-row>
    </div>
  </b-modal>
</template>

<script>
import { range } from 'lodash';

import { ApiGeoUnits } from '@/api';
import BSelectLabel from '@/components/base/BSelectLabel.vue';

export default {
  name: 'IntercomButtonsModal',
  components: { BSelectLabel },
  props: {
    buttonsCount: {
      type: Number,
      required: true,
    },
    value: {
      type: Object,
      default() {
        return {};
      },
    },
    intercomGeoUnitId: {
      type: Number,
    },
  },
  data() {
    return {
      apartments: [],
      buttons: [],
    };
  },
  mounted() {},
  methods: {
    show() {
      this.$refs.modal.show();
      this.fetch();
    },
    onOk() {
      let formattedData = {};
      this.buttons.forEach((button) => {
        formattedData[button.key] = button.geoUnitId;
      });
      this.$emit('input', formattedData);
    },
    async fetch() {
      try {
        this.apartments = await ApiGeoUnits.getPagination({
          q: { type_eq: 'Apartment', id_family_equal: this.intercomGeoUnitId },
        }).then((res) => res.data);
        this.generateButtons();
      } catch (err) {
        this.$bvToast.toast(this.$t('error.get_data'));
      }
    },
    checkInApartments(id) {
      if (id) {
        const check = this.apartments.find((apartment) => apartment.id === id);
        return check ? id : null;
      }
      return null;
    },
    generateButtons() {
      this.buttons = [];
      range(1, this.buttonsCount + 1).forEach((button) => {
        const value = this.value[button];
        this.buttons.push({
          key: button,
          geoUnitId: this.checkInApartments(value),
        });
      });
    },
  },
};
</script>

<style scoped></style>
