<script>
import CamerasListSlim from '@/vue_slim/components/forms/CamerasListSlim.vue';
import RelaysListSlim from '@/vue_slim/components/forms/RelaysListSlim.vue';
import BFormGroupSlot from '@/components/base/BFormGroupSlot.vue';
import GeoUnitsAutocomplete from '@/components/autocompletes/geoUnitsAutocomplete';
import { API_URLS } from '@/consts';
import { request3 } from '@/api/request';
const EMPTY_RELAY = [
  {
    npr_barrier_id: null,
    npr_delay: null,
    npr_action: null,
    id: null,
  },
  {
    npr_barrier_id: null,
    npr_delay: null,
    npr_action: null,
    id: null,
  },
];
export default {
  name: 'BarrierForm',
  components: {
    RelaysListSlim,
    CamerasListSlim,
    BFormGroupSlot,
    GeoUnitsAutocomplete,
  },
  props: {
    barrierId: {
      type: [Number, String],
      default: null,
    },
    geoUnit: {
      type: Object,
      default: null,
    },
    barrierName: {
      type: String,
      default: null,
    },
    barrierIntercom: {
      type: Object,
      default: {},
    },
  },
  async created() {
    this.intercom = this.barrierIntercom;
    if (this.barrierIntercom?.id) {
      this.relays = await request3
        .get(API_URLS.backendManage.intercoms.fetch(this.clientId), {
          params: {
            q: {
              uuid_or_name_or_geo_unit_short_name_cont_any:
                this.barrierIntercom.uuid,
            },
          },
        })
        .then((res) => res.data[0].relays);
    } else this.relays = EMPTY_RELAY;
  },
  data() {
    return {
      relays: null,
      intercom: null,
      cameras: null,
      selected_geo_unit_id: null,
      selected_geo_unit: null,
      name: null,
    };
  },
  mounted() {
    this.selected_geo_unit = this.geoUnit;
    this.selected_geo_unit_id = this.selected_geo_unit
      ? this.selected_geo_unit.id
      : null;
    this.name = this.barrierName;
  },
  computed: {
    disabledSubmit() {
      if (
        this.relays &&
        this.intercom &&
        this.cameras &&
        this.selected_geo_unit_id &&
        this.name
      ) {
        return !(
          this.invalideCameras.length == 0 && this.validateRelays.length > 0
        );
      } else return true;
    },
    validateRelays() {
      return this.relays.filter(
        (relay) =>
          relay.id &&
          relay.npr_action == 'open' &&
          (relay.npr_barrier_id == this.barrierId || !relay.npr_barrier_id),
      );
    },
    invalideCameras() {
      return this.cameras.filter(
        (camera) =>
          !camera.uuid ||
          !camera.relay_id ||
          !camera.npr_direction ||
          !camera.attempt_time ||
          camera.attempt_time > 180 ||
          camera.attempt_time == 0,
      );
    },
    districtId() {
      return this.selected_geo_unit ? this.selected_geo_unit.district_id : null;
    },
  },
  methods: {
    onInputRelay(relays, intercom) {
      this.relays = relays;
      this.intercom = intercom.id;
    },
    onInputCameras(cameras) {
      this.cameras = cameras;
    },
    selectGeoUnit(geoUnit) {
      this.selected_geo_unit = geoUnit;
    },
  },
};
</script>
