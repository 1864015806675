<template>

  <div class="portlet mb-5">
    <h4>{{ $t("intercom.dashboard.intercoms_and_accesses") }}</h4>

    <div class="row row-md mt-3">

      <div v-for="tab in tabs" :key="tab.id" class="col-sm-6 col-md-4 mb-1 mb-md-0">
        <div class="card information-count-card px-4 py-3 card-link" :class="{'card-active': selectTab === tab.id}" @click="selectTab = tab.id">
          <div class="text-muted mb-1">{{tab.name}}</div>
          <div class="h4 m-0">{{tab.variable}}</div>
        </div>
      </div>

    </div>

    <!-- Вызовы -->
    <div v-show="selectTab === '1'" class="row row-md mt-5">
      <div class="col-xl-7">

        <div class="table-responsive">
          <table class="table table-striped">
            <thead>
            <tr>
              <th><a class="text-primary pointer">{{$t("intercom.dashboard.time")}} <span class="icon-local-shevron-down"></span></a></th>
              <th>{{$t("intercom.title")}}</th>
              <th>{{$t("global.status")}}</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="call in calls" :key="call.id" :class="{'b-table-row-selected table-active': call.active}">
              <td>{{call.time}}</td>
              <td>{{call.device}}</td>
              <td>
                <span v-if="call.status" class="text-success">{{$t("intercom.call.status.accepted")}}</span>
                <span v-else class="text-danger">{{ $t("intercom.call.status.missed") }}</span>
              </td>
            </tr>
            </tbody>
          </table>
        </div>

      </div>

      <div class="col-xl-5">
        <div class="portlet portlet-call p-0 d-flex align-items-center justify-content-center pointer">
          <div class="icon-local-arrow-right h1 text-white"></div>
        </div>
      </div>

    </div>

    <!-- Проходы -->
    <div v-show="selectTab === '2'" class="row row-md mt-5">
      <div class="col-xl-7">

        <div class="table-responsive">
          <table class="table table-striped">
            <thead>
            <tr>
              <th><a class="text-primary pointer">Время: 24 января 2021 - 29 января 2021 <span class="icon-local-shevron-down"></span></a></th>
              <th>Владелец</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="entry in entries" :key="entry.id" :class="{'b-table-row-selected table-active': entry.active}">
              <td>{{entry.time}}</td>
              <td>{{entry.owner}}</td>
            </tr>
            </tbody>
          </table>
        </div>

      </div>

      <div class="col-xl-5">
        <div class="portlet pb-4 portlet-grey">
          <!-- <div class="h5">Проход 28 января 2021, 12:43:59</div> -->

          <div>

            <div class="bg-white rounded-top rounded-bottom p-3 mb-2 d-flex">
              <div class="text-success icon-local-round font-size-16px"></div>
              <div class="ml-2 w-100">
                <div class="font-weight-bold">Калитка с востока</div>
                <div class="d-flex justify-content-between w-100">
                  <div class="">По лицу</div>
                  <div class="text-muted">28 января 2021, 10:34:59</div>
                </div>
              </div>
            </div>

            <div class="bg-white rounded-top rounded-bottom p-3 mb-2 d-flex">
              <div class="text-danger icon-local-round font-size-16px"></div>
              <div class="ml-2 w-100">
                <div class="font-weight-bold">Дверь в подъезд 1 корпуса 1</div>
                <div class="d-flex justify-content-between w-100">
                  <div class="text-danger">Ошибка прохода по приложению</div>
                  <div class="text-muted">28 января 2021, 10:38:39</div>
                </div>
              </div>
            </div>

            <div class="bg-white rounded-top rounded-bottom p-3 mb-2 d-flex">
              <div class="text-success icon-local-round font-size-16px"></div>
              <div class="ml-2 w-100">
                <div class="font-weight-bold">Дверь в подъезд 1 корпуса 1</div>
                <div class="d-flex justify-content-between w-100">
                  <div class="">По лицу</div>
                  <div class="text-muted">28 января 2021, 10:38:42</div>
                </div>
              </div>
            </div>

            <div class="bg-white rounded-top rounded-bottom p-3 mb-2 d-flex">
              <div class="text-success icon-local-round font-size-16px"></div>
              <div class="ml-2 w-100">
                <div class="font-weight-bold">Дверь в секцию 2 этажа 6</div>
                <div class="d-flex justify-content-between w-100">
                  <div class="">По лицу</div>
                  <div class="text-muted">28 января 2021, 10:41:02</div>
                </div>
              </div>
            </div>

          </div>

        </div>
      </div>

    </div>

    <!-- Ключи -->
    <div v-show="selectTab === '3'" class="row row-md mt-5">
      <div class="col-xl-7">

        <div class="table-responsive">
          <table class="table table-striped">
            <thead>
            <tr>
              <th>Ключ</th>
              <th>Владелец</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="key in keys" :key="key.id" :class="{'b-table-row-selected table-active': key.active}">
              <td>{{key.key_number}}</td>
              <td>{{key.owner}}</td>
            </tr>
            </tbody>
          </table>
        </div>


      </div>

      <div class="col-xl-5">
        <div class="portlet pb-4 portlet-grey">
          <!-- <div class="h5">Ключ 34543 456783</div> -->

          <div>

            <div class="bg-white rounded-top rounded-bottom p-3 mb-2 d-flex">
              <div class="text-success icon-local-round font-size-16px"></div>
              <div class="ml-2 w-100">
                <div class="font-weight-bold">Калитка с востока</div>
                <div class="d-flex justify-content-between w-100">
                  <div class="">Синхронизирован</div>
                  <div class="text-muted">Вчера, 10:34:59</div>
                </div>
              </div>
            </div>

            <div class="bg-white rounded-top rounded-bottom p-3 mb-2 d-flex">
              <div class="text-danger icon-local-round font-size-16px"></div>
              <div class="ml-2 w-100">
                <div class="font-weight-bold">Дверь в подъезд 1 корпуса 1</div>
                <div class="d-flex justify-content-between w-100">
                  <div class="text-danger">Ошибка синхронизации (12 мин)</div>
                  <div class="text-muted">19 января 2021, 10:38:39</div>
                </div>
              </div>
            </div>

            <div class="bg-white rounded-top rounded-bottom p-3 mb-2 d-flex">
              <div class="text-warning icon-local-round font-size-16px"></div>
              <div class="ml-2 w-100">
                <div class="font-weight-bold">Дверь в секцию 2 этажа 6</div>
                <div class="d-flex justify-content-between w-100">
                  <div class="text-warning">Синхронизация (3мин)</div>
                  <div class="text-muted">Сегодня, 10:41:02</div>
                </div>
              </div>
            </div>

          </div>

        </div>
      </div>

    </div>

  </div>

</template>

<script>

export default {
  data () {
    return {
      tabs: [
        {id: '1', name: 'Вызовы', variable: '612'},
        {id: '2', name: 'Проходы', variable: '375'},
        {id: '3', name: 'Ключи', variable: '4'},
      ],
      selectTab: '1',
      calls: [
        {id: '1', device: 'Этаж 8', time: 'Сегодня, 10:34:59', status: true, active: ''},
        {id: '2', device: 'Этаж 8', time: 'Сегодня, 10:34:59', status: false, active: ''},
        {id: '3', device: 'Подъезд 4', time: 'Вчера, 22:34:59', status: true, active: true},
        {id: '4', device: 'Подъезд 4', time: '19.12.2020, 10:34:59', status: true, active: ''},
        {id: '5', device: 'Этаж 8', time: 'Сегодня, 10:34:59', status: true, active: ''},
        {id: '6', device: 'Этаж 8', time: 'Сегодня, 10:34:59', status: false, active: ''},
        {id: '7', device: 'Подъезд 4', time: 'Вчера, 22:34:59', status: true, active: ''},
        {id: '8', device: 'Подъезд 4', time: '19.12.2020, 10:34:59', status: true, active: ''},
        {id: '9', device: 'Этаж 8', time: 'Сегодня, 10:34:59', status: true, active: ''},
      ],
      entries: [
        {id: '1', owner: 'Иван Иванов', time: '29 января 2021, 12:32:43', active: ''},
        {id: '2', owner: 'Иван Иванов', time: '28 января 2021, 12:43:59', active: true},
        {id: '3', owner: 'Иван Иванов', time: '27 января 2021, 22:34:59', active: ''},
        {id: '4', owner: 'Марина Александровна', time: '25 января 2021, 19:34:59', active: ''},
        {id: '5', owner: 'Иван Иванов', time: '25 января 2021, 10:34:59', active: ''},
        {id: '6', owner: 'Марина Александровна', time: '24 января 2021, 14:34:59', active: ''},
        {id: '7', owner: 'Иван Иванов', time: '24 января 2021, 11:34:59', active: ''},
      ],
      keys: [
        {id: '1', owner: 'Марина Александровна', key_number: '34543 456756', active: ''},
        {id: '2', owner: 'Иван Иванов', key_number: '34543 456775', active: ''},
        {id: '3', owner: 'Ирина Иванова', key_number: '34543 456783', active: true},
        {id: '4', owner: 'Василий Иванов', key_number: '34543 456713', active: ''},
      ],
    }
  },
}

</script>

<style lang="scss" scoped>
@import '../../../assets/styles/components/variables';

.portlet-call {
  //margin-top: 41px;
  height: 320px;
  background-color: $color-default;
}

.portlet-grey {
  //margin-top: 41px;
  background-color: $bg-card;
}

</style>
