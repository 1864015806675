var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex align-items-center justify-content-center selector mb-3"},[_c('b-form-group',{attrs:{"id":`legend-${_vm.id}`,"label":_vm.label,"label-class":_vm.labelIsActive || _vm.value !== ''
        ? 'float-label-active'
        : 'float-label-inactive'}},[_c('b-form-input',{staticClass:"d-flex align-items-center justify-content-center number-block",attrs:{"id":_vm.id,"value":_vm.modifier ? _vm.modifier : _vm.value,"disabled":Boolean(_vm.modifier || _vm.disabled),"type":_vm.modifier ? 'text' : 'number',"number":!_vm.modifier,"state":_vm.state,"no-wheel":""},on:{"input":_vm.onInput,"focus":() => {
          _vm.activatedLabel();
          _vm.$emit('focus');
        },"blur":() => {
          _vm.deactivateLabel();
          _vm.$emit('blur');
        }}})],1),_vm._v(" "),_c('div',{staticClass:"selector-btn-block"},[_c('div',{staticClass:"pointer text-primary selector-chevron no-select",style:(`border-bottom: 1px solid #ced4da;${
        _vm.disabled ? 'pointer-events: none;' : ''
      }`),on:{"mousedown":function($event){return _vm.startIncValue(_vm.value)},"mouseup":function($event){return _vm.stopIncValue()},"mouseleave":function($event){return _vm.stopIncValue()}}},[_vm._v("\n      +\n    ")]),_vm._v(" "),_c('div',{staticClass:"pointer text-primary selector-chevron no-select",style:(_vm.disabled ? 'pointer-events: none;' : ''),on:{"mousedown":function($event){return _vm.startDecValue(_vm.value)},"mouseup":function($event){return _vm.stopDecValue()},"mouseleave":function($event){return _vm.stopIncValue()}}},[_vm._v("\n      ‒\n    ")])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }