<template>
  <div class="b-dropdown-input" :class="{ show: isShowItems }">
    <BInputLabel
      ref="input"
      :autocomplete="autocomplete"
      :class="[{ 'have-items': isShowItems }, fieldsetClass]"
      :description="description"
      :disabled="disabled"
      :label="label"
      :state="state"
      :value="value"
      @focus="onFocusInput"
      @input="onInput"
    />
    <div class="icon-container d-flex align-items-center">
      <i class="icon-local-times" v-if="isShowClear" @click="onClear"></i>
      <BHintPopover
        v-if="hintId"
        :popover-text="popoverText"
        :popover-hint="popoverHint"
        :hintId="hintId"
        :placement="placementPopover"
      />
    </div>
    <ul class="list-group" :class="{ scrollable }">
      <li
        v-for="(item, index) in items"
        :key="index"
        class="list-group-item"
        @click="onSelectItem(item)"
      >
        {{ formattedTitle(item) }}
      </li>
      <li
        v-if="
          canCreate &&
          items.length === 0 &&
          this.value &&
          this.value.length >= 2
        "
        class="list-group-item"
      >
        Not found,
        <a href="javascript:void(0)" @click="onCreateNew">create new</a>
      </li>
    </ul>
  </div>
</template>

<script>
import { debounce, get, isFunction, isString } from 'lodash';
import { BSpinner } from 'bootstrap-vue';

import BInputLabel from '@/components/base/BInputLabel.vue';
import BHintPopover from '@/components/base/BHintPopover.vue';

import { SubscribeToEvents } from '@/mixins';

export default {
  name: 'BDropdownInput',
  components: { BInputLabel, BHintPopover, BSpinner },
  mixins: [SubscribeToEvents],
  props: {
    canCreate: {
      type: Boolean,
    },
    compareProperty: {
      type: String,
    },
    debounce: {
      type: Number,
      default: 300,
    },
    debounceMaxWait: {
      type: Number,
      default: 1500,
    },
    description: {
      type: String,
    },
    disabled: {
      type: Boolean,
    },
    displayProperty: {
      type: [String, Function],
    },
    items: {
      type: Array,
      default: () => [],
    },
    label: {
      type: String,
    },
    state: {
      type: Boolean,
    },
    validator: {
      type: Function,
    },
    value: {
      type: [Number, String],
    },
    scrollable: { type: Boolean, default: false },
    autocomplete: { type: String, default: 'on' },
    fieldsetClass: {
      type: String,
      default: null,
    },
    popoverText: {
      type: String,
      default: 'i',
    },
    popoverHint: {
      type: String,
      default: null,
    },
    hintId: {
      type: String,
      default: null,
    },
    placementPopover: {
      type: String,
      default: 'bottom',
    },
  },
  data() {
    return {
      debounced: null,
      inputFocus: false,
    };
  },
  computed: {
    isShowClear() {
      return !this.disabled && this.value?.length > 0;
    },
    isShowCreate() {
      return (
        this.canCreate && this.items.length === 0 && this.value?.length >= 2
      );
    },
    isShowItems() {
      if (this.isShowCreate) return true;
      return this.items.length && this.inputFocus;
    },
  },
  mounted() {
    this.registerEventClick(this.eventClick);
    this.createDebounce();
  },
  methods: {
    createDebounce() {
      this.debounced = debounce(
        (value) => {
          this.$emit('input', value, { reason: 'input' });
        },
        this.debounce,
        { maxWait: this.debounceMaxWait },
      );
    },
    eventClick(event) {
      if (!this.$el.contains(event.target)) {
        this.inputFocus = false;
        this.$emit('hide');
      }
    },
    formattedTitle(item) {
      const type = typeof this.displayProperty;
      if (isString(type)) {
        return get(item, this.displayProperty);
      }
      if (isFunction(type)) {
        return this.displayProperty(item);
      }
      return item;
    },
    getItemId(item) {
      return this.compareProperty ? get(item, this.compareProperty) : item;
    },
    onCreateNew() {
      this.$emit('create', this.value);
    },
    onClear() {
      this.$emit('input', '', { reason: 'clear' });
      this.$emit('select', null);
      this.$emit('clear');
    },
    onFocusInput(event) {
      this.inputFocus = true;
      this.$emit('focus', event);
    },
    onInput(value) {
      this.debounced(value);
    },
    onSelectItem(value) {
      if (isFunction(this.validator)) {
        if (!this.validator(value)) return;
      }
      this.$emit('select', this.getItemId(value));
      this.$emit('select:item', value);
      this.$emit('input', this.formattedTitle(value), { reason: 'select' });
      this.inputFocus = false;
    },
  },
};
</script>
