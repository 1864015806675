<template>
  <div>
    <div class="row my-3">
      <div class="col-auto">
        <div class="form-group">
          <b-form-radio-group
            id="period"
            v-model="filter.device_type"
            :options="deviceTypes"
            buttons
            button-variant="outline-primary"
            size="md"
            name="period"
            @change="getData"
          ></b-form-radio-group>
        </div>
      </div>
    </div>
    <div class="position-relative">
      <div class="spinner" v-show="loading">
        <b-spinner small variant="primary" type="grow"></b-spinner>
        <b-spinner small variant="primary" type="grow"></b-spinner>
        <b-spinner small variant="primary" type="grow"></b-spinner>
      </div>
      <div>
        <line-chart 
          :chart-data="chartdata" 
          :kind="$t('meters.devices_with_errors_qty')"
        ></line-chart>
      </div>
    </div>
    <div class="row justify-content-center mt-3">
      <div class="col-auto">
        {{ $t("global.date") }}
      </div>
    </div>
  </div>
</template>

<script>
import { BFormRadioGroup } from 'bootstrap-vue';
import LineChart from '@/components/charts/LineChart.js';
import axios from 'axios';

export default {
  name: 'MetersTroubleshootingChart',
  components: {
    LineChart,
    BFormRadioGroup,
  },
  props: {
    buildingId: Number,
    clientId: Number,
    deviceType: String,
    data: {},
  },
  data() {
    return {
      chartdata: {},
      loading: false,
      filter: {
        device_type: 'meter',
      },
      deviceTypes: [
        { text: this.$t("meters.title"), value: 'meter' },
        { text: this.$t("abbreviations.PR"), value: 'pr_device' },
        { text: this.$t("abbreviations.USPD"), value: 'uspd_device' },
      ],
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    chartData(data) {
      return {
        labels: data.map(rec => rec.date),
        datasets: this.datasets(data),
      }
    },
    datasets(data) {
      if (this.filter.device_type === 'meter') {
        return this.meters_dataset(data);
      }
      return this.uspd_pr_devices_dataset(data);
    },
    meters_dataset(data) {
      return [
        {
          fill: false,
          label: this.$t("global.total_errors"),
          borderColor: 'rgb(255, 77, 77, 1)',
          borderWidth: 0,
          backgroundColor: 'rgb(255, 77, 77, 1)',
          data: data.map(rec => rec.total_count),
          tension: 0,
        },
        {
          fill: false,
          label: this.$t("problems.no_connection"),
          borderColor: 'rgba(248, 157, 43, 1)',
          borderWidth: 0,
          backgroundColor: 'rgba(248, 157, 43, 1)',
          data: data.map(rec => rec.offline_count),
          tension: 0,
        },
        {
          fill: false,
          label: this.$t("problems.magnet"),
          borderColor: 'rgb(3, 169, 244, 1)',
          borderWidth: 0,
          backgroundColor: 'rgb(3, 169, 244, 1)',
          data: data.map(rec => rec.magneted_count),
          tension: 0,
        },
        {
          fill: false,
          label: this.$t("problems.case_opened"),
          borderColor: 'rgba(90, 183, 92, 1)',
          borderWidth: 0,
          backgroundColor: 'rgba(90, 183, 92, 1)',
          data: data.map(rec => rec.tampered_count),
          tension: 0,
        },
        {
          fill: false,
          label: this.$t("problems.reverse"),
          borderColor: 'rgba(149, 161, 244, 1)',
          borderWidth: 0,
          backgroundColor: 'rgba(149, 161, 244, 1)',
          data: data.map(rec => rec.inverted_count),
          tension: 0,
        },
      ]
    },
    uspd_pr_devices_dataset(data) {
      return [
        {
          fill: false,
          label: this.$t("problems.no_connection"),
          borderColor: 'rgb(255, 77, 77, 1)',
          borderWidth: 0,
          backgroundColor: 'rgb(255, 77, 77, 1)',
          data: data.map(rec => rec.offline),
          tension: 0,
        },
      ]
    },
    getData() {
      const app = this;
      app.loading = true;
      let prms = {
        device_type: this.filter.device_type,
      };
      axios
        .get(`/api/backend/manage/${this.clientId}/metering/buildings/${this.buildingId}/troubleshooting`, {
          params: prms,
        })
        .then((response) => {
          app.chartdata = app.chartData(response.data);
        })
        .catch((error) => {
          console.log(error);
        })
        .then(() => {
        app.loading = false;
        });
    },
  },
};
</script>

<style lang="scss">
$primary-color: #03a9f4;

.spinner {
  position: absolute;
  z-index: 2;
  top: 50%;
  left: 50%;
}
</style>
