<template>
  <div>
    <img
      :src="thumbSrc"
      :width="width"
      :height="height"
      :class="selector"
      @click="openImageModal"
    />
    <ImageModal :url="url" ref="imageModal" :title="title" />
  </div>
</template>

<script>
import ImageModal from '@/components/modals/ImageModal.vue';
export default {
  name: 'BImage',
  components: { ImageModal },
  props: {
    width: {
      type: [String, Number],
      default: 'auto',
    },
    height: {
      type: [String, Number],
      default: 150,
    },
    url: {
      type: String,
      required: true,
    },
    selector: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      default: null,
    },
    thumbUrl: {
      type: String,
    },
  },
  computed: {
    thumbSrc() {
      return this.thumbUrl || this.url;
    },
  },
  methods: {
    openImageModal() {
      this.$refs.imageModal.show();
    },
  },
};
</script>

<style></style>
