export const TURBO_EVENTS = {
  click: 'turbo:click',
  beforeVisit: 'turbo:before-visit',
  visit: 'turbo:visit',
  submitStart: 'turbo:submit-start',
  beforeFetchRequest: 'turbo:before-fetch-request',
  beforeFetchResponse: 'turbo:before-fetch-response',
  submitEnd: 'turbo:submit-end',
  beforeCache: 'turbo:before-cache',
  beforeRender: 'turbo:before-render',
  beforeStreamRender: 'turbo:before-stream-render',
  render: 'turbo:render',
  load: 'turbo:load',
  frameRender: 'turbo:frame-render',
  frameLoad: 'turbo:frame-load',
  fetchRequestError: 'turbo:fetch-request-error',
};

/** CALLING EVENTS

| Update turbo-frame          | Change page                 |
| --------------------------- | --------------------------- |
|                             | turbo:click                 |
| turbo:before-fetch-request  |                             |
| turbo:submit-start          |                             |
| turbo:before-fetch-response |                             |
| turbo:submit-end            |                             |
| turbo:frame-render          |                             |
| turbo:frame-load            |                             |
| turbo:before-visit          | turbo:before-visit          |
|                             | turbo:before-fetch-request  |
| turbo:visit                 |                             |
|                             | turbo:before-fetch-response |
| turbo:before-cache          | turbo:before-cache          |
| turbo:before-render         | turbo:before-render         |
| turbo:render                | turbo:render                |
| turbo:load                  | turbo:load                  |

 */

// Object.values(TURBO_EVENTS).forEach((eventName) => {
//   document.addEventListener(eventName, () => {
//     console.log(`TURBO_EVENT: ${eventName}`);
//   });
// });
