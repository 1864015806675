<template>
  <div class="cameras-list" :key="cameras.length">
    <div class="d-flex justify-content-between align-items-center mb-3">
      <h5>{{ $t('barrier.cameras') }}</h5>
      <b-button
        class="b-button"
        variant="primary"
        @click="onAddItem()"
        :disabled="isDisabledAddBtn"
      >
        {{ $t('camera.add') }}
      </b-button>
    </div>
    <div
      v-for="(camera, index) in cameras"
      :key="camera.id"
      class="cameras-item mb-3 p-3"
    >
      <div class="d-flex flex-grow-1">
        <CamerasAutocomplete
          :placeholder="$t('barrier.placeholder_autocomplete')"
          :clientId="clientId"
          :value="camera.uuid"
          :cameraData="camera"
          :selectedCameras="camerasIds"
          @clear="(value) => onClear(value, index)"
          @select="(value) => onSelect(value, index)"
          class="flex-grow-1"
        />
        <b-button
          variant="outline-danger"
          class="btn-square btn-trash cameras-list__btn"
          :disabled="cameras.length == 1"
          @click="onRemoveItem(index)"
        >
          <i class="icon-local-trash" />
        </b-button>
      </div>
      <div class="d-flex justify-content-between align-items-start mt-3">
        <SelectSlot
          :label="$t('camera.npr_direction')"
          :items="drivingDirections"
          class="w-50 mr-3"
          @select="(value) => onSelect(value, index)"
          :disabled="!camera.id"
        >
          <select
            v-model="camera.npr_direction"
            class="form-control select optional"
          >
            <option
              v-for="(direction, index) in drivingDirections"
              :value="direction.value"
              :key="index"
              :title="direction.title"
            >
              {{ direction.title }}
            </option>
          </select>
        </SelectSlot>
        <b-button
          class="cameras-list__btn btn-info w-50"
          @click="openSelectZone(camera)"
          :disabled="!camera.id"
        >
          {{ $t('camera.npr_zone') }}
        </b-button>
      </div>
      <div class="d-flex justify-content-between mt-2">
        <SelectSlot
          :label="$t('camera.relay_id')"
          :items="validatedRelays"
          class="w-50 mr-3"
          :disabled="validatedRelays.length == 0 || !camera.id"
          :key="validatedRelays.length"
          @select="(value) => onSelect(value, index)"
        >
          <select
            v-model="camera.relay_id"
            class="form-control select optional"
          >
            <option
              v-for="relay in validatedRelays"
              :value="relay.id"
              :key="relay.id"
              :title="relay.title"
            >
              {{ relay.title }}
            </option>
          </select>
        </SelectSlot>
        <!-- <SelectSlot
          :label="$t('camera.npr_mode')" 
          :items="modeRecognitions" 
          class="w-50"
          @select="(value) => onSelect(value, index)"
          :disabled="true"
        >
          <select
            v-model="camera.npr_mode"
            class="form-control select optional"
          >
            <option
              v-for="(mode, index) in modeRecognitions"
              :value="mode.value"
              :key="index"
              :title="mode.title"
            >
              {{ mode.title }}
            </option>
          </select>
        </SelectSlot> -->
        <div class="w-50 d-flex align-items-center">
          <BInputHintLabel
            :value="camera.attempt_time"
            :placeholder="$t('camera.attempt_time.title')"
            popover-text="i"
            :popover-hint="$t('camera.attempt_time.hint')"
            :disabled="!camera.id"
            :hint-id="`attemptTimeHint${index}`"
            class="flex-grow-1"
            @update:value="(value) => attemptTimeUpdate(value, camera, index)"
            :ref="`attemptTimeInput${index}`"
            :error="
              camera.attempt_time > 180 ||
              !camera.attempt_time ||
              camera.attempt_time == 0
                ? $t('camera.attempt_time.error_validate')
                : null
            "
            :state="
              camera.attempt_time > 180 ||
              !camera.attempt_time ||
              camera.attempt_time == 0
                ? false
                : undefined
            "
            :key="key"
          />
        </div>
      </div>
    </div>
    <NprCameraZone ref="nprCameraZone" @updateSelectZone="updateSelectZone" />
  </div>
</template>

<script>
import BInputHintLabel from '@/components/base/BInputHintLabel';
import SelectSlot from '@/vue_slim/components/base/SelectSlot';
import CamerasAutocomplete from '@/components/autocompletes/CamerasAutocomplete';
import NprCameraZone from '@/components/modals/CameraZone.vue';
import i18n from '@app/i18n';
import { cloneDeep } from 'lodash-es';

export default {
  name: 'CamerasList',
  components: {
    SelectSlot,
    CamerasAutocomplete,
    NprCameraZone,
    BInputHintLabel,
  },
  props: {
    addTitle: {
      type: String,
      default: '',
    },
    clientId: {
      type: [Number, String],
    },
    value: {
      type: Array,
      default: [],
    },
    required: {
      type: Boolean,
      default: false,
    },
    drivingDirections: {
      type: Array,
      default: null,
    },
    modeRecognitions: {
      type: Array,
      default: null,
    },
    relays: {
      type: Array,
      default: [],
    },
    intercom: {
      type: [Number, String],
      default: null,
    },
    barrierId: {
      type: [Number, String],
      default: null,
    },
    geoUnitId: {
      type: [Number, String],
      default: null,
    },
  },
  data() {
    return {
      cameras: [],
      key: Date.now(),
    };
  },
  created() {
    this.cameras = cloneDeep(this.value);

    if (this.cameras.length == 0) {
      this.onAddItem();
    }
  },
  computed: {
    isDisabledAddBtn() {
      return this.value.length >= 4 ? true : false;
    },
    relaysList() {
      if (this.relays) {
        return this.relays.map((relay, index) => {
          return Object.assign({}, relay, {
            title: `${i18n.t('relay.title')} ${index + 1}`,
          });
        });
      } else {
        return [];
      }
    },
    validatedRelays() {
      return this.relaysList.filter(
        (relay) =>
          relay.id &&
          relay.npr_action == 'open' &&
          (relay.npr_barrier_id == this.barrierId || !relay.npr_barrier_id),
      );
    },
    camerasIds() {
      return this.cameras.map((camera) => {
        return camera.uuid;
      });
    },
  },
  watch: {
    relays: {
      immediate: false,
      deep: true,
      handler() {
        this.cameras.forEach((camera) => {
          camera.relay_id = this.validatedRelays.find(
            (relay) => relay.id == camera.relay_id,
          )
            ? camera.relay_id
            : null;
        });
        this.$emit('input', this.cameras);
      },
    },
  },
  methods: {
    clonedItems() {
      return this.value.map((item) => item);
    },
    onAddItem() {
      this.cameras = [
        ...this.value,
        {
          id: null,
          uid: null,
          uuid: null,
          name: '',
          npr_direction: null,
          npr_mode: 'db',
          attempt_time: 15,
          relay_id: null,
        },
      ];
      this.$emit('input', this.cameras);
    },
    onSelect(item, index) {
      if (item.attempt_time == null) {
        item.attempt_time = 15;
      }
      this.cameras.splice(index, 1, item);
      this.$emit('input', this.cameras);
    },
    onClear(item, index) {
      this.cameras.splice(index, 1, {
        id: null,
        uid: null,
        name: '',
        uuid: null,
        npr_direction: null,
        npr_mode: 'db',
        attempt_time: 15,
        relay_id: null,
      });

      this.$emit('input', this.cameras);
      this.$emit('clear');
    },
    onRemoveItem(index) {
      this.cameras.splice(index, 1);
      this.$emit('input', this.cameras);
    },
    openSelectZone(camera) {
      this.$refs.nprCameraZone.show(camera);
    },
    updateSelectZone(camera) {
      const idx = this.cameras.findIndex((el) => el.id == camera.id);
      this.cameras.splice(idx, 1, camera);
      this.$emit('input', this.cameras);
    },
    attemptTimeUpdate(value, camera, index) {
      let device = camera;
      value = value.replace(/\D/g, '');
      value = Number(value);
      device.attempt_time = value;
      this.cameras.splice(index, 1, device);
      this.key = Date.now();
      this.$nextTick(() => {
        this.$refs['attemptTimeInput' + index][0].onFocus();
      });
    },
  },
};
</script>

<style lang="scss">
@import 'app/javascript/assets/styles/components/variables';

.cameras-list {
  .cameras-item {
    display: flex;
    flex-direction: column;
    background: #fbfbfb;
    border-radius: 5px;
  }
  .btn-trash {
    color: $blue;
    &:hover {
      color: $white;
    }
  }
  .btn-add {
    &:not([disabled]) {
      border-color: $blue;
    }
  }

  &__btn {
    padding: 13px;
    color: $blue;
    font-weight: bold;
  }
}
</style>
