<script>
import GeoUnitsAutocomplete from '@/components/autocompletes/geoUnitsAutocomplete';

export default {
  name: 'IntercomModelForm',
  components: {
    GeoUnitsAutocomplete,
  },
  props: {
    intercom_model: Object,
  },
  data() {
    return {
      changes: this.intercom_model,
    };
  },
};
</script>
