<template>
  <div>
    <b-modal :title="$t('devices.modal.create')" ref="needSettings" no-stacking>
      <div class="modal-body-row with-cols text-center p-8">
        <div
          class="d-flex gap-2 align-items-center justify-content-center mb-3"
        >
          <i
            class="icon-local-attention intercom-final__icon intercom-final__icon_attention"
          />
          <p class="intercom-final__text mb-0">
            {{ $t('devices.modal.added.need_setting.title') }}
          </p>
        </div>
        <p class="intercom-final__text">
          {{ $t('devices.modal.added.need_setting.text') }}
        </p>
      </div>
      <template #modal-footer>
        <div
          class="d-flex align-items-center justify-content-between flex-grow-1"
        >
          <b-button
            @click="openUuidSelectModal"
            size="sm"
            variant="outline-primary"
            class="intercom-final__btn w-auto h-auto"
          >
            {{ $t('devices.modal.more_add') }}
          </b-button>
          <b-button
            size="sm"
            variant="outline-primary"
            class="intercom-final__btn w-auto h-auto"
            @click="openSettingModal"
          >
            {{ $t('devices.modal.setting') }}
          </b-button>
        </div>
      </template>
    </b-modal>
    <IntercomEditModal
      :clientId="clientId"
      ref="IntercomSettingModal"
      id="intercom-setting-modal"
    />
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import IntercomEditModal from '../../IntercomEditModal.vue';
import EventBus from '@/packs/EventBus';
import { EVENTS } from '@/consts';

export default {
  name: 'NeedSettingIntercom',
  components: { IntercomEditModal },
  props: {
    clientId: {
      type: [String, Number],
      required: true,
    },
  },
  computed: {
    ...mapState('intercom', ['intercom']),
    ...mapGetters('intercom', ['getIntercom']),

    intercom() {
      return this.getIntercom;
    },
  },
  methods: {
    show() {
      this.$refs.needSettings.show();
    },
    hide() {
      this.$refs.needSettings.hide();
    },
    openSettingModal() {
      this.hide();
      this.$refs.IntercomSettingModal.show(this.intercom);
    },
    openUuidSelectModal() {
      this.hide();
      EventBus.$emit(EVENTS.intercom.fast.modal.show);
    },
  },
};
</script>

<style lang="sass">
@import 'app/javascript/assets/styles/components/variables';

.intercom-final {
  &__btn {
    min-height: 0 !important;
    padding: 8px 30px;
  }

  &__hint {
    color: $color-default-blue;
    font-size: 12px;
  }

  &__text {
    font-size: 18px;
  }

  &__icon {
    font-size: 25px;
    &_ok {
      color: $color-default-green;
    }
    &_attention {
      color: #FFDD00;
    }
  }
}
</style>
