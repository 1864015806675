import { BTooltip, ModalPlugin, ToastPlugin } from 'bootstrap-vue';
import vueCustomScrollbar from 'vue-custom-scrollbar';
import PieChart from 'vue-pie-chart/src/PieChart.vue';
import Sticky from 'vue-sticky-directive';
import Vue from 'vue/dist/vue.esm';
import store from '@/store/index';

import '@/utils/initCompositionApi';

import GeoUnitsAutocomplete from '@/components/autocompletes/geoUnitsAutocomplete.vue';
import BarriersAutocomplete from '@/components/autocompletes/BarriersAutocomplete.vue';
import PlatesAutocomplete from '@/components/autocompletes/PlatesAutocomplete.vue';
import EventsPlatesAutocomplete from '@/components/autocompletes/EventsPlatesAutocomplete.vue';
import CarsAutocomplete from '@/components/autocompletes/CarsAutocomplete.vue';
import ResetFilters from '@slim/components/base/ResetFilters';
import GeoUnitsAutocompleteComplex from '@/components/autocompletes/geoUnitsAutocompleteComplex.vue';
import IntercomModal from '@/components/modals/IntercomModal.vue';
import IntercomFastModal from '@/components/modals/IntercomFastModal.vue';
import KeyModal from '@/components/modals/KeyModal.vue';
import KeySetModal from '@/components/modals/KeySetModal.vue';
import KeysImportFileModal from '@/components/modals/KeysImportFileModal.vue';
import PlateModal from '@/components/modals/PlateModal.vue';
import UserEditModal from '@/components/modals/user/UserEditModal.vue';
import i18n from '@app/i18n';

import { ConfirmModalSlot, Preloader } from '@/components';
import {
  BDropdownMenu,
  BFormGroupSlot,
  BFormPhoneSlot,
  BFormEmailSlot,
} from '@/components/base';
import BuildingStructure from '@/components/building/BuildingStructure.vue';
import NavBarUserDropdown from '@/components/layout/NavBarUserDropdown.vue';
import BuildingHintMessages from '@/components/manage/buildings/BuildingHintMessages.vue';
import BImage from '@/components/base/BImage.vue';
import IdTypeSearch from '@/components/manage/buildings/IdTypeSearch.vue';
import MetersTroubleshootingChart from '@/components/manage/buildings/MetersTroubleshootingChart.vue';
import ConsumptionChart from '@/components/manage/ConsumptionChart.vue';
import IntercomDevicesValues from '@/components/manage/IntercomDevicesValues.vue';
import ManageRelaysGroupForm from '@/components/manage/ManageRelaysGroupForm.vue';
import MeterDevicesValues from '@/components/manage/MeterDevicesValues.vue';
import MeterValueFilters from '@/components/manage/MeterValueFilters.vue';
import NavBarButtonAdd from '@/components/manage/NavBarButtonAdd.vue';
import ReportDatePickerSlot from '@/components/manage/ReportDatePickerSlot.vue';
import StaticConsumptionChart from '@/components/manage/StaticConsumptionChart.vue';
import ModalSlot from '@/components/ModalSlot';
import UserSearch from '@slim/components/base/UserSearch';
import DatePickerSlot from '@slim/components/base/DatePickerSlot';
import MultiSelectSlot from '@slim/components/base/MultiSelectSlot';
import RangeDatePickerSlot from '@slim/components/base/RangeDatePickerSlot';
import ButtonCopyBuilding from '@slim/components/buttons/ButtonCopyBuilding.vue';
import AcceptInvitation from '@slim/manage_components/users/AcceptInvitation';

import BuildingUpdateStructureStatus from '@/components/building/BuildingUpdateStructureStatus';
import AvatarUpload from '@slim/components/base/AvatarUpload';
import SelectSlot from '@slim/components/base/SelectSlot';
import PlateCreateLink from '@/vue_slim/components/buttons/PlateCreateLink.vue';
import PlateActionsLinks from '@/vue_slim/components/buttons/PlateActionsLinks.vue';
import UserEditLink from '@slim/components/buttons/UserEditLink.vue';
import UserPermissionsSlim from '@slim/components/forms/UserPermissionsSlim.vue';
import GeoUnitsListSlim from '@slim/components/forms/GeoUnitsListSlim.vue';
import CamerasListSlim from '@slim/components/forms/CamerasListSlim.vue';
import RelaysListSlim from '@slim/components/forms/RelaysListSlim.vue';
import FiltersEventsFormSlim from '@slim/components/forms/FiltersEventsFormSlim.vue';
import FiltersPlatesFormSlim from '@slim/components/forms/FiltersPlatesFormSlim.vue';

// manage components
import BuildingAddressEditor from '@slim/components/forms/BuildingAddressEditor.vue';
import CameraPlayStream from './components/buttons/CameraPlayStream.vue';
import IntercomImageFormSlim from '@slim/components/forms/IntercomImageFormSlim.vue';
import ManageAccountForm from '@slim/manage_components/ManageAccountForm';
import ManageBuildingAddressEdit from '@slim/manage_components/ManageBuildingAddressEdit';
import ManageGeoUnitForm from '@slim/manage_components/ManageGeoUnitForm';
import ManageMeteringMeterForm from '@slim/manage_components/metering/meters/Form';
import NprBarrier from '@slim/manage_components/npr/Barrier';
import NprEvents from '@slim/manage_components/npr/Events';
import userClientsAutocomplete from '@/components/autocompletes/userClientsAutocomplete';
import CameraPlayerArchive from '@slim/manage_components/cctv/CameraPlayerArchive.vue';

// admin components
import AdminCityForm from '@slim/admin_components/AdminCityForm';
import AdminClientsAutocomplete from '@/components/autocompletes/adminClientsAutocomplete.vue';
import AdminDistrictsAutocomplete from '@/components/autocompletes/adminDistrictsAutocomplete.vue';
import AdminGeoUnitsAutocomplete from '@/components/autocompletes/adminGeoUnitsAutocomplete.vue';
import AdminGlobalSearch from '@/components/autocompletes/adminGlobalSearch.vue';
import AdminUsersAutocomplete from '@/components/autocompletes/adminUsersAutocomplete.vue';
import AdminUserShow from '@slim/admin_components/AdminUserShow';
import ConfigSmsAlertForm from '@slim/admin_components/ConfigSmsAlertForm';
import CctvCamerasForm from '@slim/admin_components/CctvCamerasForm';
import CodeConfirmForm from '@slim/admin_components/CodeConfirmForm';
import BarrierForm from '@slim/manage_components/BarrierForm.vue';
import IntercomForm from '@slim/admin_components/IntercomForm';
import IntercomModelForm from '@slim/admin_components/IntercomModelForm';
import MeterAdminForm from '@slim/admin_components/MeterAdminForm';
import PermissionAdminForm from '@slim/admin_components/PermissionAdminForm';
import PulseCountersDeviceForm from '@slim/admin_components/PulseCountersDeviceForm';
import 'chart.js';
import VueChartkick from 'vue-chartkick';

// app components
import GeoUnitsUsersPortlet from '@app/components/apartment/GeoUnitsUsersPortlet';
import IntercomsDashboard from '@app/components/dashboard/IntercomsDashboard';
// personal components
import GuestInviteModal from '@app/components/personal/modals/GuestInviteModal';
import PersonalIntercomsDashboard from '@app/components/personal/PersonalIntercomsDashboard';
import PersonalIntercomsDashboardPreview from '@app/components/personal/PersonalIntercomsDashboardPreview';
import PersonalResourcesDashboard from '@app/components/personal/PersonalResourcesDashboard';
import PersonalResourcesDashboardPreview from '@app/components/personal/PersonalResourcesDashboardPreview';

// Development components
import VueJsonPretty from 'vue-json-pretty';
import 'vue-json-pretty/lib/styles.css';

// Action Cable Vue
import ActionCableVue from 'actioncable-vue';

// DayJS
import { getLocale } from '@/helpers';
import dayjs from 'dayjs';
import 'dayjs/locale/ru';
import relativeTime from 'dayjs/plugin/relativeTime';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import utc from 'dayjs/plugin/utc';
import { TURBO_EVENTS } from '@/consts/hotwire';

const locale = getLocale();

dayjs.locale(locale);
dayjs.extend(relativeTime);
dayjs.extend(localizedFormat);
dayjs.extend(utc);

const configBootstrapVue = {
  BButton: {
    variant: 'primary',
  },
  BModal: {
    okTitle: i18n.t('button.save'),
    cancelTitle: i18n.t('button.cancel'),
    cancelVariant: 'secondary',
  },
  BToast: {
    solid: true,
    toaster: 'b-toaster-top-right',
    variant: 'danger',
  },
};

Vue.use(ModalPlugin, configBootstrapVue);
Vue.use(ToastPlugin);
Vue.use(Sticky);
Vue.use(VueChartkick);
Vue.use(ActionCableVue, {
  debug: false,
  debugLevel: 'error',
  connectionUrl: process.env.WS_CONNECTION_URL,
  connectImmediately: true,
});

const VueInstance = {
  value: undefined,
};

document.addEventListener(TURBO_EVENTS.load, () => {
  const components = {
    NprEvents,
    NprBarrier,
    GeoUnitsAutocompleteComplex,
    GeoUnitsAutocomplete,
    BarriersAutocomplete,
    CarsAutocomplete,
    PlatesAutocomplete,
    EventsPlatesAutocomplete,
    ResetFilters,
    BFormGroupSlot,
    BFormPhoneSlot,
    BFormEmailSlot,
    BDropdownMenu,
    MultiSelectSlot,
    BTooltip,
    PieChart,
    vueCustomScrollbar,
    ButtonCopyBuilding,

    NavBarUserDropdown,
    BuildingUpdateStructureStatus,
    UserPermissionsSlim,
    GeoUnitsListSlim,
    CamerasListSlim,
    RelaysListSlim,
    FiltersEventsFormSlim,
    FiltersPlatesFormSlim,

    // wrappers
    DatePickerSlot,
    RangeDatePickerSlot,
    SelectSlot,

    Preloader,

    // modals
    ConfirmModalSlot,
    ModalSlot,
    KeySetModal,
    KeyModal,
    IntercomModal,
    IntercomFastModal,
    NavBarButtonAdd,
    KeysImportFileModal,
    PlateModal,
    UserEditModal,

    // simple
    PlateCreateLink,
    PlateActionsLinks,
    UserEditLink,
    AvatarUpload,

    // other
    BImage,
    BuildingHintMessages,
    IdTypeSearch,
    BuildingStructure,
    ConsumptionChart,
    StaticConsumptionChart,
    MetersTroubleshootingChart,
    MeterValueFilters,
    MeterDevicesValues,
    IntercomDevicesValues,
    ReportDatePickerSlot,
    ManageRelaysGroupForm,
    BarrierForm,
    UserSearch,

    // manage
    BuildingAddressEditor,
    CameraPlayStream,
    IntercomImageFormSlim,
    ManageAccountForm,
    ManageBuildingAddressEdit,
    ManageGeoUnitForm,
    ManageMeteringMeterForm,
    userClientsAutocomplete,
    CameraPlayerArchive,

    // admin
    IntercomForm,
    IntercomModelForm,
    PulseCountersDeviceForm,
    MeterAdminForm,
    CodeConfirmForm,
    CctvCamerasForm,
    PermissionAdminForm,
    AdminCityForm,
    AdminUsersAutocomplete,
    AdminDistrictsAutocomplete,
    AdminGeoUnitsAutocomplete,
    AdminClientsAutocomplete,
    AdminGlobalSearch,
    AdminUserShow,
    ConfigSmsAlertForm,

    // app components
    IntercomsDashboard,
    GeoUnitsUsersPortlet,

    // personal components
    PersonalIntercomsDashboard,
    PersonalIntercomsDashboardPreview,
    PersonalResourcesDashboard,
    PersonalResourcesDashboardPreview,
    AcceptInvitation,
    // personal modals
    GuestInviteModal,
  };

  // For development
  if (process.env.RAILS_ENV === 'development') {
    Object.assign(components, { Json: VueJsonPretty });
  }

  VueInstance.value = new Vue({
    el: '#app',
    i18n,
    delimiters: [String(`{{{${Math.random()}`), '}}}}}'],
    components,
    store
  });
});

export default VueInstance;
