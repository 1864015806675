<script>
import { geocode } from '@/api';
import BFormGroupSlot from '@/components/base/BFormGroupSlot.vue';

export default {
  name: 'AdminCityForm',
  components: {
    BFormGroupSlot
  },
  props: {
    coordsData: Object,
    cityName: String
  },
  computed: {
    ymapsCoords() {
      return [this.coords.lat, this.coords.lng];
    },
  },
  data() {
    return {
      coords: this.coordsData,
      name: this.cityName
    };
  },
  methods: {
    changeCoords(event) {
      const [lat, lng] = event.get('coords');
      geocode.findParamsByCoords(lng, lat).then((response) => {
        let lat = response.address?.city?.meta?.lat
        let lng = response.address?.city?.meta?.lng
        this.coords = { lat: lat, lng: lng };
        this.name = response.address?.city?.name
      });

    },
  },
};
</script>
