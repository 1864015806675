import Vue from 'vue';
import Vuex from 'vuex';
import intercom from '@/store/intercom';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    intercom
  },
});
