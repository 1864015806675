<template>
  <div>
    <div class="row">
      <div class="col-lg-6">
        <div class="portlet">
          <div class="h5 mt-1">
            {{ $t("global.device") }}
          </div>
          <div class="row pt-2">
            <div class="col-lg-6">
              <BSelectLabel
                v-model="form.area"
                :label="$t('meters.form.device_type')"
                :items="areas_filtered"
                display="name"
                @selected="areaChanged"
              />
            </div>
            <div class="col-lg-6">
              <BSelectLabel
                v-model="form.kind"
                :label="$t('meters.form.resource')"
                :items="data.kinds"
                display="name"
                @selected="kindChanged"
              />
            </div>
          </div>
          <div class="row pt-2">
            <div class="col-lg-6">
              <BSelectLabel
                v-model="form.model"
                :label="$t('meters.form.model')"
                :items="models"
                display="name"
                @selected="formChanged"
              />
            </div>
            <div class="col-lg-6">
              <BInputLabel
                v-model="form.serial"
                :label="$t('meters.form.serial')"
                @input="formChanged"
                :is-invalid="errors['serial']"
              />
              <div v-if="errors['serial']" class="invalid-feedback d-block">
                {{ $t("global.serial") }} {{ errors['serial'].join(', ') }}
              </div>
            </div>
          </div>
          <div class="row pt-2">
            <div class="col-lg-6">
              <date-picker
                class="w-100 d-block"
                v-model="form.issued_on"
                :label="$t('meters.form.manufacture_date')"
                format="YYYY-MM-D HH:m:s"
                :is-invalid="errors['issued_on']"
              ></date-picker>
              <div v-if="errors['issued_on']" class="invalid-feedback d-block mt-0">
                {{ $t('meters.form.manufacture_date') }} {{ errors['issued_on'].join(', ') }}
              </div>
            </div>
            <div class="col-lg-6">
              <date-picker
                class="w-100 d-block"
                v-model="form.installed_on"
                :label="$t('meters.form.installation_date')"
                format="YYYY-MM-D HH:m:s"
                :is-invalid="errors['installed_on']"
              ></date-picker>
              <div v-if="errors['installed_on']" class="invalid-feedback d-block mt-0">
                {{ $t('meters.form.installation_date') }} {{ errors['installed_on'].join(', ') }}
              </div>
            </div>
          </div>
          <div v-if="showUnitedName" class="h5 mt-3">
            {{ unitedName }}
          </div>
          <div class="row pt-2">
            <div class="col-lg-6">
              <BInputLabel
                v-model="form.start_value"
                :label="$t('meters.form.reading_on_installation') "
              />
            </div>
            <div class="col-lg-6">
              <div v-if="this.photo_url === null" >
                <BInputFile
                  accept="image/*"
                  v-model="form.photo"
                  :label="$t('meters.form.upload_device_photo')"
                  type="file"
                >
                </BInputFile>
              </div>
              <div v-else>
                <img
                  :src="this.photo_url"
                  alt="photo"
                  width="50px"
                  height="50px"
                />
                <button @click="photoRemove" class="btn btn-sm btn-outline-primary">
                  {{ $t("meters.form.delete_photo") }}
                </button>
              </div>
            </div>
          </div>
          <div v-if="pipeIdentifierShow" class="row pt-2">
            <div class="col-lg-6">
              <BSelectLabel
                v-model="form.pipe_identifier"
                :label="$t('meters.form.choose_pipe')"
                :items="data.pipes"
                display="name"
              />
            </div>
          </div>
          <div v-if="isCalcShow" class="row pt-2">
            <div class="col-lg-6">
              <BFormCheckbox
                size="lg"
                class="creator-checkbox"
                v-model="form.is_calc"
              >
                {{ $t("meters.form.input_riser") }}
              </BFormCheckbox>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="portlet">
          <div class="h5 mt-1">
            {{ $t("global.location") }}
            <div class="mt-3">
              <geo-units-autocomplete
                v-model="form.geo_unit.id"
                :geo-unit="form.geo_unit"
                :placeholder="$t('global.address')"
                ref="address"
                :additional-query-params="{ type_in: this.addressSearchTypeIn }"
                :client-id='clientId'
                :disabled="noArea"
                @input="geoUnitChanged"
              ></geo-units-autocomplete>
            </div>
          </div>
        </div>
        <div v-if="showUspdConnection" class="portlet">
          <div class="h5 mt-1">
            {{ $t("meters.form.binding_to_USPD") }}
          </div>
          <div class="mt-3">
            <uspd-devices-autocomplete
              v-model="form.uspd_device.id"
              :placeholder="$t('meters.form.serial_USPD')"
              ref="uspd_device"
              :client-id='clientId'
              :geo-unit-id='form.geo_unit.id'
              :uspd="form.uspd_device"
              @input="formChanged"
            ></uspd-devices-autocomplete>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import BInputFile from '@/components/base/BInputFile';
import BInputLabel from '@/components/base/BInputLabel';
import BSelectInputLabel from '@/components/base/BSelectInputLabel';
import DatePicker from '@app/components/base/DatePicker';
import GeoUnitsAutocomplete from '@/components/autocompletes/geoUnitsAutocomplete';
import UspdDevicesAutocomplete from '@/components/autocompletes/uspdDevicesAutocomplete';

export default {
  name: 'meteringMeterBaseForm',
  components: {
    BInputLabel,
    BInputFile,
    BSelectInputLabel,
    DatePicker,
    GeoUnitsAutocomplete,
    UspdDevicesAutocomplete,
  },
  props: {
    areas: {
      type: Array,
      required: true,
    },
    clientId: {
      type: Number,
      required: true,
    },
    currentArea: null,
    data: {
      type: Object,
    },
    device: {
      type: Object,
    },
    errors: {
      type: Object,
    },
  },
  data() {
    return {
      area: null,
      areas_filtered: [],
      form: {
        geo_unit: {},
        kind: {},
        model: {},
        uspd_device: {},
        start_value: 0.0,
      },
      models: [],
      photo_url: null,
    };
  },
  computed: {
    addressSearchTypeIn() {
      if (this.noArea) return;

      let types = ['Apartment', 'Bkfn', 'TechRoom', 'ParkingPlace'];
      if (this.form.area.id === 'chmd')
        types = ['Building'];
      return types;
    },
    editMode() {
      return this.getProp(this, 'device') != null;
    },
    kindHumanState() {
      if (this.noKind) return '';

      let kind_united = { state: 'water', human: this.$t("meters.form.of_water")};
      switch (this.form.kind.id) {
        case 'heat':
          kind_united = { state: 'heat', human: this.$t('meters.form.of_heat') };
          break;
        case 'electro':
          kind_united = { state: 'electro', human: this.$t("meters.form.of_electricity")};
          break;
      }
      return kind_united;
    },
    modelHuman() {
      return this.getProp(this.form, 'model.name');
    },
    noArea() {
      return this.getProp(this.form, 'area') === null;
    },
    noModel() {
      return this.getProp(this.form, 'model') === null;
    },
    noKind() {
      return this.getProp(this.form, 'kind') === null;
    },
    showUnitedName() {
      return !this.noKind || !this.noModel;
    },
    showUspdConnection() {
      return !this.noArea && (!this.noKind && this.form.area.id === 'imd' && this.form.kind.id === 'electro');
    },
    unitedName() {
      return [this.$t('meters.meter'), this.getProp(this, 'kindHumanState.human'), this.modelHuman].join(' ');
    },
    pipeIdentifierShow() {
      return this.getProp(this.form, 'area.id') === 'imd' && this.waterCounter;
    },
    isCalcShow() {
      return this.getProp(this.form, 'area.id') === 'chmd' && this.waterCounter;
    },
    waterCounter() {
      return this.getProp(this, 'kindHumanState.state') === 'water';
    }
  },
  methods: {
    areaChanged() {
      this.$emit('areaChanged', this.form.area);
      this.$refs.address.wordReset();
      this.modelsSearch();
      this.formChanged();
    },
    areasSet() {
      if (this.editMode) {
        this.areas_filtered = this.areas.filter(x => x.id != 'pulse');
      } else {
        this.areas_filtered = this.areas;
      }
    },
    editDeploy() {
      this.$set(this, 'form',
        {
          area: this.areas.filter(x => x.id === this.device.area)[0],
          kind: this.data.kinds.filter(x => x.id === this.device.kind)[0],
          pipe_identifier: this.data.pipes.filter(x => x.id === this.device.pipe_identifier)[0],
          model: this.device.model,
          serial: this.device.serial,
          issued_on: this.device.issued_on,
          installed_on: this.device.installed_on,
          start_value: this.device.start_value || 0.0,
          uspd_device: this.device.uspd_device || {},
          geo_unit: this.device.geo_unit || {},
          is_calc: this.device.is_calc,
        },
      );
      this.photo_url = this.device.photo_url;
    },
    formChanged() {
      this.$emit('formValide', this.formValide());
    },
    formValide() {
      return Object.values(this.paramsRequired()).filter(x => x === null || x === '').length === 0
    },
    geoUnitChanged() {
      this.uspdDeviceIdReset();
    },
    getProp(obj, prop) {
      return _.get(obj, prop, null);
    },
    kindChanged() {
      this.modelReset();
      this.modelsSearch();
      this.pipeIdentifierSet();
      this.formChanged();
    },
    paramsRequired() {
      let data = {
        area: this.getProp(this.form, 'area.id'),
        kind: this.getProp(this.form, 'kind.id'),
        serial: this.getProp(this.form, 'serial'),
        meter_model_id: this.getProp(this.form, 'model.id'),
      }
      if (this.showUspdConnection) {
        data = Object.assign({}, data, { uspd_device_id: this.getProp(this.form, 'uspd_device.id') });
      }

      return data;
    },
    params() {
      let params = Object.assign({}, this.paramsRequired(), {
        geo_unit_id: this.getProp(this.form, 'geo_unit.id'),
        issued_on: this.stringifyDate(this.getProp(this.form, 'issued_on')),
        installed_on: this.stringifyDate(this.getProp(this.form, 'installed_on')),
        start_value: this.getProp(this.form, 'start_value'),
        pipe_identifier: this.getProp(this.form, 'pipe_identifier.id'),
        is_calc: this.getProp(this.form, 'is_calc'),
      });

      if (this.photo_url === null) params = Object.assign({}, params, { photo: this.getProp(this.form, 'photo') });

      return params;
    },
    modelReset() {
      this.$set(this.form, 'model', undefined);
    },
    async modelsSearch() {
      if(this.getProp(this.form, 'area.id') === 'pulse') return;

      let prms = {
        q: {
          meter_resource: this.getProp(this, 'kindHumanState.state'),
          area: this.getProp(this.form, 'area.id'),
        }
      };
      this.models = await request2(
        {
          method: 'get',
          url: `/api/backend/manage/${this.clientId}/meter_models`,
        },
        prms,
      ).then((r) => {
        if (this.getProp(this.model, 'id')) {
          this.model = r.models.filter(x => x.id === this.model.id)[0];
        }
        return r.models;
      });
    },
    photoRemove() {
      this.photo_url = null;
    },
    pipeIdentifierSet() {
      if (this.waterCounter) {
        this.$set(this.form, 'pipe_identifier', { id: 1, name: 1 });
      } else {
        this.$set(this.form, 'pipe_identifier', null);
      }
    },
    stringifyDate(val) {
      return val ? dayjs(val).format('YYYY-MM-DD') : null;
    },
    uspdDeviceIdReset() {
      if (this.$refs.uspd_device === undefined) return;
      this.$refs.uspd_device.wordReset();
    },
  },
  created() {
    this.areasSet();
    this.modelsSearch();
    if(this.editMode) {
      this.editDeploy();
    } else {
      this.$set(this.form, 'area', this.currentArea);
      this.models = this.data.models;
    }
    this.formChanged();
  },
};
</script>
