<template>
  <div>
    <RelaysList
      :relays="list"
      disable-add-check-property="id"
      :value="intercom"
      :relaysActions="relaysActions"
      :barrierId="barrierId"
      :geoUnitId="geoUnitId"
      @input="onInput"
    />
    <div v-for="relay in inputsValue" :key="relay.value.id">
      <input
        v-for="key of Object.keys(relay.value)"
        :name="`${relay.name}[][${key}]`"
        :value="relay.value[key]"
        :key="key"
        hidden
      />
    </div>
  </div>
</template>

<script>
import RelaysList from '@/components/lists/RelaysList.vue';
export default {
  name: 'RelaysListSlim',
  components: {
    RelaysList,
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    relays: {
      type: Array,
      required: true,
    },
    value: {
      type: Object,
      default: null,
    },
    relaysActions: {
      type: Array,
      default: null,
    },
    barrierId: {
      type: [Number, String],
      default: null,
    },
    geoUnitId: {
      type: [Number, String],
      default: null,
    },
  },
  created() {
    this.list = this.relays;
  },
  data() {
    return {
      list: [],
      intercom: this.value || { id: null, uid: null },
    };
  },
  computed: {
    inputsValue() {
      if (this.list)
        return this.list
          .filter(
            (relay) =>
              relay.id &&
              relay.npr_action !== 'not_used' &&
              (relay.npr_barrier_id == this.barrierId || !relay.npr_barrier_id),
          )
          .map((relay) => {
            return {
              value: relay,
              name: `${this.name}`,
            };
          });
      else [];
    },
  },
  methods: {
    onInput(list, intercom) {
      this.list = list;
      this.intercom = intercom;
      this.input();
    },
    input() {
      this.$emit('input', this.list, this.intercom);
    },
  },
};
</script>
