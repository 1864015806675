<template>
  <div class="d-flex align-items-center justify-content-center selector mb-3">
    <b-form-group
      :id="`legend-${id}`"
      :label="label"
      :label-class="
        labelIsActive || value !== ''
          ? 'float-label-active'
          : 'float-label-inactive'
      "
    >
      <b-form-input
        :id="id"
        :value="modifier ? modifier : value"
        :disabled="Boolean(modifier || disabled)"
        @input="onInput"
        :type="modifier ? 'text' : 'number'"
        :number="!modifier"
        :state="state"
        no-wheel
        class="d-flex align-items-center justify-content-center number-block"
        @focus="
          () => {
            activatedLabel();
            $emit('focus');
          }
        "
        @blur="
          () => {
            deactivateLabel();
            $emit('blur');
          }
        "
      />
    </b-form-group>
    <div class="selector-btn-block">
      <div
        class="pointer text-primary selector-chevron no-select"
        v-on:mousedown="startIncValue(value)"
        v-on:mouseup="stopIncValue()"
        v-on:mouseleave="stopIncValue()"
        :style="`border-bottom: 1px solid #ced4da;${
          disabled ? 'pointer-events: none;' : ''
        }`"
      >
        +
      </div>
      <div
        class="pointer text-primary selector-chevron no-select"
        v-on:mousedown="startDecValue(value)"
        v-on:mouseup="stopDecValue()"
        v-on:mouseleave="stopIncValue()"
        :style="disabled ? 'pointer-events: none;' : ''"
      >
        &#8210;
      </div>
    </div>
  </div>
</template>

<script>
import { BFormGroup, BFormInput } from 'bootstrap-vue';

export default {
  name: 'BInputCount',
  components: { BFormGroup, BFormInput },
  props: {
    value: {},
    id: {
      type: [String || Number],
    },
    label: String,
    modifier: {},
    disabled: {
      type: Boolean,
      default: false,
    },
    state: {
      type: [Boolean || undefined],
      default: undefined,
    },
    minus: {
      type: Boolean,
      default: false,
    },
    minValue: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      isFocus: false,
      labelIsActive: false,
    };
  },
  computed: {
    getValue() {
      if (this.value === '') {
        return '';
      }
      return Number(this.value);
    },
  },
  methods: {
    startIncValue(value) {
      this.stopIncValue();
      let count = Number(value);
      this.$emit('input', (count += 1));
      this.incTimeout = setTimeout(() => {
        this.incInterval = setInterval(() => {
          this.$emit('input', (count += 1));
        }, 75);
      }, 425);
    },
    stopIncValue() {
      clearInterval(this.incTimeout);
      clearInterval(this.incInterval);
    },
    startDecValue(value) {
      this.stopDecValue();
      if (this.minus) {
        let count = Number(value);
        this.$emit('input', (count -= 1));
        this.decTimeout = setTimeout(() => {
          this.decInterval = setInterval(() => {
            this.$emit('input', count);
          }, 75);
        }, 425);
      } else if (value > this.minValue) {
        let count = Number(value);
        this.$emit('input', (count -= 1));
        this.decTimeout = setTimeout(() => {
          this.decInterval = setInterval(() => {
            this.$emit('input', count > 0 ? (count -= 1) : 0);
          }, 75);
        }, 425);
      }
    },
    stopDecValue() {
      clearInterval(this.decTimeout);
      clearInterval(this.decInterval);
    },
    onInput(val) {
      // console.log(val === '');
      this.$emit('input', val === '' ? '' : Number(val));
    },
    activatedLabel() {
      this.labelIsActive = true;
    },
    deactivateLabel() {
      this.labelIsActive = false;
    },
  },
  watch: {
    modifier(val) {
      if (val) this.labelIsActive = true;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../assets/styles/components/variables';

.selector {
  justify-content: space-between;
  align-items: center;
  height: 50px;
  width: 100%;

  .form-group {
    margin: 0;
    width: 100%;
  }

  .form-control {
    height: 50px;
  }

  &-chevron {
    display: flex;
    width: 24px;
    height: 50%;
    font-size: $font-size-16px;
    line-height: $line-height-24px;
    align-items: center;
    justify-content: center;

    &:active {
      opacity: 0.5;
    }
  }

  &-btn-block {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    border-top: 1px solid $border;
    border-bottom: 1px solid $border;
    border-right: 1px solid $border;
    border-radius: 0 5px 5px 0;
  }
}

.number-block {
  height: 100%;
  width: 100%;
  border: 1px solid $border;
  border-radius: 5px 0 0 5px;
  font-size: $font-size-16px;
  line-height: $line-height-24px;
  padding-top: 20px;

  &:focus {
    padding-top: 20px !important;
  }
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
</style>
