<template>
  <div class="cars-autocomplete">
    <div class="dropdown">
      <BInputLabel
        v-model="word"
        @keyup="onKeyUp"
        type="text"
        :label="placeholder"
        ref="word"
        class="autocomplete-results-dropdown"
        :state="state"
        :is-invalid="isInvalid"
        :disabled="disabled"
      />
      <input v-if="q_name"
        :value="selected_id"
        :id="`${model}_${q_name}`"
        type="hidden"
        :name="`${model}[${q_name}]`"
      />
      <div class="close-icon-container" v-if="!disabled">
        <i class="icon-local-times" v-if="!empty_word" @click="wordReset"></i>
      </div>
      <div v-show="!empty_results" class="dropdown-menu py-0">
        <div
          v-for="result in results"
          :key="result.id"
          @click="choosed(result)"
          :data-id="result.id"
          :data-name="result.id"
          class="dropdown-item"
        >
          {{ result.mark.name }}
          {{ result.model.name }}
        </div>
      </div>
      <slot></slot>
    </div>
  </div>
</template>

<script>
import BInputLabel from '@/components/base/BInputLabel';
import { debounce, isEmpty, isNull } from 'lodash';
import { API_URLS } from '@/consts';
import { request3 } from '@/api/request';

export default {
  name: 'CarsAutocomplete',
  components: {
    BInputLabel,
  },
  props: {
    saveFormId: String,
    model: String,
    name: String,
    carData: {
      type: Object,
      default: null
    },
    clientId: {
      type: Number,
    },
    placeholder: {
      type: String,
    },
    value: {
      type: [String, Number],
    },
    state: {},
    debouncedTts: {
      type: Number,
      default: 500,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isInvalid: {
      type: Boolean,
      default: false,
    },
    additionalQueryParams: {
      type: Object,
      default() {
        return {};
      },
    },
    optional: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      debouncedSearch: null,
      selected_id: null,
      word: '',
      results: [],
      isShowItems: false,
      q_name: this.name
    };
  },
  computed: {
    empty_results() {
      return isEmpty(this.results);
    },
    empty_word() {
      return this.word < 1;
    },
    debounced() {
      return this.debouncedTts > 0;
    },
    isCastomQuery() {
      if (this.optional) {
        return true
      } else return false
    },
  },
  mounted() {
    if (this.debounced) {
      this.initDebounce();
    }
    if (this.carData instanceof Object) {
      if (this.carData.id) {
        this.word = this.carData.name;
        this.selected_id = this.carData.id;
      }
    }
  },
  methods: {
    choosed(result) {
      this.word = `${result.mark.name}  ${result.model.name ? result.model.name : ''}`;
      this.selected_id = result.model.id ? result.model.id : result.mark.id;
      this.getName(result)
      this.$emit('input', this.selected_id);
      this.$emit('select', result);
      this.resultsReset();
      this.saveForm();
    },
    getName(value) {
      if (this.isCastomQuery) {
        this.q_name = value.model.id ? this.optional.model : this.optional.mark
      } else this.q_name = this.name
    },
    onKeyUp() {
      if (this.debounced) {
        this.debouncedSearch();
      } else {
        this.search();
      }
    },
    resultsOpen() {
      $('.autocomplete-results-dropdown').dropdown('show');
      this.$refs.word.focus();
    },
    resultsClose() {
      $('.autocomplete-results-dropdown').dropdown('hide');
      this.$refs.word.focus();
    },
    resultsReset() {
      this.results = [];
    },
    saveForm() {
      if (_.isUndefined(this.saveFormId)) {
        return;
      }

      const vm = this;
      setTimeout(function () {
        $(`#${vm.saveFormId}`).submit();
      }, 100);
    },
    wordReset() {
      this.word = '';
      this.selected_id = null;
      this.q_name = this.optional.mark;
      this.$emit('input', null);
      this.saveForm();
    },
    async getCars() {
      let params = {
        name_cont_any: this.word,
      };
      let query = {
        ...params,
        ...this.additionalQueryParams,
      };

      this.results = await request3
        .get(API_URLS.npr.cars.fetch(this.clientId), {
          params: {
            q: query,
          },
        })
        .then((res) => res.data);
    },
    search() {
      if (isEmpty(this.word)) return;
      this.getCars();
    },
    onClear() {
      this.$emit('clear');
    },
    initDebounce() {
      this.debouncedSearch = debounce(() => {
        this.search();
      }, this.debouncedTts);
    },
    getOverlapText(value) {
      return value
        ? String(value).replace(this.word, (change) => `<u>${change}</u>`)
        : '';
    },
  },
  watch: {
    word(value) {
      if (isNull(value) || isEmpty(value)) {
        this.results = [];
      }
    },
    results(value) {
      if (!isEmpty(value)) {
        this.resultsOpen();
      } else {
        this.resultsClose();
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import '../../assets/styles/components/variables';
.cars-autocomplete {
  position: relative;
  .close-icon-container {
    padding: 5px;
    cursor: pointer;
    position: absolute;
    right: 13px;
    top: 13px;
  }
  fieldset {
    width: 100%;
  }
  .dropdown-menu {
    overflow-x: auto;
    .dropdown-item {
      border: 1px solid #edeef0;
      height: 50px;
      cursor: pointer;
      display: flex;
      align-items: center;
      &:hover {
        background: $table-hover-bg;
      }
    }
  }
    &__result-list-item {
    &-top,
    &-middle,
    &-bottom {
      display: flex;
      p {
        margin-bottom: 0;
      }
    }

    &-top {
      &-text {
        color: #c1c4cb;
        font-weight: 400;
      }
      &-text:first-child {
        color: $gray-900;
        padding-right: 0.5rem;
        font-weight: 300;
      }
    }
    &-bottom {
      top: 26px;
      &-text {
        font-size: 10px;
        font-weight: 400;
        color: #c1c4cb;
        transition: color ease-in-out 0.2s;
      }
    }
    &-middle {
      width: 100%;
      height: 100%;
      top: 0;
      align-items: center;
    }
  }
}
</style>
