<template>
  <div class="geo-units-autocomplete-2">
    <BDropdownInput
      v-model="input"
      :description="description"
      :display-property="displayProperty"
      :disabled="disabled"
      :label="label"
      :items="items"
      :state="state"
      :validator="validator"
      compare-property="id"
      @clear="onClear"
      @focus="onFocus"
      @hide="onHide"
      @input="onInput"
      @select="onSelect"
      @select:item="onSelectItem"
      :fieldsetClass="fieldsetClass"
      :popoverText="popoverText"
      :popoverHint="popoverHint"
      :hintId="hintId"
    />
  </div>
</template>

<script>
import { ApiBackendGeoUnits, ApiBackendNprGeoUnits } from '@/api';
import BDropdownInput from '@/components/base/BDropdownInput.vue';
import { GEO_UNIT_TYPES_ARRAY } from '@/consts/geo_unit';
import i18n from '@app/i18n';
import { request3 } from '@/api/request';
import { API_URLS } from '@/consts';

export default {
  name: 'GeoUnitsAutocomplete2',
  components: { BDropdownInput },
  props: {
    clientId: {
      type: [Number, String],
    },
    description: {
      type: String,
    },
    disabled: {
      type: Boolean,
    },
    displayProperty: {
      type: String,
      default: 'short_name',
    },
    geoUnitDeepAt: {
      type: Array,
    },
    initTitle: {
      type: String,
    },
    label: {
      type: String,
      default: () => i18n.t('global.address'),
    },
    minInputLength: {
      type: Number,
      default: 2,
    },
    hideResetInput: {
      type: Boolean,
    },
    q: {
      type: Object,
    },
    resetInput: {
      type: Boolean,
    },
    state: {
      type: Boolean,
      default: undefined,
    },
    typeEq: {
      type: String,
      validator: (val) => GEO_UNIT_TYPES_ARRAY.includes(val),
    },
    validator: {
      type: Function,
    },
    value: {
      type: Number,
    },
    npr: {
      type: Boolean,
      default: false,
    },
    fieldsetClass: {
      type: String,
      default: null,
    },
    popoverText: {
      type: String,
      default: 'i',
    },
    popoverHint: {
      type: String,
      default: null,
    },
    hintId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      input: null,
      items: [],
      openedInput: null,
      user_id: null,
    };
  },
  watch: {
    input(input) {
      this.$emit('input:search', input);
    },
    value(val) {
      if (!val) {
        this.input = '';
        this.items = [];
      }
    },
  },
  async mounted() {
    this.initInputValue();
  },
  methods: {
    async fetchItems() {
      if (
        !this.user_id &&
        !(this.input?.trim().length > this.minInputLength - 1)
      )
        return;

      const q = {
        [`${this.displayProperty}_cont_all`]: this.input,
      };

      if (this.npr && this.user_id) {
        q.geo_units_users_user_id_eq = this.user_id;
      }

      if (this.typeEq) q.type_eq = this.typeEq;
      if (this.geoUnitDeepAt) q.type_in = this.geoUnitDeepAt;
      if (this.q) Object.assign(q, this.q);

      const address = this.npr
        ? API_URLS.backendManage.nprGeoUnits
        : API_URLS.backendManage.geoUnits;

      this.items = await request3
        .get(address.fetch(this.clientId), {
          params: { q, limit: 8 },
        })
        .then((res) => res.data.geo_units);
    },
    getTitle(geoUnit) {
      return geoUnit[this.displayProperty];
    },
    initInputValue() {
      if (this.initTitle) {
        this.input = this.initTitle;
      } else if (this.value) {
        ApiBackendGeoUnits.getOne(this.value).then((res) => {
          this.input = this.getTitle(res.geo_unit);
        });
      }
    },
    onFocusAction(user_id) {
      this.user_id = user_id;
      this.fetchItems();
    },
    onClear() {
      this.$emit('clear');
    },
    onFocus() {
      this.$emit('focus');
      this.openedInput = this.input;
    },
    onInput() {
      this.fetchItems();
    },
    onHide() {
      if (this.resetInput && this.value) {
        this.input = this.openedInput;
      }
    },
    onSelectItem(item) {
      this.$emit('select:item', item);
    },
    onSelect(value) {
      this.$emit('input', value);
      this.$emit('select', value);
    },
  },
};
</script>
