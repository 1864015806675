<template>
  <b-form-row>
    <b-col cols="5">
      <GeoUnitsAutocomplete2
        :geo-unit-deep-at="geoUnitDeepAt"
        :label="$t('devices.tabs.relay.geo_unit')"
        v-model="value.geo_unit_id"
        :state="accessState(relay_idx, gu_idx, 'geo_unit_id')"
        @select:item="onInputGeoUnit"
        @clear="onClearGeoUnit"
        fieldsetClass="my-0"
      />
      <BError
        v-if="!accessState(relay_idx, gu_idx, 'geo_unit_id') && isSubmit"
        :error="$t('devices.errors.not_empty')"
      />
    </b-col>
    <b-col cols="3">
      <BSelectLabel
        v-model="value.geo_unit_type"
        :label="$t('devices.tabs.relay.type')"
        :disabled="ifDisabledType"
        :items="typeItems"
        objectProperty="type"
        display="translate"
        @select="onSelectType"
      />
    </b-col>
    <b-col cols="3">
      <BInputLabel
        :label="$t('devices.tabs.relay.range')"
        :disabled="ifDisabledType"
        v-model="value.range"
        @input="onInputRange"
      ></BInputLabel>
    </b-col>
    <b-col cols="1">
      <b-button
        @click="deleteAccess(relay_idx, gu_idx, value)"
        class="w-auto h-auto relay-modal__button"
        variant="outline-primary"
      >
        <i class="icon-local-trash relay-modal__icon" />
      </b-button>
    </b-col>
  </b-form-row>
</template>

<script>
import { GEO_UNITS } from '@/consts';
import GeoUnitsAutocomplete2 from '@/components/autocompletes/GeoUnitsAutocomplete2.vue';
import { ApiGeoUnits } from '@/api';
import { mapGetters, mapMutations, mapState } from 'vuex';
import BError from '@/components/base/BError';
import { cloneDeep } from 'lodash';
const { Apartment, Bkfn, ParkingPlace, StoreRoom } = GEO_UNITS;

export default {
  name: 'IntercomRelayPermission',
  components: { GeoUnitsAutocomplete2, BError },
  props: {
    geoUnitDeepAt: Array,
    geo_unit: {
      type: Object,
      required: true,
    },
    gu_idx: {
      type: [Number, String],
      required: true,
    },
    relay_idx: {
      type: [Number, String],
      required: true,
    },
    isSubmit: Boolean,
    errors: {},
  },
  data() {
    return {
      geoUnit: null,
      value: cloneDeep(this.geo_unit),
    };
  },
  computed: {
    ifDisabledType() {
      return this.geoUnit?.type !== GEO_UNITS.Building;
    },
    typeItems() {
      const geoUnits = [ParkingPlace, Apartment, StoreRoom, Bkfn].map(
        (type) => ({
          type,
          translate: this.$tc(`geo_unit.type_only_names.${type}`, 0),
        }),
      );
      return [
        { type: 'none', translate: this.$t('global.not_selected') },
        ...geoUnits,
      ];
    },
  },
  async mounted() {
    await this.getGeoUnit();
  },
  watch: {
    geo_unit() {
      this.value = cloneDeep(this.geo_unit);
    },
  },
  methods: {
    deleteAccess(relay_idx, gu_idx, gu) {
      this.$emit('deleteAccess', relay_idx, gu_idx, gu);
    },
    async getGeoUnit() {
      if (this.geo_unit.geo_unit_id) {
        try {
          this.geoUnit = await ApiGeoUnits.getOne(this.geo_unit.geo_unit_id);
        } catch (err) {
          this.$bvToast.toast(this.$t('error.get_data'));
        }
      }
    },
    accessState(relay_idx, access_idx, field) {
      if (!this.errors || !this.isSubmit) {
        return undefined;
      }
      const res =
        this.errors.$each.$iter[relay_idx]?.relays_geo_units_attributes?.$each
          ?.$iter[access_idx];
      if (!res) {
        return undefined;
      }
      return !res[field]?.$invalid;
    },
    onSelectType(type) {
      if (type === 'none') {
        this.value = Object.assign(this.value, {
          geo_unit_type: null,
          range: null,
        });
      } else {
        this.value = Object.assign(this.value, {
          geo_unit_type: type,
        });
      }
      this.editRelay();
    },
    onInputGeoUnit(geoUnit) {
      this.geoUnit = geoUnit;
      const data = { geo_unit_id: geoUnit.id || null };
      if (geoUnit.type !== GEO_UNITS.Building) {
        data.geo_unit_type = null;
        data.range = null;
      }
      this.value = Object.assign(this.value, data);
      this.editRelay();
    },
    onInputRange(range) {
      this.value = Object.assign(this.value, { range: range });
      this.editRelay();
    },
    editRelay() {
      this.$emit(
        'editRelayPermission',
        this.relay_idx,
        this.gu_idx,
        this.value,
      );
    },
    onClearGeoUnit() {
      this.geoUnit = {};
      this.value = Object.assign(this.value, {
        geo_unit_id: null,
        geo_unit_type: null,
        range: null,
      });
      this.editRelay();
    },
  },
};
</script>

<style></style>
