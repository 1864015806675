<template>
  <div class="plates-autocomplete">
    <div class="dropdown">
      <BInputLabel
        v-model="word"
        @keyup="onKeyUp"
        @keyup.native.enter="onEnter"
        type="text"
        :label="placeholder"
        ref="word"
        class="autocomplete-results-dropdown"
        :state="state"
        :is-invalid="isInvalid"
        :disabled="disabled"
      />
      <input
        :value="selected_id"
        :id="`${model}_${name}`"
        type="hidden"
        :name="`${model}[${name}]`"
      />
      <div class="close-icon-container" v-if="!disabled">
        <i class="icon-local-times" v-if="!empty_word" @click="wordReset"></i>
      </div>
      <div v-show="!empty_results" class="dropdown-menu py-0">
        <div
          v-for="result in results"
          :key="result.plate"
          @click="choosed(result)"
          :data-id="result.plate"
          :data-name="result.plate"
          class="dropdown-item"
        >
          {{ result.plate }}
        </div>
      </div>
      <slot></slot>
    </div>
  </div>
</template>

<script>
import BInputLabel from '@/components/base/BInputLabel';
import { debounce, isEmpty, isNull } from 'lodash';
import { API_URLS } from '@/consts';
import { request3 } from '@/api/request';

export default {
  name: 'EventsPlatesAutocomplete',
  components: {
    BInputLabel,
  },
  props: {
    saveFormId: String,
    model: String,
    name: String,
    plateData: Object,
    clientId: {
      type: Number,
    },
    placeholder: String,
    value: {
      type: [String, Number],
    },
    state: {},
    debouncedTts: {
      type: Number,
      default: 200,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isInvalid: {
      type: Boolean,
      default: false,
    },
    additionalQueryParams: {
      type: Object,
      default() {
        return {};
      },
    },
    userId: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      debouncedSearch: null,
      selected_id: null,
      word: '',
      results: [],
      isShowItems: false,
    };
  },
  computed: {
    empty_results() {
      return isEmpty(this.results);
    },
    empty_word() {
      return this.word < 1;
    },
    debounced() {
      return this.debouncedTts > 0;
    },
  },
  mounted() {
    if (this.debounced) {
      this.initDebounce();
    }
    if (this.plateData instanceof Object) {
      if(this.plateData.plate) {
        this.word = this.plateData.plate;
        this.selected_id = this.plateData.plate;
      }
    }
  },
  methods: {
    choosed(result) {
      this.word = result.plate;
      this.selected_id = result.plate;
      this.$emit('input', result.plate);
      this.resultsReset();
      this.saveForm();

      this.$emit('select', result);
    },
    onKeyUp() {
      if (this.debounced) {
        this.debouncedSearch();
      } else {
        this.search();
      }
    },
    resultsOpen() {
      $('.autocomplete-results-dropdown').dropdown('show');
      this.$refs.word.focus();
    },
    resultsClose() {
      $('.autocomplete-results-dropdown').dropdown('hide');
      this.$refs.word.focus();
    },
    resultsReset() {
      this.results = [];
    },
    saveForm() {
      if (_.isUndefined(this.saveFormId)) {
        return;
      }

      const vm = this;
      setTimeout(function () {
        $(`#${vm.saveFormId}`).submit();
      }, 100);
    },
    wordReset() {
      this.word = '';
      this.selected_id = null;
      this.$emit('input', null);
      this.saveForm();
    },
    async getPlates() {
      let params = {
        plate_cont_any: this.word,
        user_id_eq: this.userId
      };
      let query = {
        ...params,
        ...this.additionalQueryParams,
      };

      this.results = await request3
        .get(API_URLS.backendManage.plates.fetch(this.clientId), {
            params: {
              q: query,
            },
          })
        .then((res) => res.data);
    },
    search() {
      if (isEmpty(this.word)) return;
      this.getPlates();
    },
    onClear() {
      this.$emit('clear');
    },
    initDebounce() {
      this.debouncedSearch = debounce(() => {
        this.search();
      }, this.debouncedTts);
    },
    getOverlapText(value) {
      return value
        ? String(value).replace(this.word, (change) => `<u>${change}</u>`)
        : '';
    },
    onEnter() {
      this.choosed({plate: this.word});
    }
  },
  watch: {
    word(value) {
      if (isNull(value) || isEmpty(value)) {
        this.results = [];
      }
    },
    results(value) {
      if (!isEmpty(value)) {
        this.resultsOpen();
      } else {
        this.resultsClose();
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import '../../assets/styles/components/variables';
.plates-autocomplete {
  position: relative;
  .close-icon-container {
    padding: 5px;
    cursor: pointer;
    position: absolute;
    right: 13px;
    top: 13px;
  }
  fieldset {
    width: 100%;
  }
  .dropdown-menu {
    overflow-x: auto;
    .dropdown-item {
      border: 1px solid #edeef0;
      height: 50px;
      cursor: pointer;
      display: flex;
      align-items: center;
      &:hover {
        background: $table-hover-bg;
      }
    }
  }
    &__result-list-item {
    &-top,
    &-middle,
    &-bottom {
      display: flex;
      p {
        margin-bottom: 0;
      }
    }

    &-top {
      &-text {
        color: #c1c4cb;
        font-weight: 400;
      }
      &-text:first-child {
        color: $gray-900;
        padding-right: 0.5rem;
        font-weight: 300;
      }
    }
    &-bottom {
      top: 26px;
      &-text {
        font-size: 10px;
        font-weight: 400;
        color: #c1c4cb;
        transition: color ease-in-out 0.2s;
      }
    }
    &-middle {
      width: 100%;
      height: 100%;
      top: 0;
      align-items: center;
    }
  }
}
</style>
