<template>
  <b-form-group
    :id="id"
    class="b-selector-with-label"
    style="height: 50px !important"
    :class="getSelectedClassToActive"
  >
    <legend :class="getLabelClass()" ref="legend">
      {{ label }}
    </legend>
    <div class="d-flex align-items-center justify-content-center w-100">
      <b-dropdown
        :id="id"
        variant="transparent"
        no-caret
        class="w-100"
        :toggle-class="getState"
        :disabled="disabled"
      >
        <template slot="button-content">
          <div
            class="d-flex h-100 w-100 justify-content-around align-items-center"
          >
            <div
              class="d-flex float-dropdown"
              :style="getLabelOffset"
              :class="{ 'float-dropdown-without-padding': noPaddingLabel }"
            >
              {{ (isNotEmptyValue && getName) || placeholder || empty }}
            </div>
            <div class="icon-local-shevron-down dropdown-caret"></div>
          </div>
        </template>
        <b-dropdown-item
          v-for="(item, index) in items"
          :key="item + index"
          @click="selectItem(item, index)"
        >
          {{ getDisplayName(item, index) }}
        </b-dropdown-item>
        <b-dropdown-item v-if="Array.isArray(items) && !items.length" disabled>
          {{ empty }}
        </b-dropdown-item>
      </b-dropdown>
    </div>
  </b-form-group>
</template>

<script>
import { BFormGroup, BDropdown, BDropdownItem } from 'bootstrap-vue';

export default {
  name: 'BSelectLabel',
  components: { BFormGroup, BDropdown, BDropdownItem },
  props: {
    value: {},
    items: {
      required: true,
    },
    id: {
      type: String,
      default() {
        return `id_rand${Math.random()}`;
      },
    },
    label: String,
    display: String,
    objectProperty: String,
    objectKeyValue: Boolean,
    deselect: Boolean,
    placeholder: String,
    empty: String,
    disabled: Boolean,
    customDisplay: Function,
    state: {
      type: [Boolean || undefined],
      default: undefined,
    },
    labelOnlySelected: Boolean,
    noPaddingLabel: Boolean,
    fixedMiddleLabel: Boolean,
    middleLabelSize: String,
    showActive: Boolean,
    notShowActiveValue: String,
    zeroValActive: Boolean,
    booleanValActive: Boolean,
  },
  data() {
    return {
      isMounted: false,
      labelOffset: 30,
    };
  },
  mounted() {
    // console.log('hello');
    this.isMounted = true;
  },
  computed: {
    getSelectedClassToActive() {
      if (this.notShowActiveValue) {
        if (
          this.notShowActiveValue &&
          this.getName !== this.notShowActiveValue
        ) {
          return 'selected';
        }
      } else if (this.showActive && this.isNotEmptyValue) {
        return 'selected';
      }
      return '';
    },
    isNotEmptyValue() {
      if (this.zeroValActive && this.value === 0) return true;
      if (this.booleanValActive && this.value === false) return true;
      return this.value;
    },
    getName() {
      if (this.objectProperty) {
        const element = this.items.find((option) => {
          return option[this.objectProperty] === this.value; // TODO add multi-path
        });
        return this.getDisplayName(element);
      }
      return this.getDisplayName(this.value, this.value);
    },
    getState() {
      if (this.state === undefined) return '';
      if (this.state) return 'is-valid';
      return 'is-invalid';
    },
    getLabel() {
      if (this.labelOnlySelected) {
        if (this.value) return this.label;
      } else {
        return this.label;
      }
      return '';
    },
    getLabelOffset() {
      if (this.isMounted && this.fixedMiddleLabel) {
        const legendWidth = this.$refs.legend.clientWidth;
        return `left: ${legendWidth + this.labelOffset}px`;
      }
      return '';
    },
  },
  methods: {
    getLabelClass() {
      if (this.fixedMiddleLabel) {
        if (this.middleLabelSize) {
          return `fixed-middle-label-${this.middleLabelSize}`;
        }
        return 'fixed-middle-label';
      }
      const active =
        this.isNotEmptyValue || this.placeholder
          ? 'float-label-active'
          : 'float-label-inactive';
      const disabled = this.disabled ? 'disabled' : '';
      return `${active} ${disabled}`;
    },
    selectItem(item, index) {
      if (item === this.value && this.deselect) {
        this.$emit('input', null);
        this.$emit('select', null); // TODO delete duplicate emits
        this.$emit('selected', null);
        this.$emit('input:item', item);
      } else {
        let value;
        if (this.objectProperty) {
          value = item[this.objectProperty];
        } else if (this.objectKeyValue) {
          value = index;
        } else {
          value = item;
        }
        this.$emit('input', value);
        this.$emit('select', value);
        this.$emit('selected', value);
        this.$emit('input:item', item);
      }
    },
    getDisplayName(item, index) {
      if (this.objectKeyValue) {
        if (this.customDisplay) return this.customDisplay(index);
        return index;
      }
      if (this.customDisplay) return this.customDisplay(item);
      if (this.display) {
        let value = item;
        const keys = this.display.split('.');
        keys.forEach((key) => {
          value = value && value[key];
        });
        return value;
      }
      return item;
    },
  },
};
</script>

<style lang="scss">
@import '../../assets/styles/components/variables';

.dropdown-caret {
  color: #ced4da;
  position: absolute;
  right: 1rem;
  top: 18px;
}
.float-dropdown {
  height: 50px;
  padding-top: 20px;
  &:focus {
    padding-top: 18px !important;
  }
  &-without-padding {
    padding-top: 18px;
  }
}

.dropdown-menu {
  width: 100%;
}
.float-placeholder {
  opacity: 0.6;
}
</style>
