<template>
  <div class="form-group">
    <BInputLabel
      v-bind="$attrs"
      :value="formattedValue"
      :state="state"
      @inputNative="onInput($event)"
      @focus="onFocus($event)"
      @blur="onBlur($event)"
      class="m-0"
    >
      <div class="d-none">
        <slot />
      </div>
    </BInputLabel>
    <div v-if="receivedError" v-html="receivedError" class="invalid-feedback d-block"/>
  </div>
</template>

<script>
import { parsePhoneNumberFromString } from 'libphonenumber-js/mobile';
import BInputLabel from './BInputLabel.vue';

export default {
  name: 'BFormPhoneSlot',
  components: { BInputLabel },
  data() {
    return {
      input: {},
      initialValue: '+7',
      hasError: false,
      receivedError: '',
      rawValue: '',
      formattedValue: '',
    };
  },
  mounted() {
    const defaultSlot = this.$slots.default[0];
    this.input =
      defaultSlot.tag === 'input'
        ? defaultSlot.elm
        : defaultSlot.elm.querySelector('input');

    const error = this.$el.querySelector('.invalid-feedback');

    if (error) {
      this.receivedError = error.innerText;
      this.hasError = true
    }

    this.input.value && this.setValues(this.input.value);
  },
  computed: {
    state() {
      if (!this.input.value && !this.hasError) {
        return null;
      }
      return this.receivedError ? !this.receivedError : !this.hasError;
    },
  },
  methods: {
    createInputEvent() {
      return new Event('input', { bubbles: true, cancelable: true });
    },
    replaceLetters(value) {
      return `+${value.replace(/\D+/g, '')}`;
    },
    validatePhoneNumber(parsedPhone) {
      if (!parsedPhone) return;
      this.hasError = !parsedPhone.isValid();
    },
    onInput(event) {
      this.setValues(event.target.value);

      event.target.value = this.formattedValue;

      this.input.value = this.rawValue;
      this.input.dispatchEvent(this.createInputEvent());
    },
    setValues(value) {
      this.rawValue = this.replaceLetters(value || this.initialValue);
      const parsedProneNumber = parsePhoneNumberFromString(this.rawValue);
      this.validatePhoneNumber(parsedProneNumber);

      this.formattedValue = parsedProneNumber
        ? parsedProneNumber.format('INTERNATIONAL')
        : this.rawValue;
    },
    onFocus(event) {
      if (!this.rawValue) {
        this.formattedValue = this.initialValue;
        this.rawValue = this.initialValue;
        setTimeout(() => {
          const newPosition = event.target.value.length;
          event.target.setSelectionRange(newPosition, newPosition);
        }, 0);
      }
    },
    onBlur() {
      if (this.rawValue === this.initialValue) {
        this.rawValue = '';
        this.formattedValue = '';
        this.input.value = '';
        this.input.dispatchEvent(this.createInputEvent());
      }
    },
  },
};
</script>
