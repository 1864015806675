<template>
  <UuidSelectModal
    :clientId="clientId"
    ref="UuidSelectModal"
    id="intercom-uuid-select"
  />
</template>

<script>
import UuidSelectModal from './intercom/createModal/fastAdd/UuidSelectModal.vue';
import { EVENTS } from '@/consts';
import EventBus from '@/packs/EventBus';

export default {
  name: 'IntercomFastModal',
  props: {
    clientId: {
      type: [String, Number],
      required: true,
    },
  },
  components: {
    UuidSelectModal,
  },
  mounted() {
    EventBus.$on(EVENTS.intercom.fast.modal.show, this.show);
  },
  methods: {
    show(type) {
      this.$refs.UuidSelectModal.show(type);
    },
  },
};
</script>

<style lang="sass">
@import '../../assets/styles/components/variables';

.intercom-fast-modal{
  &__btn {
    min-height: 0 !important;
    padding: 8px 30px;
  }

  &__hint {
    color: $color-default-blue;
    font-size: 12px;
  }

  &__text {
    font-size: 18px;
  }

  &__icon {
    font-size: 25px;
    &_ok {
      color: $color-default-green;
    }
    &_attention {
      color: #FFDD00;
    }
  }

}
</style>
