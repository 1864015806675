<template>
  <div class="keys-tab">
    <b-form-row>
      <b-col v-if="isShowKeyTypes">
        <BSelectLabel
          id="select-intercom-modal_key_types"
          :items="keyTypes"
          display="label"
          object-property="type"
          :label="$t('devices.tabs.keys.type')"
          v-model="intercom.settings.key_types"
          @input:item="onInputKeyTypes"
        />
      </b-col>
    </b-form-row>
    <b-form-row>
      <b-col v-if="isShowKeysProfiles" class="mb-3">
        <BSelectLabel
          id="select-intercom-modal_keys_profile_id"
          name="intercom-keys-profiles"
          :items="intercomKeysProfiles"
          display="name"
          object-property="id"
          :label="$t('devices.tabs.keys.keys_profile')"
          v-model="intercom.settings.intercom_keys_profile_id"
          :state="
            validateState
              ? validateState('settings.intercom_keys_profile_id')
              : undefined
          "
          class="my-0"
          zero-val-active
        />
        <BError
          v-if="!errors.intercom_keys_profile_id.required && isSubmit"
          :error="$t('devices.errors.not_empty')"
        />
      </b-col>
    </b-form-row>
    <b-form-row>
      <b-col v-if="isShowKeysProfiles">
        <BSelectLabel
          id="select-intercom-modal_code_reverse_order"
          name="intercom-code-reverse-order"
          :items="intercomCodeReverseOrders"
          display="label"
          object-property="value"
          :label="$t('devices.tabs.keys.reverse_order.title')"
          v-model="intercom.settings.code_reverse_order"
          boolean-val-active
        />
      </b-col>
    </b-form-row>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex';
import BError from '@/components/base/BError';
import { ApiIntercomKeysProfiles } from '@/api';

export default {
  name: 'IntercomKeysTab',
  props: {
    clientId: {
      type: [Number, String],
      required: true,
    },
    validateState: {
      type: Function,
    },
    isShowKeysProfiles: {
      type: Boolean,
    },
    isSubmit: {
      type: Boolean,
    },
    errors: {},
  },
  components: {
    BError,
  },
  data() {
    return {
      intercomKeysProfiles: [],
      intercomCodeReverseOrders: [
        { label: this.$t('intercom.code_reverse_order.direct'), value: false },
        { label: this.$t('intercom.code_reverse_order.reverse'), value: true },
      ],
    };
  },
  computed: {
    ...mapState('intercom', ['intercom', 'intercomModel']),
    ...mapGetters('intercom', ['getIntercom', 'getIntercomModel']),
    intercomModel() {
      return this.getIntercomModel;
    },
    intercom: {
      get() {
        return this.getIntercom;
      },
      set(value) {
        this.setIntercom(value);
      },
    },
    keyTypes() {
      if (Number(this.clientId) === 1) {
        return [
          { label: 'SL 1', type: 'sl1' },
          { label: 'SL 3', type: 'sl3' },
          { label: 'SL 1 & SL 3', type: 'sl1_sl3' },
          { label: 'SL 0', type: 'sl0' },
        ];
      } else {
        return [
          { label: 'SL 3', type: 'sl3' },
          { label: 'SL 0', type: 'sl0' },
        ];
      }
    },
    isShowKeyTypes() {
      return !this.intercomModel?.tags?.includes('no_key_types');
    },
  },
  created() {
    this.getIntercomKeysProfiles();
  },
  methods: {
    ...mapMutations('intercom', ['setIntercom']),

    async getIntercomKeysProfiles() {
      await ApiIntercomKeysProfiles.get({
        q: { key_types_eq: this.intercom.settings.key_types },
      })
        .allAsync()
        .then((res) => {
          this.intercomKeysProfiles = res;
        });
    },

    async onInputKeyTypes() {
      await this.getIntercomKeysProfiles();
      const findKey = this.intercomKeysProfiles.filter((profile) => {
        profile.id == this.intercom.settings.intercom_keys_profile_id;
      });
      if (findKey.length > 0) {
        return;
      } else {
        this.intercom.settings.intercom_keys_profile_id = null;
      }
    },
  },
};
</script>

<style lang="scss">
.keys-tab {
  padding: 0 30px 30px 30px;
}
</style>
