<template>
  <div class="range-list card p-3">
    <div class="d-flex justify-content-between align-items-center flex-row">
      <p class="m-0">{{ title }}</p>
      <b-button
        variant="primary"
        class="w-auto h-auto range-list__button range-list__button_add"
        @click="rangeAdd"
      >
        <i class="icon-local-add relay-modal__icon relay-modal__icon_blue" />
      </b-button>
    </div>
    <div
      class="mt-3"
      v-if="
        intercom &&
        Array.isArray(intercom.settings.proxy_schema) &&
        intercom.settings.proxy_schema.length > 0
      "
    >
      <div
        v-for="(item, index) in intercom.settings.proxy_schema"
        :key="index"
        class="d-flex gap-3"
      >
        <b-form-row class="mb-3 flex-grow-1">
          <b-col md="3">
            <BInputLabel
              v-model="item.prefix"
              :label="$t('global.prefix')"
              type="text"
              :state="getState(index, 'prefix')"
              class="mb-0"
            />
            <BError
              v-if="!getIntegerState(index, 'prefix') == false && isSubmit"
              :error="$t('devices.errors.formatter')"
            />
          </b-col>
          <b-col md="4">
            <BInputLabel
              v-model="item.range"
              :label="$t('global.range')"
              type="text"
              :state="getState(index, 'range')"
              class="mb-0"
            />
            <BError
              v-if="!getEmptyState(index, 'range') == false && isSubmit"
              :error="$t('devices.errors.not_empty')"
            />
            <BError
              v-else-if="!getRangeState(index, 'range') == false && isSubmit"
              :error="$t('devices.errors.formatter')"
            />
          </b-col>
          <b-col md="5">
            <BInputLabel
              v-model="item.ip"
              :label="$t('global.ip_address')"
              type="text"
              :state="getState(index, 'ip')"
              class="mb-0"
            />
            <BError
              v-if="!getEmptyState(index, 'ip') == false && isSubmit"
              :error="$t('devices.errors.not_empty')"
            />
            <BError
              v-else-if="!getIpState(index, 'ip') == false && isSubmit"
              :error="$t('devices.errors.formatter')"
            />
          </b-col>
        </b-form-row>
        <b-button
          variant="outline-primary"
          class="range-list__button w-auto"
          v-if="removeBtnVisible"
          @click="rangeRemove(index)"
        >
          <i class="range-list__icon icon-local-trash" />
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex';
import BError from '@/components/base/BError';

export default {
  name: 'IntercomRangeList',
  components: { BError },
  props: {
    notEmpty: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: null,
    },
    proxyErrors: {},
    isSubmit: Boolean,
  },
  computed: {
    ...mapState('intercom', ['intercom', 'intercomModel', 'callMode']),
    ...mapGetters('intercom', ['getIntercom']),
    intercom: {
      get() {
        return this.getIntercom;
      },
      set(value) {
        this.setIntercom(value);
      },
    },

    removeBtnVisible() {
      if (this.notEmpty && this.intercom.settings.proxy_schema?.length == 1) {
        return false;
      } else return true;
    },
  },
  methods: {
    ...mapMutations('intercom', ['addRange', 'removeRange']),
    rangeAdd() {
      this.addRange();
    },
    rangeRemove(index) {
      this.removeRange(index);
    },
    getState(index, field) {
      if (!this.proxyErrors || !this.isSubmit) {
        return undefined;
      }
      return !this.proxyErrors.$each.$iter[index][field].$invalid;
    },
    getEmptyState(index, field) {
      if (!this.proxyErrors || !this.isSubmit) {
        return undefined;
      }
      return !this.proxyErrors.$each.$iter[index][field].required;
    },
    getRangeState(index, field) {
      if (!this.proxyErrors || !this.isSubmit) {
        return undefined;
      }
      return !this.proxyErrors.$each.$iter[index][field].isRange;
    },
    getIpState(index, field) {
      if (!this.proxyErrors || !this.isSubmit) {
        return undefined;
      }
      return !this.proxyErrors.$each.$iter[index][field].ipAddress;
    },
    getIntegerState(index, field) {
      if (!this.proxyErrors || !this.isSubmit) {
        return undefined;
      }
      return !this.proxyErrors.$each.$iter[index][field].isInteger;
    },
  },
};
</script>

<style lang="scss">
@import 'app/javascript/assets/styles/components/variables';

.range-list {
  border-color: #e0e6ec;
  background-color: #e0e6ec;

  &__button {
    border-color: $border-color;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      background: #e0e6ec;
      border-color: #e0e6ec;
    }

    &_add {
      background: transparent;
      border: none;
      padding: 0;
      min-height: unset !important;
    }
  }

  &__icon {
    font-size: 22px;
    color: $color-font-black-black;

    &_blue {
      color: $blue;
    }
  }
}
</style>
