<template>
  <div class="users-autocomplete">
    <div class="dropdown">
      <BInputLabel
        v-model="word"
        @keyup="onKeyUp"
        type="text"
        :label="placeholder"
        ref="word"
        class="user-autocomplete-results-dropdown"
        :is-invalid="isInvalid"
      />
      <div class="close-icon-container">
        <i class="icon-local-times" v-if="!empty_word" @click="wordReset"></i>
      </div>
      <input :value="selected_id" type="hidden" :name="hiddenFieldName" />
      <div v-show="!empty_results" class="dropdown-menu py-0">
        <div
          v-for="result in results"
          :key="result.id"
          @click="choosed(result)"
          :data-id="result.id"
          :data-name="result.serial"
          class="dropdown-item flex-column"
        >
          <div>
            {{ result.short_name }}
            <span class="small text-muted">{{ ` #${result.id}` }}</span>
          </div>
          <span class="small text-muted">
            {{ result.phone || result.email }}
          </span>
        </div>
      </div>
      <slot></slot>
    </div>
  </div>
</template>

<script>
import { isEmpty, isNull, debounce } from 'lodash';
import BInputLabel from '@/components/base/BInputLabel';

export default {
  name: 'adminUsersAutocomplete',
  components: {
    BInputLabel,
  },
  props: {
    hiddenFieldName: String,
    saveFormId: String,
    userData: Object,
    placeholder: String,
    autoSubmit: { type: Boolean, default: false },
    isInvalid: {
      type: Boolean,
      default: false,
    },
    additionalQueryParams: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      selected_id: null,
      word: '',
      results: [],
    };
  },
  computed: {
    empty_results() {
      return isEmpty(this.results);
    },
    empty_word() {
      return this.word < 1;
    },
  },
  mounted() {
    this.initDebounce();
    if (this.userData?.short_name) {
      this.word = this.userData.short_name;
      this.selected_id = this.userData.id;
    }
  },
  methods: {
    choosed(result) {
      this.word = result.short_name;
      this.selected_id = result.id;
      if (this.saveFormId) {
        this.saveForm();
      }
      this.resultsReset();
    },
    onKeyUp() {
      this.debouncedSearch();
    },
    saveForm() {
      if (_.isUndefined(this.saveFormId)) {
        return;
      }

      const vm = this;
      setTimeout(function () {
        $(`#${vm.saveFormId}`).submit();
      }, 100);
    },
    resultsOpen() {
      $('.user-autocomplete-results-dropdown').dropdown('show');
      this.$refs.word.focus();
    },
    resultsClose() {
      $('.user-autocomplete-results-dropdown').dropdown('hide');
      this.$refs.word.focus();
    },
    resultsReset() {
      this.results = [];
    },
    wordReset() {
      this.word = '';
      this.selected_id = null;
      if (this.autoSubmit === true) {
        this.saveForm();
      }
    },
    async getUsers() {
      let qParams = {
        q: {
          name_or_surname_or_patronymic_or_email_or_phone_or_full_name_or_reverse_full_name_cont_any:
            this.word,
        },
      };
      let query = {
        ...qParams,
        ...this.additionalQueryParams,
      };
      this.results = await request2(
        {
          method: 'get',
          url: `/api/backend/admin/autocomplete/users/`,
        },
        query,
      );
    },
    search() {
      if (isEmpty(this.word)) return;
      this.getUsers();
    },
    initDebounce() {
      this.debouncedSearch = debounce(() => {
        this.search();
      }, 200);
    },
  },
  watch: {
    word(value) {
      if (isNull(value) || isEmpty(value)) {
        this.resultsReset();
      }
    },
    results(value) {
      isEmpty(value) ? this.resultsClose() : this.resultsOpen();
    },
  },
};
</script>

<style scoped lang="scss">
@import '../../assets/styles/components/variables';
.users-autocomplete {
  position: relative;
  .close-icon-container {
    padding: 5px;
    cursor: pointer;
    position: absolute;
    right: 13px;
    top: 13px;
  }
  fieldset {
    width: 100%;
  }
  .dropdown-menu {
    overflow-x: auto;
    .dropdown-item {
      border: 1px solid #edeef0;
      height: 50px;
      cursor: pointer;
      display: flex;
      &:hover {
        background: $table-hover-bg;
      }
    }
  }
}
</style>
