<template>
  <b-form-group :label-class="labelClass" v-bind="$attrs">
    <slot ref="slot"></slot>
    <div class="small text-danger" v-if="error">{{ this.$tc("user.edit.email.wrong_format")}}</div>
  </b-form-group>

</template>

<script>
import { BFormGroup } from 'bootstrap-vue';

export default {
  name: 'BFormEmailSlot',
  components: {
    BFormGroup,
  },
  props: {
    value: {},
    hasError: Boolean,
  },
  data() {
    return {
      isValue: false,
      focus: false,
      input: '',
      error: false
    };
  },
  computed: {
    labelClass() {
      return this.isValue || this.focus
          ? 'float-label-active'
          : 'float-label-inactive';
    },
  },
  mounted() {
    this.error = this.hasError
    const input = this.$el.querySelector('input');
    if (input) {
      this.input = input;

      this.input.classList.add('float-input', 'form-control');
      this.isValue = !!this.input.value;

      this.input.onfocus = (event) => {
        this.focus = true;
        this.$emit('focus', event.target.value);
      };

      this.input.oninput = (event) => {
        this.$emit('input', event.target.value);
      };

      this.input.onblur = (event) => {
        this.isValue = !!event.target.value;
        this.focus = false;
        this.$emit('blur', event.target.value);
        this.validateEmail(this.input.value)

      };
    } else {
      const select = this.$el.querySelector('select');
      if (select) {
        this.isValue = true;
      }
    }
  },
  methods: {
    setInputValue(val) {
      this.input.value = val;
      this.isValue = !!String(val);
    },
    validateEmail(val) {
      if (!this.isValue) { return }

      let valid = String(val).match(/^[a-zA-Z0-9_!#$%&'*+/=?`{|}~^.-]+@[a-zA-Z0-9.-]+$/);
      this.error = valid === null || val.length > 70;
    }
  },
  watch: {
    value(val) {
      this.setInputValue(val);
    },
    error(val) {
      this.input.setAttribute('aria-invalid', val);
      const classAction = val ? 'add' : 'remove';
      this.input.classList[classAction]('is-invalid');
      EventBus.$emit('errorUpdate', val)
    },
  },
};
</script>
