<script>
import UserSearch from '@/vue_slim/components/base/UserSearch.vue'
import BarriersAutocomplete from '@/components/autocompletes/BarriersAutocomplete.vue'
import CarsAutocomplete from '@/components/autocompletes/CarsAutocomplete.vue'
import EventsPlatesAutocomplete from '@/components/autocompletes/EventsPlatesAutocomplete.vue'
import SelectSlot from '@/vue_slim/components/base/SelectSlot.vue';
import GeoUnitsAutocomplete from '@/components/autocompletes/geoUnitsAutocomplete';
import RangeDatePickerSlot from '@/vue_slim/components/base/RangeDatePickerSlot.vue';
export default {
  name: "FiltersEventsFormSlim",
  components: { 
    UserSearch,
    BarriersAutocomplete,
    CarsAutocomplete,
    EventsPlatesAutocomplete,
    GeoUnitsAutocomplete,
    SelectSlot,
    RangeDatePickerSlot
  },
  props: {
    userData: {
      type: Object,
      default: {}
    },
    plateData: {
      type: Object,
      default: {}
    },
    barrierData: {
      type: Object,
      default: {}
    },
    clientId: {
      type: Number,
      default: null
    },
    carMarkData: {
      type: Object,
      default: null
    },
    carModelData: {
      type: Object,
      default: null
    },
    geoUnitData: {
      type: Object,
      default: null
    },
    optionCars: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      
    }
  },
  computed: {
    userId() {
      return this.userData?.id ? this.userData.id : null
    },
    geoUnitId() {
      return this.geoUnitData?.id ? this.geoUnitData.id : null
    },
    carData() {
      if (this.carModelData?.id) {
        return this.carModelData
      } else if (this.carMarkData?.id) {
        return this.carMarkData
      }
      return null
    },
    nameCarsAutocomplete() {
      if (this.carModelData?.id) {
        return this.optionCars.model
      } else if (this.carMarkData?.id) {
        return this.optionCars.mark
      }
      return null
    }
  }
}
</script>
